<div class="header flex flex-row items-center pr-5">
  <div class="headline flex flex-row basis-full items-center">
    <i [className]="'icon icon-settings'"></i>
    <h2>Allgemeine Einstellungen</h2>
  </div>
  <ng-container *ngIf="form?.disabled" >
    <app-nx-button (click)="form?.enable(); isEditable = true;"  toolTip="Einstellungen bearbeiten" icon="icon icon-edit" *ngIf="rolesService?.hasRole('framework_settings_manage')"/>
  </ng-container>
</div>
<div class="body">
  <app-settings-form [form]="form" [settings]="settings" [isEditable]="isEditable"></app-settings-form>
  <div class="flex basis-full gap-25 start pr-4">
    <button mat-flat-button (click)="updateProfile()" color="primary"
      [disabled]="!(form?.valid && !form.pristine)">Speichern</button>
    <button mat-flat-button (click)="abortEditProfile()" color="primary" [disabled]="!isEditable">Abbrechen</button>
  </div>
</div>
