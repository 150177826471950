<div class="content disposition" [ngStyle]="{'height': 'calc(100% - 20px)'}">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i [className]="'icon-taskplanner'"></i>
      <h2>Disposition</h2>
    </ng-container>
    <ng-container ngProjectAs="[tools]">
      <app-filter-input className="grey" [showIcon]="false" (filter)=filter($event) [placeholder]="'Aufgabe suchen'" class="pr-2">
      </app-filter-input>
      <app-date-selector class="date-selector" [displayLabel]=false (date)="dateChanged($event)"
        [initialDate]="this.date">
      </app-date-selector>
      <app-nx-button (click)="drawerRight.toggle()" icon="icon icon-options">
      </app-nx-button>
    </ng-container>

  </app-layout-content-title>
  <div class="body full">
    <mat-drawer-container [hasBackdrop]="false" class="container">
      <!-- filter start -->
      <mat-drawer #drawerRight [mode]="'side'" [position]="'end'" class="filter">
        <!-- <h2 class="header">Einstellungen</h2> -->
        <div>
          <h3>Filter</h3>
          <app-location-selection [locations]="locations" [multiple]=true (selectLocation)=selectTasksLocations($event)
          [displayLabel]=true [location]="selectedLocation"/>
          <mat-form-field class="white">
            <mat-label>Typ</mat-label>
            <mat-select [(ngModel)]="taskType" [multiple]="true">
              <mat-option *ngFor="let type of taskTypes" [value]="type.id">
                {{ type.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <app-offset-selector [initialOffset]="selectedPreview" [placeholder]="'Vorausschau'" (offset)="offsetChanged($event)">
          </app-offset-selector> -->
          <!-- <mat-form-field class="full-width white">
            <mat-label>Vorausschau</mat-label>
            <mat-select [placeholder]="'Vorausschau'" [formControl]="offsetFormControl">
              <mat-option *ngFor="let i of values" [value]="i">
                {{i}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
      </mat-drawer>
      <mat-drawer-content class="drawer-content">
        <mat-tab-group animationDuration="0ms" mat-align-tabs="start" mat-stretch-tabs="false" class="full">
          <mat-tab label="Offen" class="full">
            <ng-template matTabContent>
              <app-task-assignment-list-unassigned class="full" [settings]="{'selectedLocation':selectedLocation, 'date':date, 'taskType': taskType, 'filterValue': filterValue}"></app-task-assignment-list-unassigned>
            </ng-template>
          </mat-tab>
          <mat-tab label="Disponiert" class="full">
            <ng-template matTabContent>
              <app-task-assignment-list-assigned class="full" [settings]="{'selectedLocation':selectedLocation, 'date':date, 'taskType': taskType, 'filterValue': filterValue}"></app-task-assignment-list-assigned>
            </ng-template>
          </mat-tab>
          <mat-tab label="Abgeschlossen" class="full">
            <ng-template matTabContent>
              <app-task-assignment-list-completed class="full tab" [settings]="{'selectedLocation':selectedLocation, 'date':date, 'taskType': taskType, 'filterValue': filterValue}"></app-task-assignment-list-completed>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </mat-drawer-content>
    </mat-drawer-container>

  </div>
