import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel, LoadingService, SettingsService } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss']
})
export class SettingsFormComponent {

  @Input() settings: any;
  @Input() form: UntypedFormGroup;
  @Input() isEditable = true;
  @Input() locationId: string;
  uploading = false;
  public value = '';
  public isFile = false;
  public fileName = '';
  public fileApi = environment.fileApi;
  errorMessage: string | null = null;
  constructor(
    private fb: UntypedFormBuilder,
    public settingsService: SettingsService,
    private http: HttpClient,
    private loadingService: LoadingService,
    public dialog: MatDialog,
  ) {}

  isCategory(category: any): boolean {
    for(let setting of category?.settingDescriptions) {
        if( setting?.type === 'String' || setting?.type === 'Boolean' || setting?.type === 'Integer' || setting?.type === 'File') {
          return true;
        }
    }
    return false;
  }

  reset(field: any) {
    this.form.controls[field?.key]?.setValue(field.defaultValue);
    this.form.controls[field?.key]?.markAsPristine();
    if (this.locationId) {
      this.settingsService.resetSettingLocation(
        field?.key,
        this.locationId,
        this.returnCallback()
      );
    } else {
      this.settingsService.resetSetting(
        field?.key,
        this.returnCallback()
      );
    }

  }

  returnCallback(): void {
    console.log('saved');
 }
  fileChangeEvent(event: any, field: any): void {
    this.loadingService.setLoading(true);
    if (this.uploading || event.target?.files?.length < 1) {
      this.loadingService.setLoading(false);
      return;
    }

    const img = new Image();
    const objectUrl = URL.createObjectURL(event.target?.files[0]);
    img.onload = () => {
      if (img.width >= field?.settings?.minWidth && img.height >= field?.settings?.minHeight) {
        this.errorMessage = null;
        this.uploadFile(event, field);
      } else {
        this.loadingService.setLoading(false);
        this.errorMessage = `Die Bildgröße sollte mindestens ${field?.settings?.minWidth}x${field?.settings?.minHeight}px.`;
        return;
      }
      URL.revokeObjectURL(objectUrl);
    };
    img.onerror = () => {
      this.errorMessage = 'Invalid Datei.';
      URL.revokeObjectURL(objectUrl);
      this.loadingService.setLoading(false);

      return;
    };
    img.src = objectUrl;
  }

uploadFile(event: any, field: any)  {
  let extension = event.target?.files[0].type;
  extension = extension.replace(/(.*)\//g, '');
  const uuid = uuidv4();
  this.value = uuid;
  this.uploading = true;
  const params = new HttpParams({
    fromObject: {
      fileName:  event.target?.files[0].name
    }
  });
  console.log('value', this.value);

  let settingInput = {
    "key": field?.key,
    "value": this.value,
    "locationId": this.locationId
  };
  console.log('Settings Input', settingInput);
  this.settingsService.updateSettings(
    settingInput,
    ((result: any) => {
        let ownerId = result?.data?.updateSetting?.id;
        console.log('result', result?.data?.updateSetting?.id);
        const url = environment.fileApi + '/file/upload/?ownerId=' + ownerId;
        const formData: FormData = new FormData();
        formData.append('file', event.target?.files[0], this.value + '.' + extension);
        this.http
          .post(url,formData, {params})
          .toPromise()
          .then((data) => {
            console.log('Upload Success', data);
            this.returnUpload();
            this.uploading = false;
          })
          .catch((error) => {
            console.log('Upload Error', error);
            // this.returnUpload();
          });
    })
  );

}
returnUpload(): void {
  setTimeout(() => {
    this.uploading = false;
    window.location.reload();
    this.loadingService.setLoading(false);
  }, 1000);
}

confirmDialog(field: any): void {
  const message = 'Möchtest Du die Datei wirklich löschen?';
  const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: dialogData,
    autoFocus: false
  });

  dialogRef.afterClosed().subscribe((dialogResult: any) => {
    if (dialogResult) {
      this.loadingService.setLoading(true);
      this.deleteFile(field);
    }
  });
}

deleteFile(field : any) {
  this.loadingService.setLoading(true);
  console.log('field', field);
  let settingInput = {
    "key": field?.key,
    "value": '',
    "locationId": this.locationId
  };
  console.log('Settings Input', settingInput);
  this.settingsService.updateSettings(
    settingInput,
    ((result: any) => {
      window.location.reload();
      this.loadingService.setLoading(false);
    }))
}
}
