<div class="content">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i [className]="'icon-dashboard'"></i>
      <h2>Dashboard</h2>
    </ng-container>
    <ng-container ngProjectAs="[tools]">
     <app-date-selector class="date-selector" className="white" [displayLabel]=false (date)="dateChanged($event)"
            [initialDate]="this.date">
      </app-date-selector>
      <app-nx-button [matMenuTriggerFor]="matmenu" [text]="selectedLocation?.label ? selectedLocation?.label : selectedLocation?.name" toolTip="Niederlassugen" [icon]="'icon-open-down'">
      </app-nx-button>
      <mat-menu #matmenu="matMenu">
        <button mat-menu-item *ngFor="let item of locations" (click)="selectedLocation= item; selectLocation()">
            <span>{{item.label ? item.label : item.name}}</span>
        </button>
      </mat-menu>
    </ng-container>

  </app-layout-content-title>
  <div class="full">
    <div class="grid grid-cols-1 w-full h-max content-start place-content-start self-start">
      <div class="w-full h-max">
        <div class="grid 2xl:grid-cols-3 grid-rows-1 gap-3 w-full h-max content-start  self-start">
          <div class="w-full h-max flex details">
            <app-dashboard-tour-list [filter]="{'type':'planned', 'location': selectedLocation?.id }" class="basis-full w-full" [selectedLocation]="selectedLocation?.id"
              [setDate]="date" />
          </div>
          <div class="w-full h-max flex details">
            <app-dashboard-tour-list [filter]="{'type':'running', 'location': selectedLocation?.id }" class="basis-full w-full" [selectedLocation]="selectedLocation?.id"
              [setDate]="date" />
          </div>
          <div class="w-full h-max flex details">
            <app-dashboard-tour-list [filter]="{'type':'finished', 'location': selectedLocation?.id }" class="basis-full w-full" [selectedLocation]="selectedLocation?.id"
              [setDate]="date" />
          </div>
        </div>
      </div>
      <div class="row-start-2 w-full h-max">
        <div class="grid 2xl:grid-cols-3 grid-rows-1 gap-3 w-full h-max content-start  self-start">
          <div class="w-full h-max flex details mt-2">
            <app-dashboard-task-list [filter]="{'type':'out', 'location': selectedLocation?.id }" class="basis-full w-full"
              [selectedLocation]="selectedLocation?.id" [setDate]="date" />
          </div>
          <div class="w-full h-max flex details mt-2">
            <app-dashboard-task-list [filter]="{'type':'in', 'location': selectedLocation?.id }" class="basis-full w-full"
              [selectedLocation]="selectedLocation?.id" [setDate]="date" />
          </div>
          <div class="w-full h-max flex details mt-2">
            <app-dashboard-task-list [filter]="{'type':'completed', 'location': selectedLocation?.id }"
              class="basis-full w-full" [selectedLocation]="selectedLocation?.id" [setDate]="date" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
