import { Apollo } from 'apollo-angular';
import * as fromGraphQl from './graphql';
import { NXDataSource } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';
import { LocationEntity } from '../../../shared-module/shared/entities/location/location';

export class LocationDataSource extends NXDataSource<LocationEntity> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.LOCATIONS_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.LocationsQueryResponse): void {
    super.setSubject(
      data.locations?.content ? data.locations?.content : [],
      data.locations?.totalElements ? data.locations.totalElements : 0
    );
  }

  loadLocations(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
