<div class="body">
  <div class="block-header flex items-center">
    <h2 class="flex items-center" *ngIf="type === 'planned'">
      <i class="icon icon-tour-scheduled"></i>&nbsp;
      <span class="heading">Geplante Touren</span>&nbsp; ({{dataSource?.count}})
    </h2>
    <h2 class="flex items-center" *ngIf="type === 'running'">
      <i class="icon icon-tour-started"></i>&nbsp;
      <span class="heading">Aktive Touren</span>&nbsp; ({{dataSource?.count}})
    </h2>
    <h2 class="flex items-center" *ngIf="type === 'finished'">
      <i class="icon icon-tour-finished"></i>&nbsp;
      <span class="heading">Abgeschlossene Touren</span>&nbsp; ({{dataSource?.count}})
    </h2>
  </div>
  <div [hidden]="dataSource?.loading| async" class="full">
    <mat-table [dataSource]="dataSource" matSort class="full">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="table_column">Tour</mat-header-cell>
        <mat-cell *matCellDef="let tour" class="table_column items-center">
          <span class="noWrap"
          #name
          [matTooltipDisabled]="isEllipsisActive(name)"
          matTooltipShowDelay="250"
          matTooltip="{{tour.name}}">
          <a [routerLink]="['../tour-planner/detail/', tour.id ]" class="jump">
            {{tour.name}} <i [className]="'icon icon-jump-to'"></i></a>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="resource">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="table_column">Fahrzeug</mat-header-cell>
        <mat-cell *matCellDef="let tour" class="table_column">
          <span class="noWrap"
          #resource
          [matTooltipDisabled]="isEllipsisActive(resource)"
          matTooltipShowDelay="250"
          matTooltip="{{tour.resource?.name}}">
          {{tour.resource?.name? tour.resource?.name: '-'}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="assignee">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="table_column">Fahrer</mat-header-cell>
        <mat-cell *matCellDef="let tour" class="table_column">
          <span class="noWrap"
          #resource
          [matTooltipDisabled]="isEllipsisActive(resource)"
          matTooltipShowDelay="250"
          matTooltip="{{tour.getAssigneeName()}}">
          {{tour.getAssigneeName()}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="scheduledDepartureDateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="table_column">Startzeit</mat-header-cell>
        <mat-cell *matCellDef="let tour" class="table_column">
          {{tour.scheduledDepartureDateTime?  (tour.scheduledDepartureDateTime | date:'HH:mm'): '-'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actualDepartureDateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="table_column">Startzeit</mat-header-cell>
        <mat-cell *matCellDef="let tour" class="table_column">
          {{tour.actualDepartureDateTime? (tour.actualDepartureDateTime | date:'HH:mm'): '-'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actualArrivalDateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="table_column">Endzeit</mat-header-cell>
        <mat-cell *matCellDef="let tour" class="table_column">
          {{tour.actualArrivalDateTime? (tour.actualArrivalDateTime | date:'HH:mm'): '-'}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"  class="table_header">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="table_row border-b">
      </mat-row>
      <div *matNoDataRow class="no-data flex items-top justify-center">
        <p>keine Touren vorhanden</p>
      </div>
    </mat-table>
  </div>
  <div *ngIf="dataSource?.loading | async" class="spinner">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
  <div class="footer">
    <div class="pagination">
      <mat-paginator [length]="dataSource?.totalElements" [pageSize]="10" [pageIndex]="0"
        [pageSizeOptions]="[10, 15, 20]" aria-label="Select page" class="paginator"
        [hidden]="!dataSource?.totalElements || dataSource?.totalElements< 11"></mat-paginator>
    </div>
  </div>
</div>
