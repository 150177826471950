import { Component, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';


@Component({
  selector: 'app-task-assignment-list-assigned',
  templateUrl: './task-assignment-list-assigned.component.html',
  styleUrls: ['./task-assignment-list-assigned.component.scss']
})
export class TaskAssignmentListAssignedComponent {

  public filterValue = '';
  public date = new Date();
  public selectedLocation: string[];
  public taskType:string[] = [];
  public displayedColumns: String[] =
    [
      'select', 'type', 'order.number', 'dueDateTimePeriod', 'description', 'customer', 'order.location.name', 'info', 'assignment'
    ];
  public states = ['ASSIGNED' as TaskState];

  @Input() set settings(settings: any) {
    this.selectedLocation = settings?.selectedLocation;
    this.date = settings?.date;
    this.taskType = settings?.taskType;
    this.filterValue = settings?.filterValue;
  }


  constructor(
    private apollo: Apollo,
  ) {
  }
}
