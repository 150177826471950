import { gql } from 'apollo-angular';

export const UPDATE_RESOURCE_MUTATION = gql`
mutation updateResource($resourceInput: ResourceInput) {
  updateResource(input: $resourceInput) {
    id
  }
}
`;

export const CREATE_RESOURCE_MUTATION = gql`
mutation createResource($resourceInput: ResourceInput) {
  createResource(input: $resourceInput) {
    id
  }
}
`;

export const DELETE_RESOURCE_MUTATION = gql`
mutation deleteResource($id: ID) {
  deleteResource(id: $id) {
    id
  }
}
`;
