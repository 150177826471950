<div class="">
    <div class="headlineRow flex flex-row">
        <div class="basis-1/3 label"></div>
        <div class="basis-1/3 out">
            <div class="text-right p-2">
                <ng-container *ngIf="_leftProtocol; else noProtocol">
                    <span class="font-bold">{{_leftProtocol?.formControlTemplate?.settings?.label}}</span><br>
                    <ng-container *ngIf="_leftProtocol?.state === 'COMPLETED' || _leftProtocol?.state === 'ARCHIVED' || _leftProtocol?.state === 'UPLOADING'; else notCompleted">
                        {{_leftProtocol.completedDateTime | date:'dd.MM.YYYY HH:mm'}}
                        / {{_leftProtocol.completedBy.firstName}} {{_leftProtocol.completedBy.lastName}}
                        <ng-container *ngIf="_leftProtocol?.task?.assignment"><br /><app-task-assignment-strategy class="assignmnet" [assignment]="_leftProtocol?.task?.assignment"/></ng-container>
                        <span *ngIf="_leftProtocol?.state === 'UPLOADING'" class="uploading"><br />Noch nicht vollständig hochgeladen - bitte mobiles Gerät prüfen - <a href="https://nexato.crunch.help/de/troubleshooting/offene-uploads" target="_blank">Hilfe<i [className]="'icon-jump-to'"></i></a>.</span>
                    </ng-container>
                    <ng-template #notCompleted>
                        {{_leftProtocol?.formControlTemplate?.settings?.notCompletedLabel}}
                    </ng-template>
                </ng-container>
            </div>
        </div>
        <div class="basis-1/3 in">
            <div class="text-right p-2">
                <ng-container *ngIf="_rightProtocol; else noProtocol">
                    <span class="font-bold">{{_rightProtocol?.formControlTemplate?.settings?.label}}</span><br>
                    <ng-container *ngIf="_rightProtocol?.state === 'COMPLETED' || _rightProtocol?.state === 'ARCHIVED' || _rightProtocol?.state === 'UPLOADING'; else notCompleted">
                        {{_rightProtocol.completedDateTime | date:'dd.MM.YYYY HH:mm'}}
                        / {{_rightProtocol.completedBy.firstName}} {{_rightProtocol.completedBy.lastName}}
                        <ng-container *ngIf="_rightProtocol?.task?.assignment"><br /><app-task-assignment-strategy class="assignmnet" [assignment]="_rightProtocol?.task?.assignment"/></ng-container>
                        <span *ngIf="_rightProtocol?.state === 'UPLOADING'" class="uploading"><br />Noch nicht vollständig hochgeladen - bitte mobiles Gerät prüfen - <a href="https://nexato.crunch.help/de/troubleshooting/offene-uploads" target="_blank">Hilfe<i [className]="'icon-jump-to'"></i></a>.</span>
                    </ng-container>
                    <ng-template #notCompleted>
                        {{_rightProtocol?.formControlTemplate?.settings?.notCompletedLabel}}
                    </ng-template>
                </ng-container>
            </div>      
        </div>
        <ng-template #noProtocol>
            <span class="font-bold">-</span>
        </ng-template>
    </div>
    <ng-container *ngIf="referenceFormTemplate">
        <div class="bodyRow">
            <ng-container *ngFor="let referenceSectionControlTemplate of referenceBodyControlTemplates; let i = index">
            <div class="sectionRow" *ngIf="shouldShowRow(leftBodyControlTemplates?.[i], rightBodyControlTemplates?.[i])">
                <div class="sectionHeaderRow font-bold p-2 border-b border-t border-solid border-gray-300 backgroundColor">{{referenceSectionControlTemplate?.settings?.label}}</div>
                <ng-container *ngFor="let referenceControlTemplate of referenceSectionControlTemplate?.controls; let j = index">
                <div *ngIf="shouldShowRow(leftBodyControlTemplates?.[i]?.controls?.[j], rightBodyControlTemplates?.[i]?.controls?.[j])"
                    class="controlRow border-b border-solid border-gray-300 flex flex-row last:border-b-0">
                    <div class="basis-1/3 label">
                        <app-protocol-control-label-view [controlTemplate]="referenceControlTemplate">
                        </app-protocol-control-label-view>
                    </div>
                    <div class="basis-1/3 left value">
                        <app-protocol-control-value-view [controlTemplate]="leftBodyControlTemplates?.[i]?.controls?.[j]">
                        </app-protocol-control-value-view></div>
                    <div class="basis-1/3 right value">
                        <app-protocol-control-value-view [controlTemplate]="rightBodyControlTemplates?.[i]?.controls?.[j]">
                        </app-protocol-control-value-view>
                    </div>
                </div>
                </ng-container>
            </div>
            </ng-container>
        </div>
        <div class="completionRow">
            <ng-container *ngFor="let referenceSectionControlTemplate of referenceCompletionControlTemplates; let i = index">
            <div class="sectionRow" *ngIf="shouldShowRow(leftCompletionControlTemplates?.[i], rightCompletionControlTemplates?.[i])">
                <div class="sectionHeaderRow font-bold p-2 border-b border-t border-solid border-gray-300 backgroundColor">{{referenceSectionControlTemplate?.settings?.label}}</div>
                <ng-container *ngFor="let referenceControlTemplate of referenceSectionControlTemplate?.controls; let j = index">
                <div *ngIf="shouldShowRow(leftCompletionControlTemplates?.[i]?.controls?.[j], rightCompletionControlTemplates?.[i]?.controls?.[j])"
                    class="controlRow border-b border-solid border-gray-300 flex flex-row last:border-b-0">
                    <div class="basis-1/3 label">
                        <app-protocol-control-label-view [controlTemplate]="referenceControlTemplate">
                        </app-protocol-control-label-view>
                    </div>
                    <div class="basis-1/3 out">
                        <app-protocol-control-value-view [controlTemplate]="leftCompletionControlTemplates?.[i]?.controls?.[j]">
                        </app-protocol-control-value-view></div>
                    <div class="basis-1/3 in">
                        <app-protocol-control-value-view [controlTemplate]="rightCompletionControlTemplates?.[i]?.controls?.[j]">
                        </app-protocol-control-value-view>
                    </div>
                </div>
                </ng-container>
            </div>
            </ng-container>
        </div>
    </ng-container>
</div>
