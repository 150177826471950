import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';

import { Container, LoadingService } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';
import { Resource, ResourceInput } from '../../shared/entities/resource/resource';
import { ResourceService } from '../../shared/services/resource/resource.service';
import * as fromGraphQl from './graphql';
import { LocationEntity, LocationInput } from 'src/app/shared-module/shared/entities/location/location';

@Component({
  selector: 'app-resource-dialog',
  templateUrl: './resource-dialog.component.html',
  styleUrls: ['./resource-dialog.component.scss'],
  providers: [],
})
export class ResourceDialogComponent
  extends Container<fromGraphQl.ResourceQueryResponse>
  implements OnInit, OnDestroy
{
  // general
  public resource: Resource;
  public id: string;

  // form
  public resourceFormGroup: UntypedFormGroup;
  public functions: any[];
  public locations: LocationEntity[]

  constructor(
    public dialog: MatDialog,
    apollo: Apollo,
    private resourceService: ResourceService,
    public dialogRef: MatDialogRef<ResourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    loadingService: LoadingService,
    private fb: UntypedFormBuilder
  ) {
    super(apollo, loadingService, fromGraphQl.RESOURCE_QUERY, environment);
  }

  ngOnInit(): void {
    if (this.data?.id) {
      this.id = this.data.id;
    }

    this.initTypes();

    // load resource (if we have one) and load entity form
    if(this.id){
      this.subscribeToQuery({
        id: this.id,
      });
    }
    this.initializeForm();
  }

  initTypes(): void {
    this.functions = [ {
      name: 'Transport',
      value: 'TRANSPORT'
    }
    ];
  }

  initializeForm(): void {
    this.resourceFormGroup = this.fb.group({
      name: [null, [Validators.required]],
      number: [null, [Validators.required]],
      functions: [null],
      location: [null]
    });

    if(this.id) {
      this.resourceFormGroup.get('number')?.disable();
    }
  }

  handleSubscriptionResult(data: fromGraphQl.ResourceQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data) {
      this.queryRef.stopPolling();
      // if(data.form){
      //   this.formArray = this.formBuilder.build(data.form) as NxFormArray;
      // }
      this.locations = data?.locations;
      if (data.resource) {
        this.resource = new Resource(data.resource);
        this.resourceFormGroup.patchValue(data.resource);
      }

      let key = 'functions';
      if (this.resource?.functions) {
        this.resourceFormGroup.controls[key].setValue(this.resource.functions);
      }
      key = 'location';
      if (this.resource?.location) {
      this.resourceFormGroup.controls[key].setValue(this.resource?.location?.id);
      }

    }
    // throw new Error('Method not implemented.');
  }

  saveResource(): void {
    this.loadingService.setLoading(true);
    if (this.id) {
      this.resourceService.updateResource(
        this.prepareResourceModel(),
        this.returnCallback()
      );
    } else {
      this.resourceService.createResource(
        this.prepareResourceModel(),
        this.returnCallback()
      );
    }
  }

  returnCallback(): void {
    this.loadingService.setLoading(false);
    setTimeout(() => {
      this.dialogRef.close({
        abort: false,
        saved: true,
      });
    }, 500);
  }

  prepareResourceModel(): ResourceInput {
    const resourceModel = new ResourceInput();
    // set the id, if we have one
    if (this.id) {
      resourceModel.id = this.id;
    }
    // set attributes from the input
    const formModel = this.resourceFormGroup.value;
    resourceModel.name = formModel?.name;
    resourceModel.number = formModel?.number;
    resourceModel.functions = formModel?.functions;
    resourceModel.location = formModel?.location? new LocationInput({id: formModel?.location}): null;

    // copy other attributes, that cannot be filled out by the user
    // no resource available, when we create the resource
    if(this.resource) {
      resourceModel.customAttributes = this.getCustomAttributeInput(this.resource?.customAttributes);
      resourceModel.externalId = this.resource?.externalId; // the overwrite durig update should be
      // prevented on the backend, too - but just to make sure
    }

    console.log('To svae', resourceModel);

    return resourceModel;
  }

  getCustomAttributeInput(customAttributes: any[]){
    let customAttributesInputs = [];
    if(customAttributes) {
      for(let customAttribute of customAttributes) {
        let customAttributeInput = {
          id: customAttribute?.id,
          key: customAttribute?.customAttributeDefinition?.id,
          value: customAttribute?.value,
          ownerId:customAttribute?.ownerId
        };
        customAttributesInputs.push(customAttributeInput);
      }
    }

    return customAttributesInputs;
  }

}
