import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoadingService, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { Subscription, debounceTime, distinctUntilChanged, merge, tap } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { Order } from '../../../order-module/shared/entities/order/order';
import { ResourceDialogComponent } from '../../components/resource-dialog-component/resource-dialog.component';
import { ResourceDataSource } from './resourceDataSource';

interface DynmaicTableComponentState{
  displayedColumns: string[],
}
@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss']
})
export class ResourcesListComponent implements OnInit, OnDestroy, AfterViewInit{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Order>;
  @ViewChild('drawerRight') drawerRight: any;

  public name = '';
  public number = '';
  resourceNameControl = new UntypedFormControl();
  resourceNameControlSub: Subscription;
  resourceNumberControl = new UntypedFormControl();
  resourceNumberControlSub: Subscription;
  public location: LocationEntity;


  public displayedColumns: string[] = [
    'number', 'name', 'functions', 'location'
  ];

  public dataSource: ResourceDataSource;

  constructor(
    private router: Router,
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    public rolesService: RolesService
  ) {
    let ng = this;
    this.setTableDataSource();
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect(this.table);
    this.resourceNameControlSub?.unsubscribe();
    this.resourceNumberControlSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
    this.resourceNameControlSub = this.resourceNameControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.name = term;
        this.selectionChanges();
      });
    this.resourceNumberControlSub = this.resourceNumberControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.number = term;
        this.selectionChanges();
      });
  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchResources()))
      .subscribe();
  }

  setTableDataSource(): void{
    this.dataSource = new ResourceDataSource(this.apollo);
    this.dataSource.loadresources({
      number: 0,
      size: 15,
      sortProperty: 'name',
      sortDirection: 'asc',
      locationId : this.location
    });
  }

  openObject(resource: any): void {
    this.router.navigate(['./resources/resource/' + resource.id]);
  }


  refetchResources(): void {
    const name = this.name ? this.name : '';
    const resourceNumber = this.number ? this.number : '';
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 15,
      sortProperty: this.sort?.active ? this.sort.active : 'name',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      name,
      resourceNumber,
      locationId : this.location
    });
  }

  selectionChanges() {
    this.paginator.pageIndex = 0
    this.refetchResources();
  }

  createResource(): void {
    const dialogRef = this.dialog.open(ResourceDialogComponent, {
      width: '760px',
      data: {
        locations: this.dataSource?.locations
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.saved) {
        this.refetchResources();
        this.loadingService.setLoading(false);

      }
    });
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  selectLocation($event: any){
    this.location = $event;
    this.refetchResources();
  }
}
