<h1 mat-dialog-title>
  Adresse {{ address ? 'bearbeiten' : 'hinzufügen'}}</h1>

<mat-dialog-content [formGroup]="addressForm">
  <div class="white flex basis-full flex-col px-5">
    <mat-form-field class="flex basis-full" *ngIf="showImportedAddress">
      <mat-label>Adresse (importiert)</mat-label>
      <input matInput placeholder="Adresse (importiert)" disabled [value]="address?.externalAddress">
    </mat-form-field>
    <app-address-autocomplete (addressSelected)="addressSelected($event)"></app-address-autocomplete>
  </div>
<div class="white flex flex-row gap-4 px-5">
  <div class="basis-1/3 flex flex-col">
    <h2 class="">Ausgewählte Adresse:</h2>
    <ng-container *ngIf="this.addressForm?.errors && !this.addressForm?.pristine">
      <div class="error">{{showError(this.addressForm.errors)}}</div>
    </ng-container>
    <mat-form-field class="" *ngIf="showNameInput">
      <mat-label>Addressname</mat-label>
      <textarea matInput placeholder="Addressname" formControlName="name"></textarea>
    </mat-form-field>
    <mat-form-field class="" *ngIf="showNoteInput">
      <mat-label>Adresszusatz</mat-label>
      <textarea matInput placeholder="Adresszusatz" formControlName="note"></textarea>
    </mat-form-field>
    <div class="flex flex-row gap-5" *ngIf="!addressForm?.get('coordinatesOnly')?.value">
      <mat-form-field class="basis-2/3">
        <mat-label>Straße</mat-label>
        <input matInput placeholder="" formControlName="street">
      </mat-form-field>
      <mat-form-field class="houseNumber" class="basis-1/3" [attr.disabled]="true">
        <mat-label>Nummer</mat-label>
        <input matInput placeholder="Nummer" formControlName="houseNumber" >
      </mat-form-field>
    </div>
    <div class="flex basis-full gap-5" *ngIf="addressForm?.get('coordinatesOnly')?.value">
      <mat-form-field class="flex basis-1/2">
        <mat-label>Breitengrad</mat-label>
        <input matInput placeholder="Lat" formControlName="lat">
      </mat-form-field>
      <mat-form-field class="flex basis-1/2">
        <mat-label>Längengrad</mat-label>
        <input matInput placeholder="Lng" formControlName="lng">
      </mat-form-field>
    </div>
    <div class="flex basis-full gap-5">
      <mat-form-field class="flex basis-2/3">
        <mat-label>Stadt</mat-label>
        <input matInput placeholder="Stadt" formControlName="city">
      </mat-form-field>
      <mat-form-field class="flex basis-1/3" >
        <mat-label>Postleitzahl</mat-label>
        <input matInput placeholder="PLZ" formControlName="postalCode">
      </mat-form-field>
    </div>
    <div class="flex basis-full gap-5">
      <mat-form-field class="flex basis-full">
        <mat-label>Land</mat-label>
        <input matInput placeholder="Land" formControlName="country">
      </mat-form-field>
    </div>
    <mat-slide-toggle *ngIf="allowCoordinatesOnly" formControlName="coordinatesOnly">nur Koordinaten</mat-slide-toggle>

  </div>
  <div
    #mapContainer class="basis-2/3 here-map">
  </div>
</div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="save()" [disabled]="!addressForm?.valid || addressForm?.pristine">Speichern</button>
  <button mat-button (click)="abort()">Abbrechen</button>
  </mat-dialog-actions>
