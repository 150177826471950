    <mat-accordion multi="true" class="tour-planning">

      <mat-expansion-panel
        class="mat-elevation-z0"
        #panel
        [expanded]="checkOpen(tour)"
        (afterExpand)="opened(tour.id)"
        (afterCollapse)="closed(tour.id)"
        [disabled]="false"
        [ngClass]="{ empty : tour?.tasks?.length === 0 }"
        *ngFor="let tour of _tours; let last = last; trackBy: trackByIndex; let i = index" [ngClass]="tour?.state"
        (opened)="panelOpenState[i] = true" (closed)="panelOpenState[i] = false"
        hideToggle>
        <!-- (mouseenter)="mouseEnter($event, panel, tour.id)"
          cdkDropListSortingDisabled
        (mouseleave)="mouseLeave($event, panel, tour.id)" -->
        <mat-expansion-panel-header
          class="ignore-elements disableRipple"
          cdkDropList
          [id]="'tourHeader'"
          [cdkDropListSortPredicate]="tourDropListSortPredicate"
          [cdkDropListData]="tour"
          [cdkDropListEnterPredicate]="tourDropPredicate"
          (cdkDropListDropped)="dropOnExpansionPanelHeader($event); this.panelOpened.emit(tour?.id);"

          (cdkDragStarted)=dragStarted($event)
          (cdkDragReleased)=dragReleased($event)
          [collapsedHeight]="'36px'"
          [expandedHeight]="'36px'"
          [ngClass]="'tour?.state'">
          <div class="tourHeader row flex flex-row items-center" [ngClass]="tour.state">
            <div class="basis-12 py-1">
              <i [className]="tour.indicator()"></i>
            </div>
            <div class="tourLink basis-28 py-1 items-center flex">
              <span class="noWrap name"
                #text
                [matTooltipDisabled]="isEllipsisActive(text)" matTooltipPosition="left"
                  matTooltipShowDelay="250"
                matTooltip="{{tour.name}}">
              <a [routerLink]="['/tour-planner/detail/', tour.id ]" class="jump">
                {{tour.name}}<i [className]="'icon icon-jump-to'"></i></a></span>
            </div>
            <div class="startEndTime basis-14 py-1 ">
              <div>{{tour?.effectiveDepartureDateTime ? (tour?.effectiveDepartureDateTime | date:'HH:mm')  : '--:--'}}</div>
              <div>{{tour?.effectiveArrivalDateTime ? (tour?.effectiveArrivalDateTime | date:'HH:mm') : '--:--'}}</div>
            </div>
            <div class="driver basis-9/12 py-1">
              <span class="noWrap driver"
              #driver1
              [matTooltipDisabled]="isEllipsisActive(driver1)"
              matTooltipShowDelay="250"
              matTooltip="{{sortBy === 'resource'? tour?.getAssigneeName() : tour?.resource?.name? tour?.resource?.name: ''}}">
              {{sortBy === 'resource'? tour?.getAssigneeName() : tour?.resource?.name? tour?.resource?.name: ''}}</span>
            </div>
            <div class="flex flex-row items-center justify-end">
              <i [className]="'icon icon-info'" (click)="$event.stopPropagation();" [matTooltip]="tour?.note? tour.note : 'Kein Tour-Hinweis hinterlegt'" [ngClass]="tour?.note? 'note' :'nonote'"></i>
              <i [className]="'icon icon-settings'" (click)="$event.stopPropagation();openSettings(tour)" [ngClass]="tour?.state !== 'FINISHED'? '' :'nonote'" *ngIf="rolesService?.hasRole('nexcore_tour_update')"
              [matTooltip]="'Tour-Einstellungen'"></i>
              <i [className]="'icon icon-open-down'" *ngIf="!panelOpenState[i]"></i>
              <i [className]="'icon icon-close-up'" *ngIf="panelOpenState[i]"></i>
            </div>

          </div>

        </mat-expansion-panel-header>

        <!-- Task -->
        <div
          cdkDropList
          [id]="'toursTaskList'"
          (cdkDropListDropped)="dropInsideTourList($event)"
          [cdkDropListData]="tour"
          [cdkDropListSortPredicate]="taskDropListSortPredicate"
          [cdkDropListEnterPredicate]="taskDropListEnterPredicate"
          [cdkDropListDisabled]="false"
          class="tasks  basis-full">
          <div class="test py-2" *ngIf="tour?.tasks?.length === 0">Keine Aufträge geplant.</div>
          <div task-row class="flex flex-row task items-center basis-full"
          *ngFor="let task of tour?.tasks; last as isLast; first as isFirst; let i = index"
            cdkDrag
            (cdkDragStarted)=dragStarted($event)
            (cdkDragReleased)=dragReleased($event)
            [cdkDragData]=task
            [cdkDragDisabled]=dragDisabled(task)
            [class.done]="task.state === 'done'"
            [class.last]="isLast"
            [task]=task>
          </div>
          <!-- [task]=task -->
        </div>
      </mat-expansion-panel>

    </mat-accordion>
