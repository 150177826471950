import { gql } from 'apollo-angular';

export const COMPLETE_TASK_MUTATION = gql`
mutation completeTask($taskId: UUID, $formModelInput: FormModelInput) {
  completeTask(taskId: $taskId, formModelInput: $formModelInput){
    id
  }
}
`;

export const ASSIGN_TASK_MUTATION = gql`
mutation assignTask($taskAssignmentInput: TaskAssignmentInput) {
  assignTask(taskAssignmentInput: $taskAssignmentInput){
    id
  }
}
`;

export const UNASSIGN_TASKS_MUTATION = gql`
mutation unassignTasks($taskIds: [UUID]) {
  unassignTasks(taskIds: $taskIds){
    id
  }
}
`;
