import { gql } from 'apollo-angular';
import { Resource } from '../../shared/entities/resource/resource';

export const RESOURCE_QUERY = gql`
query getResourceQuery(
  $resourceId: UUID, $entity: String
)
{
  resource: getResource(
    id: $resourceId
  )
  {
    id
	  externalId
    text
    number
	  name
    functions
    customAttributes {
      id
      name
      value
      customAttributeDefinition {
        id
      }
      customAttributeSection {
        id
      }
    }
    location {
      id
      name
    }
  }
  customAttributeSections: getCustomAttributeSections(filter: {
    entity: $entity
  }
    ) {
      id
      label
      index
      customAttributeDefinitions {
        id
        type
        label
        index
        key
      }
    }
}
`;


export interface ResourceQueryResponse {
  resource: Resource;
  loading: boolean;
  customAttributeSections: any;
}
