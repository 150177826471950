import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as fromGraphQl from './graphql';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Container, LoadingService, RolesService, SettingsService } from '@nexato/nx-core-module';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent
  extends Container<fromGraphQl.SettingsQueryResponse>
  implements OnInit
{
  public terms: boolean;
  public settings: any;
  termsControl = new UntypedFormControl();
  formCtrlSub: any;
  form: UntypedFormGroup;
  isEditable = false;

  constructor(
    apollo: Apollo,
    loadingService: LoadingService,
    private http: HttpClient,
    public rolesService: RolesService,
    public settingsService: SettingsService,
    private fb: UntypedFormBuilder,
  ) {
    super(apollo, loadingService, fromGraphQl.SETTINGS_QUERY, environment);

  }

  ngOnInit(): void {
    this.subscribeToQuery();
  }
  handleSubscriptionResult(data: fromGraphQl.SettingsQueryResponse): void {
    this.settings = data?.terms;
    this.form = this.createGroup();
    if (!this.isEditable) {
      this.form.disable();
    }
  }


  save(key: string, value: any) {
    let settingInput:any = {};
    settingInput = {
      "key": key,
      "value": value
    };
    this.settingsService.updateSettings(
      settingInput,
      this.returnCallback()
    );
  }
  returnCallback(): void {
      console.log('saved');
  }

  createGroup() {
    const group = this.fb.group({});
    for (let setting of this.settings) {
      setting?.settingDescriptions.forEach((control: any) => group.addControl(control.key, new FormControl(
        control.type === 'Boolean' && control.currentValue === 'false' ? false : control.currentValue
      )));
    }
    return group;
  }

  abortEditProfile(): void {
    window.location.reload();
  }

  updateProfile() {
    Object.keys(this.form.controls).forEach((name) => {
      const currentControl = this.form.controls[name];
      if (currentControl.dirty) {
       this.save(name, currentControl?.value);
      }
    });
    this.isEditable = false;
    this.form.disable();
  }

}
