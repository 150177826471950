<div class="content">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i [className]="'icon-tour'"></i>
      <h2>Tourenplaner</h2>
    </ng-container>
    <ng-container ngProjectAs="[tools]">
      <app-date-selector class="date-selector" className="white" [displayLabel]=false (date)="dateChanged($event)"
            [initialDate]="this.date">
      </app-date-selector>
    </ng-container>
  </app-layout-content-title>
  <div class="body flex flex-row basis-full gap-3 pt-3" cdkDropListGroup>
    <div [ngClass]="isTourFull? 'basis-full' : 'basis-1/3 xl:basis-1/4'" class="wrapUp">

      <!-- toolbar start -->
      <div class="toolbar flex basis-full">
        <div class="checkbox flex items-center basis-1/2">
          <span class="noWrap" #name [matTooltipDisabled]="isEllipsisActive(name)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{getSite(location)?.name}}"
           *ngIf="sitesActive"><h3>{{this.date | date:'dd.MM.YYYY'}}&nbsp;({{getSite(location)?.label ? getSite(location)?.label : getSite(location)?.name}})</h3></span>
        </div>

        <div class="buttons flex items-center  basis-1/2 place-content-end" #filterIcon>
          <div [matTooltip]="sitesActive ? null : 'Bitte eine Niederlassung auswählen'"
          [matTooltipDisabled]="sitesActive">
          <!-- !componentstate.toursSite && -->
          <app-nx-button (click)="createTour()" [disabled]="!sitesActive" icon="icon icon-plus" toolTip="neue Tour anlegen" *ngIf="rolesService?.hasRole('nexcore_tour_create')">
          </app-nx-button>
          </div>
          <app-nx-button (click)="drawerLeft.toggle()" icon="icon icon-options">
          </app-nx-button>
          <app-nx-button [icon]="'icon  icon-after'" (click)="isTourFull = true" *ngIf="!isTourFull" [toolTip]="'Modul erweitern'"/>
          <app-nx-button [icon]="'icon  icon-back-to'" (click)="isTourFull = false" *ngIf="isTourFull" [toolTip]="'Modul reduzieren'"/>
        </div>
      </div>
      <!-- toolbar end -->
      <div class="row flex basis-full main-component-wrapper items-start items-stretch"
        [ngClass]="{ loading : loading}" >
        <!-- drawer container start -->
        <mat-drawer-container class="container" [hasBackdrop]="false" >

          <!-- filter start -->
          <mat-drawer #drawerLeft [mode]="'side'" [position]="'end'" class="filter">
            <h2>Filter</h2>
            <app-location-selection [locations]=locations [multiple]=false (selectLocation)=selectToursLocation($event)
              [displayLabel]=true [location]="location">
            </app-location-selection>
            <!-- [locationsValue]=componentstate.toursSite
           [tourStatesValue]="componentstate?.selectedTourStates" -->
            <app-tour-state-selector (selectTourStates)="selectTourStates($event)">
            </app-tour-state-selector>
            <h2>Einstellungen</h2>
            <mat-form-field class="white">
              <mat-select (selectionChange)="selectionChanges()" [(value)]="sortValue">
                <mat-option value="resource" default>
                  Fahrzeug
                </mat-option>
                <mat-option value="driver">
                  Fahrer
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-drawer>
          <!-- filter end -->

          <!-- content start -->
          <mat-drawer-content class="drawer-content">
            <div [ngClass]="isTourFull? 'flex flex-row': ''">
              <div *ngIf="dailyToursSortedNotPlanned?.length > 0 && this.location" class="truck unplanned" [ngClass]="isTourFull? 'basis-1/2': 'basis-full'">

                <h2>{{headline}}</h2>
                <div tours-planner-tours-list class="toursList" [_headline]="headline" [tours]="dailyToursSortedNotPlanned" [sortBy] = "sortValue"
                  [resources]="resources" [drivers]="drivers" [openPanels]="openPanels" [draggingActive]="draggingActive"
                  (addTaskToTour)="addTaskToTour($event)" (addTaskToTourAtPosition)="addTaskToTourAtPosition($event)"
                  (dragStart)="onDragStart($event)" (dragEnd)="onDragEnd($event)" (panelClosed)="panelClosed($event)"
                  (panelOpened)="panelOpened($event)" (deleteTour)="deleteTour($event)"
                  (saveSettings)="saveSettings($event)" [isTourFull]="isTourFull" [loading]="loading">
                </div>
                <!-- [date]="componentstate.date"
                   (addTaskToTour)="addTaskToTour($event)"
                   (changeOrder)="changeOrder($event)"
                   (addTaskToTourAtPosition)="addTaskToTourAtPosition($event)"
                   (removeTaskFromTour)="removeTaskFromTour($event)"

                 -->
              </div>
              <!-- no vehicle end -->
              <div class="truck unplanned" *ngFor="let daily of dailyToursSorted; let i = index" [ngClass]="isTourFull? 'basis-1/2': 'basis-full'">
                <h2 *ngIf="sortValue==='resource'">{{daily.resource?.name}}</h2>
                <h2 *ngIf="sortValue==='driver'">{{daily?.driver?.firstName + ' ' + daily?.driver?.lastName}}</h2>
                <div tours-planner-tours-list fxLayout='column'
                  [_headline]="sortValue==='resource'? daily?.resource.name + ':': daily?.driver?.lastName" [sortBy] = "sortValue"
                  [tours]="daily.tours" [resources]="resources" [drivers]="drivers" [openPanels]="openPanels"
                  [draggingActive]="draggingActive" (addTaskToTour)="addTaskToTour($event)"
                  (addTaskToTourAtPosition)="addTaskToTourAtPosition($event)" (dragStart)="onDragStart($event)"
                  (dragEnd)="onDragEnd($event)" (panelClosed)="panelClosed($event)" (panelOpened)="panelOpened($event)"
                  (deleteTour)="deleteTour($event)" (saveSettings)="saveSettings($event)" [isTourFull]="isTourFull" [loading]="loading">
                </div>
              </div>
            </div>
            <!-- show vehicles
           [ngClass]="{ 'empty' : dailyToursNoVehiclePlanned?.length === 0 && dailyToursByVehicle?.length === 0 }"-->
            <!-- <ng-container *ngIf="sortValue === 'resource'"> -->
            <!-- <img *ngIf="dailyToursNoVehiclePlanned?.length === 0 && dailyToursByVehicle?.length === 0" src="/assets/NX_Route-Plan_Icon.svg" alt="image"> -->

            <!-- no vehicle tours start -->


            <div *ngIf="!this.location" class="flex items-center justify-center pt-3 no-data">
              Keine Niederlassung ausgewählt
            </div>
          </mat-drawer-content>
          <!-- content end -->
        </mat-drawer-container>
      </div>
      <!-- drawer container end -->
    </div>
    <div class="basis-2/3 xl:basis-3/4" *ngIf="!isTourFull">
      <!-- toolbar start -->
      <div class="toolbar flex basis-full">
        <div class="toolbar_left flex basis-1/3 items-center">
          <span class="noWrap" #header [matTooltipDisabled]="isEllipsisActive(header)" matTooltipPosition="left"
          matTooltipShowDelay="250" matTooltip="{{getSite(location)?.name}}"><h3>Offene Aufgaben</h3></span>
          <!-- [searchValue]="componentstate?.filterValue" -->
        </div>
        <div class="toolbar_right flex basis-2/3 place-content-end items-center" #filterIcon>
          <app-filter-input className="white" [showIcon]="false" (filter)=filter($event) class="pr-1" [placeholder]="'Aufgabe suchen'">
          </app-filter-input>
          <!-- [initialDate]="componentstate?.date" -->
          <!-- <button mat-icon-button class="today" [disabled]="!checkReset()" (click)="reset()">
               <mat-icon>today</mat-icon>
             </button> -->
          <app-nx-button (click)="drawerRight.toggle()" icon="icon icon-options">
          </app-nx-button>
        </div>
      </div>
      <!-- toolbar end -->
      <div class="row flex basis-full main-component-wrapper items-start items-stretch"
        [ngClass]="{ loading : loading}" >
        <!-- <div class="flex flex-column gap-3 basis-2/3 right" [ngStyle]="{'height': 'calc(100% - 53px)'}"> -->
        <!-- drawer container start -->
        <mat-drawer-container class="container" [hasBackdrop]="false">
          <!-- filter start -->
          <mat-drawer #drawerRight [mode]="'side'" [position]="'end'" class="filter">
            <h2>Filter</h2>
            <app-location-selection [locations]=locations (selectLocation)=selectTasksLocations($event)
              [displayLabel]="true" [location]="selectedLocations">
            </app-location-selection>
            <!-- [sitesValue]=componentstate.tasksSites -->
            <!-- TODO -->
            <app-offset-selector [initialOffset]="selectedPreview" [placeholder]="'Vorausschau'" (offset)="offsetChanged($event)">
            </app-offset-selector>
            <mat-slide-toggle
              class="showLocation"
              [checked]=getShowLocation()
              (change)="showLocationChanged($event)">
              Niederlassung anzeigen
            </mat-slide-toggle>
            <mat-slide-toggle
              [checked]=getShowAddressNote()
              (change)="showAddressNoteChanged($event)">
              Adresszusatz anzeigen
            </mat-slide-toggle>

            <!-- [initialOffset]="componentstate.offset"
               <app-include-ins-selector
                 [initialIncludeReturns]="componentstate.includeReturns"
                 (includeReturns)="includeReturnsChanged($event)">
               </app-include-ins-selector> -->
            <!-- <h2 class="filterSettings">Einstellungen</h2> -->
            <!-- <ng-container *ngIf="sitesActive">
                 <mat-slide-toggle>
                   [checked]=componentstate?.showSite
                 (change)="showSiteChanged($event)"
                   Niederlassung anzeigen
                 </mat-slide-toggle>
               </ng-container>
               <mat-slide-toggle>
                 [checked]=componentstate?.showStreet
                (change)="showStreetChanged($event)"
                 Straße anzeigen
               </mat-slide-toggle> -->
            <!-- <mat-slide-toggle
                 [checked]=componentstate?.showHouseNumber
                 (change)="showHouseNumberChanged($event)">
                 Hausnummer anzeigen
               </mat-slide-toggle>
               <mat-slide-toggle
                 [checked]=componentstate?.showNote
                 (change)="showNoteChanged($event)">
                 Notiz anzeigen
               </mat-slide-toggle> -->
          </mat-drawer>
          <!-- filter end -->

          <!-- content start -->
          <mat-drawer-content class="drawer-content">
            <app-tours-planner-tasks-list #taskslist fxFlex="calc(70%-25px)" [style.height]="height" [loading]="loading"
              [settings]="{locationIds : selectedLocations, text: filterValue, fromDate: fromDate, toDate: toDate, preview: this.selectedPreview}" [draggingActive]="draggingActive" (deleteTask)="deleteTask($event)"
              [showLocation]="getShowLocation()"
              [showAddressNote]="getShowAddressNote()"
              (splitTask)="splitTask($event)" (acceptAddress)="acceptAddress($event)"
              (updateAddress)="updateAddress($event)" (dragStart)="onDragStart($event)"
              (removeTaskFromTour)="removeTaskFromTour($event)" (dragEnd)="onDragEnd($event)" (sortTable)="sortTableTask($event)">
            </app-tours-planner-tasks-list>
          </mat-drawer-content>
          <!-- content end -->
        </mat-drawer-container>
        <!-- drawer container end -->
        <!-- </div> -->
      </div>
    </div>
  </div>

</div>
