import { DatePipe } from '@angular/common';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { Address } from 'src/app/shared-module/shared/entities/address/address';
import { TaskInfoDialogComponent } from '../task-info-dialog/task-info-dialog.component';


@Component({
  selector: 'div[task-row], app-task-row-component',
  templateUrl: './task-row.component.html',
  styleUrls: ['./task-row.component.scss'],
})
export class TaskRowComponent {

  @HostBinding('style.width.px') width: Number;

  @Input() task: Task;
  @Input() setHeight = false;
  // calculates the ca. size of the preview element while dragging
  // the percentage is the ca. value of the width of the element
  // as soon as its added to a tour
  @Input() set container(container: any) {
    if ( container && container.offsetWidth ) {
      this.height = container.offsetWidth * 0.406
    }
  }
  @HostBinding('style.width.px') height: number;

  constructor(
    public tasksService: TasksService,
    private datePipe: DatePipe,
    private router: Router,
    public dialog: MatDialog,
   ){
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: any) {
      if(this.setHeight){
        // console.log(this.el.nativeElement.offsetWidth);
        // this.height = window.innerHeight;
      }
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  buildArticleString(taskPositions: any[]){
    let articleNumberString = "";
    if(taskPositions && taskPositions.length > 0){
      for (let index = 0; index < taskPositions.length; index++) {
        const element = taskPositions[index];
        articleNumberString = articleNumberString + taskPositions[index].articleNumber;
        if(index < taskPositions.length -1){
          articleNumberString = articleNumberString + ", ";
        }
      }
    }
    return articleNumberString;
  }

  protocolNotesAvailable(task: Task){
    // return this.taskService.protocolNotesAvailable(task);
  }

  goToOrder(task: Task){
    // console.log('hier bin ich', task)
    if(task && task.order && task.order.id){
      this.router.navigate(["./orders/order/" + task.order.id]);
    }
  }

  buildProtocolNote(task: Task){
    // return this.taskService.buildProtocolNote(task);
  }

  getWishedDeliveryTimeCustomAttribute(task: Task): boolean{
    // if(task?.order?.customAttributes
    //   && task?.order?.customAttributes?.length > 0 ){
    //     return this.customAttributeService.getCustomAttribute('Wunschzeit_Kunde', task?.order?.customAttributes);
    //   }
    return undefined;
  }
  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  taskInfo(task: Task) {
    const dialogRef = this.dialog.open(TaskInfoDialogComponent, {
      width: '700px',
      minHeight:'650px',
      data: {
        task: task,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(dialog=>{
    })
  }

  getAddressToolTip(address: Address) {
    if(!address) {
      return null;
    }
    let addressToolTip = address.getAddressString();
    // add the note of the address to the tooltip
    if(address.note){
      addressToolTip += ", " + address.note;
    }
    return addressToolTip;
  }
}
