<div class="content">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i [className]="'icon-group'"></i>
      <h2>Kunden</h2>
    </ng-container>
    <ng-container ngProjectAs="[tools]">
      <!-- <mat-icon mat-list-icon (click)="createUser()">add</mat-icon> -->
      <app-nx-button (click)="drawerRight.toggle()" icon="icon icon-options">
      </app-nx-button>
    </ng-container>
  </app-layout-content-title>
  <div class="body">
    <mat-drawer-container [hasBackdrop]="false" class="container">
      <!-- filter start -->
      <mat-drawer #drawerRight [mode]="'side'" [position]="'end'" class="filter">
        <!-- <h2 class="header">Einstellungen</h2> -->
        <div>
          <h3>Filter</h3>
            <mat-form-field class="white">
              <mat-label>Name</mat-label>
              <input matInput [formControl]="contactNameControl">
              <button *ngIf="text" matSuffix mat-icon-button aria-label="Clear" (click)="contactNameControl.setValue('')">
                <mat-icon class="black-icon">close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="white">
              <mat-label>Typ</mat-label>
              <mat-select (selectionChange)="selectionChanges()" [(ngModel)]="contactType" >
                <mat-option [value]="null">Alle</mat-option>
                <mat-option *ngFor="let type of contactTypes" [value]="type.id">
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
      </mat-drawer>
      <mat-drawer-content class="drawer-content">
        <div class="details flex flex-col basis-full">
          <div [hidden]="dataSource?.loading| async" class="table flex flex-row basis-full">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"
                  class="header_column table_column firstHeader">Typ</mat-header-cell>
                <mat-cell *matCellDef="let contact" fxFlex="10%" class="table_column first">
                  <i [className]="Contact?.getContactIcon(contact?.type)" [matTooltip]="Contact?.getContactType(contact?.type)"></i>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%" class="header_column table_column"
                  ><span class="noWrap">Kontakt-ID</span></mat-header-cell>
                <mat-cell *matCellDef="let contact" fxFlex="15%" class="table_column">
                  <span class="noWrap" #num [matTooltipDisabled]="isEllipsisActive(num)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{contact.number}}">
                    {{contact?.number? contact.number: ''}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef fxFlex="25%" class="header_column table_column"
                >Name</mat-header-cell>
                <mat-cell *matCellDef="let contact" fxFlex="25%" class="table_column">
                  <span class="noWrap" #name [matTooltipDisabled]="isEllipsisActive(name)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{getContactName(contact)}}">
                    {{getContactName(contact)}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="address.street">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%" class="header_column table_column"
                  >Straße</mat-header-cell>
                <mat-cell *matCellDef="let contact" fxFlex="15%" class="table_column">
                  <span class="noWrap" #street [matTooltipDisabled]="isEllipsisActive(street)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{contact?.address?.street? contact.address.street: ''}}  {{contact?.address?.houseNumber? ' ' + contact?.address?.houseNumber: ''}}">
                    {{contact?.address?.street? contact.address.street: ''}}  {{contact?.address?.houseNumber? ' ' + contact?.address?.houseNumber: ''}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="address.postalCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%" class="header_column table_column"
                  >PLZ</mat-header-cell>
                <mat-cell *matCellDef="let contact" fxFlex="15%" class="table_column">
                  <span class="noWrap" #plz [matTooltipDisabled]="isEllipsisActive(plz)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{contact?.address?.postalCode}}">
                    {{contact?.address?.postalCode? contact.address.postalCode: '-'}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="address.city">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%" class="header_column table_column"
                  >Ort</mat-header-cell>
                <mat-cell *matCellDef="let contact" fxFlex="8%" class="table_column">
                  <span class="noWrap" #plz [matTooltipDisabled]="isEllipsisActive(plz)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{contact?.address?.city}}">
                    {{contact?.address?.city? contact.address.city: '-'}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="address.country">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%" class="header_column table_column place-content-end justify-start"
                  >Land</mat-header-cell>
                <mat-cell *matCellDef="let contact" fxFlex="8%" class="table_column place-content-end justify-start">
                  <span class="noWrap" #plz [matTooltipDisabled]="isEllipsisActive(plz)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{contact?.address?.country}}">
                  {{contact?.address?.country? contact.address.country: '-'}}
                  </span>
                </mat-cell>
              </ng-container>

              <!-- <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef fxFlex="20%" fxLayoutAlign="end center" class="header_column"></mat-header-cell>
                <mat-cell *matCellDef="let contact" fxFlex="20%" fxLayoutAlign="end center" class="last" (click)="$event.stopPropagation()">
                  <app-nx-button *ngIf="rolesService.hasRole('fleetstage_contacts_manage')" toolTip="löschen" icon="icon icon-trash" (click)="contact.isDeletable && confirmDialog(contact)" [disabled]="!contact.isDeletable"></app-nx-button>
                </mat-cell>
              </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedColumns ;sticky: true" class="table_header" [hidden]="!dataSource?.totalElements">
              </mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" class="table_row" (click)="openContact(row.id)">
              </mat-row>
              <div *matNoDataRow class="no-data">
                <p>keine Kontakte vorhanden</p>
              </div>
            </mat-table>
          </div>
          <div *ngIf="dataSource?.loading | async" class="spinner">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
          <div class="sticky list-footer">
            <div class="pagination justify-end flex basis-full ">
              <mat-paginator [length]="dataSource?.totalElements" [pageSize]="15" [pageIndex]="0"
                [pageSizeOptions]="[15, 20, 25, 30]" aria-label="Select page" class="paginator"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>

