<div class="content">
  <!-- <app-layout-content-title> -->
  <div class="header">
    <h3> Kontaktpersonen</h3>
    <div class="buttons">
      <app-nx-button  (click)="createContactPerson()" toolTip="Kontaktperson anlegen" icon="icon icon-plus"
        class="style='float:right'"  *ngIf="rolesService.hasRole('nexcore_contactPerson_create')">
      </app-nx-button>
      <app-nx-button (click)="drawerRight.toggle()" icon="icon icon-options" class="style='float:right'">
      </app-nx-button>
    </div>
  </div>

  <mat-drawer-container [hasBackdrop]="false" class="container">
    <!-- filter start -->
    <mat-drawer #drawerRight [mode]="'side'" [position]="'end'" class="filter">
      <!-- <h2 class="header">Einstellungen</h2> -->
      <div>
        <h3>Filter</h3>
        <mat-form-field class="white">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="contactNameControl">
          <button *ngIf="text" matSuffix mat-icon-button aria-label="Clear" (click)="contactNameControl.setValue('')">
            <mat-icon class="black-icon">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <div class="details flex flex-col basis-full">
        <div [hidden]="dataSource?.loading| async" class="border-t border-gray-300 full flex flex-row basis-full">
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef class="header_column table_column">Name</mat-header-cell>
              <mat-cell *matCellDef="let linkedContact" class="table_column">
                {{linkedContact.getFullName()}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                class="header_column table_column">Telefon</mat-header-cell>
              <mat-cell *matCellDef="let linkedContact" class="table_column">
                <span class="noWrap" matTooltip="{{linkedContact.phoneNumber}}">{{linkedContact.phoneNumber? linkedContact.phoneNumber: '-'}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mobileNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                class="header_column table_column">Mobilfunk</mat-header-cell>
              <mat-cell *matCellDef="let linkedContact" class="table_column">
                <span class="noWrap"
                matTooltip="{{linkedContact.mobileNumber}}">{{linkedContact.mobileNumber? linkedContact.mobileNumber: '-'}}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                class="header_column table_column">E-Mail</mat-header-cell>
              <mat-cell *matCellDef="let linkedContact" class="table_column">
                <span class="noWrap"
                matTooltip="{{linkedContact.email}}">{{linkedContact.email? linkedContact.email: '-'}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="button" >
              <mat-header-cell *matHeaderCellDef class="header_column table_column flex  justify-end items-center">
              </mat-header-cell>
              <mat-cell *matCellDef="let linkedContact" class="table_column flex  justify-end items-center">
                 <i [className]="'icon icon-options'" [matMenuTriggerFor]="menu"></i>
                 <mat-menu #menu="matMenu" class="menu">
                  <button mat-menu-item (click)="editContactPerson(linkedContact)" *ngIf="rolesService?.hasRole('nexcore_contactPerson_update')">
                    <i [className]="'icon icon-edit'"></i>
                    <span class="flex items-center menulist">Kontaktperson bearbeiten</span>
                  </button>
                   <!--<button mat-menu-item>Öffentlichen Link kopieren</button> -->
                  <button mat-menu-item (click)="confirmDialog(linkedContact)" *ngIf="rolesService?.hasRole('nexcore_contactPerson_delete')">
                    <i [className]="'icon icon-trash'"></i>
                    <span class="flex items-center menulist">Kontaktperson löschen</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns ;sticky: true" class="table_header"
              [hidden]="!dataSource?.totalElements">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="table_row border-b">
            </mat-row>
            <div *matNoDataRow class="no-data">
              <p>keine Kontakte verbunden</p>
            </div>
          </mat-table>
        </div>
        <div *ngIf="dataSource?.loading | async" class="spinner">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        <div [hidden]="!dataSource?.totalElements || dataSource.totalElements< 7" class="border-t">
          <div class="pagination">
            <mat-paginator [length]="dataSource?.totalElements" [pageSize]="6" [pageIndex]="0"
              [hidden]="!dataSource?.totalElements || dataSource.totalElements< 7" aria-label="Select page"
              class="paginator"></mat-paginator>
          </div>
        </div>
      </div>

      <!-- </app-layout-content-body> -->
    </mat-drawer-content>
  </mat-drawer-container>

</div>
