import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModuleModule } from '@nexato/nx-core-module';
import { AppRoutingModule } from '../app-routing.module';
import { FrontendMaterialModule } from '../material-module/material.module';
import { SharedModule } from '../shared-module/shared.module';
import { AddDescriptionComponent } from './components/add-description/add-description.component';
import { AddTimesDialogComponent } from './components/add-times-dialog/add-times-dialog.component';
import { OffsetSelectorComponent } from './components/offset-selector/offset-selector.component';
import { TaskInfoDialogComponent } from './components/task-info-dialog/task-info-dialog.component';
import { TaskRowComponent } from './components/task-row-component/task-row.component';
import { TourSettingsDialogComponent } from './components/tour-settings-dialog/tour-settings-dialog.component';
import { TourStateSelectorComponent } from './components/tour-state-selector/tour-state-selector.component';
import { ToursDetailPlanningMapComponent } from './components/tours-detail-planning-map/tours-detail-planning-map.component';
import { ToursPlannerTasksListComponent } from './components/tours-planner-tasks-list/tours-planner-tasks-list.component';
import { ToursPlannerToursListComponent } from './components/tours-planner-tours-list/tours-planner-tours-list.component';
import { ToursDetailPageComponent } from './containers/tours-detail-page/tours-detail-page.component';
import { ToursPlannerPageComponent } from './containers/tours-planner-page/tours-planner-page.component';
import { AddressService } from './shared/services/address/address.service';
import { TaskService } from './shared/services/task/task.service';
import { TourService } from './shared/services/tour/tour.service';

// Import locale data for 'de'
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

// Register the locale data
registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    ToursPlannerPageComponent,
    TourStateSelectorComponent,
    OffsetSelectorComponent,
    ToursPlannerTasksListComponent,
    ToursPlannerToursListComponent,
    TaskRowComponent,
    AddTimesDialogComponent,
    AddDescriptionComponent,
    TourSettingsDialogComponent,
    TaskInfoDialogComponent,
    ToursDetailPageComponent,
    ToursDetailPlanningMapComponent
  ],
  imports: [
    CommonModule,
    FrontendMaterialModule,
    AppRoutingModule,
    CoreModuleModule,
    SharedModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
  providers: [
    TourService,
    TaskService,
    AddressService,
    { provide: LOCALE_ID, useValue: 'de' }
  ]
})
export class TourPlanningModule { }
