
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModuleModule } from '@nexato/nx-core-module';
import { LightgalleryModule } from 'lightgallery/angular';
import { AppRoutingModule } from '../app-routing.module';
import { FrontendMaterialModule } from '../material-module/material.module';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { FilterInputComponent } from './components/filter-input/filter-input.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LocationSelectionComponent } from './components/location-selection/location-selection.component';
import { NonLicensePageComponent } from './components/non-license-page/non-license-page.component';
import { OrderFilesComponent } from './components/order-files/order-files.component';
import { OrdersSearchComponent } from './components/orders-search/orders-search.component';
import { RefuelViewComponent } from './components/refuel-view/refuel-view.component';
import { SettingsFormComponent } from './components/settings-form/settings-form.component';
import { TaskAssignmentStrategyComponent } from './components/task-assignment-strategy/task-assignment-strategy.component';
import { TaskFilesDialogComponent } from './components/task-files-dialog/task-files-dialog.component';
import { CustomAttributesViewComponent } from './containers/custom-attributes-view/custom-attributes-view.component';
import { NavigationComponent } from './containers/navigation/navigation.component';
import { OrderCommentsListComponent } from './containers/order-comments-list/order-comments-list.component';
import { ChargeInputControlLabelViewComponent } from './protocols/controls/chargeInput-control-label-view/chargeInput-control-label-view.component';
import { ChargeInputControlValueViewComponent } from './protocols/controls/chargeInput-control-value-view/chargeInput-control-value-view.component';
import { CheckboxInputControlLabelViewComponent } from './protocols/controls/checkboxInput-control-label-view/checkboxInput-control-label-view.component';
import { CheckboxInputControlValueViewComponent } from './protocols/controls/checkboxInput-control-value-view/checkboxInput-control-value-view.component';
import { CommentInputControlLabelViewComponent } from './protocols/controls/commentInput-control-label-view/commentInput-control-label-view.component';
import { CommentInputControlValueViewComponent } from './protocols/controls/commentInput-control-value-view/commentInput-control-value-view.component';
import { FuelInputControlLabelViewComponent } from './protocols/controls/fuelInput-control-label-view/fuelInput-control-label-view.component';
import { FuelInputControlValueViewComponent } from './protocols/controls/fuelInput-control-value-view/fuelInput-control-value-view.component';
import { NumberInputControlLabelViewComponent } from './protocols/controls/numberInput-control-label-view/numberInput-control-label-view.component';
import { NumberInputControlValueViewComponent } from './protocols/controls/numberInput-control-value-view/numberInput-control-value-view.component';
import { PictureInputControlLabelViewComponent } from './protocols/controls/pictureInput-control-label-view/pictureInput-control-label-view.component';
import { PictureInputControlValueViewComponent } from './protocols/controls/pictureInput-control-value-view/pictureInput-control-value-view.component';
import { PicturesInputControlLabelViewComponent } from './protocols/controls/picturesInput-control-label-view/picturesInput-control-label-view.component';
import { PicturesInputControlValueViewComponent } from './protocols/controls/picturesInput-control-value-view/picturesInput-control-value-view.component';
import { RangeInputControlLabelViewComponent } from './protocols/controls/rangeInput-control-label-view/rangeInput-control-label-view.component';
import { RangeInputControlValueViewComponent } from './protocols/controls/rangeInput-control-value-view/rangeInput-control-value-view.component';
import { RefuelInputControlLabelViewComponent } from './protocols/controls/refuelInput-control-label-view/refuelInput-control-label-view.component';
import { RefuelInputControlValueViewComponent } from './protocols/controls/refuelInput-control-value-view/refuelInput-control-value-view.component';
import { SelectInputControlLabelViewComponent } from './protocols/controls/selectInput-control-label-view/selectInput-control-label-view.component';
import { SelectInputControlValueViewComponent } from './protocols/controls/selectInput-control-value-view/selectInput-control-value-view.component';
import { SignatureInputControlLabelViewComponent } from './protocols/controls/signatureInput-control-label-view/signatureInput-control-label-view.component';
import { SignatureInputControlValueViewComponent } from './protocols/controls/signatureInput-control-value-view/signatureInput-control-value-view.component';
import { TextInputControlLabelViewComponent } from './protocols/controls/textInput-control-label-view/textInput-control-label-view.component';
import { TextInputControlValueViewComponent } from './protocols/controls/textInput-control-value-view/textInput-control-value-view.component';
import { ProtocolControlLabelViewComponent } from './protocols/protocol-control-label-view/protocol-control-label-view.component';
import { ProtocolControlValueViewComponent } from './protocols/protocol-control-value-view/protocol-control-value-view.component';
import { ProtocolSideBySideViewComponent } from './protocols/protocol-side-by-side-view/protocol-side-by-side-view.component';
import { ProtocolViewComponent } from './protocols/protocol-view/protocol-view.component';

@NgModule({
  declarations: [
    LayoutComponent,
    NavigationComponent,
    FilterInputComponent,
    DateSelectorComponent,
    OrdersSearchComponent,
    CustomAttributesViewComponent,
    LocationSelectionComponent,
    RefuelViewComponent,
    OrderCommentsListComponent,
    ProtocolSideBySideViewComponent,
    ProtocolViewComponent,
    ProtocolControlValueViewComponent,
    ProtocolControlLabelViewComponent,
    CheckboxInputControlValueViewComponent,
    CheckboxInputControlLabelViewComponent,
    NumberInputControlLabelViewComponent,
    NumberInputControlValueViewComponent,
    RangeInputControlLabelViewComponent,
    RangeInputControlValueViewComponent,
    RefuelInputControlLabelViewComponent,
    RefuelInputControlValueViewComponent,
    ChargeInputControlLabelViewComponent,
    ChargeInputControlValueViewComponent,
    PictureInputControlLabelViewComponent,
    PictureInputControlValueViewComponent,
    PicturesInputControlLabelViewComponent,
    PicturesInputControlValueViewComponent,
    CommentInputControlLabelViewComponent,
    CommentInputControlValueViewComponent,
    SignatureInputControlLabelViewComponent,
    SignatureInputControlValueViewComponent,
    SelectInputControlLabelViewComponent,
    SelectInputControlValueViewComponent,
    TextInputControlLabelViewComponent,
    TextInputControlValueViewComponent,
    FuelInputControlLabelViewComponent,
    FuelInputControlValueViewComponent,
    RangeInputControlLabelViewComponent,
    RangeInputControlValueViewComponent,
    TaskAssignmentStrategyComponent,
    NonLicensePageComponent,
    OrderFilesComponent,
    TaskFilesDialogComponent,
    SettingsFormComponent
  ],
  imports: [
    CommonModule,
    FrontendMaterialModule,
    AppRoutingModule,
    CoreModuleModule,
    ReactiveFormsModule,
    FormsModule,
    LightgalleryModule
  ],
  exports: [
    LayoutComponent,
    FilterInputComponent,
    DateSelectorComponent,
    OrdersSearchComponent,
    CustomAttributesViewComponent,
    RefuelViewComponent,
    LocationSelectionComponent,
    OrderCommentsListComponent,
    ProtocolSideBySideViewComponent,
    ProtocolViewComponent,
    ProtocolControlValueViewComponent,
    ProtocolControlLabelViewComponent,
    CheckboxInputControlValueViewComponent,
    CheckboxInputControlLabelViewComponent,
    NumberInputControlLabelViewComponent,
    NumberInputControlValueViewComponent,
    RangeInputControlLabelViewComponent,
    RangeInputControlValueViewComponent,
    RefuelInputControlLabelViewComponent,
    RefuelInputControlValueViewComponent,
    PictureInputControlLabelViewComponent,
    PictureInputControlValueViewComponent,
    PicturesInputControlLabelViewComponent,
    PicturesInputControlValueViewComponent,
    CommentInputControlLabelViewComponent,
    CommentInputControlValueViewComponent,
    SignatureInputControlLabelViewComponent,
    SignatureInputControlValueViewComponent,
    SelectInputControlLabelViewComponent,
    SelectInputControlValueViewComponent,
    TextInputControlLabelViewComponent,
    TextInputControlValueViewComponent,
    FuelInputControlLabelViewComponent,
    FuelInputControlValueViewComponent,
    ChargeInputControlLabelViewComponent,
    ChargeInputControlValueViewComponent,
    TaskAssignmentStrategyComponent,
    OrderFilesComponent,
    TaskFilesDialogComponent,
    SettingsFormComponent
  ],
})
export class SharedModule { }
