<div [hidden]="dataSource?.loading| async">
  <mat-table [dataSource]="dataSource" matSort>
      <!-- state Column -->
      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="flex types-header"> Status </mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex types">
          <i [className]="'icon icon-check-circle'" [matTooltip]="getCompletedBy(task)" *ngIf="task?.state === 'COMPLETED'"></i>
          <i [className]="'icon  icon-time'" *ngIf="task?.state !== 'COMPLETED'"></i>
        </mat-cell>
      </ng-container>
    <!-- type Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef class="flex types-header pl-2"> Typ </mat-header-cell>
      <mat-cell *matCellDef="let task" class="flex types pl-2">
        <i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i>
      </mat-cell>
    </ng-container>

     <!-- dueDate Column -->
     <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef  class="flex">Fälligkeit</mat-header-cell>
      <mat-cell *matCellDef="let task" class="flex">
        <span class="noWrap" #dueDate [matTooltipDisabled]="isEllipsisActive(dueDate)"
          matTooltipPosition="left" matTooltipShowDelay="250"
          matTooltip="{{taskService.getDeliveryDateFull(task?.dueDateTimePeriod)}}&nbsp;{{taskService.getDeliveryTime(task?.dueDateTimePeriod)}} Uhr">
    {{taskService.getDeliveryDateFull(task?.dueDateTimePeriod)}}&nbsp;{{taskService.getDeliveryTime(task?.dueDateTimePeriod)}} Uhr
        </span>
      </mat-cell>
    </ng-container>

      <!-- note Column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef  class="flex">Beschreibung</mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex">
          <span class="noWrap" #note [matTooltipDisabled]="isEllipsisActive(note)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{task.description}}">
            {{task.description}}
          </span>
        </mat-cell>
      </ng-container>

      <!-- assignment Column -->
      <ng-container matColumnDef="assignment">
        <mat-header-cell *matHeaderCellDef class="flex place-content-end">Zuweisung</mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex place-content-end">
          <app-task-assignment-strategy [assignment]="task?.assignment"/>
        </mat-cell>
      </ng-container>

        <!-- assignment suggestion column -->
      <ng-container matColumnDef="assignmentSuggestion">
        <mat-header-cell *matHeaderCellDef class="basis-1/5 justify-end mr-1">Zuweisung (Vorschlag)</mat-header-cell>
        <mat-cell *matCellDef="let task" class="basis-1/5 justify-end mr-1">
          <ng-container *ngIf="task.assignmentSuggestion">
            <span class="noWrap" matTooltipPosition="left" #name [matTooltipDisabled]="isEllipsisActive(name)"
              matTooltipShowDelay="250" matTooltip="{{task.assignmentSuggestion.name}}">{{task.assignmentSuggestion.name}}
              &nbsp;</span>
            <ng-container *ngIf="task.assignmentSuggestion.dueDateTimePeriod">
              <span class="noWrap" matTooltipPosition="left" #suggest [matTooltipDisabled]="isEllipsisActive(suggest)"
                matTooltipShowDelay="250" matTooltip="
                    {{taskService.getDeliveryDateFull(task.assignmentSuggestion.dueDateTimePeriod)}}
                    {{taskService.getDeliveryTime(task.assignmentSuggestion.dueDateTimePeriod)}}">
                {{taskService.getDeliveryDateFull(task.assignmentSuggestion.dueDateTimePeriod)}}
                {{taskService.getDeliveryTime(task.assignmentSuggestion.dueDateTimePeriod)}}</span>
            </ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="completedBy">
        <mat-header-cell *matHeaderCellDef class="basis-1/5 justify-end mr-1">Erledigt</mat-header-cell>
        <mat-cell *matCellDef="let task" class="basis-1/5 justify-end mr-1">
          {{task?.completedDateTime | date:'dd.MM.YYYY HH:mm' }} /
          {{getName(task?.completedBy)}} <ng-container *ngIf="task?.assignment">(<app-task-assignment-strategy [assignment]="task?.assignment"/>)</ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row class="table_header"
      *matHeaderRowDef="displayedColumns; sticky: true"
      fxLayout="row"
      fxLayoutGap="10px"
      [hidden]="!dataSource.data || ( dataSource.data && dataSource?.data.length < 1 )">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row">
    </mat-row>
    <div *matNoDataRow class="no-data mt-5">
      <p>keine Aufgaben vorhanden</p>
    </div>
  </mat-table>
</div>
<div *ngIf="dataSource?.loading | async" class="flex items-center justify-center mt-5">
  <mat-spinner [diameter]="30"></mat-spinner>
</div>
