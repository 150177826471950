import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import * as fromGraphQl from './graphql';
import { ComponentState, Container, LoadingService, PersistenceScope, StateService, StateVar } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

interface TaskAssignmentComponentState{
  selectedDateTask: Date,
  selectedLocationTask: string[]
}

@Component({
  selector: 'app-task-assignment',
  templateUrl: './task-assignment.component.html',
  styleUrls: ['./task-assignment.component.scss']
})
export class TaskAssignmentComponent extends Container<fromGraphQl.AssignmentsQueryResponse>
implements OnInit{
  public locations: LocationEntity[];
  public selectedLocation: string[];
  public date = new Date();
  initialDate = new Date();
  @ViewChild('drawerRight') drawerRight: any;
  taskType:string[]= [];
  public filterValue = '';

  taskTypes = [
    {
      id: 'nexcore_rental_resourceAssignmenmt_out',
      name: 'Übergabe'
    },
    {
      id: 'nexcore_rental_resourceAssignmenmt_in',
      name: 'Rückgabe'
    }];

  componentState: ComponentState<TaskAssignmentComponent>;
  selectedDateTask: StateVar<Date>;
  selectedLocationTask: StateVar<string[]>;

  previousState:any = null;
  componentStateCount = 0;

  constructor(
    apollo: Apollo,
    loadingService: LoadingService,
    stateService: StateService,
    private cdRef: ChangeDetectorRef,
  ) {
    super(apollo, loadingService, fromGraphQl.ASSIGNMENT_QUERY, environment);
    this.componentState = new ComponentState<TaskAssignmentComponent>("TaskAssignmentComponent", stateService, apollo);
    this.selectedDateTask = new StateVar({
      key: "selectedDateTask",
      initialValue: new Date(),
      persistenceScope: PersistenceScope.SessionStorage
    });
    this.componentState.addStateVar(this.selectedDateTask);
    this.selectedLocationTask = new StateVar({
      key: "selectedLocationTask",
      initialValue: [],
      persistenceScope: PersistenceScope.Backend
    });
    this.componentState.addStateVar(this.selectedLocationTask);
    this.subscribeToQuery();

  }

   ngOnInit() {
     this.componentState?.watch((state: any) => {
         this.selectedDateTask.currentValue = state[this.selectedDateTask.key];
         if (this.selectedDateTask?.currentValue) {
           this.date = new Date(this.selectedDateTask.currentValue);
         }
         if (this.selectedLocationTask?.currentValue) {
           this.selectedLocation = this.selectedLocationTask?.currentValue;
         }
         this.cdRef.detectChanges();
     });
  }

  handleSubscriptionResult(data: fromGraphQl.AssignmentsQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data.locations) {
      this.queryRef.stopPolling();
      this.locations = LocationEntity.createLocations(data?.locations);
    }
  }
  dateChanged($event: any) {
    this.date = $event;
    console.log('new date', this.date)
    this.componentState.update(this.selectedDateTask, this.date);
  }

  filter($event: any) {
    this.filterValue = $event;
  }

  selectTasksLocations($event: any){
    this.selectedLocation = $event;
    this.componentState.update(this.selectedLocationTask,  this.selectedLocation);
  }
}
