import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
// import { HttpLink } from 'apollo-angular/http';
import {HttpBatchLink} from 'apollo-angular/http';
import { environment } from 'src/environments/environment';

export function createApollo(batchHttpLink: HttpBatchLink): ApolloClientOptions<any> {
  return {
    link: batchHttpLink.create({uri: environment.backendUri + '/graphql', batchInterval: 20 }),
    // batchHttpLink.create({ uri: environment.backendUri + '/graphql' }),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    },
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpBatchLink],
    },
  ],
})
export class GraphQLModule {}
