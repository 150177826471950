import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { ComponentState, LoadingService, PersistenceScope, RolesService, StateService, StateVar } from '@nexato/nx-core-module';
import { debounceTime, distinctUntilChanged, merge, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrderDataSource } from './orderDataSource';
import { Order } from '../../shared/entities/order/order';
import { Router } from '@angular/router';
import { Contact } from '../../../rent-module/shared/entities/contact/contact';
import { LineItem } from 'src/app/rent-module/shared/entities/lineItem/lineItem';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

interface OrderComponentState{
  selectedLocationOrder: LocationEntity[]
}
@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Order>;
  @ViewChild('drawerRight') drawerRight: any;

  public Order = Order;
  public number = '';
  public customerId: string = null;
  public resourceId: string = null;
  orderNumberControl = new UntypedFormControl();
  formCtrlSub: any;
  public location: LocationEntity[];
  public locations: LocationEntity[];
  public isFirst = true;
  public componentStateCount = 0;
  previousState: any = null;


  public displayedColumns: string[] = [
    'type',
    'number',
    'customer',
    'lineItems',
    'state',
    'location'
  ];


  componentState: ComponentState<OrderComponentState>;
  selectedLocationOrder: StateVar<LocationEntity[]>;

  @Input() set customer(customer: string) {
    this.customerId = customer;
    this.displayedColumns= [
      'type',
      'number',
      'lineItems',
      'state'
    ];
    // console.log('customer id', this.customerId);
    this.refetchOrders();
  }

  @Input() set resource(resource: string) {
    this.resourceId = resource;
    // console.log('customer id', this.customerId);
    this.refetchOrders();
  }

  public fileApi = environment.fileApi;
  public dataSource: OrderDataSource;

  constructor(
    private router: Router,
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    // public dialog: MatDialog,
    private loadingService: LoadingService,
    public rolesService: RolesService,
    stateService: StateService,
    private cdRef:ChangeDetectorRef,

  ) {
    this.componentState = new ComponentState<OrderComponentState>("OrdersListComponent", stateService, apollo);
    this.selectedLocationOrder = new StateVar({
      key: "selectedLocationTask",
      initialValue: this.location,
      persistenceScope: PersistenceScope.Backend
    });
    this.componentState.addStateVar(this.selectedLocationOrder);
  }

  createOrder(): void {
    // const dialogRef = this.dialog.open(VehicleDialogComponent, {
    //   width: '760px',
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result && result.saved) {
    //     this.refetchVehicles();
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect(this.table);
  }

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
    this.formCtrlSub = this.orderNumberControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.number = term;
        this.selectionChanges();
      });
      this.componentState.watch( (state:any) => {
        if (this.componentStateCount > 0 && this.previousState!= state) {
          this.selectedLocationOrder.currentValue = state[this.selectedLocationOrder.key];
          this.location = this.selectedLocationOrder.currentValue;
          this.isFirst? this.setTableDataSource(): this.refetchOrders();
          this.isFirst = false;
          this.previousState = state;
          this.cdRef.detectChanges();
         }
        this.componentStateCount++;
      });

  }
  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchOrders()))
      .subscribe();
  }

  setTableDataSource(): void {

    this.dataSource = new OrderDataSource(this.apollo);
    this.dataSource.loadorders({
      number: 0,
      size: 15,
      sortProperty: this.sort?.active ? this.sort.active : 'type',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      customer: this.customerId,
      resource: this.resourceId,
      locationId: this.locations
    });
  }

  openOrder(order: Order): void {
    this.router.navigate(['./orders/order/' + order.id]);
  }

  refetchOrders(): void {
    const number = this.number ? this.number : '';
    this.dataSource?.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 15,
      sortProperty: this.sort?.active ? this.sort.active : 'type',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      orderNumber: number,
      customer: this.customerId,
      resource: this.resourceId,
      locationId: this.location
    });
  }

  selectionChanges() {
    this.paginator.pageIndex = 0
    this.refetchOrders();
  }

  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  getLineItems(lineItems : LineItem[]): string {
    let name = undefined;
    for (const lineItem of lineItems) {
      const name1 = this.findArticleNumber(lineItem);
      if (name1) {
        name = name? name + ', ' + name1 : name1;
      }
    }
    return name;
  }

  findArticleNumber(lineItem: LineItem) {
    // we have an article available
    if (lineItem?.article?.number) {
      return lineItem?.article?.number;
    }
    // we do not have an article available, so we display
    // resource.number. If no article is available, the constraint is,
    // that we have only one resourceAssignment per lineItem, so we can
    // safely acces the first resourceAssignment harcoded
    if (lineItem?.resourceAssignments?.length > 0){
      return lineItem?.resourceAssignments[0].resource?.name;
    }
    return undefined;
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  selectLocation($event: any){
    // this.sitesActive = true;
    this.location = $event;
    this.componentState.update(this.selectedLocationOrder,  this.location);
    this.refetchOrders();
  }
}



