import { Injectable } from '@angular/core';
import * as fromGraphQl from './graphql';
import { ApolloService } from '@nexato/nx-core-module';
import { LocationInput } from '../../../../shared-module/shared/entities/location/location';

/**
 * The models service.
 */
@Injectable()
export class LocationService {
  constructor(private apolloService: ApolloService) {}

  /**
   * updates a Location
   * @param locationInput Location Input
   * @param callback method
   */
  public updateLocation(locationInput: LocationInput, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UPDATE_LOCATION_MUTATION,
      {
        locationInput,
      },
      callback
    );
  }

  /**
   * creates a Location
   * @param locationInput locationInput input
   * @param callback method
   */
  public createLocation(locationInput: LocationInput, callback: any): void {
    // console.log('Input', locationInput);
    this.apolloService.mutateAndExecute(
      fromGraphQl.CREATE_LOCATION_MUTATION,
      {
        locationInput,
      },
      callback
    );
  }

  /**
   * deletes a location
   * @param locationId licencePlate Id
   * @param callback method
   */
  public deleteLocation(locationId: string, callback: any): void {

    this.apolloService.mutateAndExecute(
      fromGraphQl.DELETE_LOCATION_MUTATION,
      {
        locationId,
      },
      callback
    );
  }
}
