import { FormVariant } from "../formVariant/formVariant";

export class Form {
  key: string | undefined;
  name: string | undefined;
  variants: FormVariant[];
  activeVariant: FormVariant;
  isCustomizable: boolean | undefined;

  constructor(payload?: any) {
    if (payload) {
      this.key = payload.key;
      this.name = payload.name;
      if (payload.variants) {
        this.variants = FormVariant.createFormVariants(payload.variants);
      }
      this.isCustomizable = payload.isCustomizable;
      if (payload.activeVariant) {
        this.activeVariant = new FormVariant(payload.activeVariant);
      }
    }

  }

  /**
   * creates  from a result
   * @param result Array of any
   * @returns Array of
   */
  static createForms(result: Array<any>): Form[] {
    const variants = new Array<Form>();
    if (result) {
      result.map((model) => {
        variants.push(new Form(model));
      });
    }
    return variants;
  }
}




