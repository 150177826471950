import { CdkDrag, CdkDropList, DragRef } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
// import { selectProtocolModel } from '../../../base-data/reducers';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

// import { TaskPosition } from '../../entities/taskPosition';
// import { TaskService } from '../../services/task/task.service';
// import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import { Router } from '@angular/router';
import { RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { merge, tap } from 'rxjs';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { AddressDialogComponent } from 'src/app/shared-module/components/address-dialog/address-dialog.component';
import { TaskFilesDialogComponent } from 'src/app/shared-module/components/task-files-dialog/task-files-dialog.component';
import { Tour } from '../../shared/entities/tour';
import { TaskInfoDialogComponent } from '../task-info-dialog/task-info-dialog.component';
import { ToursPlannerTaskListDataSource } from './toursPlannerTaskListDataSource';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-tours-planner-tasks-list",
  templateUrl: "./tours-planner-tasks-list.component.html",
  styleUrls: ["./tours-planner-tasks-list.component.scss"]
})
export class ToursPlannerTasksListComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild('container') private container: ElementRef;
  heightOfPreviewElement: number;
  public innerWidth: any;

  _dragRef: DragRef<CdkDrag>;
  selected: any[] = [];
  public locationIds: any;
  public text: string;
  public fromDate: string;
  public toDate: string;
  public preview: string;
  public taskAssignmentInput: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<Task>;


  displayedColumns: string[] = [
    "type",
    "order.number",
    //'dueDate',
    "dueDateTimePeriod",
    'description',
    'customer',
    // "timeWindow",
    // "units",
    // "customerName1",
    "address.street",
    "address.postalCode",
    "address.city",
    "address.geocodingResultGrade",
    'order.location.name',
    'task.address.note',
    'info'
    // "notes",
    // "actions"
  ];
  initDisplayedColumns: string[] = [
    "type",
    "order.number",
    //'dueDate',
    "dueDateTimePeriod",
    'description',
    'customer',
    "address.street",
    "address.postalCode",
    "address.city",
    "address.geocodingResultGrade",
    'order.location.name',
    'task.address.note',
    'info'
  ];
  mediumDisplayedColumns: string[] = [
    "type",
    "order.number",
    'dueDate',
    "dueDateTimePeriod",
    'description',
    'customer',
    "address.geocodingResultGrade",
    'order.location.name',
    'info'
  ];
  smallDisplayedColumns: string[] = [
    "type",
    "order.number",
    'dueDate',
    "dueDateTimePeriod",
    // 'description',
    'customer',
    // "address.postalCode",
    "address.geocodingResultGrade",
    'info'
  ];
  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number; }; }) {
    if (event.target.innerWidth < 1800) {
      this.displayedColumns = this.smallDisplayedColumns;
    } else if (event.target.innerWidth < 2100) {
      this.displayedColumns = this.mediumDisplayedColumns;
    }else if (event.target.innerWidth >= 2100) {
      this.displayedColumns = this.initDisplayedColumns;
    }
  }
  dataSource : ToursPlannerTaskListDataSource;
  test = false;
  // @ViewChild(MatSort) set matSort(sort: MatSort) {
  //   if (!this.dataSource.sort) {
  //     this.dataSource.sort = sort;
  //   }
  // }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input()
  set showSite(showSite: boolean) {
    if (showSite) {
      this.displayedColumns.splice(5, 0, "subsidiary");
    } else {
      const index = this.displayedColumns.indexOf("subsidiary", 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  @Input()
  set showStreet(showStreet: boolean) {
    if (showStreet) {
      const indexOfCity = this.displayedColumns.indexOf("city", 0);
      const indexOfHouseNumber = this.displayedColumns.indexOf("houseNumber", 0);
      this.displayedColumns.splice(indexOfHouseNumber > -1 ? indexOfHouseNumber : indexOfCity, 0, "street");
    } else {
      const index = this.displayedColumns.indexOf("street", 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  @Input()
  set showHouseNumber(showHouseNumber: boolean) {
    if (showHouseNumber) {
      const indexOfCity = this.displayedColumns.indexOf("city", 0);
      // console.log(indexOfCity);
      const indexOfStreet = this.displayedColumns.indexOf("street", 0);
      // console.log(indexOfStreet);
      this.displayedColumns.splice(indexOfStreet > -1 ? indexOfStreet + 1 : indexOfCity, 0, "houseNumber");
    } else {
      const index = this.displayedColumns.indexOf("houseNumber", 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  @Input()
  set showNote(showNote: boolean) {
    if (showNote) {
      const indexOfGeoCoded = this.displayedColumns.indexOf("geoCoded", 0)
      this.displayedColumns.splice(indexOfGeoCoded + 1, 0, "note");
    } else {
      const index = this.displayedColumns.indexOf("note", 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  @Input()
  set displayColumns(displayedColumns: string[]) {
    if (displayedColumns && displayedColumns.length > 0) {
      this.displayedColumns = displayedColumns;
    }
  }
  @Input() set settings(settings: any) {
    this.locationIds = settings?.locationIds;
    this.fromDate = settings?.fromDate;
    this.toDate = settings?.toDate;
    this.text = settings?.text;
    this.preview = settings?.preview;
    this.taskAssignmentInput = {
      "assignmentType": "TourTaskAssignmentStrategy",
      "fromDateTime": this.fromDate,
      "toDateTime": this.toDate
    };
    // this.displayedColumns = settings?.displayedColumns;
    this.setTableDataSource();
  }
  @Input() draggingActive: boolean;
  @Input() loading: boolean;

  @Input() showLocation = true;
  @Input() showAddressNote = false;

  @Output() removeTaskFromTour = new EventEmitter();
  @Output() deleteTask = new EventEmitter();
  @Output() splitTask = new EventEmitter();
  @Output() sortTasks = new EventEmitter();
  @Output() dragStart = new EventEmitter();
  @Output() dragEnd = new EventEmitter();
  @Output() sortTable = new EventEmitter();
  @Output() acceptAddress = new EventEmitter();
  @Output() updateAddress = new EventEmitter();


  constructor(
    public tasksService: TasksService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    public rolesService: RolesService,
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
  ) { }

  /**
   *  99999999999 is an unreal high value to sort null or
   * existing values at the end of the list
   */
  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchloadTaskList()))
      .subscribe();
  }

  ngOnInit(): void {

    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1800) {
      this.displayedColumns = this.smallDisplayedColumns;
    } else if (this.innerWidth < 2100) {
      this.displayedColumns = this.mediumDisplayedColumns;
    }else if (this.innerWidth >= 2100) {
      this.displayedColumns = this.initDisplayedColumns;
    }
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
  }

  setTableDataSource(): void {
    this.dataSource = new ToursPlannerTaskListDataSource(this.apollo);
    // if(this.orderId) {
      this.dataSource.loadTaskList({
        locationIds: this.locationIds,
        fromDate: this.fromDate,
        toDate: this.toDate,
        text: this.text,
        preview: this.preview,
        sortProperty: this.sort?.active ? this.sort.active : 'id',
        sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
        taskAssignmentInput:  this.taskAssignmentInput,
        number: 0,
        size: 15,

      });
    // }
  }

  refetchloadTaskList(): void {
    this.dataSource.refetchQuery({
      locationIds: this.locationIds,
      fromDate: this.fromDate,
      toDate: this.toDate,
      text: this.text,
      preview: this.preview,
      sortProperty: this.sort?.active ? this.sort.active : 'id',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      taskAssignmentInput:  this.taskAssignmentInput,
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 15,
    });
  }


  removeTask($event: Task) {
    let task: Task;
    if ($event.id) {
      task = $event;
    }
    // else {
    //   task = $event.dragData;
    // }
    // if(task.tour){
    //   this.removeTaskFromTour.emit(task);
    // }
  }

  delete($event: any) {
    this.deleteTask.emit($event);
  }

  split($event: any) {
    this.splitTask.emit($event);
  }

  checkSplitDisabled(task: Task) {
    // if(task && task.taskPositions && task.taskPositions.length > 1){
    //   return false;
    // }
    return true;
  }

  _acceptAddress(task: Task) {
    this.acceptAddress.emit(task.address.id);
  }

  checkAcceptAddress(task: Task) {
    if (task && task.address) {
      // && task.address.geoCodeResultGrade == 'yellow'
      return false;
    }
    return true;
  }

  onDragStart($event: any) {
    console.log("stopPolling polling");
    this.dataSource?.stopPolling();
    this.dragStart.emit($event);
  }

  onDragEnd($event: any) {
    this.dataSource?.startPolling();
    this.dragEnd.emit($event);
  }

  removeDropZone() {
    this.test = false;
  }

  attachDropZone() {
    this.test = true;
  }

  protocolNotesAvailable(task: Task) {
    // return this.taskService.protocolNotesAvailable(task);
  }

  buildProtocolNote(task: Task) {
    return '';
    // return this.taskService.buildProtocolNote(task);
  }

  editAddress(task: Task) {
    let dialogRef = this.dialog.open(AddressDialogComponent, {
      width: "1100px",
      data: {
        address: task.address,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      // address update
      if (result?.addressInput) {
        this.updateAddress.emit(result.addressInput);
      }
      // // accept address
      // if(result?.addressId) {
      //   this.acceptAddress.emit(result.addressId);
      // }
    });
  }

  dragStarted($event: any) {
    // console.log("drag started");
    this.dataSource?.stopPolling();
    this.dragStart.emit();
    // console.log("stop polling");
  }

  dragReleased($event: any) {
    // console.log("drag released");
    // this.refetchloadTaskList();
    this.dataSource?.startPolling();
    this.dragEnd.emit();
  }

  refetch() {
    this.refetchloadTaskList();
  }

  dragDropped(event: any) {
    // check, if we want to receive this event
    // we want to receive it, if the preivous container is:
    // - toursTaskList
    //console.log("Open task list: Received drop event list from " + event.previousContainer.id);
    if (event.previousContainer.id === "toursTaskList") {
      //console.log("Open task list: Task comes from " + event.previousContainer.id + " and we want to receive it.");
      const tour = event.previousContainer.data as Tour;
      const task = event.item.data as Task;
      // console.log("Open task list:  Dropped task " + event.item.data.id + " from tour " + tour.id + " into open task list.");
      this.removeTaskFromTour.emit({
        tour: tour.id,
        task: task.id
      });
      this.dragEnd.emit();
    } else {
      //console.log("Open task list: Ignore drop event.");
    }

  }

  enterPredicate(drag: CdkDrag, drop: CdkDropList) {
    return true;
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  buildArticleString(taskPositions: any[]) {
    let articleNumberString = "";
    if (taskPositions && taskPositions.length > 0) {
      for (let index = 0; index < taskPositions.length; index++) {
        const element = taskPositions[index];
        articleNumberString = articleNumberString + taskPositions[index].articleNumber;
        if (index < taskPositions.length - 1) {
          articleNumberString = articleNumberString + ", ";
        }
      }
    }
    return articleNumberString;
  }

  dragDisabled(task: Task) {
    if(this.loading) {
      return true;
    }
    if (!this.rolesService?.hasRole('nexcore_tour_update')) {
      return true;
    }
    return !task.isAssignable;
  }

  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  taskInfo(task: Task) {
    const dialogRef = this.dialog.open(TaskInfoDialogComponent, {
      width: '700px',
      minHeight: '650px',
      data: {
        task: task,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(dialog => {
    })
  }
  goToOrder(task: Task) {
    // console.log('hier bin ich', task)
    if (task && task.order && task.order.id) {
      this.router.navigate(["./orders/order/" + task.order.id]);
    }
  }


  confirmDialog(task: Task): void {
    // const message = 'Möchtest Du den Auftrag (' + this.order.number +') wirklich löschen?';
    // const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
    // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //   maxWidth: "400px",
    //   data: dialogData,
    //   autoFocus: false
    // });

    // dialogRef.afterClosed().subscribe((dialogResult: any) => {
    //   if (dialogResult) {
    //     this.loadingService.setLoading(true);
    //     this.deleteOrder();
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect(this.table);
  }

  taskFiles(task: Task) {
    const dialogRef = this.dialog.open(TaskFilesDialogComponent, {
      width: '700px',
      minHeight:'650px',
      data: {
        task: task,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(dialog=>{
    })
  }

  getDisplayedColumns(displayedColumns: any): any {
    // if showLocation is false, remove location column from array
    // by creatintg a new array
    if (!this.showLocation) {
      displayedColumns = displayedColumns.filter((item: any) => item !== 'order.location.name');
    }
    if (!this.showAddressNote) {
      displayedColumns = displayedColumns.filter((item: any) => item !== 'task.address.note');
    }
    return displayedColumns;
  }

}
