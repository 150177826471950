import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';

export const TASK_ASSIGNMENT_QUERY = gql`
query TasksQuery(
  $number: Int, $size: Int,
  $sortProperty: String,
  $sortDirection: String,
  $states: [TaskState],
  $text: String,
  $fromDateTime: String,
  $toDateTime: String,
  $locationIds: [UUID],
  $types: [String]
)
{
  tasks: getTasksPaged(
    taskFilter: { states: $states, handlingType: PICKUP, text: $text, toDateTime: $toDateTime, fromDateTime: $fromDateTime,
      locationIds: $locationIds, types: $types,
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
  }){
    totalPages
    totalElements
    number
    size
    content{
      id
      description
      type
      state
      dueDateTimePeriod {
        modifier
        dateTime
        fromDateTime
        toDateTime
      }
      order{
        id
        number
        customer {
          type
          companyName
          companyNameExtension
          firstName
          lastName
        }
        location {
          name
        }
        comments {
          text
          lastModifiedBy {
            id
            firstName
            lastName
          }
          lastModifiedDateTime
        }
        note
        fileCount
      }
      assignmentStrategyCandidates{
        label
        type
        settings
      }
      assignmentSuggestion {
        assigneeId
        assignmentType
        typeLabel
        name
        dueDateTimePeriod{
            modifier
            dateTime
            fromDateTime
            toDateTime
        }
      }
      assignment {
        assigneeId
        assignmentType
        typeLabel
        name
        dueDateTimePeriod{
            modifier
            dateTime
            fromDateTime
            toDateTime
        }
      }
      completedBy {
        firstName
        lastName
      }
      completedDateTime
    }
  }
}
`;

export interface TasksQueryResponse {
  tasks: Page<Task>;
  loading: boolean;
}


