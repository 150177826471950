import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Order } from '../../../order-module/shared/entities/order/order';
import { LoadingService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { merge, tap } from 'rxjs';
import { TourDataSource } from './tourDataSource';
import { Router } from '@angular/router';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import moment from 'moment';

@Component({
  selector: 'app-dashboard-tour-list',
  templateUrl: './dashboard-tour-list.component.html',
  styleUrls: ['./dashboard-tour-list.component.scss']
})
export class DashboardTourListComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Order>;

  public states: string[] = [];
  public type: string;
  public dataSource: TourDataSource;
  public displayedColumns: string[] = ['name', 'resource', 'assignee'];
  public location: string;
  public date = new Date();
  public initialDate = new Date();

  @Input() set selectedLocation(location: string){
    this.location = location;
  }
  @Input() set filter(filter: any) {
    // console.log('type', this.type);
    this.type = filter?.type;
    this.location = filter?.location;
    if (this.type === 'planned') {
      this.states = ['NEW'];
      this.displayedColumns = ['name', 'resource', 'assignee', 'scheduledDepartureDateTime'];
    } else if (this.type === 'running') {
      this.states = ['RUNNING'];
      this.displayedColumns = ['name', 'resource', 'assignee', 'actualDepartureDateTime'];
    } else if (this.type === 'finished'){
      this.states = ['FINISHED'];
      this.displayedColumns = ['name', 'resource', 'assignee', 'actualArrivalDateTime'];
    }
    // console.log('customer id', this.displayedColumns);
    this.setTableDataSource();
  }

  @Input() set setDate(date: any) {
    this.date = date;
  }

  constructor(
    private router: Router,
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    // public dialog: MatDialog,
    private loadingService: LoadingService,
    //public rolesService: RolesService
  ) {
   // this.setTableDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLocation) {
      this.location = changes.selectedLocation.currentValue
      this.refetchTours();
    }
    if (changes.setDate) {
      this.date = changes.setDate.currentValue;
      this.refetchTours();
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect(this.table);
  }

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
  }
  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchTours()))
      .subscribe();
  }

  setTableDataSource(): void {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    this.dataSource = new TourDataSource(this.apollo);
    this.dataSource.loadTours({
      number: 0,
      size: 10,
      sortProperty: 'id',
      sortDirection: 'asc',
      states: this.states,
      locationIds: this.location,
      fromDate,
      toDate
    });
  }

  refetchTours(): void {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 10,
      sortProperty: this.sort?.active ? this.sort.active : 'name',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      states: this.states,
      locationIds: this.location,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }
}
