<div [formGroup]="form" *ngIf="form">
  <div *ngFor="let setting of this.settings; let isLast = last;" [ngClass]="isLast? '': 'border-b'">
    <ng-container *ngIf="isCategory(setting)">
      <div class="flex flex-row py-2" >
        <div class="title basis-1/3">
          <h3>{{setting.label}}</h3>
          <div class="hint">{{setting?.description}}</div>
        </div>
        <div class="settings basis-2/3 items-center">
          <div *ngFor="let field of setting.settingDescriptions; let i=index;" class="pb-5">
            <!-- quick fix for not getting a list of filtered descriptions from the backend -->
            <ng-container *ngIf="(locationId && field?.allowLocationOverride) || !locationId">
            <div [ngSwitch]="true">
              <mat-label class="flex items-center"  *ngSwitchCase="field.type === 'String' || field.type === 'Integer' || field.type === 'Boolean' || field.type === 'File'">{{field.label}} <i [className]="'icon icon-info'" (click)="$event.stopPropagation();"
                [matTooltip]="field.description? field.description : 'Kein Note hinterlegt'"  matTooltipPosition="right"
                [ngClass]="field.description? 'note' :'nonote'"></i></mat-label>
              <mat-slide-toggle *ngSwitchCase="field.type === 'Boolean'" formControlName={{field.key}} />
              <mat-form-field class="w-96 white dense-3" *ngSwitchCase="field.type === 'String' || field.type === 'Integer'">
                <input *ngSwitchCase="field.type === 'String'" matInput formControlName={{field.key}} class="w-1/2">
                <input *ngSwitchCase="field.type === 'Integer'" matInput formControlName={{field.key}} class="w-1/2" type="number">
                <mat-hint *ngSwitchCase="field.type === 'String' || field.type === 'Integer'">{{field?.defaultValue}}</mat-hint>
              </mat-form-field>
              <app-nx-button (click)="reset(field)" icon="icon icon-return"
                *ngSwitchCase="isEditable && (field.type === 'String' || field.type === 'Integer')"
                [disabled]="form.controls[field?.key].value === field.defaultValue" toolTip="zurücksetzen">
              </app-nx-button>
                <div fxFlex="60%" class="image items-center flex basis-full" *ngSwitchCase="field.type === 'File'">
                  <div class="basis-1/2">
                    <img src="{{fileApi}}/view/{{field.currentValue?.file?.fullPath}}?width=69&height=48}}" *ngIf="field.currentValue" class="img-responsive">
                    <span *ngIf="!field.currentValue" class="grey">Keine Dateien vorhanden</span>
                  </div>
                  <div class="basis-1/2 flex justify-end">
                    <app-nx-button *ngIf="field.currentValue" (click)="isEditable? confirmDialog(field): $event.stopPropagation()" toolTip="Datei Löschen" icon="icon icon-trash" [disabled]="!isEditable">
                    </app-nx-button>
                    <app-nx-button (click)="isEditable? fileInput.click(): $event.stopPropagation()" toolTip="Datei hochladen" icon="icon icon-document-upload" [disabled]="!isEditable">
                    </app-nx-button>
                    <input #fileInput type="file" style="display:none;" (change)="fileChangeEvent($event, field)" [accept]="field?.settings?.fileTypes"/>
                    <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>
                  </div>
                </div>
            </div>
            </ng-container>
          </div>
        </div>
      </div>

    </ng-container>
  </div>
</div>
