<form [formGroup]="addressForm" class="flex flex-row space-x-4">
  <mat-form-field class="w-2/6">
    <input matInput placeholder="Straße" formControlName="street">
  </mat-form-field>
  <mat-form-field class="w-1/6">
    <input matInput placeholder="Hausnummer" formControlName="houseNumber">
  </mat-form-field>
  <mat-form-field class="w-1/6">
    <input matInput placeholder="PLZ" formControlName="postalCode">
  </mat-form-field>
  <mat-form-field class="w-2/6">
    <input matInput placeholder="Stadt" formControlName="city">
  </mat-form-field>
  <button mat-icon-button class="small" (click)="editAddress()">
    <mat-icon>mode_edit</mat-icon>
  </button>
</form>