<ng-container *ngIf="controlTemplate?.settings?.value; else noValue">
    <div class="flex flex-row py-2 pr-2 pt-3 justify-end border-b border-solid border-gray-300 last:border-b-0">
        <input type="checkbox" [checked]="checked()" disabled>
    </div>
    <div class="flex flex-row py-2 pr-2 justify-end border-b border-solid border-gray-300 last:border-b-0" *ngIf="showText()">
        {{controlTemplate?.settings?.value?.text}}
    </div>
</ng-container>
<ng-template #noValue>
    <div class="text-right p-2">-</div>
</ng-template>