import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmDialogComponent, ConfirmDialogModel, File, FileInput, LoadingService, RolesService, User } from '@nexato/nx-core-module';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrderService } from '../../../order-module/shared/services/order/order.service';
import { OrderFilesListDataSource } from './orderFilesListDataSource';
import { Apollo } from 'apollo-angular';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { merge, tap } from 'rxjs';

@Component({
  selector: 'app-order-files',
  templateUrl: './order-files.component.html',
  styleUrls: ['./order-files.component.scss']
})
export class OrderFilesComponent implements OnInit, AfterViewInit{

  public uploading = false;
  public ownerId: string;
  public value = '';
  public isFile = false;
  public fileName = '';
  public fileApi = environment.fileApi;

  @Input() set order(orderId: string) {
    this.ownerId = orderId;
    this.setTableDataSource();
  }
  @Input() header: string;
  @Input() headerColor :string = '#EBEEEF';
  @Output() updateChild = new EventEmitter();
  public displayedColumns = ['text'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Task>;

  public dataSource: OrderFilesListDataSource;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    protected keycloakAngular: KeycloakService,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    private router: Router,
    public orderService: OrderService,
    public matPaginatorIntl: MatPaginatorIntl,
    public rolesService: RolesService,
  ) {}

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchloadTaskList()))
      .subscribe();
  }

  setTableDataSource(): void {
    this.dataSource = new OrderFilesListDataSource(this.apollo);
    this.dataSource.loadFilesList({
      number: 0,
      size: 5,
      sortProperty: 'lastModifiedDate',
      sortDirection: 'desc',
      ownerId: this.ownerId,
    });
  }

  refetchloadTaskList(): void {
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 5,
      sortProperty: this.sort?.active ? this.sort.active : 'uploadedDateTime',
      sortDirection: this.sort?.direction ? this.sort.direction : 'desc',
      ownerId: this.ownerId,
    });
  }

  fileChangeEvent(event: any): void {
    if (this.uploading || event.target?.files?.length < 1) {
      return;
    }
    this.loadingService.setLoading(true);
    let extension = event.target?.files[0].type;
    extension = extension.replace(/(.*)\//g, '');
    const uuid = uuidv4();
    this.value = uuid;
    this.uploading = true;
    const params = new HttpParams({
      fromObject: {
        fileName:  event.target?.files[0].name
      }
    });
    const url = environment.fileApi + '/file/upload/?ownerId=' + this.ownerId;
    const formData: FormData = new FormData();
    formData.append('file', event.target?.files[0], this.value + '.' + extension);
    this.http
      .post(url,formData, {params})
      .toPromise()
      .then((data) => {
        console.log('Upload Success', data);
        this.returnUpload();
        this.uploading = false;
      })
      .catch((error) => {
        console.log('Upload Error', error);
        this.returnUpload();
      });
  }
  returnUpload(): void {
    setTimeout(() => {
      this.uploading = false;
      this.refetchloadTaskList();
      this.updateChild.emit({
        refetch : true
      });
      this.loadingService.setLoading(false);
    }, 1000);
  }

  public downloadPDF(file: any): void {
    const downloadName = file?.fileName;
    const url = environment.fileApi + '/file/'+ file?.fullPath;
    this.http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
      //const blob = new Blob([response]);
      saveAs(response, downloadName);
    });
  }
  getFileInput(files: File[]) : FileInput[] {
    let fileInputs = [];
    for (let val of files) {
      fileInputs.push(new FileInput({'id': val?.id}));
    }
    return fileInputs;
  }

  confirmDialog(file: File): void {
    const message = 'Möchtest Du den Datei wirklich löschen?';
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.loadingService.setLoading(true);
        this.deleteFile(file.id);
      }
    });
  }
  deleteFile(id: string){
    this.orderService.deleteFile(
      id,
      this.returnCallback(this.router)
    );
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.refetchloadTaskList();
      this.updateChild.emit({
        refetch : true
      });
      this.loadingService.setLoading(false);
    }, 500);
  }

  getSizeinMb(size: number) {
   return (size /(1024*1024) ).toFixed(2);
  }

}
