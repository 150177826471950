import { Component, forwardRef } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import moment, { Moment } from 'moment';
import { DateTimePeriod, DateTimePeriodInput } from '../../shared/entities/dateTimePeriod/dateTimePeriod';


@Component({
  selector: "app-date-time-period-form-control",
  templateUrl: "./date-time-period-form-control.component.html",
  styleUrls: ["./date-time-period-form-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePeriodFormControlComponent),
      multi: true
    }
  ]
})
export class DateTimePeriodFormControlComponent {
  
  public dateTimePeriodForm: FormGroup;
  public _dateTimePeriod: DateTimePeriod;

  constructor(
    private fb: FormBuilder,
  ) {
    this.initializeForm();
  }


  initializeForm() {
    this.dateTimePeriodForm = this.fb.group({
      'id': [null],
      'modifier': [null, [Validators.required]],
      'dateTime': [null, [Validators.required]],
      'fromDateTime': [null, [Validators.required]],
      'toDateTime': [null, [Validators.required]]
    });

    // set the validators according to the currently set modifier
    this.dateTimePeriodForm.get('modifier')?.valueChanges.subscribe( (modifier) => {
      if(modifier === 'BETWEEN') {
        this.dateTimePeriodForm.removeControl('dateTime');
        if (! this.dateTimePeriodForm.get('fromDateTime') ) {
          this.dateTimePeriodForm.addControl('fromDateTime', this.fb.control(null, [Validators.required]));
          if(this._dateTimePeriod?.fromDateTime){
            this.dateTimePeriodForm.get('fromDateTime')?.setValue(moment(this._dateTimePeriod.fromDateTime));
          }
        }
        if (! this.dateTimePeriodForm.get('toDateTime') ) {
          this.dateTimePeriodForm.addControl('toDateTime', this.fb.control(null, [Validators.required]));
          if(this._dateTimePeriod?.toDateTime){
            this.dateTimePeriodForm.get('toDateTime')?.setValue(moment(this._dateTimePeriod.toDateTime));
          }
        }
      } else {
        this.dateTimePeriodForm.removeControl('fromDateTime');
        this.dateTimePeriodForm.removeControl('toDateTime'); 
        if (! this.dateTimePeriodForm.get('dateTime') ) {
          this.dateTimePeriodForm.addControl('dateTime', this.fb.control(null, [Validators.required]));
          if(this._dateTimePeriod?.dateTime){
            this.dateTimePeriodForm.get('dateTime')?.setValue(moment(this._dateTimePeriod.dateTime));
          }
        }
      }
      this.dateTimePeriodForm.updateValueAndValidity();
    });

    // this.dateTimePeriodForm.valueChanges.subscribe(() => {
    //   console.log(this.dateTimePeriodForm);
    //   // console.log(this.createModel())
      
    // })

    this.dateTimePeriodForm.statusChanges.subscribe( ( status ) => {
      if(status == 'VALID') {
        this.propagateChange(this.createModel())
      } else {
        this.propagateChange(undefined)
      }
    })
  }


  /** value accessor */
  writeValue(dateTimePeriod: DateTimePeriod): void {
    if (dateTimePeriod) {
      this._dateTimePeriod = dateTimePeriod;
      this.patchValue(dateTimePeriod);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateTimePeriodForm.disable();
    } else {
      this.dateTimePeriodForm.enable();
    }
  }

  createModel(): DateTimePeriodInput{
    const dateTimePeriodInput = new DateTimePeriodInput();
    dateTimePeriodInput.id = this.dateTimePeriodForm.get('id')?.value;
    dateTimePeriodInput.modifier = this.dateTimePeriodForm.get('modifier')?.value;

    if (this.dateTimePeriodForm.get('dateTime')?.value) {
      let dateTime = this.dateTimePeriodForm.get('dateTime')?.value as any as Moment;
      dateTimePeriodInput.dateTime = dateTime.toISOString();
    }

    if (this.dateTimePeriodForm.get('fromDateTime')?.value) {
      let fromDateTime = this.dateTimePeriodForm.get('fromDateTime')?.value as any as Moment;
      dateTimePeriodInput.fromDateTime = fromDateTime.toISOString();
    }

    if (this.dateTimePeriodForm.get('toDateTime')?.value) {
      let toDateTime = this.dateTimePeriodForm.get('toDateTime')?.value as any as Moment;
      dateTimePeriodInput.toDateTime = toDateTime.toISOString();
    }
    // console.log(dateTimePeriodInput);
    return dateTimePeriodInput;
    // return undefined;
  }

  showTwoColums(): boolean {
    const currentModifier = this.dateTimePeriodForm.value['modifier'];
    if(currentModifier === 'BETWEEN'){
      return true;
    }
    return false;
  }

  /**
   * We use a custom patchValue function to patch the form because
   * we must convert the strig iso timestamps to moments
   * 
   * @param dateTimePeriodInput 
   */
  patchValue(dateTimePeriodInput: DateTimePeriodInput) {
  
    if(dateTimePeriodInput?.modifier){
      this.dateTimePeriodForm?.get('modifier')?.setValue(dateTimePeriodInput.modifier);
    }
    if(dateTimePeriodInput?.dateTime){
      this.dateTimePeriodForm?.get('dateTime')?.setValue(moment(dateTimePeriodInput.dateTime));
    }
    if(dateTimePeriodInput?.fromDateTime){
      this.dateTimePeriodForm?.get('fromDateTime')?.setValue(moment(dateTimePeriodInput.fromDateTime));
    }
    if(dateTimePeriodInput?.toDateTime){
      this.dateTimePeriodForm?.get('toDateTime')?.setValue(moment(dateTimePeriodInput.toDateTime));
    }
  
  }

  getMaxFromDateTime(): any {
    // the max from Date Time is the value of toDateTime
    if(this.dateTimePeriodForm.get('toDateTime')?.value){
      return this.dateTimePeriodForm.get('toDateTime')?.value;
    }
    return undefined;
  }

  getMinFromDateTime(): any {
    return undefined;
  }

}
