<div class="body">
  <div class="block-header flex items-center">
    <h2 class="flex items-center" *ngIf="displayType === 'out'">
      <i [className]="'icon icon-pickup-out'"></i>
      &nbsp;
      <span class="heading">Abholungen</span>&nbsp; ({{dataSource?.count}})
    </h2>
    <h2 class="flex items-center" *ngIf="displayType === 'in'">
      <i [className]="'icon icon-pickup-in'"></i>&nbsp;
      <span class="heading">Rücknahmen</span>&nbsp; ({{dataSource?.count}})
    </h2>
    <h2 class="flex items-center" *ngIf="displayType === 'completed'">
      <i class="icon icon-pickup_check"></i>&nbsp;
      <span class="heading">Erledigt</span>&nbsp; ({{dataSource?.count}})
    </h2>
  </div>

  <div [hidden]="dataSource?.loading| async" class="full">
    <mat-table [dataSource]="dataSource" matSort class="full">
      <ng-container matColumnDef="order.number">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="header_column table_column firstHeader">Auftragsnummer</mat-header-cell>
        <mat-cell *matCellDef="let task" class="table_column first items-center flex">
          <i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i>
          &nbsp;
          <span class="noWrap"
          #number
          [matTooltipDisabled]="isEllipsisActive(number)"
          matTooltipShowDelay="250"
          matTooltip="{{task.order.number}}">
            <a [routerLink]="['/orders/order/', task.order.id ]" class="jump">
              {{task.order.number}}
              <i [className]="'icon icon-jump-to'"></i>
            </a>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="resource">
        <mat-header-cell *matHeaderCellDef class="table_column">Artikel</mat-header-cell>
        <mat-cell *matCellDef="let task" class="table_column">
          <span class="noWrap"
          #resource
          [matTooltipDisabled]="isEllipsisActive(resource)"
          matTooltipShowDelay="250"
          matTooltip="{{getResources(task?.order?.lineItems)}}">
            {{getResources(task?.order?.lineItems)}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="contact">
        <mat-header-cell *matHeaderCellDef class="table_column">Kunde</mat-header-cell>
        <mat-cell *matCellDef="let task" class="table_column">
          <span class="noWrap"
          #customer
          [matTooltipDisabled]="isEllipsisActive(customer)"
          matTooltipShowDelay="250"
          matTooltip="{{task.order?.customer?.getFullName()}}">
            {{task.order?.customer?.getFullName()}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dueDateTimePeriod">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Fällig</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap"
          #dueDateTimePeriod
          [matTooltipDisabled]="isEllipsisActive(dueDateTimePeriod)"
          matTooltipShowDelay="250"
          matTooltip="{{taskService.getDeliveryTime(task?.dueDateTimePeriod)}} /{{taskService.getDeliveryDate(task?.dueDateTimePeriod)}}">{{taskService.getDeliveryTime(task?.dueDateTimePeriod)}} /
            {{taskService.getDeliveryDate(task?.dueDateTimePeriod)}}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="table_header">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openTask(row)" class="table_row border-b items-center">
      </mat-row>
      <div *matNoDataRow class="no-data flex items-top justify-center">
        <p>keine Aufgaben vorhanden</p>
      </div>
    </mat-table>
  </div>
  <div *ngIf="dataSource?.loading | async" class="spinner">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
  <div class="footer">
    <div class="pagination">
      <mat-paginator [length]="dataSource?.totalElements" [pageSize]="10" [pageIndex]="0"
        [pageSizeOptions]="[10, 15, 20]" aria-label="Select page" class="paginator"
        [hidden]="!dataSource?.totalElements || dataSource?.totalElements< 11"></mat-paginator>
    </div>
  </div>
</div>
