import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { RolesService } from '@nexato/nx-core-module';
import { CustomerDataSource } from './customerDataSource';
import { Router } from '@angular/router';
import { Contact, ContactType } from '../../shared/entities/contact/contact';
import { UntypedFormControl } from '@angular/forms';
// import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { debounceTime, distinctUntilChanged, merge, tap } from 'rxjs';


interface DynmaicTableComponentState{
  displayedColumns: string[],
}
@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent  implements AfterViewInit, OnDestroy, OnInit{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Contact>;
  @ViewChild('drawerRight') drawerRight: any;

  public text = '';
  public contactType: any = null;
  public contactTypes = Contact.getContactTypes();
  contactNameControl = new UntypedFormControl();
  formCtrlSub: any;
  public Contact = Contact;


  public displayedColumns: string[] = [
    'type',
    'number',
    'name',
    'address.street',
    'address.postalCode',
    'address.city',
    'address.country'
  ];
  public dataSource: CustomerDataSource;

  constructor(
    private router: Router,
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    // public dialog: MatDialog,
    public rolesService: RolesService
  ) {
    this.setTableDataSource();
  }
  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
    this.formCtrlSub = this.contactNameControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.text = term;
        this.selectionChanges();
      });
  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchContacts()))
      .subscribe();
  }

  setTableDataSource(): void {
    this.dataSource = new CustomerDataSource(this.apollo);
    const name = this.text ? this.text : '';
    this.dataSource.loadCustomers({
      number: 0,
      size: 15,
      sortProperty: 'type',
      sortDirection: 'asc',
      name,
      type: this.contactType
    });
  }

  createContact(): void {
    // const dialogRef = this.dialog.open(ContactComponent, {
    //   width: '760px',
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result && result.saved) {
    //     this.refetchContacts();
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect(this.table);
  }

  openContact(id: string): void {
    this.router.navigate(['./customers/customer/' + id]);
  }

  refetchContacts(): void {
    const name = this.text ? this.text : '';
    // console.log('type', this.contactType);
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 15,
      sortProperty: this.sort?.active ? this.sort.active : 'type',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      name,
      type: this.contactType
    });
  }

  selectionChanges() {
    this.paginator.pageIndex = 0
    this.refetchContacts();
  }

  getContactType(id: any): string | undefined {
    const type = Contact.getContactTypes().find((o) => o?.id === id?.toString());
    return type?.name;
  }

  // deleteContact(contactId: string) {
  //   this.contactService.deleteContact(
  //     contactId,
  //     this.returnCallback(this.router)
  //   );
  // }
  // confirmDialog(contact: Contact): void {
  //   const message = 'Möchtest Du den Kontakt (' + this.getContactName(contact) +') wirklich löschen?';
  //   const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     maxWidth: "400px",
  //     data: dialogData,
  //     autoFocus: false
  //   });

  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     if (dialogResult) {
  //       this.loadingService.setLoading(true);
  //       this.deleteContact(contact.id);
  //     }
  //   });
  // }
  // returnCallback(router: Router): void {
  //   setTimeout(() => {
  //     this.loadingService.setLoading(false);
  //     this.refetchContacts();
  //   }, 500);
  // }
  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }
}


