import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogModel, LoadingService, RolesService } from '@nexato/nx-core-module';
import { LocationEntity } from '../../../shared-module/shared/entities/location/location';
import { LocationDataSource } from './locationsDataSource';
import { LocationService } from '../../shared/services/location/location.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent implements OnInit, OnDestroy, AfterViewInit {
  public apiKeys: LocationEntity[];
  public dataSource: LocationDataSource;

  public displayedColumns: string[] = [
    'name',
    'button'
  ];

  @ViewChild('sort') sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<LocationEntity>;
  constructor(
    private router: Router,
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    private loadingService: LoadingService,
    public dialog: MatDialog,
    public locationService: LocationService,
    public rolesService: RolesService
  ) {
    if (!rolesService?.hasRole('nexcore_location_delete')) {
        this.displayedColumns = ['name']
    }
    this.setTableDataSource();
  }

  setTableDataSource(): void{
    this.dataSource = new LocationDataSource(this.apollo);
    this.dataSource.loadLocations({
      number: 0,
      size: 10,
      sortProperty: 'id',
      sortDirection: 'asc',
    });
  }

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchLocations()))
      .subscribe();
  }
  refetchLocations(): void {
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 10,
      sortProperty: this.sort?.active ? this.sort.active : 'id',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
    });
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect(this.table);
  }

  createLocation(): void {
    this.router.navigate(['./settings/locations/location/create']);
  }

  confirmDialog(key: LocationEntity): void {
    const message = 'Möchtest Du den Location (' + key.name +') wirklich löschen?';
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.loadingService.setLoading(true);
        this.deleteLocation(key.id);
      }
    });
  }

  editLocation(location: LocationEntity) {
    if (this.rolesService?.hasRole('nexcore_location_update')) {
      this.router.navigate(['./settings/locations/location/' + location.id]);
    }
  }

  deleteLocation(id: string) {
    this.locationService.deleteLocation(
      id,
      this.returnCallback(this.router)
    );
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.loadingService.setLoading(false);
      this.refetchLocations();
    }, 500);
  }


}
