import { Injectable } from '@angular/core';
import { ResourceInput } from '../../entities/resource/resource';
import * as fromGraphQl from './graphql';
import { ApolloService } from '@nexato/nx-core-module';

/**
 * sites service.
 */
@Injectable()
export class ResourceService {
  constructor(private apolloService: ApolloService) {}

  /**
   * updates a resource
   * @param resourceInput resource
   * @param callback method
   */
  public updateResource(resourceInput: ResourceInput, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UPDATE_RESOURCE_MUTATION,
      {
        resourceInput,
      },
      callback
    );
  }

  /**
   * creates a resource
   * @param resourceInput resource
   * @param callback method
   */
  public createResource(resourceInput: ResourceInput, callback: any): void {
    // upload resource
    // console.log("upload resource",  resourceInput);
    this.apolloService.mutateAndExecute(
      fromGraphQl.CREATE_RESOURCE_MUTATION,
      {
        resourceInput,
      },
      callback
    );
  }
}
