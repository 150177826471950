import { Apollo } from 'apollo-angular';
import { NXDataSource } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';

export class CustomerDataSource extends NXDataSource<any> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.CUSTOMERS_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.customersQueryResponse): void {
    // console.log('dta', data);
    super.setSubject(
      data.customers?.content ? data.customers?.content : [],
      data.customers?.totalElements ? data.customers.totalElements : 0
    );
  }

  loadCustomers(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
