import { Apollo } from 'apollo-angular';
import { NXDataSource } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';
import { Resource } from '../../shared/entities/resource/resource';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

export class ResourceDataSource extends NXDataSource<any> {
  public locations : LocationEntity[];
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.RESOURCES_QUERY, environment);
  }
  // customAttributes: string[] = [];
  handleSubscriptionResult(data: fromGraphQl.ResourcesQueryResponse): void {
    this.locations = data?.locations;
    super.setSubject(
      data.resources?.content ? Resource.createResources(data.resources?.content) : [],
      data.resources?.totalElements ? data.resources.totalElements : 0
    );

    // if (data.resources?.content && data.resources?.totalElements > 0) {
    //   for (let resource of data.resources?.content) {
    //     for (let customAttribute of resource?.customAttributes) {
    //         let name = customAttribute.name;
    //         if(this.customAttributes. indexOf(name) === -1){
    //           this.customAttributes. push(name);
    //         }
    //     }
    //   }
    //   console.log('custom attributes', this.customAttributes);
    // }
  }

  loadresources(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
