import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Order } from '../../../order-module/shared/entities/order/order';
import { LoadingService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { merge, tap } from 'rxjs';
import { Router } from '@angular/router';
import { TaskDataSource } from './taskDataSource';
import { TasksService } from '../../shared/services/tasks/tasks.service';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import moment from 'moment';
import { LineItem } from '../../shared/entities/lineItem/lineItem';

@Component({
  selector: 'app-dashboard-task-list',
  templateUrl: './dashboard-task-list.component.html',
  styleUrls: ['./dashboard-task-list.component.scss']
})
export class DashboardTaskListComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Order>;
  location: string;
  public states: string[] = ['NEW', 'ASSIGNED', 'DELEGATED', 'UNASSIGNED'];
  public types: string[];
  public displayType: string;
  public dataSource: TaskDataSource;
  public displayedColumns: string[] = ['order.number', 'resource', 'contact', 'dueDateTimePeriod'];

  @Input() set filter(filter: any) {
    this.displayType = filter?.type;
    // console.log('type', type);
    if (this.displayType === 'in') {
      this.types = ['nexcore_rental_resourceAssignmenmt_in'];
    } else if(this.displayType === 'out') {
      this.types = ['nexcore_rental_resourceAssignmenmt_out'];
    } else {
      this.states = ['COMPLETED']
    }
    this.location = filter?.location;
    this.setTableDataSource();
    // console.log('customer id', this.customerId);
  }

  @Input() set selectedLocation(location: string){
    this.location = location;
  }
  public date = new Date();
  public initialDate = new Date();

  @Input() set setDate(date: any) {
    this.date = date;
  }
  constructor(
    private router: Router,
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    // public dialog: MatDialog,
    private loadingService: LoadingService,
    public taskService: TasksService,
    //public rolesService: RolesService
  ) {
   // this.setTableDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLocation) {
      this.location = changes.selectedLocation.currentValue
      this.refetchTasks();
    }
    if (changes.setDate) {
      this.date = changes.setDate.currentValue;
      this.refetchTasks();
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect(this.table);
  }

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
  }
  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchTasks()))
      .subscribe();
  }

  setTableDataSource(): void {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    this.dataSource = new TaskDataSource(this.apollo);
    this.dataSource.loadTasks({
      number: 0,
      size: 10,
      sortProperty: 'dueDateTimePeriod',
      sortDirection: 'asc',
      states: this.states,
      types: this.types,
      locationIds: this.location,
      fromDate: fromDate,
      toDate: toDate,
      includeOverDue: false
    });
  }

  refetchTasks(): void {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    this.dataSource?.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 10,
      sortProperty: this.sort?.active ? this.sort.active : 'dueDateTimePeriod',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      states: this.states,
      types: this.types,
      locationIds: this.location,
      fromDate: fromDate,
      toDate: toDate,
      includeOverDue: false
    });
  }

  openTask(row: any) {

  }
  getResources(lineItems: LineItem[]) {
    let resources = [];
    for (let lineItem of lineItems) {
      for (let assignment of lineItem?.resourceAssignments) {
        resources.push(assignment?.resource?.name)
      }
    }
    return resources?( resources.join(', \n') ): '-';
  }
  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

}
