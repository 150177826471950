import { LocationEntity, LocationInput } from "src/app/shared-module/shared/entities/location/location";

export class Resource {
  id: string | undefined;
  externalId: string | undefined;
  number: string;
  name: string;
	resourceAssignments: any[]
	customAttributes: any[]
	isDeletable: Boolean
  functions: string[];
  text: string;
  location: LocationEntity

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.number = payload.number;
      this.name = payload.name;
      this.resourceAssignments = payload.resourceAssignments;
      this.customAttributes = payload.customAttributes;
      this.isDeletable = payload.isDeletable;
      this.functions = payload?.functions;
      this.text = payload?.text;
      if (payload.location) {
        this.location = new LocationEntity(payload.location);
      }
    }
  }

  /**
   * creates models from a result
   * @param result Array of any
   * @returns Bookigs
   */
  static createResources(result: Array<any>): Resource[] {
    const resources = new Array<Resource>();
    if (result) {
      result.map((resource) => {
        resources.push(new Resource(resource));
      });
    }
    return resources;
  }

  public getFunctionName() {
    let functionString = null;
    if (this.functions) {
      for(let funct of this.functions) {
        switch (funct) {
          case 'TRANSPORT' :
            functionString = functionString? functionString + 'Transport': 'Transport';
            break;
          default: functionString += '';
        }
      }
    }
    return functionString? functionString: '-';
  }
}

export class ResourceInput {
  id: string;
  externalId: string;
  name: string;
  number: string;
  functions: any;
	customAttributes: any[];
  location: LocationInput;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.name = payload.name;
      this.functions = payload.functions;
      this.customAttributes = payload.customAttributes;
      this.location = payload?.location;
    }
  }
}
