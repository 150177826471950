import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleService } from '../../../rent-module/shared/services/google.service';
import { HereService } from '../../../rent-module/shared/services/here.service';
import { Address, AddressInput, AddressType } from '../../shared/entities/address/address';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-address-dialog",
  templateUrl: "address-dialog.html",
  styleUrls: ["./address-dialog.scss"]
})
export class AddressDialogComponent {

  showImportedAddress = true;
  allowCoordinatesOnly = true;
  showNameInput = true;
  showNoteInput = true;

  typeFormControl: FormControl<any>;
  countryFormControl: FormControl<any>;
  cityFormControl: FormControl<any>;
  postalCodeFormControl: FormControl<any>;
  streetFormControl: FormControl<any>;
  houseNumberFormControl: FormControl<any>;
  lngFormControl: FormControl<any>;
  latFormControl: FormControl<any>;

  // map
  @ViewChild('mapContainer') set mapContainer(mapContainer: ElementRef) {
    if (mapContainer) {
      this._mapContainer = mapContainer;
      this.initializeMap();
    }
  }
  private _mapContainer: ElementRef;
  private map: any;
  private behavior: any;
  private marker: any;

  // address form
  addressForm: FormGroup;
  public address: Address; // this comes in, if an order already exists and has an address
  // OLD

  constructor(
    public dialogRef: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // public addressesService: AddressesService,
    public hereService: HereService,
    public googleService: GoogleService,
    private fb: FormBuilder,
  ) {
    // copy the address to a new instance to work with
    // the dialog will return the new instance of the address
    this.address = data.address;
    if(data?.showImportedAddress !== undefined){
      this.showImportedAddress = data.showImportedAddress;
    }
    if(data?.allowCoordinatesOnly !== undefined){
      this.allowCoordinatesOnly = data.allowCoordinatesOnly;
    }
    if(data?.showNameInput !== undefined){
      this.showNameInput = data.showNameInput;
    }
    if(data?.showNoteInput !== undefined){
      this.showNoteInput = data.showNoteInput;
    }
    this.initializeAddressForm();
  }

  initializeMap(){
    this.map = this.hereService.initializeMap(this._mapContainer.nativeElement);
    this.behavior = this.hereService.addBehaviorToMap(this.map);
    this.centerMap();
    if(!this.marker){
      // we have an address
      if(this.address){
        this.marker = this.hereService.addMarkerByAddress(this.map, this.marker, this.address);
      }
      this.makeMarkerDragable();
    }
  }

  makeMarkerDragable(){
    this.hereService.makeMarkerDraggable(this.marker, this.map, this.behavior, (lat: any,lng: any) => {
      this.hereService.reverseGeocode( lat, lng, (result: any) => {
        const address = this.hereService.createAddressFromLookupResult(result.items[0], !this.addressForm?.get('coordinatesOnly')?.value);
        if(this.addressForm?.get('coordinatesOnly')?.value) {
          this.addressForm.get('country').setValue(address.country);
          this.addressForm.get('city').setValue(address.city);
          this.addressForm.get('postalCode').setValue(address.postalCode);
          this.addressForm.get('street').setValue(address.street);
          this.addressForm.get('houseNumber').setValue(address.houseNumber);
          this.addressForm.get('lng').setValue(lng);
          this.addressForm.get('lat').setValue(lat);
          this.addressForm.markAsDirty();
          this.addressForm.markAsTouched();
          this.addressForm.updateValueAndValidity();
          this.hereService.moveMarker(this.map, this.marker, lat, lng);
        } else {
          this.addressForm.get('country').setValue(address.country);
          this.addressForm.get('city').setValue(address.city);
          this.addressForm.get('postalCode').setValue(address.postalCode);
          this.addressForm.get('street').setValue(address.street);
          this.addressForm.get('houseNumber').setValue(address.houseNumber);
          this.addressForm.get('lng').setValue(address.lng);
          this.addressForm.get('lat').setValue(address.lat);
          this.addressForm.markAsDirty();
          this.addressForm.markAsTouched();
          this.addressForm.updateValueAndValidity();
          this.hereService.moveMarker(this.map, this.marker, result.items[0]?.position?.lat, result.items[0]?.position?.lng);
        }
      })
    })
  }

  validateAddress = (control: AbstractControl): ValidationErrors | null  => {

     if(this.isEmptyStringOrNull(this.countryFormControl?.value)){
      return { 'country_missing': "Land fehlt." };
     }

     if(this.isEmptyStringOrNull(this.cityFormControl?.value)){
      return { 'city_missing': "Stadt fehlt." };
     }

     if(this.isEmptyStringOrNull(this.lngFormControl?.value)){
      return { 'lng_missing': "Längengrad fehlt." };
     }

     if(this.isEmptyStringOrNull(this.latFormControl?.value)){
      return { 'lat_missing': "Breitengrad fehlt." };
     }

     if(this.typeFormControl.value === 'COMPLETE') {
      if(this.isEmptyStringOrNull(this.postalCodeFormControl?.value)){
        return { 'postalCode_missing': "Postleitzahl fehlt." };
       }

      if(this.isEmptyStringOrNull(this.streetFormControl?.value)){
        return { 'street_missing': "Straße fehlt." };
      }

      // if(this.isEmptyStringOrNull(this.houseNumberFormControl?.value)){
      //   return { 'houseNumber_missing': "Hausnummer fehlt." };
      // }
     }

    return null;
  }

  initializeAddressForm() {
    this.typeFormControl = new FormControl();
    this.countryFormControl = new FormControl({value: null, disabled: true});
    this.cityFormControl = new FormControl({value: null, disabled: true});
    this.postalCodeFormControl = new FormControl({value: null, disabled: true});
    this.streetFormControl = new FormControl({value: null, disabled: true});
    this.houseNumberFormControl = new FormControl({value: null, disabled: true});
    this.lngFormControl = new FormControl({value: null, disabled: true});
    this.latFormControl = new FormControl({value: null, disabled: true});

    this.addressForm = this.fb.group({
      'id': [null],
      'type': this.typeFormControl,
      'country': this.countryFormControl,
      'city': this.cityFormControl,
      'postalCode': this.postalCodeFormControl,
      'street': this.streetFormControl,
      'houseNumber': this.houseNumberFormControl,
      'lng': this.lngFormControl,
      'lat': this.latFormControl,
      'externalAddress': [null]
    });

    if(this.showNameInput) {
      this.addressForm.addControl('name', new FormControl(null));
    }
    if(this.showNoteInput) {
      this.addressForm.addControl('note', new FormControl(null));
    }
    if(this.allowCoordinatesOnly) {
      this.addressForm.addControl('coordinatesOnly', new FormControl(false));
    }

    this.addressForm.setValidators(this.validateAddress)

    if(this.address){
      this.addressForm.patchValue(this.address);
      // if we´re here we want to edit the address
      // in nexcore we have only COMPLETE addresses, so the
      // form makes sure, that really all data, that is needed
      // is available. Because of that, we set the type here to
      // "COMPLETE"
      // If the users searches for an address or moves the
      // PIN, we always want to have a full address. If the user
      // uses coordinates only, we set the type to COORDINTAES_ONLY
      if(this.address?.type + '' === 'COORDINATES_ONLY'){
        this.typeFormControl.setValue('COORDINATES_ONLY');
        this.addressForm?.get('coordinatesOnly')?.setValue(true);
        // this.setCoordinatesOnlyValidators();
      } else {
        this.typeFormControl.setValue('COMPLETE');
        // this.setCompleteValidators();
      }
    }

    this.addressForm?.get('coordinatesOnly')?.valueChanges.subscribe( coordinatesOnly => {
      // console.log('test');
      if(coordinatesOnly) {
        this.typeFormControl.setValue('COORDINATES_ONLY');
      } else {
        this.typeFormControl.setValue('COMPLETE');
      }
    });

  }


  centerMap(){
    this.map.setCenter({lat:50.54896, lng:9.67951});
    this.map.setZoom(14);
  }

  save(){
    this.dialogRef.close({
      "addressInput" :  this.createModel()
    });
  }

  abort(){
    this.dialogRef.close();
  }

  createModel(): AddressInput{
    const addressInput = new AddressInput();
    addressInput.id = this.addressForm.get('id')?.value;
    addressInput.type = this.addressForm.get('type')?.value;
    addressInput.country = this.addressForm.get('country')?.value;
    addressInput.city = this.addressForm.get('city')?.value;
    addressInput.postalCode = this.addressForm.get('postalCode')?.value;
    addressInput.street = this.addressForm.get('street')?.value;
    addressInput.houseNumber = this.addressForm.get('houseNumber')?.value;
    addressInput.lat = this.addressForm.get('lat')?.value;
    addressInput.lng = this.addressForm.get('lng')?.value;
    addressInput.note = this.addressForm.get('note')?.value;
    addressInput.name = this.addressForm.get('name')?.value;
    return addressInput;
  }

  isEmptyStringOrNull(str: string){
    if(str === undefined || str === ''){
      return true;
    }
    return false;
}

  showError(error: any): string {
    let firstKey = Object.keys(error)[0];
    return error[firstKey];
  }

  addressSelected(address: Address) {
    // console.log(address);
    this.map.setCenter({lat: address.lat, lng:address.lng});
    this.marker = this.hereService.moveMarker(this.map, this.marker, address.lat, address.lng);
    this.map.setZoom(14);
    this.makeMarkerDragable();
    this.addressForm.get('country').setValue(address.country);
    this.addressForm.get('city').setValue(address.city);
    this.addressForm.get('postalCode').setValue(address.postalCode);
    this.addressForm.get('street').setValue(address.street);
    this.addressForm.get('houseNumber').setValue(address.houseNumber);
    this.addressForm.get('lng').setValue(address.lng);
    this.addressForm.get('lat').setValue(address.lat);
    if(address.type === AddressType.COMPLETE){
      this.typeFormControl.setValue('COMPLETE');
    } else if (address.type === AddressType.COORDINATES_ONLY){
      this.typeFormControl.setValue('COORDINATES_ONLY');
    }
    this.addressForm.markAsDirty();
    this.addressForm.markAsTouched();
    this.addressForm.updateValueAndValidity();
  }


}
