import { Component, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-order-task-list-view',
  templateUrl: './order-task-list-view.component.html',
  styleUrls: ['./order-task-list-view.component.scss'],
})
export class OrderTaskListViewComponent {
  public panelOpenState: boolean[] = [true, true, true];

  @Input() order: string;
  @Input() tasksCountAssigned: string;
  @Input() tasksCountUnAssigned: string;
  @Input() tasksCountCompleted: string;
  @ViewChild('accordion') Accordion: MatAccordion;

  constructor(public datePipe: DatePipe
  ) {}
}
