import { Component, Input, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

@Component({
  selector: 'app-task-assignment-list-completed',
  templateUrl: './task-assignment-list-completed.component.html',
  styleUrls: ['./task-assignment-list-completed.component.scss']
})
export class TaskAssignmentListCompletedComponent {

  public filterValue = '';
  public date = new Date();
  initialDate = new Date();
  public selectedLocation: string[];
  locations: LocationEntity [];
  @ViewChild('drawerRight') drawerRight: any;
  public taskType:string[];
  public displayedColumns: String[] = [
    'type', 'order.number', 'dueDateTimePeriod', 'description', 'customer', 'order.location.name', 'info',  'completedBy'
  ]
  public states = ['COMPLETED' as TaskState];

  @Input() set settings(settings: any) {
    this.selectedLocation = settings?.selectedLocation;
    this.date = settings?.date;
    this.taskType = settings?.taskType;
    this.filterValue = settings?.filterValue;
  }

  constructor(
    private apollo: Apollo
  ) {}
}
