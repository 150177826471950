<div class="content">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i class="icon-item icon"></i>
      <span class="noWrap"><h2>{{resource?.number}} / {{resource?.name}}</h2></span>
    </ng-container>
    <ng-container ngProjectAs="[tools]">
      <!-- *ngIf="rolesService.hasRole('fleetstage_vehicles_manage')" -->
      <app-nx-button (click)="editResource()"  toolTip="Resource bearbeiten" icon="icon icon-edit" *ngIf="rolesService.hasRole('nexcore_resource_update')">
      </app-nx-button>
    </ng-container>
  </app-layout-content-title>
  <!-- <div class="body">
    <div class="flex flex-row basis-full full gap-3">
        <div class="basis-1/2 small">
          <div class="flex flex-row basis-full">
            <div class="label basis-1/3 ">
              Functions
            </div>
            <div class="basis-2/3 flex justify-end" >{{resource?.getFunctionName()? resource.getFunctionName(): '-'}}
            </div>
          </div>
        </div>

        <div class="basis-1/2 medium">
          <app-custom-attributes-view [SetCustomAttributes]="resource?.customAttributes? resource?.customAttributes: []" [Entity]="'Resource'"/>
        </div>

    </div>

    <div class="flex flex-row basis-full mt-3 full">
      <app-orders-list class="basis-full full" [resource]="resource?.id" *ngIf="resource?.id"></app-orders-list>
    </div>
  </div> -->

  <div class="body">
    <mat-tab-group animationDuration="0ms" mat-align-tabs="start" mat-stretch-tabs="false">
      <mat-tab label="Allgemein">
        <div class="flex flex-column gap-3 md:flex-row rows">
          <div class="basis-1/2 columns">
            <mat-tab-group animationDuration="0ms" mat-align-tabs="start" mat-stretch-tabs="false">
              <mat-tab label="Stammdaten">
                <div class="details border-t border-gray-300">
                  <div class="details flex flex-col">
                    <div class="row flex flex-row basis-full border-solid border-b border-gray">
                      <div class="label basis-1/3 p-3">Nummer</div>
                      <div class="basis-2/3 flex justify-end p-3">{{resource?.number? resource?.number: '-'}}</div>
                    </div>
                    <div class="row flex flex-row basis-full border-solid border-b border-gray">
                      <div class="label basis-1/3 p-3">Name</div>
                      <div class="basis-2/3 flex justify-end p-3">{{resource?.name? resource?.name: '-'}}
                      </div>
                    </div>
                    <div class="row flex flex-row basis-full border-solid border-b border-gray">
                      <div class="label basis-1/3 p-3">Funktion</div>
                      <div class="flex basis-2/3 justify-end p-3">{{resource?.getFunctionName()}}</div>
                    </div>
                    <div class="row flex flex-row basis-full border-solid border-b border-gray">
                      <div class="label basis-1/3 p-3">Niederlassung</div>
                      <div class="flex basis-2/3 justify-end p-3">{{resource?.location? resource?.location?.name : '-'}}</div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab  *ngFor="let section of customAttributeSections; let index = index" [label]="CheckSectionInAttributes(section?.id)? section?.label: null"  class="tab-title">
                <div *ngFor="let defintion of section?.customAttributeDefinitions">
                  <div *ngIf="CheckDefintionInAttributes(defintion.id)" class="details flex flex-col gap-5">
                    <div class="row flex flex-row basis-full gap-5 border-solid border-b border-gray">
                      <div class="label basis-1/3 p-3">
                         {{defintion?.label}}
                      </div>
                      <div class="basis-2/3 flex justify-end p-3 value" *ngIf="defintion?.type !== 'DATETIME'">{{getValue(defintion?.id)? getValue(defintion?.id): '-'}}
                      </div>
                      <div class="basis-2/3 flex justify-end p-3 value" *ngIf="defintion?.type === 'DATETIME'">{{getValue(defintion?.id)? (getValue(defintion?.id) | date:'dd.MM.YYYY HH:mm'): '-'}} Uhr
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>

            </mat-tab-group>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Aufträge">
        <div class="flex flex-row basis-full mt-3 full">
          <app-orders-list class="basis-full full" [resource]="resource?.id" *ngIf="resource?.id"></app-orders-list>
        </div>
      </mat-tab>
      <!-- <mat-tab label="Schäden">

      </mat-tab> -->
    </mat-tab-group>
      </div>
</div>


