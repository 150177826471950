import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ArticleDataSource } from './articleDataSource';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { debounceTime, distinctUntilChanged, merge, tap } from 'rxjs';
import { Article } from '../../shared/entities/article/article';

interface DynmaicTableComponentState{
  displayedColumns: string[],
}

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss']
})
export class ArticlesListComponent implements OnInit, AfterViewInit, OnDestroy{
  public users: any[];
  public groups: any[];
  public dataSource: ArticleDataSource;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Article>;
  @ViewChild('drawerRight') drawerRight: any;

  public number = '';

  public displayedColumns: string[] = [
    'number',
    'name',
  ]

  articleNumberControl = new UntypedFormControl();
  formCtrlSub: any;
  constructor(
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
  ) {
    this.setTableDataSource();
  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchArticles()))
      .subscribe();
  }

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
    this.formCtrlSub = this.articleNumberControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.number = term;
        this.selectionChanges();
      });
  }

  setTableDataSource(): void{
    this.dataSource = new ArticleDataSource(this.apollo);
    this.dataSource.loadarticles({
      number: 0,
      size: 15,
      sortProperty: 'name',
      sortDirection: 'asc',
    });
  }

  openArticle(customer: any): void {
    // this.router.navigate(['./customers/customer/' + customer.id]);
  }
  createArticle(): void {

  }

  refetchArticles(): void {
    const number = this.number ? this.number : '';
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 15,
      sortProperty: this.sort?.active ? this.sort.active : 'name',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      articleNumber: number,
    });
  }

  selectionChanges() {
    this.paginator.pageIndex = 0
    this.refetchArticles();
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect(this.table);
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }
}
