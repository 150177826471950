import { User } from "@nexato/nx-core-module";
import { ControlTemplate } from "src/app/rent-module/components/form/entities/controlTemplate";
import { Task } from "../task/task";

export class Protocol {
  id: string | undefined;
  type: string | undefined;
  state: ProtocolState | undefined;
  context: any;
	formControlTemplate: ControlTemplate;
	completedDateTime: string | undefined;
  completedBy: User
  task: Task | undefined;


  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.type = payload.type;
      this.context = payload.context;
      this.state = payload.state;
      if(payload.formControlTemplate) {
        this.formControlTemplate = new ControlTemplate(payload.formControlTemplate);
      }
      this.completedDateTime = payload.completedDateTime;
      if (payload.completedBy) {
        this.completedBy = new User(payload.completedBy)
      }
      if (payload.task) {
        this.task = new Task(payload.task)
      }
    }

  }

}

export enum ProtocolState {
	NEW = "NEW", PREPARED = "PREPARED", OPEN = "OPEN", COMPLETED = "COMPLETED", UPLOADING = "UPLOADING", ARCHIVED = "ARCHIVED"
}


