import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-tour-state-selector',
  templateUrl: './tour-state-selector.component.html',
  styleUrls: ['./tour-state-selector.component.scss'],
})
export class TourStateSelectorComponent implements OnDestroy  {

  tourStatesFormControl: FormControl = new FormControl();
  tourStatesFormControlSubscription: Subscription;

  @Output() selectTourStates = new EventEmitter();
  @Input()
  set tourStatesValue(tourStatesValue: any)  {
    this.tourStatesFormControl.setValue(tourStatesValue);
  }
  public tourStates = [
    {
      name: "neue",
      key: 'NEW'
    },
    {
      name: "läuft",
      key: "RUNNING"
    },
    {
      name: "pausiert",
      key: "PAUSED"
    },
    {
      name: "beendet",
      key: "FINISHED"
    },
    {
      name: "archiviert",
      key: "ARCHIVED"
    }
  ];

  constructor() {
    this.tourStatesFormControlSubscription = this.tourStatesFormControl.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(value => {
        this.selectTourStates.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.tourStatesFormControlSubscription?.unsubscribe();
  }
}
