import { gql } from 'apollo-angular';
import { LocationEntity } from '../../../shared-module/shared/entities/location/location';

export const API_KEY_QUERY = gql`
query getLocationQuery(
  $id: UUID
)
{
  location: getLocation(
    id: $id
  )
  {
    id
    name
    label
    externalId
    email
    phoneNumber
    web
    type
    address {
      id
      country
      city
      postalCode
      street
      houseNumber
      name
      lat
      lng
      text
      type
      note
      geocodingResultGrade
      externalAddress
    }
  },
  terms: getSettingCategories(
    allowLocationOverride: true
  ){
    key
    label
    description
    settingDescriptions {
      id(locationId: $id)
      key
      label
      description
      type
      defaultValue
      currentValue(locationId: $id)
      settings
      allowLocationOverride
    }

  },
}
`;

export interface LocationQueryResponse {
  location: LocationEntity;
  terms: any;
}
