import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Address, AddressInput } from '../../shared/entities/address/address';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';


@Component({
  selector: "addresses-input-control",
  templateUrl: "./addresses-form.component.html",
  styleUrls: ["./addresses-form.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputControlComponent),
      multi: true
    }
  ]
})

export class AddressInputControlComponent implements ControlValueAccessor{

@Input()address: Address;

  public addressForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.initializeForm();
  }


  initializeForm() {
    this.addressForm = this.fb.group({
      'id': [null],
      'country': [null],
      'city': [{value: null, disabled: true}],
      'postalCode': [{value: null, disabled: true}],
      'street': [{value: null, disabled: true}],
      'houseNumber': [{value: null, disabled: true}],
      'lng': [null],
      'lat': [null],
    });
    this.addressForm.disable();
  }

  /**
   * opens the modal dialog to edit/create an address
   */
  editAddress() {
    let dialogRef = this.dialog.open(AddressDialogComponent, {
      width: "1100px",
      data: {
        showImportedAddress: false,
        allowCoordinatesOnly: false,
        showNameInput: false,
        showNoteInput: false,
        // address: this._address,
        address: this.address
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result?.addressInput) {
        this.addressForm.patchValue(result?.addressInput);
        this.propagateChange(result);
      }
    });
  }

  /** value accessor */
  writeValue(address: any): void {
    if (address) {
      this.addressForm.patchValue(address);
      // we need to create and propagate a model here
      // otherwises the form will contain an address
      // and not an addressInput
      this.propagateChange(this.createModel());
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.addressForm.disable();
    } else {
      this.addressForm.enable();
    }
  }

  createModel(): AddressInput{
    const addressInput = new AddressInput();
    addressInput.id = this.addressForm.get('id')?.value;
    addressInput.city = this.addressForm.get('city')?.value;
    addressInput.country = this.addressForm.get('country')?.value;
    addressInput.houseNumber = this.addressForm.get('houseNumber')?.value;
    addressInput.postalCode = this.addressForm.get('postalCode')?.value;
    addressInput.street = this.addressForm.get('street')?.value;
    addressInput.lat = this.addressForm.get('lat')?.value;
    addressInput.lng = this.addressForm.get('lng')?.value;
    return addressInput;
  }

}
