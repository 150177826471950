import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { Resource } from '../../shared/entities/resource/resource';

export const RESOURCES_QUERY = gql`
query resourcesQuery($number: Int, $size: Int, $sortProperty: String, $sortDirection: String, $name: String, $locationId : UUID, $resourceNumber: String)
{
  resources: getResourcesPaged(
    filter: {
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
      name: $name,
      number: $resourceNumber,
      locationId: $locationId
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
          number
	        name
          functions
          location {
            id
            name
          }
      }
  }
  locations: getLocations(filter:{}){
    id
    name
    label
  }
}
`;

export interface ResourcesQueryResponse {
  resources: Page<Resource>;
  loading: boolean;
  locations: LocationEntity[];
}
