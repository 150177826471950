import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LoadingService } from "@nexato/nx-core-module";
import { CommentInput, Comment } from "../../shared/entities/comment/comment";
import { OrderService } from "../../shared/services/order/order.service";

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {

  public id: string;
  public commentFormGroup: UntypedFormGroup;
  public comment: Comment;
  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    public orderService: OrderService
  ) { }

  ngOnInit(): void {
    this.InitializeForm();
  }

  InitializeForm(): void {
    this.commentFormGroup = this.fb.group({
      text: [null, [Validators.required]],
    });
    if (this.data) {
      this.comment = this.data.comment;
      this.id = this.data?.orderId;
      this.commentFormGroup.patchValue(this.comment);
     }

  }

  saveComment() {
    this.loadingService.setLoading(true);
    if (this.comment) {
      this.orderService.updateComment(
        this.prepareCommentModel(),
        this.returnCallback()
      );
    } else {
      this.orderService.createComment(
        this.prepareCommentModel(),
        this.returnCallback()
      );
    }
  }

  returnCallback(): void {
    setTimeout(() => {
      this.dialogRef.close({
        abort: false,
        saved: true,
      });
      this.loadingService.setLoading(false);
    }, 500);
  }

  prepareCommentModel(): CommentInput {
    const formModel = this.commentFormGroup.value;
    const commentModel = new CommentInput();
    if (this.comment) {
      commentModel.id = this.comment.id;
    }
    commentModel.text =formModel?.text;
    commentModel.ownerId = this.id;
    commentModel.ownerType = 'order';
    commentModel.references = [];
    return commentModel;
  }

}
