<app-layout-content-title>
  <ng-container ngProjectAs="[headline]">
    <i [className]="'icon-branches'"></i>
    <h2>Niederlassung {{this.id ? 'bearbeiten' : 'anlegen'}}</h2>
  </ng-container>
  <ng-container ngProjectAs="[tools]">
  </ng-container>
</app-layout-content-title>
<div class="body flex flex-col white">
  <div [formGroup]="locationFormGroup" class="flex flex-col gap-25">
    <mat-form-field class="basis-full">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field class="basis-full">
      <mat-label>Label</mat-label>
      <input matInput formControlName="label">
    </mat-form-field>
    <div class="flex flex-row basis-full space-x-4 ">
      <mat-form-field class="basis-1/2">
        <mat-label>Identifier</mat-label>
        <input matInput formControlName="externalId">
      </mat-form-field>
      <mat-form-field class="basis-1/2">
        <mat-label>E-Mail</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>
    </div>
    <div class="flex flex-row basis-full space-x-4">
      <mat-form-field class="basis-1/2">
        <mat-label>Telefon</mat-label>
        <input matInput formControlName="phoneNumber">
      </mat-form-field>
      <mat-form-field class="basis-1/2">
        <mat-label>Web</mat-label>
        <input matInput formControlName="web">
      </mat-form-field>
    </div>
    <div class="flex flex-row basis-full">
      <div class="basis-full">
        <mat-form-field class="full-width">
          <mat-label>Typ</mat-label>
          <mat-select multiple="true" formControlName="type">
            <mat-option *ngFor="let type of types" [value]="type.id">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <addresses-input-control
      formControlName="address"
      class="basis-full" [address]="location?.address">
    </addresses-input-control>
  </div>
  <app-settings-form [form]="form" [settings]="settings" [locationId]="id"></app-settings-form>
  <div class="basis-full flex flex-row gap-25">
    <button mat-flat-button (click)="saveLocation()" id="save" color="primary"
      [disabled]="!(locationFormGroup?.valid && !locationFormGroup.pristine) && !(form?.valid && !form.pristine)">Speichern</button>
    <button mat-flat-button (click)="abort()" id="cancel" color="primary">Abbrechen</button>
  </div>
</div>
