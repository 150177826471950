import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-task-list-assigned',
  templateUrl: './order-task-list-assigned.component.html',
  styleUrls: ['./order-task-list-assigned.component.scss']
})
export class OrderTaskListAssignedComponent {

  public displayedColumns: String[] =
  [
     'type',  'dueDate', 'description', 'assignment'
  ];
  public states = ['ASSIGNED' as TaskState];
  @Input() order: string;


}
