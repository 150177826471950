<div class="flex flex-row header  mt-2 items-center">
    <h3> Offene Aufgaben</h3>
</div>
<div class="content flex flex-col" [ngStyle]="{'height': 'calc(100% - 82px)', 'overflow-y': 'auto', 'overflow-x': 'hidden'}">

  <div class="basis-full border-t border-gray-300">
    <app-task-assignment-general
      [settings]="{'type':'open', 'displayedColumns':displayedColumns, 'states': states, 'selectedLocation':selectedLocation, 'taskType':taskType, 'date':date, 'filterValue': filterValue}"></app-task-assignment-general>
  </div>
</div>


