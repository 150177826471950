import { gql } from 'apollo-angular';
import { Page } from '@nexato/nx-core-module';

export const FILES_QUERY = gql`
query OrderFilesQuery(
  $number: Int, $size: Int,
  $sortProperty: String,
  $sortDirection: String,
  $ownerId: UUID
)
{
  files: getFilesPaged(
    filter:{
      ownerId: $ownerId,
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
    }){
    totalPages
    totalElements
    number
    size
    content{
      id
      fileName
      fullPath
      uploadedDateTime
      extension
      size
    }
  }
}
`;

export interface FilesQueryResponse {
  files: Page<File>;
  loading: boolean;
}


