import {gql} from 'apollo-angular';
import { Page } from '@nexato/nx-core-module';
import { Article } from '../../shared/entities/article/article';

export const ARTICLES_QUERY = gql`
query articlesQuery($number: Int, $size: Int, $sortProperty: String, $sortDirection: String, $articleNumber: String)
{
  articles: getArticlesPaged(
    filter: {
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
      number: $articleNumber
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
	        name
          number
      }
  }
}
`;

export interface ArticlesQueryResponse {
  articles: Page<Article>;
  loading: boolean;
}
