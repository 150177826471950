<mat-nav-list *ngIf="licenseService?.isLicenceUser()">
  <ng-container *ngFor="let menuitem of menuItems">
    <mat-list-item [routerLink]="[menuitem.path]" routerLinkActive="active" *ngIf="(!menuitem.role) || (rolesService.hasRole(menuitem.role))">
      <div class="nav">
        <i [className]="'icon ' + menuitem.icontype" [matTooltip]="isExpanded? null : menuitem.title"></i>
        <span *ngIf="isExpanded" class="pl-2">{{menuitem.title}}</span>
        <span [matBadge]="menuitem.count" matBadgeOverlap="false" matBadgeSize="small" [hidden]="menuitem.count <= 0"></span>
      </div>
    </mat-list-item>
  </ng-container>
</mat-nav-list>
