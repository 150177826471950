import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Task } from '../../shared/entities/task/task';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "task-scheduling-dialog",
  templateUrl: "task-scheduling-dialog.html",
  styleUrls: ["./task-scheduling-dialog.scss"]
})
export class TaskSchedulingDialogComponent {

  public taskAssignmentForm: FormGroup;
  public task: Task;


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TaskSchedulingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    public dialog: MatDialog,
  ) {
    this.task = data.task;
    this.initForm()
  }
  initForm() {
    this.taskAssignmentForm = this.fb.group({
      'taskId': [this.task?.id],
      'assigneeId': [null, [Validators.required]],
      'assignmentType': [null, [Validators.required]],
      'dueDateTimePeriod': [null, [Validators.required]]
    });
    if(this.task.assignmentSuggestion){
      this.taskAssignmentForm.patchValue(this.task.assignmentSuggestion);
    }
    
  }

  assign() {
    this.dialogRef.close(this.taskAssignmentForm.value);
  }

  abort() {
    this.dialogRef.close();
  }

  getControlsForCurrentlySelectedTasAssignmentStrategy(): any {
    for (let index = 0; index < this.task?.assignmentStrategyCandidates?.length; index++) {
      const element = this.task?.assignmentStrategyCandidates[index];
      if(element.type == this.taskAssignmentForm.value['assignmentType']){
        return element.settings;
      }
    }
    return undefined;
  }
}
