import {gql} from 'apollo-angular';
import { Page } from '@nexato/nx-core-module';
import { LocationEntity } from '../../../shared-module/shared/entities/location/location';

export const LOCATIONS_QUERY = gql`
query locationsQuery($number: Int, $size: Int, $sortProperty: String, $sortDirection: String)
{
  locations: getLocationsPaged(
    filter: {
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection}
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
          name
          isDeletable
      }
  }
}
`;

export interface LocationsQueryResponse {
  locations: Page<LocationEntity>;
  loading: boolean;
}
