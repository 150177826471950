import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';


@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-de'}
  ]
})
export class DateSelectorComponent {

  public moment = moment;
  @Input() displayLabel = true;
  @Input() placeholder = 'Datum';
  @Input()
  set initialDate(date: Date) {
    if (date) {
      this.selectedDate = date;
    }
  }
  @Output() date = new EventEmitter();
  @Input() className: string;
  public selectedDate = moment().toDate();

  constructor() {
  }

  dayBack(){
    this.selectedDate = moment(this.selectedDate).subtract(1, 'day').toDate();
    this.date.emit(this.selectedDate);
  }

  dayForward(){
    this.selectedDate = moment(this.selectedDate).add(1, 'day').toDate();;
    this.date.emit(this.selectedDate);
  }

  loadToday(): void {
    const newStartDate = moment().toDate();
    this.selectedDate = newStartDate;
    this.date.emit(this.selectedDate);
  }

  dateChange() {
    this.date.emit(this.selectedDate);
  }
}
