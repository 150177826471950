
<!-- <div class="resourceAssignment"> --> <!-- wrapper tag is currently in parent comp -->
    <div class="headerRow border-solid border-b border-t border-gray-300 pl-4 leading-10 content-center">
        <i class="icon icon-item"></i>
        <span class="font-bold pl-2">{{_resourceAssignment?.text}}</span>
    </div>
    <div class="protocolRow flex flex-row gap-4 mx-6">
        <!-- <ng-container *ngIf="outCheck">
            outCheck in single view
        </ng-container>" -->
        <div *ngIf="resourceOut || resourceIn"
            class="protocol resourceInOut sideBySideView basis-2/3 border-gray-300 border-x border-solid"
            app-protocol-side-by-side-view
            [leftProtocol]="resourceOut"
            [rightProtocol]="resourceIn">
        </div>

        <div class="protocol refuel singleView basis-1/3 border-gray-300 border-x border-solid"
            *ngIf="refuel"
            app-protocol-view
            [protocol]="refuel">
        </div>
        <!-- <ng-container *ngIf="inCheck">
            inCheck in single view
        </ng-container>" -->
        <div class="py-2" *ngIf="!refuel && !(resourceOut || resourceIn)">
            Keine Protokolle vorhanden.
        </div>
    </div>
<!-- </div> -->





