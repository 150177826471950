import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';

export const TASKS_QUERY = gql`
query getTaksQuery(
  $number: Int, $size: Int, $sortProperty: String, $locationIds: [UUID],
  $fromDate: String, $toDate: String, $includeOverDue: Boolean,
  $sortDirection: String,  $types:[String], $states: [TaskState]
)
{
  tasks: getTasksPaged(
    taskFilter: { types: $types, states: $states,handlingType: PICKUP, locationIds: $locationIds,
    fromDateTime:  $fromDate, toDateTime: $toDate, includeOverDue : $includeOverDue,
    sortRequest: {sortProperties: [$sortProperty], sortDirection: $sortDirection},
    pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
  }) {
    totalPages
      totalElements
      number
      size
      content{
        id
        dueDateTimePeriod {
          modifier
          dateTime
          fromDateTime
          toDateTime
        }
        type
        order{
          id
          number
          customer {
            type
            companyName
            companyNameExtension
            firstName
            lastName
          }
          lineItems {
            resourceAssignments {
              resource {
                name
              }
            }
          }
        }
      }
  }
}`;

export interface TaskQueryResponse {
  tasks: Page<Task>;
  loading: boolean;
}
