
<div class="details flex flex-col basis-full">
  <div [hidden]="dataSource?.loading| async"  class="table basis-full flex flex-row">
    <mat-table [dataSource]="dataSource" matSort>
      <!-- select column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="basis-16 grow-0 justify-center first">
          <mat-checkbox class="check" (change)="$event ? masterToggle(): null" [checked]="selection.hasValue() && isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="basis-16 grow-0 justify-center first">
          <mat-checkbox class="check" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- type column -->
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          class="sort basis-16 grow-0">Typ</mat-header-cell>
        <mat-cell *matCellDef="let task" class="basis-16 grow-0">
          <i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i>
        </mat-cell>
      </ng-container>

      <!-- dueDate column -->
      <ng-container matColumnDef="dueDate">
        <mat-header-cell *matHeaderCellDef>Fälligkeit</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap" #date [matTooltipDisabled]="isEllipsisActive(date)" matTooltipShowDelay="250"
            matTooltip="{{taskService.getDeliveryDateFull(task?.dueDateTimePeriod)}}">
            {{taskService.getDeliveryDateFull(task?.dueDateTimePeriod)}}
          </span>
        </mat-cell>
      </ng-container>

      <!-- dueTime column -->
      <ng-container matColumnDef="dueTime">
        <mat-header-cell *matHeaderCellDef>Zeit</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap" matTooltipPosition="left" #time [matTooltipDisabled]="isEllipsisActive(time)"
            matTooltipShowDelay="250" matTooltip="{{taskService.getDeliveryTime(task?.dueDateTimePeriod)}}">
            {{taskService.getDeliveryTime(task?.dueDateTimePeriod)}}</span></mat-cell>
      </ng-container>

      <!-- dueDateTime Column -->
      <ng-container matColumnDef="dueDateTimePeriod">
       <mat-header-cell *matHeaderCellDef mat-sort-header class="flex">Fälligkeit</mat-header-cell>
       <mat-cell *matCellDef="let task" class="flex">
         <span class="noWrap" #dueDateTimePeriod [matTooltipDisabled]="isEllipsisActive(dueDateTimePeriod)"
           matTooltipPosition="left" matTooltipShowDelay="250"
           matTooltip="{{taskService?.getDeliveryTime(task?.dueDateTimePeriod)}}">
           {{taskService?.getDeliveryDateFull(task?.dueDateTimePeriod)}} {{taskService?.getDeliveryTime(task?.dueDateTimePeriod)}}
         </span>
       </mat-cell>
      </ng-container>

      <!-- order number column -->
      <ng-container matColumnDef="order.number">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Auftragsnummer</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap" matTooltipPosition="left" #number [matTooltipDisabled]="isEllipsisActive(number)"
            matTooltipShowDelay="250" matTooltip="{{task.order.number}}">
            {{task.order.number}}
          </span>
          <i [className]="'icon icon-jump-to'" (click)="goToOrder(task)"></i>
        </mat-cell>
      </ng-container>

      <!-- description column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>Beschreibung</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap" matTooltipPosition="left" #description [matTooltipDisabled]="isEllipsisActive(description)"
            matTooltipShowDelay="250" matTooltip=" {{task.description}}">
            {{task.description}}</span>
        </mat-cell>
      </ng-container>

      <!-- customer column -->
      <ng-container matColumnDef="customer">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Kunde</mat-header-cell>
        <mat-cell *matCellDef="let task" class="noWrap ">
          <span class="noWrap" matTooltipPosition="left" #customer [matTooltipDisabled]="isEllipsisActive(customer)"
            matTooltipShowDelay="250" matTooltip="{{getContactName(task?.order?.customer)}}">
            {{getContactName(task?.order?.customer)}}</span>
        </mat-cell>
      </ng-container>

      <!-- description column -->
      <ng-container matColumnDef="order.location.name">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Niederlassung</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap" matTooltipPosition="left" #location [matTooltipDisabled]="isEllipsisActive(location)"
            matTooltipShowDelay="250" matTooltip=" {{task?.order?.location?.name}}">
            {{task?.order?.location?.name}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="info">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex items-center">
          <!-- <i [className]="'icon icon-info'" (click)="taskInfo(task)"></i> -->
          <app-nx-button [icon]="'icon icon-file-attachment'" (click)="taskFiles(task)"
            [matBadge]="task?.order?.fileCount" matBadgeSize="small"
            [matBadgeHidden]="task?.order?.fileCount === 0" />
          <app-nx-button [icon]="'icon icon-annotation'" (click)="taskInfo(task)"
            [matBadge]="taskService?.getCommentsNotesCount(task?.order)" matBadgeSize="small"
            [matBadgeHidden]="taskService?.getCommentsNotesCount(task?.order) === 0" />
        </mat-cell>
      </ng-container>

      <!-- assignment suggestion column -->
      <ng-container matColumnDef="assignmentSuggestion">
        <mat-header-cell *matHeaderCellDef class="flex justify-end mr-1">Zuweisung (Vorschlag)</mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex justify-end">
          <ng-container *ngIf="task.assignmentSuggestion">
            <span class="noWrap" matTooltipPosition="left" #name [matTooltipDisabled]="isEllipsisActive(name)"
              matTooltipShowDelay="250" matTooltip="{{task.assignmentSuggestion.name}}">{{task.assignmentSuggestion.name}}
              &nbsp;</span>
            <ng-container *ngIf="task.assignmentSuggestion.dueDateTimePeriod">
              <span class="noWrap" matTooltipPosition="left" #suggest [matTooltipDisabled]="isEllipsisActive(suggest)"
                matTooltipShowDelay="250" matTooltip="
                    {{taskService.getDeliveryDateFull(task.assignmentSuggestion.dueDateTimePeriod)}}
                    {{taskService.getDeliveryTime(task.assignmentSuggestion.dueDateTimePeriod)}}">
                {{taskService.getDeliveryDateFull(task.assignmentSuggestion.dueDateTimePeriod)}}
                {{taskService.getDeliveryTime(task.assignmentSuggestion.dueDateTimePeriod)}}</span>
            </ng-container>
            <app-nx-button [icon]="'icon icon-settings'" (click)="$event.stopPropagation();openEditTaskAssignmentDialog(task)"/>

          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- assignment column -->
      <ng-container matColumnDef="assignment">
        <mat-header-cell *matHeaderCellDef class="basis-1/12 justify-end mr-1">Zuweisung</mat-header-cell>
        <mat-cell *matCellDef="let task" class="basis-1/12 justify-end mr-1">
          <ng-container *ngIf="task.assignment">
            <span class="noWrap" matTooltipPosition="left" #name1 [matTooltipDisabled]="isEllipsisActive(name1)"
              matTooltipShowDelay="250" matTooltip="{{task.assignment.name}}">
              {{task.assignment.name}} &nbsp;</span>
            <ng-container *ngIf="task.assignment.dueDateTimePeriod">
              <span class="noWrap" matTooltipPosition="left" #name1 [matTooltipDisabled]="isEllipsisActive(name1)"
              matTooltipShowDelay="250" matTooltip="{{taskService.getDeliveryDateFull(task.assignment.dueDateTimePeriod)}}
              {{taskService.getDeliveryTime(task.assignment.dueDateTimePeriod)}}">
              {{taskService.getDeliveryDateFull(task.assignment.dueDateTimePeriod)}}
              {{taskService.getDeliveryTime(task.assignment.dueDateTimePeriod)}}</span>
            </ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="completedBy">
        <mat-header-cell *matHeaderCellDef class="basis-1/12 justify-end mr-1">Erledigt</mat-header-cell>
        <mat-cell *matCellDef="let task" class="basis-1/12 justify-end mr-1">
          {{task?.completedDateTime | date:'dd.MM.YYYY HH:mm' }} /
          {{getName(task?.completedBy)}}
        </mat-cell>
      </ng-container>

      <!-- table header container -->
      <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="tabHeader flex flex-row table_header"
        [hidden]="!dataSource?.totalElements"></mat-header-row>

      <!-- table body container -->
      <mat-row *matRowDef="let row; columns: displayedColumns;" class=" tabRow flex flex-row"></mat-row>

      <!-- no data row -->
      <div *matNoDataRow class="no-data">
        <p>keine Aufgaben vorhanden</p>
      </div>

    </mat-table>
  </div>
    <div *ngIf="dataSource?.loading | async" class="spinner basis-full">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <!-- <footer class="pagination flex flex-row items-center">
      <div class="basis-1/2  pl-3">
        <button mat-flat-button *ngIf="tasksType==='open'" [disabled]="!selection.hasValue()" (click)="assign()"
          color="primary">Disponieren</button>
        <button mat-flat-button *ngIf="tasksType==='assigned'" [disabled]="!selection.hasValue()" (click)="unassign()"
          color="primary">Disposition zurücknehmen</button>
      </div>
      <div class="pagination basis-1/2">
        <mat-paginator [length]="dataSource?.totalElements" [pageSize]="15" [pageIndex]="0"
          [pageSizeOptions]="[15, 20, 25, 30]" aria-label="Select page" class="paginator"></mat-paginator>
      </div>
    </footer> -->
    <div class="sticky list-footer flex flex-row items-center">
      <div class="basis-1/2  pl-3">
        <button mat-flat-button *ngIf="tasksType==='open'" [disabled]="!selection.hasValue()" (click)="assign()"
          color="primary">Disponieren</button>
        <button mat-flat-button *ngIf="tasksType==='assigned'" [disabled]="!selection.hasValue()" (click)="unassign()"
          color="primary">Disposition zurücknehmen</button>
      </div>
      <div class="pagination basis-1/2 justify-end flex basis-full ">
        <mat-paginator [length]="dataSource?.totalElements" [pageSize]="15" [pageIndex]="0"
          [pageSizeOptions]="[15, 20, 25, 30]" aria-label="Select page" class="paginator"></mat-paginator>
      </div>
    </div>
  </div>


