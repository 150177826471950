import {QueryRef, Apollo} from 'apollo-angular';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';

import * as fromGraphQl from './graphql';

import { Subscription } from 'rxjs';
import { ApolloService } from '@nexato/nx-core-module';
import { Order } from 'src/app/order-module/shared/entities/order/order';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import { LicenseService } from '../../shared/services/license.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: "app-orders-search",
  templateUrl: "./orders-search.component.html",
  styleUrls: ["./orders-search.component.scss"]
})
export class OrdersSearchComponent {

  public searchQuery: QueryRef<fromGraphQl.SearchQueryResponse>;
  public searchQuerySubscription: Subscription;


  searchTerm: FormControl = new FormControl();
  @ViewChild("searchField", { static: true })
  searchField: any;
  noOrderFound: boolean;
  noCustomerFound: boolean;
  noResorceFound: boolean;
  noUnitFound: boolean;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  orderSearchResult: Order[] = [];
  customerSearchResult: any[] = [];
  resourcesSearchResult: Resource[] = [];
  unitsSearchResult: any[] = [];

  constructor(
    private apollo: Apollo,
    private apolloService: ApolloService,
    private router: Router,
    public licenseService: LicenseService
  ) {
    this.searchTerm.valueChanges.pipe(debounceTime(400)).pipe(
      // filter((data: string) => data.length > 0)
    ).subscribe(data => {
      if (data.length >= 1) {
        this.noOrderFound = false;
        this.noCustomerFound = false;
        this.noResorceFound = false;
        this.noUnitFound = false;
        let subscription = this.apollo.query<fromGraphQl.SearchQueryResponse>({
          query: fromGraphQl.SEARCH_QUERY,
          variables: {
            search: data,
            number: 0,
            size: 5,
            sortPropertyOrder: 'number',
            sortPropertyResource: 'name',
            sortPropertyContact: 'id',
            sortDirection: 'asc',
          }
        }).subscribe(result => {
          if (result?.data?.orders?.content?.length > 0) {
            this.orderSearchResult = Order.createOrders(result.data?.orders?.content);
          } else {
            this.orderSearchResult = [];
            this.noOrderFound = true;
          }
          if (result?.data?.resources?.content?.length > 0) {
            this.resourcesSearchResult = Resource.createResources(result.data?.resources?.content);
          }
          else {
            this.resourcesSearchResult = [];
            this.noResorceFound = true;
          }
          if (result?.data?.customers?.content?.length > 0) {
            this.customerSearchResult = Contact.createContacts(result.data.customers?.content);
          }
          else {
            this.customerSearchResult = [];
            this.noCustomerFound = true;
          }
          // this.unitsSearchResult = this.entityService.createUnitsFromResult(result.data.units);
          subscription.unsubscribe();
        });
      } else {
        this.noCustomerFound = false;
        this.noOrderFound = false;
        this.noResorceFound = false;
        this.orderSearchResult = [];
        this.customerSearchResult = [];
        this.resourcesSearchResult = [];
        this.unitsSearchResult = [];
      }
    });
  }


  openOrder(order: any) {
    let uri = "orders/order/" + order?.id;
    this.redirect(uri);
  }

  openCustomer(customer: any) {
    let uri = "customers/customer/" + customer?.id;
    this.redirect(uri);
  }

  openResource(resource: any) {
    let uri = "resources/resource/" + resource?.id;
    this.redirect(uri);
  }

  redirect(uri: any) {
    this.router.navigateByUrl('./', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri])});
  }

  // openUnit(unit: Unit){
  //   this.cleanUp();
  //   this.router.navigate(["./tourPlanning/units/detail/" + unit.id]);
  // }

  cleanUp() {
    this.searchTerm.setValue("");
    this.orderSearchResult = [];
    this.customerSearchResult = [];
    this.resourcesSearchResult = [];
    this.unitsSearchResult = [];
    setTimeout(()=>{
      this.searchField?.nativeElement?.blur();
    })
  }

  reset() {
    this.noOrderFound = false;
    this.noCustomerFound = false;
    this.noResorceFound = false;
    this.noUnitFound = false;
    this.orderSearchResult = [];
    this.customerSearchResult = [];
    this.resourcesSearchResult = [];
    this.searchTerm.setValue("");
  }
}
