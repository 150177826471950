<div class="content">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i [className]="Order.getOrderIcon(order?.type?.toString())" [matTooltip]="Order.getOrderType(order?.type?.toString())"></i>
      <h2>{{order?.number}} </h2>
      <span>&nbsp;- {{Order.getOrderState(order?.state + '')}}</span>
    </ng-container>
    <ng-container ngProjectAs="[tools]">
      <app-nx-button mat-button [matMenuTriggerFor]="menu" icon="icon icon-options" />
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="orderService?.downloadPDF(order)">
          <mat-icon class="icon-pdf"></mat-icon>
          PDF speichern
        </button>
        <button mat-menu-item (click)="getPublicLink()">
          <mat-icon class="icon-share"></mat-icon>
          Öffentlichen Link kopieren</button>
        <button mat-menu-item (click)="confirmDialog()" *ngIf="rolesService?.hasRole('nexcore_order_delete')">
          <mat-icon class="icon-trash"></mat-icon>
          <span>Auftrag löschen</span>
        </button>
        <button mat-menu-item (click)="blockArchiving()" [disabled]="checkArchiveButtinDisabled()">
          <mat-icon class="icon-blocked"></mat-icon>
          <span>Archivierung blockieren</span>
        </button>
      </mat-menu>
    </ng-container>
  </app-layout-content-title>
  <div class="body">
    <mat-tab-group animationDuration="0ms" mat-align-tabs="start" mat-stretch-tabs="false">
      <mat-tab label="Allgemein">
        <div class="flex flex-column gap-3 md:flex-row rows">
          <div class="basis-1/2 columns">
            <div [ngSwitch]="order?.type">
              <app-order-rent-details [order]="order" *ngSwitchCase="'RENTAL'" [customSections]="customAttributeSections"></app-order-rent-details>
              <app-order-service-details [order]="order" *ngSwitchCase="'SERVICE'"></app-order-service-details>
            </div>
          </div>
          <div class="basis-1/2 view columns gap-5">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" >
              <mat-tab label="Aufgaben ({{tasksCount}})" class="mat-group">
                <div class="small">
                  <div class="details">
                    <app-order-task-list-view [order]="this?.id" [tasksCountCompleted]="tasksCountCompleted"
                      [tasksCountAssigned]="tasksCountAssigned" [tasksCountUnAssigned]="tasksCountUnAssigned"></app-order-task-list-view>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Dateien ({{order?.files?.length}})" class="mat-group">
                <div class="small">
                  <div class="details">
                    <app-order-files [order]="order?.id" (updateChild)="childUpdated()"></app-order-files>
                  </div>
                </div>
              </mat-tab>
               <mat-tab label="Kommentare ({{commentsCount}})" class="mat-group">
                <div class="small">
                  <div class="details">
                    <app-order-comments-list [order]="order?.id" (updateChild)="childUpdated()"/>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <div>
          <div class="basis-full medium gap-5 mt-3">
            <div [ngSwitch]="order?.type">
              <app-order-rent-line-items [lineItems]="order?.lineItems"  *ngSwitchCase="'RENTAL'"></app-order-rent-line-items>
              <app-order-service-line-items [lineItems]="order?.lineItems"  *ngSwitchCase="'SERVICE'"></app-order-service-line-items>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Protokolle ({{protocolsCount}})">
        <!-- here we cann add other protocol view, e.g. for service etc. -->
        <ng-container [ngSwitch]="order?.type">
          <div class="protocols" app-order-rent-protocols-view *ngSwitchCase="'RENTAL'" [order]="order"></div>
        </ng-container>

      </mat-tab>
      <!-- <mat-tab label="Schäden">

      </mat-tab> -->
    </mat-tab-group>
  </div>
