import { Injectable } from "@angular/core";
import { ApolloService } from "@nexato/nx-core-module";
import { QueryRef } from "apollo-angular";
import { AddressInput } from "src/app/shared-module/shared/entities/address/address";
import * as fromGraphQl from './graphql';

@Injectable()
export class AddressService {

  constructor(
    private apolloService: ApolloService
  ) {

  }

  acceptAddress(addressId: string, refetchQuery: QueryRef<any>) {
    this.apolloService.mutate(
        fromGraphQl.ACCEPPT_ADDRESS,
        {
          addressId: addressId
        },
        refetchQuery
      );
  }

  updateAddress(addressInput: AddressInput, refetchQuery: QueryRef<any>) {
    this.apolloService.mutate(
        fromGraphQl.UPDATE_ADDRESS,
        {
          addressInput: addressInput
        },
        refetchQuery
      );
  }
}
