
import { gql } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

export const DASHBOARD_QUERY = gql`
query DashboardQuery
{
  locations: getLocations(filter:{}){
    id
    name
    label
  }
}`;

export interface DashboardQueryResponse {
  locations: LocationEntity[];
}
