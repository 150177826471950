import { Component, Input, OnInit } from '@angular/core';
import { RolesService } from '@nexato/nx-core-module';
import { LicenseService } from '../../shared/services/license.service';
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  role: string | undefined;
  count: number;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent
implements OnInit {

  public menuItems: RouteInfo[];
  public count: number;
  @Input() isExpanded = true;

  constructor(public rolesService: RolesService,
    public licenseService: LicenseService) {
  }

  ngOnInit(): void {
    this.menuItems = [
      {
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'icon-dashboard',
        role: '',
        count: -1,
      },
      {
        path: 'tour-planner',
        title: 'Tourenplaner',
        type: 'link',
        icontype: 'icon-tour',
        role: 'nexcore_tour_view',
        count: -1,
      }
      , {
        path: '/assignment',
        title: 'Disposition',
        type: 'link',
        icontype: 'icon-taskplanner',
        role: 'nexcore_scheduling_view',
        count: 0
      },
      //{
      //   path: '/tourPlanning/pickupsPlanner',
      //   title: 'Selbstabholer',
      //   type: 'link',
      //   icontype: 'person_pin',
      //   role: ''
      // }, {
      //   path: '/tourPlanning/operatorServicePlanner',
      //   title: 'Bedieneinsätze',
      //   type: 'link',
      //   icontype: 'touch_app',
      //   role: ''
      // },
      {
        path: 'orders',
        title: 'Aufträge',
        type: 'link',
        icontype: 'icon-orders',
        role: 'nexcore_order_view',
        count: -1
      }

      //,{
      //   path: '/tourPlanning/damageItems',
      //   title: 'Schäden',
      //   type: 'link',
      //   icontype: 'icon-damage',
      //   role: ''
      // }
      , {
        path: '/customers',
        title: 'Kunden',
        type: 'link',
        icontype: 'icon-group',
        role: 'nexcore_contact_view',
        count: -1
      }, {
          path: '/resources',
          title: 'Ressourcen',
          type: 'link',
          icontype: 'icon-item',
          role: 'nexcore_resource_view',
          count: -1
      }, {
        path: '/articles',
        title: 'Artikel',
        type: 'link',
        icontype: 'icon-article',
        role: 'nexcore_article_view',
        count: -1
    }
    ];
  }


}
