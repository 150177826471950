<app-layout-content-title>
  <ng-container ngProjectAs="[headline]">
    <i [className]="'icon-documents'"></i>
    <h2>Vorlage für {{this.formName? this.formName: '-'}} {{this.id ? 'bearbeiten' : 'anlegen'}}</h2>

  </ng-container>
  <ng-container ngProjectAs="[tools]">
  </ng-container>
</app-layout-content-title>
<div class="body flex flex-col white">
  <div [formGroup]="variantFormGroup" class="flex flex-col gap-25 basis-full border-b">
    <mat-form-field class="basis-full">
      <input matInput placeholder="Name" formControlName="name">
    </mat-form-field>
    <mat-checkbox formControlName="isActive" [disabled]="formVariant?.isActive">
        Aktiv
    </mat-checkbox>
    <!-- <ngs-code-editor [theme]="theme" [codeModel]="codeModel" [options]="options" (valueChanged)="onCodeChanged($event)" class="border">
    </ngs-code-editor> -->
    <ace [mode]="'json'" class="editor" id="editor1"  [theme]="'github'" [(value)]="codeValue" (textChanged)="onCodeChanged($event)"
    #editor></ace>

    <!-- <hr> -->

  </div>
  <div class="basis-full flex flex-row gap-25 items-start mt-5">
    <button mat-flat-button (click)="saveFormVariant()" id="save"
      [disabled]="!(variantFormGroup?.valid && !variantFormGroup?.pristine)">Speichern</button>
    <button mat-flat-button (click)="abort()" id="cancel">Abbrechen</button>
  </div>
</div>

