import { FormControl } from "@angular/forms";


export class FormVariant {
  id: string;
  name: string;
  key: string;
  isSystem: boolean;
  isActive: boolean;
  isDeletable: boolean;
  formControl: any;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.name = payload.name;
      this.key = payload?.key
      this.isSystem = payload.isSystem;
      this.isActive = payload.isActive;
      this.formControl = payload.formControl;
      this.isDeletable = payload?.isDeletable;
    }
  }

  /**
   * creates  from a result
   * @param result Array of any
   * @returns Array of
   */
  static createFormVariants(result: Array<any>): FormVariant[] {
    const variants = new Array<FormVariant>();
    if (result) {
      result.map((model) => {
        variants.push(new FormVariant(model));
      });
    }
    return variants;
  }
}

export class FormVariantInput {
  id: string;
  key: string;
  name: string;
  isSystem: boolean;
  isDeletable: string;
  isActive: boolean;;
  formControl: any;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.name = payload.name;
      this.key = payload.key;
      this.isSystem = payload?.isSystem;
      this.isDeletable = payload?.isDeletable;
      this.isActive = payload.isActive;
      this.formControl = payload.formControl;
    }
  }
}
