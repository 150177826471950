<span class="dialog-close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title *ngIf="isNew">Kontaktperson anlegen</h2>
<h2 mat-dialog-title *ngIf="!isNew">Kontaktperson bearbeiten</h2>
<mat-dialog-content >
  <div [formGroup]="contactpersonFormGroup" >

    <div class="no-margin flex flex-col white pl-5 pr-5">
      <div class="content flex flex-row basis-full gap-5">
        <ng-container *ngIf="!isName">
          <mat-form-field class="flex flex-row basis-1/2 ">
            <mat-label>Vorname</mat-label>
            <input matInput placeholder="Vorname" formControlName="firstName">
          </mat-form-field>
          <mat-form-field class="flex flex-row basis-1/2">
            <mat-label>Nachname</mat-label>
            <input matInput placeholder="Nachname" formControlName="lastName">
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="isName">
          <mat-form-field class="flex flex-row basis-full ">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>
        </ng-container>
      </div>
      <div class="content flex flex-row basis-full gap-5">
        <mat-form-field class="flex flex-row basis-1/2">
          <mat-label>Telefon</mat-label>
          <input matInput placeholder="Telefon" formControlName="phoneNumber">
        </mat-form-field>
        <mat-form-field class="flex flex-row basis-1/2">
          <mat-label>Mobilfunk</mat-label>
          <input matInput placeholder="Mobilfunk" formControlName="mobileNumber">
        </mat-form-field>
      </div>
      <div class="content flex flex-row basis-full gap-5">
        <mat-form-field class="flex flex-row basis-1/2">
          <mat-label>E-Mail</mat-label>
          <input matInput placeholder="E-Mail" formControlName="email">
        </mat-form-field>
      </div>
    </div>

  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button [disabled]="!(contactpersonFormGroup.valid && !contactpersonFormGroup.pristine)"
    (click)="saveContactPerson()">Speichern</button>
  <button mat-flat-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>

