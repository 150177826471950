import { Component, OnInit } from '@angular/core';
import * as fromGraphQl from './graphql';
import { ConfirmDialogComponent, ConfirmDialogModel, Container, LoadingService } from '@nexato/nx-core-module';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { Form } from '../../shared/entities/form/form';
import { FormVariant } from '../../shared/entities/formVariant/formVariant';
import { FormVariantsService } from '../../shared/services/formVariants/formVariants.service';


@Component({
  selector: 'app-forms-list',
  templateUrl: './forms-list.component.html',
  styleUrls: ['./forms-list.component.scss']
})
export class FormsListComponent extends Container<fromGraphQl.FormsQueryResponse>
implements OnInit{
  public forms: Form[];
  constructor(
    loadingService: LoadingService,
    apollo: Apollo,
    public dialog: MatDialog,
    private router: Router,
    private formVariantsService: FormVariantsService,
  ) {
    super(apollo, loadingService, fromGraphQl.FORMS_QUERY, environment);
  }

  ngOnInit(): void {
    this.subscribeToQuery();
    }

  handleSubscriptionResult(data: fromGraphQl.FormsQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data) {
      this.queryRef.stopPolling();
      this.forms = Form.createForms(data.forms);
    }
  }

  createFormVariant(formKey: string) {
    console.log('to create');
    // TODO
    this.router.navigate(['./settings/forms/'+formKey+'/create/']);
  }

  confirmDialog(variant: FormVariant): void {
    const message = 'Möchtest Du die Vorlage (' + variant.name +') wirklich löschen?';
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.loadingService.setLoading(true);
        this.deleteVariant(variant.id);
      }
    });
  }

  deleteVariant(id: string) {
    // TODO
    this.formVariantsService.deleteFormVariant(
      id,
      this.returnCallback(this.router)
    );
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.loadingService.setLoading(false);
      this.queryRef.refetch();
    }, 500);
  }
  viewFormVariant(formKey: string, id: string) {
    // TODO
    if (id) {
      this.router.navigate(['./settings/forms/' +formKey + '/' + id]);
    }
  }

}
