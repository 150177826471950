import { Apollo } from 'apollo-angular';
import * as fromGraphQl from './graphql';
import { NXDataSource } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';
import { Task } from 'src/app/rent-module/shared/entities/task/task';


export class OrderTaskListDataSource extends NXDataSource<Comment> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.TASK_LIST_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.TaskListQueryResponse): void {
    super.setSubject(
      data.tasks ? Task.createTasks(data.tasks) : [],
      data.tasks ? data.tasks.length : 0
    );
  }

  loadTaskList(variables: any): void {
    this.subscribeToQuery(variables);
  }
}
