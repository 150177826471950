import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as fromGraphQl from './graphql';
import { Container, LoadingService } from '@nexato/nx-core-module';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { FormVariantsService } from '../../shared/services/formVariants/formVariants.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormVariant, FormVariantInput } from '../../shared/entities/formVariant/formVariant';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import { AceComponent } from 'ngx-ace-wrapper';

@Component({
  selector: 'app-form-variant',
  templateUrl: './form-variant.component.html',
  styleUrls: ['./form-variant.component.scss']
})
export class FormVariantComponent extends Container<fromGraphQl.FormVariantQueryResponse>
implements OnInit, OnDestroy {

  @ViewChild('editor') editor: AceComponent;

  // general
  public formVariant: FormVariant;
  public id: string;
  public formKey: string;
  public routeSubscription: Subscription;
  public formName: string;
  counter = 0;
  public codeValue:any;

  public variantFormGroup: UntypedFormGroup;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    loadingService: LoadingService,
    apollo: Apollo,
    private fb: UntypedFormBuilder,
    private formVariantsService: FormVariantsService,
  ) {
    super(apollo, loadingService, fromGraphQl.FORM_VARIANT_QUERY, environment);
  }

  ngAfterViewInit() {
    const aceEditor = this.editor?.directiveRef?.ace();
    aceEditor?.getSession()?.on('change', () => {
      this.onCodeChanged(aceEditor?.getValue());
    });
    aceEditor?.setOptions({
      useWorker: false,
    });
  }

  onCodeChanged(value: any) {
    console.log('value', value);
    this.codeValue = value;
    if (this.counter >0) {
      this.variantFormGroup?.markAsDirty();
    }
    this.counter ++;

  }

  ngOnInit(): void {
    const key = 'id';
    this.variantFormGroup = this.fb.group({
      name: [null, [Validators.required]],
      isActive: [null]
    });
    // if we call this compobent with an ID, we want do load a model
    this.routeSubscription = this.route.params
      .pipe(
        filter((params: { [x: string]: string; }) => params[key] !== undefined)
      )
      .subscribe((params: { [x: string]: string; }) => {
        if (params[key] && params[key] !== 'create') {
          this.id = params[key];
        }
        if (params['formKey']) {
          this.formKey = params['formKey'];
        }
        this.subscribeToQuery({
          id: this.id,
          formKey: this.formKey
        });
      });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  handleSubscriptionResult(data: fromGraphQl.FormVariantQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data) {
      this.queryRef.stopPolling();
      if (data.form?.name) {
        this.formName = data.form?.name;
      }
      if (data.formVariant) {
        this.formVariant = new FormVariant(data.formVariant);
        // console.log(this.formVariant);
        this.variantFormGroup.patchValue(this.formVariant);
        if (data.formVariant?.formControl) {
          let code = data.formVariant?.formControl;
          this.codeValue = JSON.stringify(code, undefined, 4);
          console.log('codeModel', this.codeValue);
        }
      }
    }
  }
  getCode(variant: any): any {
    return JSON.stringify(variant, undefined, 4)
  }

  saveFormVariant(): void {
    this.loadingService.setLoading(true);
    const formModel = this.variantFormGroup.value;
    // console.log('to save form', formModel);
    const userModel = new FormVariantInput();
    userModel.name = formModel.name;
    userModel.key = this.formKey;
    userModel.isActive = formModel?.isActive? formModel.isActive : false;

    if (this.codeValue) {
      userModel.formControl = JSON.parse(this.codeValue);
    } else {
      userModel.formControl = null;
    }
    console.log('model to save', userModel)

    if (this.id) {
      userModel.id = this.id;
      this.formVariantsService.updateFormVariant(
        userModel,
        this.returnCallback(this.router)
      );
    } else {
      this.formVariantsService.createFormVariant(
        userModel,
        this.returnCallback(this.router)
      );
    }
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.loadingService.setLoading(false);
      router.navigate(['/settings/forms/']);
    }, 500);
  }

  abort(): void {
    this.router.navigate(['/settings/forms/']);
  }


}
