
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import { Tour } from './tour';

// a driver is just a user with the role "DRIVER - should we check this
// during initalization?"
export class Driver extends Contact {

  tours: Tour[];

  constructor(payload?: any ) {
    super(payload);
    if(payload && payload.tours){
      this.tours = new Array<Tour>();
      payload.tours.map( (tour: any) => {
        this.tours.push(new Tour(tour));
      })
    }
  }

    /**
   * creates Drivers from a result
   * @param result array of any
   * @returns Array of Driver
   */
    static createDrivers(result: Array<any>): Driver[] {
      const drivers = new Array<Driver>();
      if (result) {
        result.map((driver) => {
          drivers.push(new Driver(driver));
        });
      }
      return drivers;
    }
}
