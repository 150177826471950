<div class="flex flex-row pr-5 pl-4 items-center add border-t" *ngIf="rolesService.hasRole('framework_files_manage')" [ngStyle]="{'background-color': headerColor}">
  <span *ngIf="header" class="basis-1/2">{{header}}</span>
  <span class="flex justify-end basis-full">
    <app-nx-button (click)="fileInput.click()" toolTip="Datei hochladen" icon="icon icon-document-upload">
    </app-nx-button>
    <input #fileInput type="file"  style="display:none;"  (change)="fileChangeEvent($event)" accept="application/pdf, image/jpg, image/jpeg, image/png"/>
  </span>
</div>

<!-- <div class="flex flex-row items-center">
  <ng-container *ngIf="uploading">
    <mat-spinner class="spinner" [diameter]="30"></mat-spinner>
  </ng-container>
</div> -->
<div [hidden]="(dataSource?.loading| async)">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="text">
      <!-- <mat-header-cell *matHeaderCellDef fxFlex="40%" class="header_column table_column">Name</mat-header-cell> -->
      <mat-cell *matCellDef="let file">
        <div class="flex flex-row items-center basis-full">
          <div class="flex basis-2/3 items-center">
            <i [className]="'icon icon-file-attachment'"></i>
            <lightgallery *ngIf="file?.extension !== 'pdf'">
              <a  data-lg-size="480-475-480, 800-791-800, 1406-1390" class="gallery-item"
                [attr.data-src]="fileApi + '/view/' + file?.fullPath + '?width=1406'">
                {{file?.fileName}}
              </a>
            </lightgallery>
            <span (click)="downloadPDF(file)" class="link" *ngIf="file?.extension === 'pdf'">{{file?.fileName}}</span>
           &nbsp;({{file?.extension}} - {{getSizeinMb(file?.size)}} MB)
          </div>
          <div class="flex basis-1/3 justify-end items-center">
            {{file?.uploadedDateTime  | date:'dd.MM.YYYY HH:mm'}} Uhr
            <i [className]="'icon icon-options'" [matMenuTriggerFor]="menu"></i>
          </div>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="downloadPDF(file)">
            <mat-icon class="icon-file-download"></mat-icon>
            <span>Datei herunterladen</span>
          </button>
          <button mat-menu-item (click)="confirmDialog(file)" *ngIf="rolesService.hasRole('framework_files_manage')">
            <mat-icon class="icon-trash"></mat-icon>
            <span>Datei löschen</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row">
    </mat-row>
    <div *matNoDataRow class="no-data">
      <p>keine Dateien vorhanden</p>
    </div>
  </mat-table>
</div>
<div *ngIf="dataSource?.loading | async" class="spinner">
  <mat-spinner [diameter]="30"></mat-spinner>
</div>
<div class="footer mt-2" [hidden]="!dataSource?.totalElements || dataSource?.totalElements< 6">
  <div class="pagination">
    <mat-paginator [length]="dataSource?.totalElements" [pageSize]="5" [pageIndex]="0"
    [pageSizeOptions]="[5, 10, 15, 20]" aria-label="Select page" class="paginator" [hidden]="!dataSource?.totalElements || dataSource?.totalElements< 6"></mat-paginator>
  </div>
</div>
