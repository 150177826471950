import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

import { DatePipe } from '@angular/common';
import { CommentDialogComponent } from 'src/app/order-module/components/comment-dialog/comment-dialog.component';


@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-task-info-dialog",
  templateUrl: "task-info-dialog.html",
  styleUrls: ["./task-info-dialog.scss"]
})
export class TaskInfoDialogComponent {
  public task: Task;

  constructor(
    public dialogRef: MatDialogRef<TaskInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    public dialog: MatDialog,

  ) {
    this.task = data.task;
  }

  abort() {
    this.dialogRef.close();
  }

  createComment() {
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '900px',
      data: {
        orderId: this.task?.order?.id,
      },
      autoFocus: false,
    });
  }
}
