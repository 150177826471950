import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { Order } from '../../shared/entities/order/order';

export const ORDERS_QUERY = gql`
query ordersQuery($number: Int, $size: Int, $sortProperty: String, $sortDirection: String,
  $orderNumber: String, $customer: UUID, $resource: UUID, $locationId: [UUID])
{
  orders: getOrdersPaged(
    filter: {
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
      number: $orderNumber,
      customerId: $customer,
      resourceId: $resource,
      locationIds: $locationId
    }

    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
          type
          number
          state
          customer {
            type
            companyName
            firstName
            lastName
          }
          lineItems {
            article {
              number
            }
            resourceAssignments {
              resource {
                name
              }
            }
          }
          location {
            name
          }
      }
  }

  locations: getLocations(filter:{}){
    id
    name
    isDeletable
    label
  }
}
`;

export interface OrdersQueryResponse {
  orders: Page<Order>;
  loading: boolean;
  locations: LocationEntity[]
}
