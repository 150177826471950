<mat-sidenav-container fullscreen class="sidenav-container" autosize>
  <mat-sidenav mode="side" opened [ngClass]="isExpanded? 'expand': ''">
    <!-- side nav header -->
    <div class="sidenav_header">
      <div class="details content-center flex flex-row">
        <div class="basis-1/5" *ngIf="isExpanded">
          <i [className]="'icon icon-user'"></i>
        </div>
        <div class="basis-3/5 userName"  *ngIf="!rolesService.hasRole('framework_tenants_switch') && isExpanded">
          <div>
            {{givenName}} {{familyName}}
          </div>
        </div>
        <div class="basis-3/5" *ngIf="rolesService.hasRole('framework_tenants_switch') && isExpanded">
          <div class="user">
            {{givenName}} {{familyName}}
          </div>
          <div class="tenant">
            {{tenantName}}
          </div>
          <div class="tenant">
            {{pageTitle}}
          </div>
        </div>
        <span class="navi basis-1/5 items-center">
          <i [className]="'icon icon-back-to'" (click)="isExpanded = !isExpanded" *ngIf="isExpanded" matTooltip="Menü reduzieren"></i>
          <i [className]="'icon icon-before'" (click)="isExpanded = !isExpanded" *ngIf="!isExpanded" matTooltip="Menü erweitern"></i>
         </span>
      </div>

    </div>
    <!-- side nav header -->

    <!-- side nav body -->
    <div class="sidenav_body">
      <app-navigation [isExpanded]="isExpanded"/>
    </div>
    <!-- side nav body -->

    <div class="spacer"></div>

    <!-- side nav footer -->
    <div class="sidenav_footer">
      <mat-nav-list>
        <mat-list-item [routerLink]="['/settings']" *ngIf="rolesService.hasRole('framework_settings_manage') && licenseService?.isLicenceUser()" routerLinkActive="active"
        class="flex flex-row items-center">
        <div class="nav">
          <i [className]="'icon icon-settings'" [matTooltip]="isExpanded? null: 'Einstellungen'"></i>
          <span *ngIf="isExpanded">Einstellungen</span>
        </div>
        </mat-list-item>
        <mat-list-item (click)="logout()" routerLinkActive="active" class="logout">
          <div class="nav">
            <i [className]="'icon icon-logout'"></i>
            <span *ngIf="isExpanded">Ausloggen</span>
          </div>
        </mat-list-item>
      </mat-nav-list>
    </div>
    <!-- side nav footer -->
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <div class="flex flex-row basis-full center items-center gap-5">
        <span class="flex justify-end basis-1/2" >
          <img src={{this.logo}} alt="Logo" class="logo">
        </span>
        <!-- <span  class="basis-1/2 items-center">
          <div class="title">{{this.pageTitle}}</div>
          <div class="subTitle">{{this.pageSubTitle}}</div>
        </span> -->
      <!-- <span class="example-fill-remaining-space"></span> -->
      <app-orders-search class="pr-7 flex justify-end basis-1/2"/>
      </div>


    </mat-toolbar>
    <div class="main" [ngClass]="{ loading : isloading }" [ngStyle]="{'height': 'calc(100% - 96px)'}">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
