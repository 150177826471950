import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel, RolesService, User } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs';
import { Tour, TourInput } from '../../shared/entities/tour';
import * as fromGraphQl from './graphql';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-tour-settings-dialog",
  templateUrl: "tour-settings-dialog.html",
  styleUrls: ["./tour-settings-dialog.scss"]
})
export class TourSettingsDialogComponent {

  tour: Tour;
  public tourForm: FormGroup;
  // public resources: Resource[];


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TourSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    public rolesService: RolesService,
    private apollo: Apollo
  ) {
    this.tourForm = this.fb.group({
      'resource': [null],
      'assignee': [null],
      'startTime': [null],
      'endTime': [null],
      'departureAddress': [null],
      'arrivalAddress': [null],
      'startDateControl': [null],
      'endDateControl': [null],
      'description': [null],
    });
    this.apollo.query<fromGraphQl.TourSettingsDialogQueryResponse>({
      query: fromGraphQl.TOUR_SETTINGS_DIALOG_QUERY,
      variables: {
        tourId: data.tour.id
      },
      fetchPolicy: 'network-only',
    })
    .pipe(take(1))
    .subscribe(data => {
      this.tour = new Tour(data.data.tour);
        this.initForm();
    });
    // this.resources = data.resources;

  }
  initForm() {
    this.tourForm.patchValue({
      assignee: this.tour?.driver?.id,
      resource: this.tour?.resource?.id,
      startTime: this.datePipe.transform(this.tour?.scheduledDepartureDateTime, 'HH:mm', 'CET'),
      endTime: this.datePipe.transform(this.tour?.scheduledArrivalDateTime, 'HH:mm', 'CET'),
      startDateControl: this.tour?.scheduledDepartureDateTime ? this.tour?.scheduledDepartureDateTime : null,
      endDateControl: this.tour?.scheduledArrivalDateTime ? this.tour?.scheduledArrivalDateTime : null,
      description: this.tour?.note,
      departureAddress: this.tour?.departureAddress?.id,
      arrivalAddress: this.tour?.arrivalAddress?.id
    });
    if (this.tour?.state === 'RUNNING') {
      this.tourForm?.controls['assignee']?.disable();
      this.tourForm?.controls['resource']?.disable();
      this.tourForm?.controls['startDateControl']?.disable();
      this.tourForm?.controls['startTime']?.disable();
      this.tourForm?.controls['departureAddress']?.disable();
    }
    this.tourForm?.controls['startDateControl']?.valueChanges.subscribe(() => {
      if (
        this.tourForm?.controls['startDateControl'].value
      ) {
        if (!this.tourForm?.controls['startTime'].value) {
          this.tourForm?.controls['startTime'].setValue("00:00");
        }
        this.tourForm?.controls['startTime'].setValidators([Validators.required]);
        this.tourForm?.controls['startTime'].updateValueAndValidity();
      }
    });
    this.tourForm?.controls['startTime']?.valueChanges.subscribe(() => {
      if (
        this.tourForm?.controls['startTime'].value
      ) {
        this.tourForm?.controls['startDateControl'].setValidators([Validators.required]);
        this.tourForm?.controls['startDateControl'].updateValueAndValidity();
      }
    });
    this.tourForm?.controls['endDateControl']?.valueChanges.subscribe(() => {
      if (
        this.tourForm?.controls['endDateControl'].value
      ) {
        if (!this.tourForm?.controls['endTime'].value) {
          this.tourForm?.controls['endTime'].setValue("00:00");
        }
        this.tourForm?.controls['endTime'].setValidators([Validators.required]);
        this.tourForm?.controls['endTime'].updateValueAndValidity();
      }
    });
    this.tourForm?.controls['endTime']?.valueChanges.subscribe(() => {
      if (
        this.tourForm?.controls['endTime'].value
      ) {
        this.tourForm?.controls['endDateControl'].setValidators([Validators.required]);
        this.tourForm?.controls['endDateControl'].updateValueAndValidity();
      }
    });
  }
  ok() {
    const formModel = this.tourForm.value;
    let tourModel = new TourInput();
    tourModel.id = this.tour.id;
    tourModel.locationId = this.tour?.location?.id;

    if (this.tourForm?.controls['description']?.dirty) {
      tourModel.note = formModel?.description;
    } else {
     tourModel.note = this.tour.note;
    }
    if (this.tourForm?.controls['assignee']?.dirty) {
       tourModel.assignee = formModel?.assignee;
    } else {
      tourModel.assignee = this.tour.driver?.id;
    }
    if (this.tourForm?.controls['resource']?.dirty) {
      tourModel.resource = formModel?.resource;
    } else {
     tourModel.resource = this.tour.resource?.id;
    }

    if (formModel.startTime) {
      tourModel.scheduledDepartureDateTime = new Date (this.datePipe.transform(
       formModel.startDateControl, 'yyyy-MM-dd' + 'T' + formModel?.startTime + 'Z')).toISOString();
    } else {
      tourModel.scheduledDepartureDateTime = formModel.scheduledDepartureDateTime;
    }
    if (formModel.endTime) {
      tourModel.scheduledArrivalDateTime = new Date (this.datePipe.transform(
        formModel.endDateControl, 'yyyy-MM-dd' + 'T' + formModel?.endTime + 'Z')).toISOString();
    } else {
      tourModel.scheduledArrivalDateTime = formModel.scheduledArrivalDateTime;
    }

    if (formModel?.arrivalAddress) {
      tourModel.arrivalAddressId = formModel?.arrivalAddress;
    } else {
      tourModel.arrivalAddressId = formModel.arrivalAddress;
    }
    if (formModel?.departureAddress) {
      tourModel.departureAddressId = formModel?.departureAddress;
    } else {
      tourModel.departureAddressId = formModel.departureAddress;
    }
    this.dialogRef.close({model: tourModel});
  }

  deleteTour(){
    if(this.tour?.isDeletable){
      const message = 'Möchtest Du die Tour (' + this.tour.name +') wirklich löschen?';
      const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData,
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.dialogRef.close({tour: this.tour});
        }
      });
    }
  }

  abort() {
    this.dialogRef.close();
  }

  getName(user: User): string {
    if (user?.firstName && user?.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName || user?.lastName) {
      return user?.firstName ? user?.firstName : user?.lastName;
    } else {
      return '-'
    }
  }
}
