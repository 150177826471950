import { Component, EventEmitter, OnDestroy, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
})
export class FilterInputComponent implements OnDestroy  {

  filterFormControl: FormControl = new FormControl();
  filterFormControlSubscription: Subscription;

  @Output() filter = new EventEmitter();
  @Input()
  set searchValue(searchValue: any)  {
    this.filterFormControl.setValue(searchValue);
  }
  @Input() showIcon: boolean;
  @Input() className: string;
  @Input() placeholder: string = 'Filter';

  constructor() {
    this.filterFormControlSubscription = this.filterFormControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(value => {
        this.filter.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.filterFormControlSubscription.unsubscribe();
  }
}
