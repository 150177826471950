import { Tour } from "src/app/tour-planning/shared/entities/tour";
import { Order } from "../../../../order-module/shared/entities/order/order";
import { Address } from "../../../../shared-module/shared/entities/address/address";
import { TaskAssignment } from "../taskAssignment/taskAssignment";
import { TaskAssignmentSuggestion } from "../taskAssignmentSuggestion/taskAssignmentSuggestion";
import { User } from "@nexato/nx-core-module";
import { AssignmentStrategyCandidate } from "../assignmentStrategyCandidate/assignmentStrategyCandidate";

export class Task {
  id: string;
  state: string;
  formTemplate: any;
  dueDateTimePeriod: any;
  type: string;
  order: Order;
  description: string;
  context: string;
  address: Address;
  protocol: any;
  isAssignable: boolean;
  tour: Tour;
  assignmentSuggestion: TaskAssignmentSuggestion;
  assignment: TaskAssignment
  assignmentStrategyCandidates: AssignmentStrategyCandidate[];
  completedDateTime: any;
  completedBy: User;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.state = payload.state;
      this.formTemplate = payload.formTemplate;
      this.dueDateTimePeriod = payload.dueDateTimePeriod;
      this.type = payload.type;
      if (payload.order) {
        this.order = new Order(payload.order);
      }
      this.description = payload.description;
      this.context = payload.context;
      this.protocol = payload.protocol;
      if (payload.address) {
        this.address = new Address(payload.address);
      }
      this.isAssignable = payload?.isAssignable;
      if (payload.tour) {
        this.tour = new Tour(payload.tour);
      }
      if (payload.assignmentSuggestion){
        this.assignmentSuggestion = new TaskAssignmentSuggestion(payload.assignmentSuggestion)
      }
      if (payload.assignment){
        this.assignment = new TaskAssignment(payload.assignment)
      }
      if (payload.assignmentStrategyCandidates){
        this.assignmentStrategyCandidates = new Array<AssignmentStrategyCandidate>();
        payload.assignmentStrategyCandidates.map((candidate: any) => {
          this.assignmentStrategyCandidates.push(new AssignmentStrategyCandidate(candidate));
        });
      }
      this.completedDateTime = payload?.completedDateTime;
      if (payload.completedBy) {
        this.completedBy = new User(payload.completedBy);
      }
    }
  }

  /**
   * creates tasks from a result
   * @param result array of any
   * @returns Array of Task
   */
  static createTasks(result: Array<any>): Task[] {
    const tasks = new Array<Task>();
    if (result) {
      result.map((task) => {
        tasks.push(new Task(task));
      });
    }
    return tasks;
  }
  public getType() {
    switch (this?.type) {
      case 'nexcore_rental_resourceAssignmenmt_out' : return 'Übergabe';
      case 'nexcore_rental_resourceAssignmenmt_in' : return 'Rücknahme';
      case 'nexcore_rental_resourceAssignmenmt_refuel' : return 'Nachtanken';
      case 'nexcore_schedule' : return 'Disposition';
      default: return '';
    }

  }

  public getClassName() {
    switch (this?.state) {
      case 'COMPLETED' : return 'icon icon-task-completed';
      case 'ASSIGNED' : return 'icon icon-task-planned';
      case 'NEW' : return 'icon icon-task-open';
      case 'UNASSIGNED' : return 'icon icon-task-open';
      case 'DELEGATED' : return 'icon icon-task-planned';
      default: return '';
    }
  }

   public getTypeIcon() {
    switch (this?.type) {
      case 'nexcore_rental_resourceAssignmenmt_out' : return 'icon icon-item-out';
      case 'nexcore_rental_resourceAssignmenmt_in' : return 'icon icon-item-in';
      case 'nexcore_rental_resourceAssignmenmt_refuel' : return 'icon icon-refuel';
      default: return '';
    }

  }

}

enum TaskType {
  IN,
  OUT,
  APPROVE_BOOKING,
}

export enum TaskState {
	NEW, ASSIGNED, DELEGATED, COMPLETED, UNASSIGNED
}

