<ng-container *ngIf="_controlTemplate?.settings?.value; else noValue">
    <div class="flex pr-2 py-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showPictures() && !archived">
        <lightgallery [settings]="settings" class="flex justify-end w-full">
            <a
                [attr.class]="'ml-2'"
                *ngFor="let file of files"
                data-lg-size="480-475-480, 800-791-800, 1406-1390" class="gallery-item"
                [attr.data-src]="fileApi + '/view/' + file + '?width=1406'"
                >
                <img class="img-responsive"
                    src="{{fileApi}}/view/{{file}}?width=69&height=48" *ngIf="fileApi && file"/>
                </a>
        </lightgallery>
    </div>
    <div class="pr-2 py-2 border-b border-solid border-gray-300 last:border-b-0 text-end italic font-light" *ngIf="archived">
        Archiviert
    </div>
    <div class="pr-2 py-2 border-b border-solid border-gray-300 last:border-b-0 text-end" *ngIf="showNoPicturesPossible()">
        {{this._controlTemplate?.settings?.noPicturesPossibleLabel}}: {{_controlTemplate?.settings?.value?.picturesNotPossibleReason ? _controlTemplate?.settings?.value?.picturesNotPossibleReason : '-'}}
    </div>
</ng-container>
<ng-template #noValue>
    <div class="text-right p-2">-</div>
</ng-template>