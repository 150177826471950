import { Apollo } from 'apollo-angular';
import * as fromGraphQl from './graphql';
import { File, NXDataSource } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';


export class OrderFilesListDataSource extends NXDataSource<File> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.FILES_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.FilesQueryResponse): void {
    super.setSubject(
      data.files?.content,
      data.files?.totalElements ? data.files.totalElements : 0
    );
  }

  loadFilesList(variables: any): void {
    this.subscribeToQuery(variables);
  }
}
