<div class="content">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i [className]="'icon-article'"></i>
      <h2>Artikel</h2>
    </ng-container>
    <ng-container ngProjectAs="[tools]">
      <!-- <app-nx-button (click)="createArticle()"  toolTip="neues Artikel anlegen" icon="icon icon-plus">
      </app-nx-button> -->
      <app-nx-button (click)="drawerRight.toggle()" icon="icon icon-options">
      </app-nx-button>
    </ng-container>
  </app-layout-content-title>
  <div class="body">
    <mat-drawer-container [hasBackdrop]="false" class="container">
      <!-- filter start -->
      <mat-drawer #drawerRight [mode]="'side'" [position]="'end'" class="filter">
        <!-- <h2 class="header">Einstellungen</h2> -->
        <div>
          <h3>Filter</h3>
          <mat-form-field class="white">
            <mat-label>Nummer</mat-label>
            <input matInput [formControl]="articleNumberControl">
            <button *ngIf="number" matSuffix mat-icon-button aria-label="Clear"
              (click)="articleNumberControl.setValue('')">
              <mat-icon class="black-icon">close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </mat-drawer>
      <mat-drawer-content class="drawer-content">
        <div class="details flex flex-col basis-full">
          <div [hidden]="dataSource?.loading| async" class="table flex flex-row basis-full">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  class="header_column table_column">Nummer</mat-header-cell>
                <mat-cell *matCellDef="let article" class="table_column">
                  <span class="noWrap" #number [matTooltipDisabled]="isEllipsisActive(number)" matTooltipPosition="left"
                    matTooltipShowDelay="250" matTooltip=" {{article.number}}">
                    {{article.number}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  class="header_column table_column">Name</mat-header-cell>
                <mat-cell *matCellDef="let article" class="table_column">
                  <span class="noWrap" #name [matTooltipDisabled]="isEllipsisActive(name)" matTooltipPosition="left"
                    matTooltipShowDelay="250" matTooltip=" {{article.name}}">
                    {{article.name}}
                  </span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="table_header"
                [hidden]="!dataSource?.totalElements">
              </mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" class="table_row">
              </mat-row>
              <div *matNoDataRow class="no-data flex items-top justify-center">
                <p>keine Artikel vorhanden</p>
              </div>
            </mat-table>
          </div>
          <div *ngIf="dataSource?.loading | async" class="spinner">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
          <div class="sticky list-footer">
            <div class="pagination justify-end flex basis-full ">
              <mat-paginator [length]="dataSource?.totalElements" [pageSize]="15" [pageIndex]="0"
                [pageSizeOptions]="[15, 20, 25, 30]" aria-label="Select page" class="paginator"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
