import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';


export const TOUR_PLANNER_TASK_QUERY = gql`
query GetTasksForPlanningQuery(
  $sortProperty: String,
  $sortDirection: String,$locationIds: [UUID], $text: String, $preview: Int
  $fromDate: String, $toDate: String,
  $taskAssignmentInput: TaskAssignmentInput,$number: Int, $size: Int
)
{
  tasksForPlanning: getTasksPaged(
    taskFilter: {
      locationIds: $locationIds, states : [NEW, UNASSIGNED], text: $text, fromDateTime: $fromDate,
      toDateTime: $toDate,handlingType: DELIVERY, preview: $preview,
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
  }){
    totalPages
    totalElements
    number
    size
    content{
      ... taskExcerpt
    }
  }


}

fragment taskExcerpt on Task{
  id
  type
  dueDateTimePeriod {
    modifier
    dateTime
    fromDateTime
    toDateTime
  }
  state
  description
  order{
    id
    number
    type
    note
    customer {
      type
      companyName
      companyNameExtension
      firstName
      lastName
    }
    location {
      id
      name
    }
    comments {
      text
    }
    fileCount
  }
  address {
    id
    country
    city
    postalCode
    street
    houseNumber
    name
    lat
    lng
    text
    type
    note
    geocodingResultGrade
    externalAddress
  }
  isAssignable(taskAssignmentInput: $taskAssignmentInput)
}
`;


export interface TourPlannerTasksQueryResponse {
  tasksForPlanning:Page<Task>;
}


