import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModuleModule, SettingsService } from '@nexato/nx-core-module';
import { LightgalleryModule } from 'lightgallery/angular';
import { AppRoutingModule } from '../app-routing.module';
import { FrontendMaterialModule } from '../material-module/material.module';
import { SharedModule } from '../shared-module/shared.module';
import { FormService } from '../tour-planning/shared/services/form/form.service';

import { ResourceDialogComponent } from './components/resource-dialog-component/resource-dialog.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TaskAssignmentComponent } from './components/task-assignment-component/task-assignment.component';
import { ArticlesListComponent } from './containers/articles-list/articles-list.component';
import { ContactPersonListComponent } from './containers/contact-person-list/contact-person-list.component';
import { CustomerViewComponent } from './containers/customer-view/customer-view.component';
import { CustomersListComponent } from './containers/customers-list/customers-list.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { GeneralSettingsComponent } from './containers/general-settings/general-settings.component';
import { LocationViewComponent } from './containers/location-view/location-view.component';
import { LocationsListComponent } from './containers/locations-list/locations-list.component';
import { AceModule } from 'ngx-ace-wrapper';


import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { OrderModule } from '../order-module/order.module';
import { AddressAutocompleteComponent } from '../shared-module/components/address-autocomplete/address-autocomplete.component';
import { AddressDialogComponent } from '../shared-module/components/address-dialog/address-dialog.component';
import { AddressInputControlComponent } from '../shared-module/components/adresses-form/addresses-form.component';
import { ContactPersonDialogComponent } from './components/contact-person-dialog/contact-person-dialog.component';
import { DateTimePeriodFormControlComponent } from './components/date-time-period-form-control/date-time-period-form-control.component';
import { TaskAssignmentListAssignedComponent } from './components/task-assignment-list-assigned/task-assignment-list-assigned.component';
import { TaskAssignmentListCompletedComponent } from './components/task-assignment-list-completed/task-assignment-list-completed.component';
import { TaskAssignmentListUnassignedComponent } from './components/task-assignment-list-unassigned/task-assignment-list-unassigned.component';
import { TaskSchedulingDialogComponent } from './components/task-scheduling-dialog/task-scheduling-dialog.component';
import { DashboardTaskListComponent } from './containers/dashboard-task-list/dashboard-task-list.component';
import { DashboardTourListComponent } from './containers/dashboard-tour-list/dashboard-tour-list.component';
import { ResourceViewComponent } from './containers/resource-view/resource-view.component';
import { ResourcesListComponent } from './containers/resources-list/resources-list.component';
import { TaskAssignmetGeneralComponent } from './containers/task-assignment-general/task-assignment-general.component';
import { ContactPersonService } from './shared/services/contactPerson/contactPerson.service';
import { GoogleService } from './shared/services/google.service';
import { HereService } from './shared/services/here.service';
import { LocationService } from './shared/services/location/location.service';
import { ResourceService } from './shared/services/resource/resource.service';
import { TasksService } from './shared/services/tasks/tasks.service';
import { FormsListComponent } from './containers/forms-list/forms-list.component';
import { FormVariantComponent } from './containers/form-variant/form-variant.component';
import { FormVariantsService } from './shared/services/formVariants/formVariants.service';

@NgModule({
  declarations: [
    SettingsComponent,
    DashboardComponent,
    CustomersListComponent,
    ResourcesListComponent,
    ArticlesListComponent,
    LocationsListComponent,
    LocationViewComponent,
    ResourceViewComponent,
    ContactPersonListComponent,
    CustomerViewComponent,
    ResourceDialogComponent,
    TaskAssignmentComponent,
    GeneralSettingsComponent,
    DashboardTourListComponent,
    DashboardTaskListComponent,
    ContactPersonDialogComponent,
    AddressDialogComponent,
    TaskSchedulingDialogComponent,
    DateTimePeriodFormControlComponent,
    TaskAssignmentListUnassignedComponent,
    TaskAssignmentListAssignedComponent,
    TaskAssignmentListCompletedComponent,
    TaskAssignmetGeneralComponent,
    AddressAutocompleteComponent,
    AddressInputControlComponent,
    FormsListComponent,
    FormVariantComponent
  ],
  imports: [
    CommonModule,
    FrontendMaterialModule,
    AppRoutingModule,
    CoreModuleModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    LightgalleryModule,
    OrderModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    AceModule
  ],
  providers:[
    LocationService,
    DatePipe,
    TasksService,
    ResourceService,
    FormService,
    SettingsService,
    ContactPersonService,
    HereService,
    GoogleService,
    FormVariantsService
  ]
})
export class RentModule { }
