import { Component, OnDestroy, OnInit } from '@angular/core';
import { Contact } from '../../shared/entities/contact/contact';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Container, LoadingService, RolesService } from '@nexato/nx-core-module';
import { Subscription, filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';

import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss']
})
export class CustomerViewComponent extends Container<fromGraphQl.CustomerQueryResponse>
implements OnInit, OnDestroy
{
public routeSubscription: Subscription;
public id: string;
public customer: any;
public customAttributeSections: any;
public Contact = Contact;

constructor(
  apollo: Apollo,
  loadingService: LoadingService,
  public router: Router,
  public route: ActivatedRoute,
  public rolesService: RolesService
) {
  super(apollo, loadingService, fromGraphQl.CUSTOMER_QUERY, environment);
}

  ngOnInit(): void {
    const key = 'id';
    this.routeSubscription = this.route.params
      .pipe(
        filter((params) => params[key] !== undefined),
        take(1)
      )
      .subscribe((params) => {
        if (params[key] && params[key] !== 'create') {
          this.id = params[key];
        }
        // load model (if we have an id) and the form
        this.subscribeToQuery({
          customerId: this.id,
          entity: 'Contact'
        });
      });
  }
  handleSubscriptionResult(data: fromGraphQl.CustomerQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data.customer) {
      this.queryRef.stopPolling();
      this.customer = data.customer;
      // console.log('customer', data.customer);
    }
    this.customAttributeSections = data?.customAttributeSections;
  }
  getContactType(id: any): string | undefined {
    const type = Contact.getContactTypes().find((o) => o?.id === id?.toString());
    return type?.name;
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  CheckSectionInAttributes( id: string) {
    if (this.customer?.customAttributes) {
      for (let attribute of this.customer?.customAttributes) {
        if (attribute?.customAttributeSection.id === id) {
          return true;
        }
      }
    }
    return false;
  }

  CheckDefintionInAttributes(id: string) {
    if (this.customer?.customAttributes) {
      for (let attribute of this.customer?.customAttributes) {
        if (attribute?.customAttributeDefinition?.id === id) {
          return true;
        }
      }
    }
    return false;
  }
  getValue(id: string) {
    for (let attribute of this.customer?.customAttributes){
      if (attribute?.customAttributeDefinition?.id === id){
        return attribute?.value;
      }
    }
    return '-';
  }

}

