import { Apollo } from 'apollo-angular';
import * as fromGraphQl from './graphql';
import { Contact } from '../../shared/entities/contact/contact';
import { NXDataSource } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';
import { ContactPerson } from '../../shared/entities/contactPerson/contactPerson';

export class ContactPersonDataSource extends NXDataSource<Contact> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.CONTACT_PERSON_LIST_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.ContactPersonListQueryResponse): void {
    super.setSubject(
      ContactPerson.createContactPersons(data.contactPersonList?.content),
      data.contactPersonList?.totalElements ? data.contactPersonList.totalElements : 0
    );
  }

  loadContactPersonList(variables: any): void {
    this.subscribeToQuery(variables);
  }
}
