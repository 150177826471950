import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ApolloService, ComponentState, LoadingService, PersistenceScope, RolesService, StateService, StateVar } from '@nexato/nx-core-module';
import { Apollo, QueryRef } from 'apollo-angular';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { environment } from '../../../../environments/environment';
import { ToursPlannerTasksListComponent } from '../../components/tours-planner-tasks-list/tours-planner-tasks-list.component';
import { Driver } from '../../shared/entities/driver';
import { Tour, TourInput } from '../../shared/entities/tour';
import { TourState } from '../../shared/entities/tourState';
import { AddressService } from '../../shared/services/address/address.service';
import { TaskService } from '../../shared/services/task/task.service';
import { TourService } from '../../shared/services/tour/tour.service';
import * as fromGraphQl from './graphql';

interface TourPlanComponentState{
  selectedDatePlan: string,
  selectedLocationTour: string,
  selectedLocationTask: Location[],
  selectedLocationPreview: number
}
@Component({
  selector: "app-tours-planner-page",
  templateUrl: "./tours-planner-page.component.html",
  styleUrls: ["./tours-planner-page.component.scss"]
})
export class ToursPlannerPageComponent implements OnInit, OnDestroy {

  @ViewChild('drawerLeft') public drawerLeft: MatDrawer;
  @ViewChild('drawerRight') public drawerRight: MatDrawer;
  @ViewChild('taskslist') public taskslist: ToursPlannerTasksListComponent;

  public height: number;
  public date = new Date();
  public initialDate = new Date();
  public location: string;
  public states: TourState[];
  public sortValue = 'resource';

  public loading = true;
  public tourplanerQuery: QueryRef<fromGraphQl.TourPlannerStandardQueryResponse>;
  public tourplanerQuerySubscription: Subscription;
  public pollingIntervalTourPlannerQuery = 15000;

  public tasksForPlanning: Task[];
  public dailyToursByResources = new Array<any>();
  public dailyToursNoResourcePlanned = new Array<Tour>();
  public dailyToursByDriver = new Array<any>();
  public dailyToursNoDriverPlanned = new Array<Tour>();
  public dailyToursSorted = new Array<any>();
  public dailyToursSortedNotPlanned = new Array<any>();
  public resources: Resource[];
  public drivers: Driver[] = [];
  public locations: any[];
  private result: any;
  public filterValue: String;
  public draggingActive = true;
  public openPanels = new Array<any>();
  public sitesActive = false;
  public selectedLocations: Location[];
  public selectedPreview: number;
  public tours: Tour[];
  public headline:string = 'Kein Fahrzeug geplant';
  public sortPropertyTask = 'type';
  public sortPropertyTaskDirection = 'asc';
  public componentStateCount = 0;
  public isFirst = true;
  public isTourFull = false;
  previousState: any = null;
  public fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
  public toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();


  componentState: ComponentState<TourPlanComponentState>;
  selectedDatePlan: StateVar<Date>;
  selectedLocationTour: StateVar<string>;
  selectedLocationTask: StateVar<Location[]>;
  selectedPreviewTask: StateVar<number>;



  constructor(
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private apollo: Apollo,
    private tourService: TourService,
    // private entityService: EntityService,
    // private store: Store<fromRoot.State>,
    private apolloService: ApolloService,
    private ref: ChangeDetectorRef,
    private taskService: TaskService,
    private loadingService: LoadingService,
    stateService: StateService,
    private cdRef:ChangeDetectorRef,
    private addressService: AddressService,
    public rolesService: RolesService,
  ) {
    this.onResize();
    this.componentState = new ComponentState<TourPlanComponentState>("ToursPlannerPageComponent", stateService, apollo);
    this.selectedDatePlan = new StateVar({
      key: "selectedDatePlan",
      initialValue: new Date(),
      persistenceScope: PersistenceScope.SessionStorage
    });
    this.componentState?.addStateVar(this.selectedDatePlan);
    this.selectedLocationTour = new StateVar({
      key: "selectedLocationTour",
      initialValue: this.location,
      persistenceScope: PersistenceScope.Backend
    });
    this.componentState?.addStateVar(this.selectedLocationTour);
    this.selectedLocationTask = new StateVar({
      key: "selectedLocationTask",
      initialValue: this.selectedLocations,
      persistenceScope: PersistenceScope.Backend
    });
    this.componentState?.addStateVar(this.selectedLocationTask);
    this.selectedPreviewTask = new StateVar({
      key: "selectedPreviewTask",
      initialValue: this.selectedPreview,
      persistenceScope: PersistenceScope.Backend
    });
    this.componentState?.addStateVar(this.selectedPreviewTask);
  }

  ngOnInit() {
    // load open panels
    const openPanels = JSON.parse(sessionStorage.getItem("openPanels"));
    if(openPanels){
      this.openPanels = openPanels;
    }
    this.componentState.watch( (state:any) => {
      if (this.componentStateCount > 2 && this.previousState!= state)  {
        this.selectedDatePlan.currentValue = state[this.selectedDatePlan.key];
        this.selectedLocationTour.currentValue = state[this.selectedLocationTour.key];
        this.selectedLocationTask.currentValue = state[this.selectedLocationTask.key];
        this.date = new Date(this.selectedDatePlan.currentValue);
        this.fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
        this.toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();

        this.location = this.selectedLocationTour.currentValue;
        this.selectedLocations =  this.selectedLocationTask.currentValue;
        this.selectedPreview = this.selectedPreviewTask.currentValue;
        this.isFirst? this.subscribeToTourPlannerData(): this.refetchQuery();
        this.previousState = state;
        this.cdRef.detectChanges();
       }
       this.componentStateCount++;
    });
  }

  ngOnDestroy(): void {
    this.tourplanerQuerySubscription?.unsubscribe();
  }

  subscribeToTourPlannerData() {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();

    let taskAssignmentInput = {
      "assignmentType": "TourTaskAssignmentStrategy",
      "fromDateTime": fromDate,
      "toDateTime": toDate
    };
      this.tourplanerQuery = this.apollo.watchQuery<fromGraphQl.TourPlannerStandardQueryResponse>({
        pollInterval: environment.pollingIntervall,
        fetchPolicy: 'network-only',
        query: fromGraphQl.TOUR_PLANNER_STANDARD_QUERY,
        variables: {
          sortPropertiesTask: this.sortPropertyTask,
          sortPropertiesTours: 'id',
          SortPropertyTaskDirection: this.sortPropertyTaskDirection,
          sortDirection: "asc",
          locationIds: this.selectedLocations,
          locationId: this.location,
          states: this.states,
          fromDate: fromDate,
          toDate: toDate,
          text: this.filterValue,
          preview: this.selectedPreview,
          taskAssignmentInput
        },
        //notifyOnNetworkStatusChange: true
      });
    this.tourplanerQuerySubscription = this.tourplanerQuery.valueChanges.subscribe(
      {
        next: (data) => {
          // console.log(data);
          this.loading = false;
          this.loadingService.setLoading(false);
          // if (result.networkStatus !== 6) {
          //   this.loading = result.loading;
          // }

          if (data.networkStatus === 7) {
            this.locations = data?.data?.locations;
            //     // we´ve received fresh data
            //     this.result = result.data;
            if (this.location) {
              this.tours = Tour.createTours(data.data.dailyTours);
              this.sortTours();
            }
            this.resources = Resource.createResources(data?.data?.resources);
          }
        },
        error: (error) => {
          console.log(error);
          this.loading = false;
          // restart polling after error
          this.tourplanerQuery = undefined;
          this.tourplanerQuerySubscription?.unsubscribe();
          setTimeout(() => this.subscribeToTourPlannerData(), this.pollingIntervalTourPlannerQuery);

        }
      }

    );

  }

  dateChanged($event: any) {
    // console.log('date Changed', $event);
    this.date = $event;
    this.fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    this.toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();

    this.componentState.update(this.selectedDatePlan,  this.date);
    this.refetchQuery();
  }

  offsetChanged($event: any) {
    this.selectedPreview = $event;
    this.componentState.update(this.selectedPreviewTask,  this.selectedPreview);
    this.refetchQuery();
  }

  showAddressNoteChanged($event: any) {
    localStorage.setItem("showAddressNote", $event.checked);
  }

  getShowAddressNote() {
    let showAddressNote = localStorage.getItem("showAddressNote");
    if(showAddressNote === 'false' || showAddressNote === undefined || showAddressNote === null){
      return false;
    } else {
      return true;
    }
  }

  showLocationChanged($event: any) {
    localStorage.setItem("showLocation", $event.checked);
  }

  getShowLocation() {
    let showAddressNote = localStorage.getItem("showLocation");
    if(showAddressNote === 'true' || showAddressNote === undefined || showAddressNote === null){
      return true;
    } else {
      return false;
    }
  }


  filter($event: any){
    this.filterValue = $event;
    this.refetchQuery();
  }

  selectTasksLocations($event: any){
    this.selectedLocations = $event;
    this.componentState.update(this.selectedLocationTask,  this.selectedLocations);
    this.refetchQuery();
  }

  selectToursLocation($event: any){
    this.sitesActive = true;
    this.location = $event;
    this.componentState.update(this.selectedLocationTour,  this.location);
    this.refetchQuery();
  }

//   selectToursSorting($event){
//     this.setComponentState(this.componentstate.date, this.componentstate.offset,
//       this.componentstate.filterValue, this.componentstate.tasksSites, this.componentstate.toursSite, this.componentstate.includeReturns,
//       this.componentstate.showSite, $event.value, this.componentstate.selectedTourStates, this.componentstate.showStreet,
//       this.componentstate.showHouseNumber, this.componentstate.showNote);
//   }

  selectTourStates($event: any){
    // console.log('states event', $event)
    this.states = $event;
    this.refetchQuery();
  }

  createTour() {
    this.loading = true;
    let tour = new TourInput();
    tour.locationId = this.getSite(this.location)?.id;
    // // correct the 'german' date format to get a valid iso string:
    // let dmy = this.datePipe.transform(this.componentstate.date.setHours(0, 0, 0, 0), "dd.MM.yyyy" ).split(".");
    let date = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    tour.dateTimeReference = date;
    this.tourService.createTour(tour, this.tourplanerQuery);
  }

  deleteTour(tour: Tour) {
    this.loading = true;
    this.tourService.deleteTour(tour, this.tourplanerQuery);
  }

  addTaskToTour($event: { tour: any; task: any; position: any}) {
    this.loading = true;
    this.tourService.addTaskToTourAtPosition($event.tour, $event.task, $event.position, () => {
      this.tourplanerQuery.refetch();
      this.taskslist?.refetch();
    });
    this.taskslist?.refetch();
  }

  removeTaskFromTour($event: any) {
    this.loading = true;
    this.taskService.UnassignTask($event.task, () => {
      this.tourplanerQuery.refetch();
      this.taskslist?.refetch();
    });

  }

  addTaskToTourAtPosition($event: any) {
    this.loading = true;
    this.tourService.addTaskToTourAtPosition($event.tour, $event.task, $event.position, () => {
      this.tourplanerQuery.refetch();
      this.taskslist?.refetch();
    });
    this.taskslist?.refetch();
  }

  deleteTask($event: any) {
    // const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //   width: "250px",
    //   data: {
    //     title: "Tätigkeit löschen" ,
    //     body: "Sind Sie sich sicher, dass Sie diese Tätigkeit löschen wollen?"
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.tourService.deleteTask($event, this.tourplanerQuery);
    //   }
    // });
  }

  splitTask($event: any){
    // const dialogRef = this.dialog.open(SplitTaskDialogComponent, {
    //   width: "400px",
    //   data: {
    //     task: $event,
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.taskService.splitTask(result.task, result.taskPositionsToMove, this.tourplanerQuery)
    //   }
    // });
  }

  reset() {
    this.date = this.initialDate;
    this.fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    this.toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();

    this.componentState.update(this.selectedDatePlan,  this.date);
    this.refetchQuery();
  }

  checkReset() {
    if (this.date && this.initialDate) {
      if (this.datePipe.transform(this.date.setHours(0, 0, 0, 0), "dd.MM.yyyy") !== this.datePipe.transform(this.initialDate.setHours(0, 0, 0, 0), "dd.MM.yyyy")) {
        return true;
      }
    }
    return false;
  }

//   trackByFn(index, item) {
//     return item.id; // or item.id
//   }

  @HostListener('window:resize', ['$event'])
  onResize() {
      this.height = window.innerHeight;
  }

  onDragStart($event: any){
    this.tourplanerQuery.stopPolling();
    this.draggingActive = true;
    this.ref.detectChanges();
  }

  onDragEnd($event: any){
    this.tourplanerQuery.startPolling(this.pollingIntervalTourPlannerQuery);
    this.draggingActive = false;
    this.ref.detectChanges();
  }

  saveSettings(event: any) {
    this.loading = true;
    this.tourService.updateTourInput(event, this.tourplanerQuery);
    this.refetchQuery();
    this.loading = false;
  }

  panelClosed(tourId: any) {
    const index = this.openPanels.indexOf(tourId, 0);
    //console.log('Paneld of tour ' + tourId + ' closed.');
    if (index > -1) {
      this.openPanels.splice(index, 1);
    }
    this.saveOpenPanels(this.openPanels);
  }

  panelOpened(tourId: any) {
    //console.log('Paneld of tour ' + tourId + ' opened.');
    this.openPanels.push(tourId);
    this.saveOpenPanels(this.openPanels);
  }

  saveOpenPanels(openPanels: any[]){
    sessionStorage.setItem("openPanels", JSON.stringify(openPanels));
    this.openPanels = openPanels;
  }

  acceptAddress(addressId: string) {
    // this.loading = true;
    this.addressService.acceptAddress(addressId, this.tourplanerQuery);
  }

  updateAddress(address: any){
    // this.loading = true;
    this.addressService.updateAddress(address, this.tourplanerQuery);
    this.taskslist?.refetch();
  }

  getSite(siteId: string){
    if(this.locations && this.locations.length > 0){
      for (let i = 0; i < this.locations.length; i++) {
        if(this.locations[i].id == siteId){
          return this.locations[i];
        }
      }
    }
    return "";
  }

  refetchQuery() {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    let taskAssignmentInput = {
      "assignmentType": "TourTaskAssignmentStrategy",
      "fromDateTime": fromDate,
      "toDateTime": toDate
    };
    this.tourplanerQuery?.refetch({
      sortPropertiesTask: this.sortPropertyTask,
      sortPropertiesTours: 'id',
      SortPropertyTaskDirection: this.sortPropertyTaskDirection,
      sortDirection: "asc",
      locationIds: this.selectedLocations,
      locationId: this.location,
      states: this.states,
      fromDate: fromDate,
      toDate: toDate,
      text: this.filterValue,
      preview: this.selectedPreview,
      taskAssignmentInput
    });
  }

  sortTableTask($event: {active: string, direction: string}) {
    // console.log('event', $event)
    this.sortPropertyTask = $event.active;
    this.sortPropertyTaskDirection = $event.direction;
    this.refetchQuery();
  }
  selectionChanges() {
    // console.log('selection', this.sortValue);
    if (this.sortValue === 'resource'){
      this.headline = 'Kein Fahrzeug geplant';
      this.dailyToursSorted = this.dailyToursByResources;
      this.dailyToursSortedNotPlanned = this.dailyToursNoResourcePlanned;
    } else {
      this.headline = 'Kein Fahrer geplant';
      this.dailyToursSorted = this.dailyToursByDriver;
      this.dailyToursSortedNotPlanned = this.dailyToursNoDriverPlanned;
    }
  }
  sortTours() {
    this.dailyToursByDriver = [];
    this.dailyToursByResources = [];
    this.dailyToursNoDriverPlanned = [];
    this.dailyToursNoResourcePlanned = [];
    let tours = this.tours;
    this.dailyToursNoResourcePlanned = tours?.filter((tour: Tour) => {
      if (tour?.resource) {
        if (this.dailyToursByResources.findIndex(x => x?.resource?.id == tour?.resource?.id) === -1) {
          this.dailyToursByResources.push(
            {
              'resource': tour?.resource,
              'tours': [tour]
            });
        } else {
          this.dailyToursByResources = this.dailyToursByResources.map((byResource: any) => {
            if (byResource.resource?.id === tour?.resource?.id) {
              byResource?.tours?.push(tour);
              return byResource;
            }
            return byResource;
          });
        }
      }
      else {
        return tour;
      }
    });
    tours = this.tours;
    this.dailyToursNoDriverPlanned = tours.filter((tour: Tour) => {
      if (tour?.driver) {
        if (this.dailyToursByDriver.findIndex(x => x?.driver?.id == tour?.driver?.id) === -1) {
          // this.tourDrivers.push(tour.assignee);
          this.dailyToursByDriver.push(
            {
              'driver': tour?.driver,
              'tours': [tour]
            });
        } else {
          this.dailyToursByDriver = this.dailyToursByDriver.map((byDriver: any) => {
            if (byDriver.driver?.id === tour?.driver?.id) {
              byDriver?.tours?.push(tour);
              return byDriver;
            }
            return byDriver;
          });
        }
      }
      else {
        return tour;
      }
    });
    this.dailyToursByResources = this.dailyToursByResources.sort((a, b) => (a?.resource?.name?.toLowerCase() < b?.resource?.name?.toLowerCase()  ? -1 : 1));
    this.dailyToursByDriver = this.dailyToursByDriver.sort((a, b) => (a?.driver?.name?.toLowerCase() < b?.driver?.name?.toLowerCase()  ? -1 : 1));

    if (this.sortValue === 'resource'){
      this.headline = 'Kein Fahrzeug geplant';
      this.dailyToursSorted = this.dailyToursByResources;
      this.dailyToursSortedNotPlanned = this.dailyToursNoResourcePlanned;
    } else {
      this.headline = 'Kein Fahrer geplant';
      this.dailyToursSorted = this.dailyToursByDriver;
      this.dailyToursSortedNotPlanned = this.dailyToursNoDriverPlanned;
    }


  }
  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }
}

