<div class="content">
  <div class="header flex flex-row" [ngClass]="tour?.state">
    <div class="headline flex flex-row  items-center basis-full">
      <ng-container ngProjectAs="[headline]">
        <i [className]="tour?.indicator()"></i>
        <span class="noWrap" #text [matTooltipDisabled]="isEllipsisActive(text)" matTooltipPosition="left"
          matTooltipShowDelay="250" matTooltip="{{getHeader()}}">
          <span>{{getHeader()}}
          </span>
        </span>
        <span class="sub">&nbsp;{{getSubHeader()}}</span>
      </ng-container>
    </div>
  </div>
  <div class="body flex flex-row basis-full gap-3 pt-3">
    <div class="basis-1/3 left">
      <div class="toolbar flex basis-full items-center">
        <div class="basis-1/2">
          <h3>Tourplan</h3>
        </div>
        <div class="buttons flex basis-1/2 place-content-end">
          <i [className]="'icon icon-info'" (click)="$event.stopPropagation();"
            [matTooltip]="tour?.note? tour.note : 'Kein Tour-Hinweis hinterlegt'" [ngClass]="tour?.note? 'note' :'nonote'"></i>
          <i [className]="'icon icon-settings'" (click)="$event.stopPropagation();openSettings(tour)"
            [ngClass]="tour?.state !== 'FINISHED'? '' :'nonote'" *ngIf="rolesService?.hasRole('nexcore_tour_update')"
            [matTooltip]="'Tour-Einstellungen'"></i>
        </div>
      </div>
      <div class="columns flex flex-col basis-full main-component-wrapper items-start items-stretch"
        [ngClass]="{ loading : loading}">
        <div class="grey flex flex-row mt-5 mx-5">
          <div class="firstColumn flex basis-7 items-center place-content-center">
            <div class="letter">A</div>
          </div>
          <span class="flex basis-1/3 items-center row pl-2">Startort</span>
          <div
            class="flex basis-2/3 items-center place-content-end pr-3">
           {{tour?.departureAddress?.getAddressString()? tour?.departureAddress?.getAddressString() : '–'}}
            </div>
        </div>
        <div class="row grey flex flex-row mt-2 mb-2 mx-5">
          <span class="flex basis-1/2 items-center pl-2">Startzeit</span>
          <span class="flex basis-1/2 items-center place-content-end pr-3">
            <div>{{tour?.effectiveDepartureDateTime ? (tour?.effectiveDepartureDateTime | date:'dd.MM.YYYY HH:mm') :
              '–'}}</div>
          </span>
        </div>
        <ng-template ngFor let-isFirst="first" let-isLast="last" let-i="index" let-c="count" let-task
          [ngForOf]="tour?.tasks">
          <div *ngIf="isFirst && !objectChoosen && _route" class="distanceRow row mx-5 flex items-center">
            <div class="distanceLine pl-2" *ngIf="!objectChoosen && _route">{{_route?.sections[0]?.summary?.length / 1000 |
              number:'1.0-1'}} km / {{_route?.sections[0]?.summary?.baseDuration / 60 | number:'1.0-0'}} min</div>
          </div>
          <div class="taskRow mx-5 flex task border-b">
            <div class="firstColumn flex basis-7 items-center place-content-center">
              <div class="letter">{{toLetters(i + 2 )}}</div>
            </div>
            <div task-row class="flex flex-row basis-full items-center" [class.done]="task.state === 'done'" [class.last]="isLast"
              [task]=task>
              <div class="row grey flex flex-row mx-5"></div>
            </div>
          </div>

          <div class="distanceRow row mx-5 flex items-center" *ngIf="_route &&_route?.sections[i + 1]?.summary?.length !== 0  && !objectChoosen">
            <div class="distanceLine pl-2" *ngIf="_route &&_route?.sections[i + 1]?.summary?.length !== 0  && !objectChoosen">{{_route?.sections[i + 1]?.summary?.length / 1000 | number:'1.0-1'}} km /  {{_route?.sections[i + 1]?.summary?.baseDuration / 60  | number:'1.0-0'}} min</div>
          </div>
          <div class="grey flex flex-row mt-2 mx-5" *ngIf="isLast">
            <div class="firstColumn flex basis-7 items-center place-content-center">
              <div class="letter">{{toLetters(tour?.tasks?.length + 2 )}}</div>
            </div>
            <span class="flex basis-1/3 items-center row pl-2">Endort</span>
            <span class="flex basis-2/3 items-center place-content-end pr-3">
              {{tour?.arrivalAddress?.getAddressString()?
                tour?.arrivalAddress?.getAddressString(): '–'}}
            </span>
          </div>

          <div class="grey flex flex-row row mt-2 mx-5 items-center pr-3 pl-2" *ngIf="isLast">
            <span class="flex basis-1/2 items-center">Summe</span>
            <div class="flex basis-1/2 items-center place-content-end" *ngIf="(_sumDistance || _sumDuration)" >{{_sumDistance? (_sumDistance | number:'1.0-1') : '–'}} km /  {{ _sumDuration?  (_sumDuration | number:'1.0-0') : '–'}} Min</div>
            <div class="flex basis-1/2 items-center place-content-end" *ngIf="!(_sumDistance || _sumDuration)" >–</div>
          </div>
        </ng-template>
        <div *ngIf="!tour?.tasks || tour?.tasks?.length === 0">
          <div class="no-data">
            <p>keine Aufträge geplant</p>
          </div>
          <div class="grey flex flex-row mt-2 mx-5">
            <div class="firstColumn flex basis-7 items-center place-content-center">
              <div class="letter">{{toLetters(2 )}}</div>
            </div>
            <span class="flex basis-1/3 items-center row pl-2">Endort</span>
            <span class="flex basis-2/3 items-center place-content-end pr-3">
              {{tour?.arrivalAddress?.getAddressString()?
                tour?.arrivalAddress?.getAddressString(): '–'}}
            </span>
          </div>
          <div class="grey flex flex-row row mt-2 mx-5 items-center">
            <span class="flex basis-1/2 items-center pl-3">Summe</span>
            <div class="flex basis-1/2 items-center place-content-end pr-3" >–</div>
          </div>
        </div>
      </div>
    </div>
    <div class="basis-2/3">
      <div class="toolbar flex flex-row basis-full items-center">
        <h3>Route</h3>
      </div>
      <div class="columns flex flex-row basis-full main-component-wrapper items-start items-stretch px-5 pt-5"
        [ngClass]="{ loading : loading}">
        <app-tours-detail-planning-map class="flex basis-full map" [tour]="tour"
          (routeCalculated)=routeCalculated($event)>
        </app-tours-detail-planning-map>
      </div>
    </div>
  </div>
</div>
