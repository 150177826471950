 <div class="flex flex-row items-center" [ngClass]="className">
  <app-nx-button (click)="dayBack()" class="dayBack"  toolTip="einen Tag zurück" icon="icon  icon-arrow-left">
  </app-nx-button>
  <app-nx-button  (click)="picker.open()"  class="date" [text]="this.selectedDate.toLocaleDateString()" toolTip="Tag" [width]="'w-24'"></app-nx-button>
  <input matInput #ref [matDatepicker]="picker"
   [(ngModel)]="selectedDate" class="hide" (dateChange)="dateChange()">
  <mat-datepicker #picker></mat-datepicker>
  <app-nx-button  (click)="selectedDate.toDateString() !== moment().toDate().toDateString()?loadToday():''"  text="Heute" toolTip="aktueller Tag" [disabled]="selectedDate.toDateString() === moment().toDate().toDateString()"></app-nx-button>
  <app-nx-button (click)="dayForward()" class="dayForward" toolTip="einen Tag vor" icon="icon  icon-arrow-right">

  </app-nx-button>
</div>
