import { gql } from 'apollo-angular';
import { Page } from '@nexato/nx-core-module';
import { Comment } from '../../../order-module/shared/entities/comment/comment';

export const COMMENTS_QUERY = gql`
query OrderCommentsQuery(
  $number: Int, $size: Int,
  $sortProperty: String,
  $sortDirection: String,
  $ownerId: UUID,
  $ownerType: String
)
{
  comments: getCommentsPaged(
    filter:{
      ownerId: $ownerId,
      ownerType: $ownerType,
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
    }){
    totalPages
    totalElements
    number
    size
    content{
      id
      text
      lastModifiedBy {
        id
        firstName
        lastName
      }
      lastModifiedDateTime
    }
  }
}
`;

export interface CommentsQueryResponse {
  comments: Page<Comment>;
  loading: boolean;
}


