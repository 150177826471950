import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-offset-selector',
  templateUrl: './offset-selector.component.html',
  styleUrls: ['./offset-selector.component.scss'],
})
export class OffsetSelectorComponent implements OnDestroy  {

  offsetFormControl: FormControl = new FormControl();
  offsetFormControlSubscription: Subscription;
  values: number[] = [];
  maxDays = 30;

  @Input() placeholder = 'Zeitraum';
  @Input()
  set initialOffset(offset: number) {
    // we must check != undefined here, because 0 will be threatened as false
    if (offset != undefined) {
      this.offsetFormControl?.setValue(offset, {emitEvent: false});
    }
  }
  @Output() offset = new EventEmitter();

  constructor() {
    // set up the form control
    this.offsetFormControl = new FormControl();
    // set up the days counter
    for (let i = 0; i <= this.maxDays; i++) {
      this.values.push(i);
    }
    // subscribe to the form control
    this.offsetFormControlSubscription = this.offsetFormControl.valueChanges.pipe(
      // we only want an event in the case that the value
      // changes
      distinctUntilChanged()
    ).subscribe(value => {
      // we want any value that is not undefined
      if (value != undefined) {
        // emit the value
        this.offset.emit(value);  
      }
    });
  }

  ngOnDestroy(): void {
    // unsubscribe from the form control
    this.offsetFormControlSubscription?.unsubscribe();
  }
}
