import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-task-list-unassigned',
  templateUrl: './order-task-list-unassigned.component.html',
  styleUrls: ['./order-task-list-unassigned.component.scss']
})
export class OrderTaskListUnassignedComponent {

  public displayedColumns: String[] = [
    'type', 'dueDate', 'description', 'assignmentSuggestion'
  ];
  public states = ['NEW' as TaskState, 'UNASSIGNED' as TaskState];

 @Input() order: string;
}
