import { Injectable } from '@angular/core';

import { Tour } from 'src/app/tour-planning/shared/entities/tour';
import { environment } from 'src/environments/environment';
import { AutoCompleteResult, AutoCompleteResultSource } from '../../../shared-module/shared/entities/address/AutoCompleteResult';
import { Address, AddressType } from '../../../shared-module/shared/entities/address/address';


declare var H: any;

/**
 * The tour service.
 */
@Injectable()
export class HereService{

  /**
   * URL for here endpoints: https://developer.here.com/documentation/geocoding-search-api/api-reference-swagger.html
   */
  private autocompletionUrl = 'https://autocomplete.search.hereapi.com/v1/autocomplete';
  private lookupUrl = 'https://lookup.search.hereapi.com/v1/lookup';
  private reverseGeoCodeUrl = 'https://revgeocode.search.hereapi.com/v1/revgeocode';
  private apiKey = environment.generalHereApiKey;
  private platform: any;

  constructor() {
    this.platform = new H.service.Platform({
      'apikey': this.apiKey
    });
  }

  public searchAddress(query: string, callback: (results: AutoCompleteResult[]) => void) {
    const url = this.autocompletionUrl +
    '?q=' +  encodeURIComponent(query) +   // The search text which is the basis of the query
    '&maxresults=5' +  // The upper limit the for number of suggestions to be included
    '&apikey=' + this.apiKey +
    '&language=de'
    this.makeRequest('GET', url, (result: { items: any[]; }) => {
      console.log(result);
      let results: AutoCompleteResult[] = [];
      result.items.forEach((item: any) => {
        results.push(new AutoCompleteResult(AutoCompleteResultSource.HERE, item.title, item));
      });
      callback(results);
    });
  }

  public lookup(id: string, callback: any) {
    const url = this.lookupUrl +
      '?id=' +  encodeURIComponent(id) +   // The search text which is the basis of the query
      '&apikey=' + this.apiKey +
      '&language=de'
    this.makeRequest('GET', url, callback);
  }

  public reverseGeocode(lat: number, lng: number, callback: any) {
    const url = this.reverseGeoCodeUrl +
      '?at=' + lat + ',' + lng +
      '&apikey=' + this.apiKey +
      '&language=de';
    this.makeRequest('GET', url, callback);
  }

  /**
   * generic method to call the here api
   *
   */
  public makeRequest (method: string, url: string | URL, done: (arg0: any) => void) {
    var xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function () {
      done(JSON.parse(xhr.response));
    };
    xhr.onerror = function () {
      done(xhr.response);
    };
    xhr.send();
  }


  createAddressFromLookupResult(result: any, snapToNearestAddress = true): Address {
    const address = new Address();
    if(result && result.address) {
      // country
      if(result.address.countryName){
        address.country = result.address.countryName;
      }
      // city
      if(result.address.city){
        address.city = result.address.city;
      }
      // postalCode
      if(result.address.postalCode){
        address.postalCode = result.address.postalCode;
      }
      if(!result.address.postalCode && !result.address.city){
        address.city = result?.title
      }

      // street
      if(result.address.street && snapToNearestAddress){
        address.street = result.address.street;
      }
      // houseNumber
      if(result.address.houseNumber && snapToNearestAddress){
        address.houseNumber = result.address.houseNumber;
      }
    }
    if(result && result.position) {
      // latitude
      if(result.position){
        address.lat = result.position.lat;
      }
      // longitude
      if(result.position){
        address.lng = result.position.lng;
      }
    }

    // the result is always "complete"
    address.type = AddressType.COMPLETE;

    return address;
  }

  /**
   * Initialize a map in a container
   * @param mapContainer the html container where the map should be placed
   * @returns the map
   */
  public initializeMap(mapContainer: HTMLElement) {
    // Obtain the default map types from the platform object:
    const defaultLayers = this.platform?.createDefaultLayers({
      lg: 'de'
    });
    // Instantiate (and display) a map object:
    let map = new H.Map( mapContainer, defaultLayers.vector.normal.map, {
      padding: {top: 50, left: 50, bottom: 50, right: 50}
    });
    var ui = H.ui.UI.createDefault(map, defaultLayers, 'de-DE');
    const resizeObserver = new ResizeObserver(entries => {
      map.getViewPort().resize();
    });
    resizeObserver.observe(mapContainer);
    return map;
  }
/**
   * adds behavior to a map
   * @param map the map where the behavior should be added
   * @returs the behavior object
   */
public addBehaviorToMap(map: any){
  var mapEvents = new H.mapevents.MapEvents(map);
  return new H.mapevents.Behavior(mapEvents);
}

/**
 * Adds a marker to a map by address
 *
 * @param map the map where to add the marker
 * @param marker the marker, if its already existing - can be null
 * @param locationId locationId
 */
public addMarkerByAddress(map: any, marker: any, address: Address) {
  return this.addMarkerToMap(map, marker, address.lat, address.lng);
}

 /**
   * Adds a marker to a map by lat and long
   *
   * @param map the map where to add the marker
   * @param marker the marker, if its already existing - can be null
   * @param lat Latitute
   * @param long Longitude
   */
 public addMarkerToMap(map: any, marker: null, lat: string, long: string): any{
  if(marker != null){
    map.removeObject(marker);
  }
  marker = new H.map.Marker(
    { lat: lat, lng: long },
    { volatility: true });
  map.addObject(marker);
  map.setCenter({ lat: lat, lng: long});
  map.setZoom(14);
  return marker;
}

public centerMap(map: any,lat: string, long: string) {
  map.setCenter({ lat: lat, lng: long});
  map.setZoom(14);
}

/**
   * calculate a route and print the route on the map
   *
   * @param tour
   * @param map
   * @param callback
   */
public calculateRoute(tour: Tour, map: { removeObjects: (arg0: any) => void; getObjects: () => any; addObject: (arg0: any) => void; getViewModel: () => { (): any; new(): any; setLookAtData: { (arg0: { bounds: any; }): void; new(): any; }; }; }, callback: { (route: any): void; (arg0: any): void; }): boolean{
  if(!this.routeCalculationPossible(tour)){
    return false;
  }
  let router = this.platform.getRoutingService(null, 8);
  router.calculateRoute({
    'origin': tour?.departureAddress?.lat + ',' + tour?.departureAddress?.lng,
    'destination': tour?.arrivalAddress?.lat + ',' + tour?.arrivalAddress?.lng,
    // defines multiple waypoints
    'via': new H.service.Url.MultiValueQueryParameter(this.createWaypoints(tour)),
    // returns route shape as a polyline in response
    'return': ['polyline', 'summary'],
    'transportMode': 'truck'
  }, (result: { routes: any[]; }) => {
    const sections = result.routes[0].sections;
    // remove all existing objects from the map
    // necessary in case we redraw the map after the tasks were changed
    map.removeObjects(map.getObjects ());
    const lineStrings: any[] = [];
    sections.forEach((section: { polyline: any; }) => {
      lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
    });
    const multiLineString = new H.geo.MultiLineString(lineStrings, {});
    const bounds = multiLineString.getBoundingBox();
    // Create an outline for the route polyline:
    var routeOutline = new H.map.Polyline(multiLineString, {
      style: {
        lineWidth: 4,
        strokeColor: '#001E18',
        lineTailCap: 'arrow-tail',
        lineHeadCap: 'arrow-head'
      }
    });
    // Create a patterned polyline:
    var routeArrows = new H.map.Polyline(multiLineString, {
      style: {
        lineWidth: 4,
        fillColor: 'white',
        strokeColor: 'rgba(255, 255, 255, 1)',
        lineDash: [0, 8],
        lineTailCap: 'arrow-tail',
        lineHeadCap: 'arrow-head' }
      }
    );
    var routeLine = new H.map.Group();
    routeLine.addObjects([routeOutline, routeArrows]);

    // render route on the map
    map.addObject(routeLine);
    // add waypoints
    let counter = 1;
    sections.map( (section: { departure: { place: { location: { lat: any; lng: any; }; }; }; arrival: { place: { location: { lat: any; lng: any; }; }; }; }) => {
      const marker = new H.map.Marker(section.departure.place.location, {icon: this.createMarkerIcon(this.toLetters(counter))});
      map.addObject(marker);
      // add a destinaton marker
      if(counter === sections.length){
        const marker = new H.map.Marker(section.arrival.place.location, {icon: this.createMarkerIcon(this.toLetters(counter + 1))});
        map.addObject(marker);
      }
      counter ++;
    })

    // zoom to polyline
    map.getViewModel().setLookAtData({bounds});
    if (callback) {
      callback( result.routes[0] );
    }
  }, console.error);
  return true;
}
createWaypoints(tour: Tour){
  if(tour && tour?.tasks && tour?.tasks.length > 0){
    const test = tour?.tasks.map( task => {
      return task.address.lat + ',' + task.address.lng
    })
    return test;
  }
}

createMarkerIcon(indicator: any): any{
  // let svgMarkup = '<svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
  //   '<rect stroke="black" fill="${FILL}" x="1" y="1" width="22" height="22" />' +
  //   '<text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" ' +
  //   'text-anchor="middle" fill="${STROKE}" >${INDICATOR}</text></svg>';
  let svgMarkup = '<svg width="50" height="50" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 35" style="enable-background:new 0 0 22 35;" xml:space="preserve"> <style type="text/css"> .st0{fill:#DAFF00} .st1{fill:#1A1A1A;} .st2{fill:#FFFFFF;} .st3{font-family:"ArialMT";} .st4{font-size:9px;} </style> <g> <g> <path class="st0" d="M19.62,14.82L10.9,34.26L2.18,14.82C0.56,11.2,1.34,6.96,4.14,4.15l0,0c3.73-3.73,9.79-3.73,13.52,0l0,0 C20.47,6.96,21.25,11.2,19.62,14.82z"/> </g> <g> <path class="st1" d="M10.9,34.61c-0.14,0-0.26-0.08-0.32-0.21L1.86,14.97C0.19,11.24,1,6.79,3.89,3.9C5.77,2.03,8.25,1,10.9,1 c2.65,0,5.14,1.03,7.01,2.9c2.89,2.89,3.71,7.34,2.03,11.06l-8.72,19.44C11.16,34.53,11.04,34.61,10.9,34.61z M10.9,1.7 c-2.46,0-4.77,0.96-6.51,2.7C1.7,7.08,0.95,11.21,2.5,14.68l8.4,18.73l8.4-18.73c1.55-3.46,0.79-7.6-1.89-10.28 C15.67,2.66,13.36,1.7,10.9,1.7z M19.62,14.82L19.62,14.82L19.62,14.82z"/> </g> <g> <circle class="st2" cx="10.9" cy="10.91" r="6.14"/> </g> </g> <text transform="matrix(1 0 0 1 7.9791 14.0097)" class="st1 st3 st4">' + indicator +'</text> </svg> ';
  let icon = new H.map.Icon(svgMarkup);
    return icon;
}

toLetters(num: number): string {
  "use strict";
  var mod = num % 26,
      pow = num / 26 | 0,
      out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
  return pow ? this.toLetters(pow) + out : out;
}

/**
 * check, if the route-calculation is possible
 * @param tour
 */
routeCalculationPossible(tour: Tour): boolean{
  // do we have a tour at all
  if(!tour){
    return false;
  }
  // do we have tasks on the tour
  if(!tour?.tasks || tour?.tasks?.length == 0){
    return false;
  }
  // do we have a start address
  if(!tour?.departureAddress || !tour?.departureAddress?.lat || !tour?.departureAddress?.lng){
    return false;
  }
  // do we have an end address
  if(!tour?.arrivalAddress || !tour?.arrivalAddress?.lat || !tour?.arrivalAddress?.lng) {
    return false;
  }
  // do we have valid addresses on al tasks
  for (let i = 0; i < tour?.tasks.length; i++) {
    const task = tour?.tasks[i]
    if(!task.address || !task?.address?.lat || !task?.address?.lng || task?.address?.geocodingResultGrade?.toString() == 'red'){
      return false;
    }
  }
  return true;
  }

  /**
   * enables a marker to be dragged by the user
   * the callback accepts an address as argument
   * the callback will be called, when the marker was
   * dragged and the target location was reverse geocoed
   * At the moment its configured, that it looks for the next
   * location within a radius of 20 metres. If it does not find
   * a location within 20 metres, it returns the lat/lang instead
   *
   * @param map the map where to add the marker
   * @param marker the marker that should be draggable
   * @param behavior behavior of the map
   * @param callback lat/lng
   */
  public makeMarkerDraggable(marker: any, map: any, behavior: any, callback: any){
    if(marker != undefined && map != undefined){
      marker.draggable = true;
    }

    // disable the default draggability of the underlying map
    // and calculate the offset between mouse and target's position
    // when starting to drag a marker object:
    map.addEventListener('dragstart', (ev: any) =>  {
      var target = ev.target,
          pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        var targetPosition = map.geoToScreen(target.getGeometry());
        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
        behavior.disable();
      }
    }, false);

    // re-enable the default draggability of the underlying map
    // when dragging has completed
    map.addEventListener('drag', (ev: any) => {
      var target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
     }
    }, false);

    // call the callback method and return the selected lat/lang, but round to 5 digits
    map.addEventListener('dragend', (ev: any) => {
      var target = ev.target;
      if (target instanceof H.map.Marker) {
        behavior.enable();
        const lat = Math.round(target.getGeometry().lat * 100000)/100000;
        const lng = Math.round(target.getGeometry().lng * 100000)/100000;
        callback(lat, lng);
      }
    }, false);
  }

  public moveMarker(map: any, marker: any, lat: any, lang: any){
    if(marker == null) {
      return this.addMarkerToMap(map, null, lat, lang);
    } else {
      return marker.setGeometry({lat:lat, lng:lang})
    }
  }

}
