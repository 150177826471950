<div class="flex flex-row space-x-4" [formGroup]="dateTimePeriodForm">
    <mat-form-field appearance="fill" class="basis-1/5 modifier">
      <mat-label>Modifier</mat-label>
      <mat-select formControlName="modifier">
        <mat-option [value]="'BETWEEN'">
          zwischen
        </mat-option>
        <mat-option [value]="'BEFORE'">
          vor
        </mat-option>
        <mat-option [value]="'AFTER'">
          nach
        </mat-option>
        <mat-option [value]="'EXACT'">
          exakt
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="basis-2/5 fromDateTime" *ngIf="showTwoColums()">
      <mat-label>Von</mat-label>
      <input matInput [ngxMatDatetimePicker]="fromDateTimePicker" [min]="getMinFromDateTime()" [max]="getMaxFromDateTime()"
        placeholder="Choose a date" formControlName="fromDateTime">
      <mat-datepicker-toggle matSuffix [for]="$any(fromDateTimePicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #fromDateTimePicker [stepMinute]="10">
        <ngx-mat-datepicker-actions>
          <button mat-button ngxMatDatepickerCancel>Abbrechen</button>
          <button mat-button color="primary" ngxMatDatepickerApply>Speichern</button>
        </ngx-mat-datepicker-actions>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="basis-2/5 toDateTime" *ngIf="showTwoColums()">
      <mat-label>Bis</mat-label>
      <input matInput [ngxMatDatetimePicker]="toDateTimePicker" formControlName="toDateTime"
        placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="$any(toDateTimePicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #toDateTimePicker [stepMinute]="10">
        <ngx-mat-datepicker-actions>
          <button mat-button ngxMatDatepickerCancel>Abbrechen</button>
          <button mat-button color="primary" ngxMatDatepickerApply>Speichern</button>
        </ngx-mat-datepicker-actions>
      </ngx-mat-datetime-picker>
    </mat-form-field>
  
    <mat-form-field appearance="fill" class="basis-4/5 dateTime" *ngIf="!showTwoColums()">
      <mat-label>Datum</mat-label>
      <input matInput [ngxMatDatetimePicker]="dateTimePicker" formControlName="dateTime"
        placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #dateTimePicker [stepMinute]="10">
        <ngx-mat-datepicker-actions>
          <button mat-button ngxMatDatepickerCancel>Abbrechen</button>
          <button mat-button color="primary" ngxMatDatepickerApply>Speichern</button>
        </ngx-mat-datepicker-actions>
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </div>
  <div>
    <!-- {{dateTimePeriodForm.valid}} -->
  </div>
