import { gql } from 'apollo-angular';
import { Form } from '../../shared/entities/form/form';

export const FORMS_QUERY = gql`
  query formsQuery
   {
    forms: getForms {
     key
     name
     variants {
      id
      name
      isActive
      isDeletable
      isSystem
     }
    }
  }
`;

export interface FormsQueryResponse {
  forms: Form[];
  loading: boolean;
}
