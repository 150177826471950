import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, take, tap } from 'rxjs/operators';

import * as fromGraphQl from './graphql';
import { ActivatedRoute, Router } from '@angular/router';
import { Resource } from '../../shared/entities/resource/resource';
import { Apollo } from 'apollo-angular';
import { Container, LoadingService, RolesService } from '@nexato/nx-core-module';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResourceDialogComponent } from '../../components/resource-dialog-component/resource-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-resource-view',
  templateUrl: './resource-view.component.html',
  styleUrls: ['./resource-view.component.scss']
})
export class ResourceViewComponent extends Container<fromGraphQl.ResourceQueryResponse>
implements OnInit, OnDestroy
{
public routeSubscription: Subscription;
public id: string;
public resource: Resource;
public customAttributeSections: any;

constructor(
  apollo: Apollo,
  loadingService: LoadingService,
  public router: Router,
  public route: ActivatedRoute,
  public dialog: MatDialog,
  public rolesService: RolesService
) {
  super(apollo, loadingService, fromGraphQl.RESOURCE_QUERY, environment);
}

ngOnInit(): void {
  const key = 'id';
  this.routeSubscription = this.route.params
    .pipe(
      filter((params) => params[key] !== undefined),
      take(1)
    )
    .subscribe((params) => {
      if (params[key] && params[key] !== 'create') {
        this.id = params[key];
      }
      // load model (if we have an id) and the form
      this.subscribeToQuery({
        resourceId: this.id,
        entity: 'Resource'
      });
    });
}
handleSubscriptionResult(data: fromGraphQl.ResourceQueryResponse): void {
  this.loadingService.setLoading(false);
  if (data.resource) {
    this.queryRef.stopPolling();
    this.resource = new Resource(data.resource);
    // console.log('order', data.resource);
  }
  this.customAttributeSections = data?.customAttributeSections;
}

  /**
   * edit the resource dialog
   * ResourceDialogComponent
   */
  editResource(): void {
    super.ngOnDestroy();
    const dialogRef = this.dialog.open(ResourceDialogComponent, {
      width: '760px',
      data: {
        id: this.id,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loadingService.setLoading(true);
      this.subscribeToQuery({
        resourceId: this.id,
        entity: 'Resource'
      });
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }
  CheckSectionInAttributes( id: string) {
    if (this.resource?.customAttributes) {
      for (let attribute of this.resource?.customAttributes){
        if (attribute?.customAttributeSection.id === id){
          return true;
        }
      }
    }
    return false;
  }

  CheckDefintionInAttributes(id: string) {
    if (this.resource?.customAttributes) {
      for (let attribute of this.resource?.customAttributes) {
        if (attribute?.customAttributeDefinition?.id === id) {
          return true;
        }
      }
    }
    return false;
  }
  getValue(id: string) {
    if (this.resource?.customAttributes) {
      for (let attribute of this.resource?.customAttributes) {
        if (attribute?.customAttributeDefinition?.id === id) {
          return attribute?.value;
        }
      }
    }
    return '-';
  }

}
