import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { Tour } from 'src/app/tour-planning/shared/entities/tour';

export const TOURS_QUERY = gql`
query getToursQuery(
  $number: Int, $size: Int, $sortProperty: String, $locationIds: UUID,
  $fromDate: String, $toDate: String,
  $sortDirection: String,  $states:[TourState],
)
{
  tours: getToursPaged(
    filter: { states: $states, locationId: $locationIds,
    fromDateTime:  $fromDate, toDateTime: $toDate,
    sortRequest: {sortProperties: [$sortProperty], sortDirection: $sortDirection},
    pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
  }) {
    totalPages
      totalElements
      number
      size
      content{
        id
        name
        scheduledDepartureDateTime
        actualDepartureDateTime
        actualArrivalDateTime
        driver{
          id
          firstName
          lastName
        }
        resource {
          id
          name
        }
      }

  }
}`;

export interface TourQueryResponse {
  tours: Page<Tour>;
  loading: boolean;
}
