<app-layout-content-title>
  <ng-container ngProjectAs="[headline]">
    <i [className]="'icon-documents'"></i>
    <h2>Formulare</h2>
  </ng-container>
</app-layout-content-title>
<div class="body">
  <div *ngFor="let form of forms">
    <div class="heading flex flex-row items-center">
      <div class="headline basis-2/3">
        <i [className]="'icon-documents'"></i>
        <h2>{{form?.name}}</h2>
      </div>
      <div class="tools flex justify-end basis-1/3">
        <app-nx-button (click)="createFormVariant(form?.key)" toolTip="neue Vorlage anlegen" icon="icon icon-plus">
        </app-nx-button>
      </div>
    </div>
    <div class="table_header p-5" *ngIf="form?.variants && form?.variants?.length > 0">
      <div  class="header basis-3/12">Name</div>
      <div  class="header basis-3/12">System</div>
      <div  class="header basis-3/12">Aktiv</div>
      <div  class="header basis-3/12"></div>
    </div>
    <div class="table_row flex flex-row basis-full pl-5 items-center" *ngFor="let variant of form?.variants" (click)="viewFormVariant(form?.key, variant.id)">
      <div  class="value basis-3/12" >
        {{variant?.name? variant?.name: '-'}}
      </div>
      <div  class="value basis-3/12">
        <mat-checkbox [checked]="variant.isSystem" [disabled]="true"></mat-checkbox>
      </div>
      <div  class="value basis-3/12" >
        <mat-checkbox [checked]="variant.isActive" [disabled]="true"></mat-checkbox>
      </div>
      <div  class="delete basis-3/12 flex justify-end">
        <app-nx-button toolTip="löschen" icon="icon icon-trash" (click)="$event.stopPropagation();variant?.isDeletable && confirmDialog(variant)" [disabled]="!variant.isDeletable"></app-nx-button>
      </div>
  </div>
  <div *ngIf="forms?.length === 0" class="no-data">
    <p>keine Forms vorhanden</p>
  </div>
</div>
