<div #container
  class="taskDropZone flex"
  cdkDropList
  [id]="'openTasksList'"
  cdkDropListSortingDisabled
  (cdkDropListDropped)="dragDropped($event)"
  [cdkDropListEnterPredicate]=enterPredicate>

  <div class="details flex flex-col basis-full">
    <div class="table flex flex-row basis-full" [hidden]="(dataSource?.loading| async)">
      <mat-table matSort
      [dataSource]="dataSource"
      >
      <!-- type Column -->
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="flex types-header"> Typ </mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex types">
          <i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i>
        </mat-cell>
      </ng-container>
       <!-- orderId Column -->
      <ng-container matColumnDef="order.number">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="flex">Auftragsnummer</mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex">
          <span class="noWrap" #number [matTooltipDisabled]="isEllipsisActive(number)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{task.order.number}}">
            <span class="jump">
              {{task.order.number}} <i [className]="'icon icon-jump-to'" (click)="goToOrder(task)"></i>
            </span>
          </span>
        </mat-cell>
      </ng-container>
      <!-- dueDate Column -->
      <ng-container matColumnDef="dueDate">
        <mat-header-cell *matHeaderCellDef class="flex">Fälligkeit</mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex">
          <span class="noWrap" #dueDate [matTooltipDisabled]="isEllipsisActive(dueDate)"
            matTooltipPosition="left" matTooltipShowDelay="250"
            matTooltip="{{tasksService?.getDeliveryDateFull(task?.dueDateTimePeriod)}}">
            {{tasksService?.getDeliveryDateFull(task?.dueDateTimePeriod)}}
          </span>
        </mat-cell>
      </ng-container>

      <!-- dueDateTime Column -->
      <ng-container matColumnDef="dueDateTimePeriod">
       <mat-header-cell *matHeaderCellDef mat-sort-header class="flex">Fälligkeit</mat-header-cell>
       <mat-cell *matCellDef="let task" class="flex">
         <span class="noWrap" #dueDateTimePeriod [matTooltipDisabled]="isEllipsisActive(dueDateTimePeriod)"
           matTooltipPosition="left" matTooltipShowDelay="250"
           matTooltip="{{tasksService?.getDeliveryTime(task?.dueDateTimePeriod)}}">
           {{tasksService?.getDeliveryDateFull(task?.dueDateTimePeriod)}} {{tasksService?.getDeliveryTime(task?.dueDateTimePeriod)}}
         </span>
       </mat-cell>
      </ng-container>

      <!-- note Column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef class="flex">Beschreibung</mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex">
          <span class="noWrap" #note [matTooltipDisabled]="isEllipsisActive(note)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{task.description}}">
            {{task.description}}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="customer">
        <mat-header-cell *matHeaderCellDef mat-sort-header  class="flex">Kunde</mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex">
          <span class="noWrap" #customer [matTooltipDisabled]="isEllipsisActive(customer)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{getContactName(task?.order?.customer)}}">
            {{getContactName(task?.order?.customer)? getContactName(task?.order?.customer): '-'}}
          </span>
        </mat-cell>
      </ng-container>

      <!-- street Column -->
      <ng-container matColumnDef="address.street">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Straße</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap" #street [matTooltipDisabled]="isEllipsisActive(street)" matTooltipPosition="left"
            matTooltipShowDelay="250"
            matTooltip="{{task.address?.street}}">{{task.address?.street}}&nbsp;{{task.address?.houseNumber}}</span>
        </mat-cell>
      </ng-container>

      <!--  Column -->
      <ng-container matColumnDef="address.postalCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="flex">PLZ</mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex">
          <span class="noWrap" #postalCode [matTooltipDisabled]="isEllipsisActive(postalCode)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{task.address?.postalCode}}">
            {{task.address?.postalCode}}
          </span>
        </mat-cell>
      </ng-container>

      <!-- city Column -->
      <ng-container matColumnDef="address.city">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Stadt</mat-header-cell>
        <mat-cell *matCellDef="let task">
            <span class="noWrap" #city [matTooltipDisabled]="isEllipsisActive(city)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{task.address?.city}}">
            {{task.address?.city}}
          </span>
        </mat-cell>
      </ng-container>

      <!-- geoCoded Column -->
      <ng-container matColumnDef="address.geocodingResultGrade">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="justify-center">Adresse</mat-header-cell>
          <mat-cell *matCellDef="let task" class="justify-center  items-center">
            <mat-icon
              [class.red]="task.address?.geocodingResultGrade.toString() ==='RED'"
              class="color">
              circle
            </mat-icon>
            <mat-icon
              [class.yellow]="task.address?.geocodingResultGrade.toString() ==='YELLOW'"
              class="color">
              circle
            </mat-icon>
            <mat-icon
              [class.green]="task.address?.geocodingResultGrade?.toString() ==='GREEN'"
              class="color">
              circle
            </mat-icon>
        </mat-cell>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="order.location.name">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          class="display:none md:display:true">Niederlassung</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap" #location [matTooltipDisabled]="isEllipsisActive(location)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{task?.order?.location?.name}}">{{task?.order?.location?.name}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="task.address.note">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          class="display:none md:display:true">Adresszusatz</mat-header-cell>
        <mat-cell *matCellDef="let task">
          <span class="noWrap" #addressNote [matTooltipDisabled]="isEllipsisActive(addressNote)" matTooltipPosition="left"
            matTooltipShowDelay="250" matTooltip="{{task?.address?.note}}">{{task?.address?.note ? task?.address?.note : '-'}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="info">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let task" class="flex items-center place-content-end last">
          <app-nx-button [icon]="'icon icon-file-attachment'" (click)="taskFiles(task)" [matBadge]="task?.order?.fileCount"
            matBadgeSize="small" [matBadgeHidden]="task?.order?.fileCount === 0" />
          <!-- <i [className]="'icon icon-info'" (click)="taskInfo(task)"></i> -->
          <app-nx-button [icon]="'icon icon-annotation'" (click)="taskInfo(task)"
            [matBadge]="tasksService?.getCommentsNotesCount(task?.order)" matBadgeSize="small"
            [matBadgeHidden]="tasksService?.getCommentsNotesCount(task?.order) === 0" />
          <i [className]="'icon icon-options'" [matMenuTriggerFor]="menu"></i>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editAddress(task)" [disabled]="!task?.address">
              <i [className]="'icon icon-edit'"></i>
              <span class="flex items-center menulist">Adresse bearbeiten</span>
            </button>
            <button mat-menu-item [disabled]="task?.address?.geocodingResultGrade !== 'YELLOW'" (click)="_acceptAddress(task)">
              <i [className]="'icon icon-check'"></i>
              <span class="flex items-center menulist">Adresse akzeptieren</span>
            </button>
            <!-- <button mat-menu-item>
                      <i [className]="'icon icon-share'"></i>
                      <span class="flex items-center menulist">Teilen</span>
                    </button>
                    <button mat-menu-item (click)="confirmDialog(task)">
                      <i [className]="'icon icon-trash'"></i>
                      <span class="flex items-center menulist">Löschen</span>
                    </button> -->
          </mat-menu>
        </mat-cell>
      </ng-container>


      <!-- protocolnote Column -->
      <!-- <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef fxFlex="40px"></mat-header-cell>
        <mat-cell *matCellDef="let task" fxFlex="40px">
          <mat-icon class="material-icons-outlined" *ngIf="protocolNotesAvailable(task)" tooltip-class="protocolNoteTooltip" [matTooltip]="buildProtocolNote(task)" placement="left" show-delay="500">info</mat-icon>
        </mat-cell>
      </ng-container> -->

      <!-- actions Column -->
      <!-- <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="40px"></mat-header-cell>
        <mat-cell *matCellDef="let task" fxFlex="40px">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="delete(task)">
                <mat-icon>delete</mat-icon>
                <span>Löschen</span>
              </button>
              <button mat-menu-item (click)="split(task)" [disabled]="checkSplitDisabled(task)">
                <mat-icon>call_split</mat-icon>
                <span>Teilen</span>
              </button>
              <button mat-menu-item (click)="editAddress(task)">
                <mat-icon>mode_edit</mat-icon>
                <span>Adresse bearbeiten</span>
              </button>
              <button mat-menu-item (click)="_acceptAddress(task)" [disabled]="checkAcceptAddress(task)">
                <mat-icon>check</mat-icon>
                <span>Adresse akzeptieren</span>
              </button>
            </mat-menu>
          </button>


        </mat-cell>
      </ng-container> -->

      <mat-header-row
        *matHeaderRowDef="getDisplayedColumns(displayedColumns); sticky: true"
        fxLayout="row"
        fxLayoutGap="10px"
        [hidden]="!dataSource.data || ( dataSource.data && dataSource?.data.length < 1 )">
      </mat-header-row>
      <mat-row
        fxLayout="row"
        fxLayoutGap="10px"
        *matRowDef="let row; columns: getDisplayedColumns(displayedColumns); let i = index"
        cdkDrag
        [cdkDragDisabled]=dragDisabled(row)
        [cdkDragData]=row
        [class.notPlanable] = "dragDisabled(row)"
        (cdkDragStarted)=dragStarted($event)
        (cdkDragReleased)=dragReleased($event)>
        <div task-row *cdkDragPreview
          [task]=row
          [setHeight]=true
          [container]=container>
        </div>
      </mat-row >
      <div *matNoDataRow class="no-data flex items-top justify-center">
        <p>keine Aufgaben vorhanden</p>
      </div>

      </mat-table>
    </div>
    <div *ngIf="dataSource?.loading | async" class="spinner">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <div class="sticky tours-list-footer">
      <div class="pagination justify-end flex basis-full ">
        <mat-paginator [length]="dataSource?.totalElements" [pageSize]="15" [pageIndex]="0"
          [pageSizeOptions]="[15, 20, 25, 30, 35]" aria-label="Select page" class="paginator"></mat-paginator>
      </div>
    </div>

  </div>

</div>
