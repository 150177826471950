import { gql } from 'apollo-angular';
import { Resource } from '../../shared/entities/resource/resource';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

export const RESOURCE_QUERY = gql`
query getResourceQuery(
  $id: UUID
)
{
  resource: getResource(
  id: $id
  )
  {
    id
    externalId
    number
    name
    functions
    customAttributes {
      id
      ownerId
      value
      customAttributeDefinition {
        id
      }
    }
    location {
      id
      name
    }
  }
  locations: getLocations(filter:{}){
    id
    name
  }
}
`;



export interface ResourceQueryResponse {
  resource: Resource;
  form: any;
  loading: boolean;
  locations: LocationEntity[];
}
