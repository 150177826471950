<mat-sidenav-container class="side-nav-level-1">
  <mat-sidenav mode="side" opened>
    <mat-nav-list>
      <mat-list-item routerLinkActive="active" [routerLink]="['./general']">Allgemein</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./owntenant', tenantName]" *ngIf="hasTenantOwnRole">Account</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./locations']" *ngIf="hasLocationsView">Niederlassungen</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./users']" *ngIf="hasUsersRole">Nutzer</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./groups']"  *ngIf="hasGroupsRole">Gruppen</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./licences']" *ngIf="env.stripeEnabled">Rechnungsstellung</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./forms']" *ngIf="hasFormsRole">Formulare</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./templates']" *ngIf="hasTemplatesRole">Vorlagen</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./developer']" *ngIf="hasDeveloperRole">Developer</mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['./tenants']" *ngIf="hasTenantsRole">Tenants</mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div  class="full-width progressbar-bar flex flex-row">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="main-component-wrapper flex flex-row justify-start itmes-center">
      <div  class="full basis-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
