
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { LoadingService } from '@nexato/nx-core-module';
import { RolesService } from '@nexato/nx-core-module';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, Renderer2, Inject } from '@angular/core';
import { LicenseService } from '../../shared/services/license.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public familyName: string;
  public givenName: string;
  public isloading = false;
  public isLoadingSubscription: Subscription;
  public theme = 'standard';
  public logo = '/assets/standard_Logo.svg';
  public tenantName = '';
  public pageTitle: string;
  public pageSubTitle: string;

  isExpanded = true;
  constructor(
    private keycloakAngular: KeycloakService,
    private router: Router,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    public rolesService: RolesService,
    private renderer: Renderer2,
    public licenseService: LicenseService
  ) {
    console.log(keycloakAngular.getKeycloakInstance());
    let key = 'family_name';
    this.familyName = keycloakAngular.getKeycloakInstance()
      ? (keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'given_name';
    this.givenName = keycloakAngular.getKeycloakInstance()
      ? (keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'page_title';
    this.pageTitle = keycloakAngular.getKeycloakInstance()
      ? (keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'page_subtitle';
    this.pageSubTitle = keycloakAngular.getKeycloakInstance()
      ? (keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';
    key = 'tenant_id';
    this.tenantName = this.keycloakAngular.getKeycloakInstance()
      ? (this.keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';

    // this.router.routeReuseStrategy.shouldReuseRoute = function() {
    //   return false;
    // };
  }

  ngOnInit(): void {
    this.renderer.removeClass(document.body, this.theme);
    let key = 'theme';
    this.theme = this.keycloakAngular.getKeycloakInstance()
      ? (this.keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : 'standard';
    if (this.theme === undefined) {
      this.theme = 'standard';
    }
    this.theme = 'standard';
    this.renderer.addClass(document.body, this.theme);
    this.logo = '/assets/' + this.theme + '_Logo.svg'; // /assets/NXD_PZ-Nuremberg_Logo.svg
    this.isLoadingSubscription =
      this.loadingService?.isLoadingSubject?.subscribe((isloading: boolean) => {
        this.isloading = isloading;
        this.cdr?.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.isLoadingSubscription?.unsubscribe();
  }

  goTo(path: any): void {
    // console.log(path);
    this.router.navigate(path);
  }

  logout(): void {
    this.keycloakAngular.logout();
    // this.keycloakAngular.logout("http://localhost:4200/");
  }
}
