<app-layout-content-title>
  <ng-container ngProjectAs="[headline]">
    <i [className]="'icon icon-branches'"></i>
    <h2>Niederlassungen</h2>
  </ng-container>
  <ng-container ngProjectAs="[tools]">
    <app-nx-button (click)="createLocation()" toolTip="Niederlassung anlegen" icon="icon icon-plus"
      *ngIf="rolesService?.hasRole('nexcore_location_create')" />
  </ng-container>
</app-layout-content-title>
<div class="body">
  <mat-drawer-container [hasBackdrop]="false" class="container">
    <!-- filter start -->
    <mat-drawer #drawerRight [mode]="'side'" [position]="'end'" class="filter">
      <!-- <h2 class="header">Einstellungen</h2> -->
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <div class="details flex flex-col basis-full">
        <div [hidden]="dataSource?.loading| async" class="table flex flex-row basis-full">
          <mat-table [dataSource]="dataSource" matSort #sort="matSort">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="header_column table_column flex basis-2/5">Name
              </mat-header-cell>
              <mat-cell *matCellDef="let location" class="table_column flex basis-2/5">
                {{location.name}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="button">
              <mat-header-cell *matHeaderCellDef
                class="header_column table_column flex basis-1/5 justify-end items-center">
              </mat-header-cell>
              <mat-cell *matCellDef="let location" class="table_column flex basis-1/5 justify-end items-center">
                <app-nx-button toolTip="löschen" icon="icon icon-trash"
                  (click)=" $event.stopPropagation();location?.isDeletable && confirmDialog(location)"
                  [disabled]="!location.isDeletable" />
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="table_header"
              [hidden]="!dataSource.totalElements">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="table_row" (click)="editLocation(row)"
              [ngClass]="rolesService?.hasRole('nexcore_location_update')? 'clickable': 'notClickable'"></mat-row>
            <div *matNoDataRow class="no-data">
              <p>keine Niederlassungen vorhanden</p>
            </div>
          </mat-table>
        </div>
        <div *ngIf="dataSource.loading | async" class="spinner">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        <div class="sticky list-footer">
          <div class="pagination justify-end flex basis-full ">
            <mat-paginator [length]="dataSource?.totalElements" [pageSize]="15" [pageIndex]="0"
              [pageSizeOptions]="[15, 20, 25, 30]" aria-label="Select page" class="paginator"></mat-paginator>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
