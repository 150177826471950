<div [ngClass]="(!customerId && !resourceId)? 'content': 'main'">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i [className]="'icon-orders'"></i>
      <h2>Aufträge</h2>
    </ng-container>
    <ng-container ngProjectAs="[tools]">
      <!-- <app-nx-button (click)="createOrder()" toolTip="neues Auftrag anlegen" icon="icon icon-plus">
      </app-nx-button> -->
      <app-nx-button (click)="drawerRight.toggle()" icon="icon icon-options">
      </app-nx-button>
    </ng-container>
  </app-layout-content-title>
  <div class="body">
    <mat-drawer-container [hasBackdrop]="false" class="container">
      <!-- filter start -->
      <mat-drawer #drawerRight [mode]="'side'" [position]="'end'" class="filter">
        <!-- <h2 class="header">Einstellungen</h2> -->
        <div>
          <h3>Filter</h3>
          <app-location-selection [locations]="dataSource?.locations" [multiple]=true (selectLocation)=selectLocation($event)
            [displayLabel]=true [location]="location">
          </app-location-selection>
          <mat-form-field class="white">
            <mat-label>Nummer</mat-label>
            <input matInput [formControl]="orderNumberControl">
            <button *ngIf="number" matSuffix mat-icon-button aria-label="Clear" (click)="orderNumberControl.setValue('')">
              <mat-icon class="black-icon">close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </mat-drawer>
      <mat-drawer-content class="drawer-content">
        <div class="details flex flex-col basis-full">
          <div [hidden]="dataSource?.loading| async" class="table flex flex-row basis-full">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"
                  class="header_column table_column firstHeader">Typ</mat-header-cell>
                <mat-cell *matCellDef="let order" fxFlex="10%" class="table_column first">
                  <i [className]="Order.getOrderIcon(order?.type)" [matTooltip]="Order.getOrderType(order?.type)"></i>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="header_column table_column"
                >Auftragsnummer</mat-header-cell>
                <mat-cell *matCellDef="let order" class="table_column">
                  <span class="noWrap" #num [matTooltipDisabled]="isEllipsisActive(num)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="                {{order.number}}">
                  {{order.number}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="customer">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="header_column table_column">
                  Kunde</mat-header-cell>
                <mat-cell *matCellDef="let order" class="table_column">
                  <span class="noWrap" #customer [matTooltipDisabled]="isEllipsisActive(customer)" matTooltipPosition="left"
                    matTooltipShowDelay="250" matTooltip="{{getContactName(order?.customer)}}">
                    {{getContactName(order?.customer)? getContactName(order?.customer): '-'}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="lineItems">
                <mat-header-cell *matHeaderCellDef class="header_column table_column"
                >Beschreibung</mat-header-cell>
                <mat-cell *matCellDef="let order" class="table_column">
                  <span class="noWrap" #line [matTooltipDisabled]="isEllipsisActive(line)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="                {{getLineItems(order.lineItems)}}">
                    {{getLineItems(order.lineItems)}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="header_column table_column flex justify-start"
                >Status</mat-header-cell>
                <mat-cell *matCellDef="let order" class="table_column flex justify-start">
                  <span class="noWrap" #state [matTooltipDisabled]="isEllipsisActive(state)" matTooltipPosition="left"
                    matTooltipShowDelay="250" matTooltip="{{Order.getOrderState(order.state)}}">
                    {{Order.getOrderState(order.state)}}
                  </span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="location" class="flex place-content-end">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="flex header_column table_column justify-start items-center">Niederlassung</mat-header-cell>
                <mat-cell *matCellDef="let order" class="table_column flex justify-start items-center">
                    <span class="noWrap" #name [matTooltipDisabled]="isEllipsisActive(name)" matTooltipPosition="left"
                    matTooltipShowDelay="250" matTooltip="{{order?.location?.name}}">
                    {{order?.location?.name}}
                  </span>
                </mat-cell>
              </ng-container>


              <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="table_header" [hidden]="!dataSource?.totalElements">
              </mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" class="table_row" (click)="openOrder(row)">
              </mat-row>
              <div *matNoDataRow class="no-data flex items-top justify-center">
                <p>keine Auträge vorhanden</p>
              </div>
            </mat-table>
          </div>
          <div *ngIf="dataSource?.loading | async" class="spinner">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
          <div class="sticky list-footer">
            <div class="pagination justify-end flex basis-full ">
              <mat-paginator [length]="dataSource?.totalElements" [pageSize]="15" [pageIndex]="0"
                [pageSizeOptions]="[15, 20, 25, 30]" aria-label="Select page" class="paginator"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>

