import { gql } from 'apollo-angular';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { Tour } from '../../shared/entities/tour';

export const TOUR_PLANNER_STANDARD_QUERY = gql`
query GetDailyToursQuery(
  $sortDirection: String
  $sortPropertiesTours: String, $locationId: UUID, $states:[TourState], $fromDate: String, $toDate: String
)
{
  locations: getLocations(filter:{}){
    id
    name
    label
  }
  dailyTours: getTours(
    filter: {
    locationId: $locationId, states: $states, fromDateTime: $fromDate, toDateTime: $toDate
    sortRequest: {sortProperties: [$sortPropertiesTours], sortDirection: $sortDirection}
  }) {
    ... tourExcerpt
  }
}
fragment tourExcerpt on Tour{
  id
  name
  scheduledDepartureDateTime
  scheduledArrivalDateTime
  actualDepartureDateTime
  actualArrivalDateTime
  dateTimeReference
  state
  note
  driver{
    id
    firstName
    lastName
  }
  resource {
    id
    name
  }
  tasks{
    ... dailyTaskExcerpt
  }
  effectiveArrivalDateTime
  effectiveDepartureDateTime
}
fragment dailyTaskExcerpt on Task{
  id
  type
  dueDateTimePeriod {
    modifier
    dateTime
    fromDateTime
    toDateTime
  }
  state
  description
  order{
    id
    number
    note
    customer {
      type
      companyName
      companyNameExtension
      firstName
      lastName
    }
    comments {
      text
    }
  }
  address {
    street
    houseNumber
    postalCode
    city
    country
    geocodingResultGrade
    name
    note
  }
}
`;


export interface TourPlannerStandardQueryResponse {
  dailyTours: Tour[];
  resources: Resource[];
  locations: LocationEntity[];
}


