import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AutoCompleteResult, AutoCompleteResultGroup, AutoCompleteResultSource } from '../../shared/entities/address/AutoCompleteResult';
import { Address } from '../../shared/entities/address/address';
import { GoogleService } from '../../../rent-module/shared/services/google.service';
import { HereService } from '../../../rent-module/shared/services/here.service';
import { MetricsService, SettingsService } from '@nexato/nx-core-module';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-address-autocomplete",
  templateUrl: "address-autocomplete.html",
  styleUrls: ["./address-autocomplete.scss"]
})
export class AddressAutocompleteComponent implements OnDestroy {


  // address autocomplete form
  public addressAutocompleteForm: FormGroup;
  private addressAutocompleteFormSubscription: Subscription;
  // resultGroups will be used, if google is active to show
  // both results: Google and Here with headlines
  public addressAutoCompleteResultGroups: AutoCompleteResultGroup[];
  // results will be used, if only Here will be queried
  public addressAutoCompleteResults: AutoCompleteResult[];
  @ViewChild("addressQuery") public addressQueryElement: ElementRef;

  public googleAutocompleteAcitve = false;
  // session token is generated every time the user types in the address input
  // and nulled, when the user selects an address
  // see google documentation: https://developers.google.com/maps/documentation/places/web-service/autocomplete#sessiontoken
  private googleAutocompleteSessionToken: string;

  // address form
  addressForm: FormGroup;
  public address: Address; // this comes in, if an order already exists and has an address
  public addressInput: any; // this comes in, if we´re in an order form and the address is only an input

  @Output() addressSelected = new EventEmitter<Address>();

  constructor(
    public hereService: HereService,
    public googleService: GoogleService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private metricService: MetricsService
  ) {
    this.settingsService.getSetting('addresses_secondary_geocoder_autocomplete_enabled', (setting) => {
      if(setting.currentValue === 'true'){
        this.googleAutocompleteAcitve = true;
      }

    });
    this.initializeAddressAutocompleteForm();
  }

  ngOnDestroy(): void {
    this.addressAutocompleteFormSubscription?.unsubscribe();
  }

  initializeAddressAutocompleteForm() {
    this.addressAutocompleteForm = this.fb.group({
      'query': [null],
    });
    this.addressAutocompleteFormSubscription = this.addressAutocompleteForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        if (value != null && value.query != null && value.query.length > 1) {
          this.hereService.searchAddress(value.query, (result: AutoCompleteResult[]) => {
            // if google is active, we want to have the result as a result groul
            if(this.googleAutocompleteAcitve) {
              let autoCompleteResultGroup = new AutoCompleteResultGroup("Here", result);
              if (this.addressAutoCompleteResultGroups == null) {
                this.addressAutoCompleteResultGroups = [];
              }
              let existingResultGroup = this.addressAutoCompleteResultGroups
                .find(resultGroup => resultGroup.label === autoCompleteResultGroup.label);
              if (!existingResultGroup) {
                this.addressAutoCompleteResultGroups.push(autoCompleteResultGroup);
              } else {
                this.addressAutoCompleteResultGroups = this.addressAutoCompleteResultGroups.map(resultGroup => {
                  if (resultGroup.label === autoCompleteResultGroup.label) {
                    return autoCompleteResultGroup;
                  } else {
                    return resultGroup;
                  }
                });
              }
            } else {
              this.addressAutoCompleteResults = result;
            }
          });
          // initiate google search only, if google is active
          if(this.googleAutocompleteAcitve) {
            if(!this.googleAutocompleteSessionToken) {
              // create a new session token, if it´s not already there
              // each time we create a new session token, we want to
              // add a metric
              this.googleAutocompleteSessionToken = this.googleService.getSessionToken();
              this.metricService.publishTimeSeriesMetricEvent('addr.geo.google.acmplt.sessn');
              console.log(this.googleAutocompleteSessionToken);
            }
            this.googleService.searchAddress(value.query, this.googleAutocompleteSessionToken, (result: AutoCompleteResult[]) => {
              let autoCompleteResultGroup = new AutoCompleteResultGroup("Google", result);
              if (this.addressAutoCompleteResultGroups == null) {
                this.addressAutoCompleteResultGroups = [];
              }
              let existingResultGroup = this.addressAutoCompleteResultGroups
                .find(resultGroup => resultGroup.label === autoCompleteResultGroup.label);
              if (!existingResultGroup) {
                this.addressAutoCompleteResultGroups.push(autoCompleteResultGroup);
              } else {
                this.addressAutoCompleteResultGroups = this.addressAutoCompleteResultGroups.map(resultGroup => {
                  if (resultGroup.label === autoCompleteResultGroup.label) {
                    return autoCompleteResultGroup;
                  } else {
                    return resultGroup;
                  }
                });
              }
            })
          }
        }
      });
  }

  displayAddressLabel(address: any): string {
    return address?.title;
  }

  async selectAddress(event: MatAutocompleteSelectedEvent) {
    this.addressAutocompleteForm.get('query').reset();
    this.addressQueryElement.nativeElement.blur();
    this.addressAutoCompleteResultGroups = undefined;
    this.addressAutoCompleteResults = undefined;
    this.googleAutocompleteSessionToken = undefined;

    if (event?.option?.value
      && event?.option?.value?.autoCompleteResultSource == AutoCompleteResultSource.HERE) {
      this.hereService.lookup(event.option.value.payload.id, (result: { address: { countryName: string; city: string; postalCode: string; street: string; houseNumber: any; }; position: { lat: any; lng: any; }; }) => {
        let address = this.hereService.createAddressFromLookupResult(result);
        this.addressSelected.emit(address);
      })
    }
    if (event?.option?.value
      && event?.option?.value?.autoCompleteResultSource == AutoCompleteResultSource.GOOGLE) {
      this.googleService.getPlaceDetails(event?.option?.value?.payload?.place_id
        , (address: any) => {
          this.addressSelected.emit(address);
        })
    }
  }


}
