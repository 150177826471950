import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LoadingService } from "@nexato/nx-core-module";
import { ContactPerson, ContactPersonInput } from "../../shared/entities/contactPerson/contactPerson";
import { ContactPersonService } from "../../shared/services/contactPerson/contactPerson.service";

@Component({
  selector: 'app-contact-person-dialog',
  templateUrl: './contact-person-dialog.component.html',
  styleUrls: ['./contact-person-dialog.component.scss']
})
export class ContactPersonDialogComponent implements OnInit {

  public id: string;
  public contactpersonFormGroup: UntypedFormGroup;
  public contactperson: ContactPerson;
  public isNew = true;
  public isName = false;
  public contactId: string;
  constructor(
    private fb: UntypedFormBuilder,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ContactPersonDialogComponent>,
    private contactPersonService: ContactPersonService,

  ) { }

  ngOnInit(): void {
    this.InitializeForm();
  }

  InitializeForm(): void {
    this.contactpersonFormGroup = this.fb.group({
      // firstName: [null, [Validators.required]],
      // lastName: [null, [Validators.required]],
      mobileNumber: [null],
      phoneNumber: [null],
      email: [null],
    });
    if (this.data) {
      this.contactperson = this.data.contactperson;
      this.contactId = this.data?.contactId;
      if (this.data.contactperson) {
        this.isNew = false;
        if (this.contactperson?.name) {
          this.isName = true;
          this.contactpersonFormGroup.addControl('name', new FormControl(null, [Validators.required]));
        } else {
          this.contactpersonFormGroup.addControl('firstName', new FormControl(null, [Validators.required]));
          this.contactpersonFormGroup.addControl('lastName', new FormControl(null, [Validators.required]));
        }
      }
      else {
        this.contactpersonFormGroup.addControl('firstName', new FormControl(null, [Validators.required]));
        this.contactpersonFormGroup.addControl('lastName', new FormControl(null, [Validators.required]));
      }

      this.contactpersonFormGroup.patchValue(this.contactperson);
     }
  }

  saveContactPerson() {
    this.loadingService.setLoading(true);
    if (this.contactperson) {
      this.contactPersonService.updateContactPerson(
        this.prepareContactPersonModel(),
        this.returnCallback()
      );
    } else {
      this.contactPersonService.createContactPerson(
        this.prepareContactPersonModel(),
        this.returnCallback()
      );
    }
  }

  returnCallback(): void {
    setTimeout(() => {
      this.dialogRef.close({
        abort: false,
        saved: true,
      });
      this.loadingService.setLoading(false);
    }, 500);
  }

  prepareContactPersonModel(): ContactPersonInput {
    const formModel = this.contactpersonFormGroup.value;
    const contactpersonModel = new ContactPersonInput();
    if (this.contactperson) {
      contactpersonModel.id = this.contactperson.id;
    }
    contactpersonModel.firstName =formModel?.firstName;
    contactpersonModel.lastName = formModel?.lastName;
    contactpersonModel.name = formModel?.name;
    contactpersonModel.mobileNumber = formModel?.mobileNumber;
    contactpersonModel.phoneNumber = formModel?.phoneNumber;
    contactpersonModel.email = formModel?.email;
    contactpersonModel.contact = this.contactId;

    return contactpersonModel;
  }

}
