<span class="dialog-close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title *ngIf="!this.id">Resource anlegen</h2>
<h2 mat-dialog-title *ngIf="this.id">Resource bearbeiten</h2>
<mat-dialog-content class="flex flex-col white">

  <div [formGroup]="resourceFormGroup" class="flex flex-col pl-5 pr-5">
    <mat-form-field class="flex flex-row basis-full">
      <mat-label>Nummer</mat-label>
      <input matInput placeholder="Nummer" formControlName="number">
    </mat-form-field>
    <mat-form-field class="flex flex-row basis-full">
      <mat-label>Name</mat-label>
      <input matInput placeholder="Name" formControlName="name">
    </mat-form-field>

    <mat-form-field class="flex flex-row basis-full">
      <mat-label>Funktionen</mat-label>
      <mat-select formControlName="functions" multiple="true">
        <!-- <mat-option [value]="null">-</mat-option> -->
        <mat-option *ngFor="let option of functions" [value]="option.value">
          {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="basis-full">
      <mat-label>Niederlassung</mat-label>
      <mat-select formControlName="location">
        <mat-option *ngFor="let location of locations" [value]="location.id">
          {{location.name}}
        </mat-option>
        <mat-option [value]="undefined">Auswahl aufheben</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button [disabled]="!(resourceFormGroup.valid && !resourceFormGroup.pristine)"
    (click)="saveResource()">Speichern</button>
  <button mat-flat-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>
