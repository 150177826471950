<div class="body flex flex-col">
  <div class="flex items-center justify-center">
    <img src="/assets/NX_Icon_Missing-License.svg" alt="Kein Lizenz" >
  </div>
  <div class="flex items-center justify-center">
    <h2>Ups! Hier fehlt eine {{licenseType}}</h2>
  </div>
  <div class="flex items-center justify-center">
    <span>Bitte wende Dich an Deinen Administrator oder setze Dich mit unserem Support in Verbindung.</span>
  </div>
  <!-- <div class="flex items-center justify-center pt-10">
    <a mat-fab extended href="https://nexato.de/">
      Help-Center
    </a>
  </div> -->
</div>
