import {gql} from 'apollo-angular';

export const UPDATE_LOCATION_MUTATION = gql`
mutation updateLocation($locationInput: LocationInput) {
  updateLocation(input: $locationInput) {
    id
  }
}
`;

export const CREATE_LOCATION_MUTATION = gql`
mutation createLocation($locationInput: LocationInput) {
  createLocation(input: $locationInput) {
    id
    name
  }
}
`;

export const DELETE_LOCATION_MUTATION = gql`
mutation deleteLocation($locationId: UUID) {
  deleteLocation(id: $locationId)
}
`;
