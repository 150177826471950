import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Container, LoadingService, RolesService, SettingsService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AddressInput } from '../../../shared-module/shared/entities/address/address';
import { LocationEntity, LocationInput } from '../../../shared-module/shared/entities/location/location';
import { LocationService } from '../../shared/services/location/location.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-location-view',
  templateUrl: './location-view.component.html',
  styleUrls: ['./location-view.component.scss']
})
export class LocationViewComponent
    extends Container<fromGraphQl.LocationQueryResponse>
    implements OnInit, OnDestroy
  {
    public location: LocationEntity;
    public id: string;
    public routeSubscription: Subscription;
    public newKey: any;
    // form
    public locationFormGroup: UntypedFormGroup;
    public types: any[];
    public addressInput: AddressInput;
    public settings: any;
    termsControl = new UntypedFormControl();
    formCtrlSub: any;
    form: UntypedFormGroup;


  constructor(
    public route: ActivatedRoute,
    public router: Router,
    loadingService: LoadingService,
    apollo: Apollo,
    private fb: UntypedFormBuilder,
    private locationService: LocationService,
    public rolesService: RolesService,
    public settingsService: SettingsService,
  ) {
    super(apollo, loadingService, fromGraphQl.API_KEY_QUERY, environment);
  }

  ngOnInit(): void {
    const key = 'id';
    this.locationFormGroup = this.fb.group({
      name: [null],
      label: [null],
      externalId: [null],
      email: [null],
      phoneNumber: [null],
      web: [null],
      type: [null],
      address: [null]
    });
    // if we call this compobent with an ID, we want do load a Api KLey
    this.routeSubscription = this.route.params
      .pipe(
        filter((params) => params[key] !== undefined),
        take(1)
      )
      .subscribe((params) => {
        if (params[key] && params[key] !== 'create') {
          this.id = params[key];
          if (!this.rolesService?.hasRole('nexcore_location_update')) {
            console.log('No role')
            this.router.navigate(['app-no-access-page'])
          }
        }

        this.subscribeToQuery({
          id: this.id,
        });
      });
      this.types = [
        {id: 'COMPANY', name: 'Firma'},
        {id: 'DEPOT', name: 'Lager' },
        {id: 'TOURBASE', name: 'Tourbasis'}
      ]
      this.locationFormGroup.get('address').disable();
  }
  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  handleSubscriptionResult(data: fromGraphQl.LocationQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data) {
      this.queryRef.stopPolling();

      if (data.location) {
        // console.log('location', data.location);
        this.locationFormGroup.patchValue(data.location);
        this.location = new LocationEntity(data.location);
      }
      if (data?.terms) {
        console.log('terms', data?.terms)
        this.settings = data?.terms;
        this.form = this.createGroup();
      }
    }
  }

  saveLocation(edit = true): void {
    this.loadingService.setLoading(true);
    const formModel = this.locationFormGroup.getRawValue();
    const locationInput = new LocationInput();
    locationInput.name = formModel?.name;
    locationInput.label = formModel?.label;
    locationInput.externalId = formModel?.externalId;
    locationInput.email = formModel?.email;
    locationInput.phoneNumber = formModel?.phoneNumber;
    locationInput.web = formModel?.web;
    locationInput.type = formModel?.type;
    if (formModel?.address?.addressInput) {
      locationInput.address = formModel?.address?.addressInput
    } else if (this.location?.address) {
      locationInput.address = new AddressInput(this.location.address);
    }

    if (this.id) {
      locationInput.id = this.id;
      this.locationService.updateLocation(
        locationInput,
        this.returnCallback(this.router, edit)
      );
    } else {
      this.locationService.createLocation(
        locationInput,
        this.returnCallback(this.router, edit)
        );
      };
      console.log('Form controls', this.form.controls);

      Object.keys(this.form.controls).forEach((name) => {
        const currentControl = this.form.controls[name];
        if (currentControl.dirty) {
         this.save(name, currentControl?.value);
        }
      });
    }

    save(key: string, value: any) {
      let settingInput:any = {};
      settingInput = {
        "key": key,
        "value": value,
        "locationId": this.id
      };
      this.settingsService.updateSettings(
        settingInput,
        this.returnCallbackSave()
      );
    }

  returnCallback(router: Router, edit: boolean): void {
    setTimeout(() => {
      this.loadingService.setLoading(false);
      if (edit) {
        router.navigate(['/settings/locations/']);
      } else {
        this.queryRef?.refetch();
      }
    }, 500);
  }

  abort(): void {
    this.router.navigate(['/settings/locations/']);
  }

  createGroup() {
    const group = this.fb.group({});
    for (let setting of this.settings) {
      setting?.settingDescriptions.forEach((control: any) => group.addControl(control.key, new FormControl(
        control.type === 'Boolean' && control.currentValue === 'false' ? false : control.currentValue
      )));
    }
    return group;
  }

  returnCallbackSave(): void {
    console.log('saved');
  }

}
