
import { BrowserModule } from '@angular/platform-browser';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeKeycloak } from './init/keycloak-init.factory';

import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModuleModule } from '@nexato/nx-core-module';
import { ApolloModule } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { GraphQLModule } from './graphql.module';
import { RentModule } from './rent-module/rent.module';
import { TourPlanningModule } from './tour-planning/tour-planning.module';
import { CurrencyPipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ApolloModule,
    KeycloakAngularModule,
    GraphQLModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSelectCountryModule.forRoot('de'),
    CoreModuleModule.forRoot(environment),
    RentModule,
    TourPlanningModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
