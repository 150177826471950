<ng-container *ngIf="controlTemplate?.settings?.value; else noValue">
    <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showLevelVisible()">
        <div class="basis-2/3 py-2">{{controlTemplate?.settings?.levelVisibleLabel}}</div>
        <div class="basis-1/3 py-2 text-right flex justify-end">
            <input *ngIf="getLevelVisibleValue() === true" type="checkbox" checked disabled>
            <input *ngIf="getLevelVisibleValue() === false" type="checkbox" disabled>
            <ng-container *ngIf="getLevelVisibleValue() === undefined || getLevelVisibleValue() === null">-</ng-container>
        </div>
    </div>
    <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showLevelInput()">
        <div class="basis-1/2 py-2">{{controlTemplate?.settings?.levelLabel}}</div>
        <div class="basis-1/2 py-2 text-right">{{getLevelAsPercentage()}}</div>
    </div>
    <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showPictureInput()">
        <div class="basis-1/2 py-2">Foto</div>
        <div class="basis-1/2 py-2 text-right">TODO</div>
    </div>
    <ng-container *ngIf="controlTemplate?.settings?.showRefueledInput">
        <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showRefueledInput()">
            <div class="basis-1/2 py-2">{{controlTemplate?.settings?.refueledLabel}}</div>
            <div class="basis-1/2 py-2 text-right">{{controlTemplate?.settings?.value.refueled !== undefined ? controlTemplate?.settings?.value.refueled + ' ' + controlTemplate?.settings?.refueledSuffix : "-"}}</div>
        </div>
        <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="refuelTaskShowRefueled()">
            <div class="basis-1/2 py-2">{{controlTemplate?.settings?.refuelTaskRefueledLabel}}</div>
            <div class="basis-1/2 py-2 text-right">{{controlTemplate?.settings?.value.refueledTaskRefueled !== undefined ? controlTemplate?.settings?.value.refueledTaskRefueled + " " + controlTemplate?.settings?.refueledSuffix : '-' }}</div>
        </div>
        <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showRefueledSum()">
            <div class="basis-1/2 py-2">{{controlTemplate?.settings?.refueledSumLabel}}</div>
            <div class="basis-1/2 py-2 text-right">{{controlTemplate?.settings?.value.refueledSum !== undefined ? controlTemplate?.settings?.value.refueledSum + " " + controlTemplate?.settings?.refueledSuffix : '-'}}</div>
        </div>
    </ng-container>
</ng-container>
<ng-template #noValue>
    <div class="text-right p-2">-</div>
</ng-template>
