
<div class="header flex flex-row basis-full items-center">
  <h3 class="basis-1/2">Positionen</h3>
  <div class="flex justify-end basis-1/2 pr-5">
    <i (click)="accordion.openAll();test('opened');" [className]="'icon icon-expand-all'" *ngIf="state==='closed'">
    </i>
    <i (click)="accordion.closeAll();test('closed');" [className]="'icon icon-collapse-all'" *ngIf="state==='opened'">
    </i>
  </div>
</div>
<div class="details lineitems">
  <mat-accordion  #accordion="matAccordion" [multi]="true">
    <div *ngFor="let item of orderLineItems; let i = index">
        <mat-expansion-panel [expanded]="true" hideToggle (opened)="panelOpenState[i] = true; test('check')" (closed)="panelOpenState[i] = false; test('closed');">
          <mat-expansion-panel-header class="border-solid border-b border-t border-gray-300" >
            <div class="flex flex-row basis-full">
              <span class="flex basis-2/4 items-center date"><span class="counter pr-4">{{i + 1}}</span>
                <span class="noWrap" #text [matTooltipDisabled]="isEllipsisActive(text)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{item.text}}">{{item.text}}</span></span>
              <span class="date items-center flex basis-2/4 place-content-end pr-5">
                <span class="noWrap" #Date [matTooltipDisabled]="isEllipsisActive(Date)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{item?.startDateTime | date:'dd.MM.YYYY HH:mm'}}&nbsp; - &nbsp;
                            {{item?.endDateTime
                              | date:'dd.MM.YYYY HH:mm'}}">
                  {{item?.startDateTime | date:'dd.MM.YYYY HH:mm'}}&nbsp; - &nbsp;
                  {{item?.endDateTime
                  | date:'dd.MM.YYYY HH:mm'}}
                </span>
              </span>
              <i [className]="'icon icon-open-down'" *ngIf="!panelOpenState[i]"></i>
              <i [className]="'icon icon-close-up'" *ngIf="panelOpenState[i]"></i>
            </div>
          </mat-expansion-panel-header>
          <div *ngFor="let resourceAssignment of item?.resourceAssignments" class="assignments">
            <div class="mt-3 pl-2 flex flex-row basis-full border-b border-gray row grey items-center">
              <div class="flex basis-1/2 items-center dates header1">
                <i [className]="'icon  icon-item'"></i>
                <span class="noWrap items-center" #text2 [matTooltipDisabled]="isEllipsisActive(text2)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{resourceAssignment?.resource?.text}} - {{resourceAssignment?.free? 'freigemeldet': 'nicht freigemeldet'}}">
                  <a [routerLink]="['/resources/resource/', resourceAssignment?.resource?.id ]" class="jump">
                    {{resourceAssignment?.resource?.text}}
                    <i [className]="'icon icon-jump-to'"></i>
                  </a>
                  - {{resourceAssignment?.free? 'freigemeldet': 'nicht freigemeldet'}}
                </span>
                <!-- <div class="info" [ngClass]="resourceAssignment?.customAttributes && resourceAssignment?.customAttributes?.length>0? '' :'nonote'">
                  <div class="hide">
                    <app-custom-attributes-view
                      [SetCustomAttributes]="resourceAssignment?.customAttributes? resourceAssignment?.resource?.customAttributes: []"
                      [Entity]="'Resource'" [Info]="true" />
                  </div>
                  <i [className]="'icon icon-info'" *ngIf="resourceAssignment?.resource"></i>
                </div> -->
              </div>
              <span class="dates header1 items-center flex basis-1/2 place-content-end">
                <span class="noWrap" #endDate [matTooltipDisabled]="isEllipsisActive(endDate)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{resourceAssignment?.startDateTime | date:'dd.MM.YYYY HH:mm'}}&nbsp; - &nbsp;{{resourceAssignment?.endDateTime
                                              | date:'dd.MM.YYYY HH:mm'}}">
                  {{resourceAssignment?.startDateTime | date:'dd.MM.YYYY HH:mm'}} &nbsp; - &nbsp;
                  {{resourceAssignment?.endDateTime
                  | date:'dd.MM.YYYY HH:mm'}}
                </span>
              </span>
            </div>
            <div class="pl-4 flex flex-row basis-full border-b border-gray row">
              <div class="flex basis-1/2 items-center pr-3 border-gray-300 border-r" >
                <span class="flex basis-1/3 handlingType">
                  <span class="noWrap" #handle [matTooltipDisabled]="isEllipsisActive(handle)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{this.getHandlingType(resourceAssignment?.effectiveSchedulingInformation?.outHandlingType.toString(), 'out')}} (Übergabe)">
                  {{this.getHandlingType(resourceAssignment?.effectiveSchedulingInformation?.outHandlingType.toString(), 'out')}} (Übergabe)
                  </span>
                </span>
                <span class="flex basis-2/3 place-content-end"> <span class="dates items-center flex basis-2/4 place-content-end">
                  <span class="noWrap" #handleDate [matTooltipDisabled]="isEllipsisActive(handleDate)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{ resourceAssignment?.effectiveSchedulingInformation?.outDateTimePeriod?.getDatePeriod(datePipe)}}">
                  {{ resourceAssignment?.effectiveSchedulingInformation?.outDateTimePeriod?.getDatePeriod(datePipe)}}
                </span>
                </span>
                </span>
              </div>
              <div class="items-center flex basis-1/2 pl-5">
                <span class="flex basis-1/3 handlingType">
                  <span class="noWrap" #handle1 [matTooltipDisabled]="isEllipsisActive(handle1)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{this.getHandlingType(resourceAssignment?.effectiveSchedulingInformation?.inHandlingType.toString(), 'in')}} (Rücknahme)">
                  {{this.getHandlingType(resourceAssignment?.effectiveSchedulingInformation?.inHandlingType.toString(), 'in')}} (Rücknahme)
                </span></span>
                <span class="flex basis-2/3 place-content-end"> <span class="dates items-center flex basis-2/4 place-content-end">
                  <span class="noWrap" #handle2 [matTooltipDisabled]="isEllipsisActive(handle2)" matTooltipPosition="left"
                  matTooltipShowDelay="250" matTooltip="{{ resourceAssignment?.effectiveSchedulingInformation?.inDateTimePeriod?.getDatePeriod(datePipe)}}">
                  {{ resourceAssignment?.effectiveSchedulingInformation?.inDateTimePeriod?.getDatePeriod(datePipe)}}
                </span>
                </span></span>
              </div>
            </div>
            <div class="pl-4 flex flex-row basis-full border-b border-gray row">
              <div class="flex basis-1/2 items-center pr-3 border-gray-300 border-r">
                <span class="items-center flex basis-1/2 address">
                  <span>Adresse</span>
                <mat-icon
                  [class.red]="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.geocodingResultGrade.toString() ==='RED'"
                  class="color">
                  circle
                </mat-icon>
                <mat-icon
                  [class.yellow]="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.geocodingResultGrade.toString() ==='YELLOW'"
                  class="color">
                  circle
                </mat-icon>
                <mat-icon
                  [class.green]="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.geocodingResultGrade?.toString() ==='GREEN'"
                  class="color">
                  circle
                </mat-icon>
                </span>
                <span class="address flex flex-col basis-1/2 place-items-end py-2">
                  <span *ngIf="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.name"
                  class="noWrap addressnote"
                  #addressname1 [matTooltipDisabled]="isEllipsisActive(addressname1)" matTooltipPosition="left"
                    matTooltipShowDelay="250"
                    matTooltip="{{resourceAssignment?.effectiveSchedulingInformation?.outAddress?.name}}">{{resourceAssignment?.effectiveSchedulingInformation?.outAddress?.name}}</span>
                  <span *ngIf="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.note"
                  class="noWrap addressnote py-2"
                  #addressnote1 [matTooltipDisabled]="isEllipsisActive(addressnote1)" matTooltipPosition="left"
                    matTooltipShowDelay="250"
                    matTooltip="{{resourceAssignment?.effectiveSchedulingInformation?.outAddress?.note}}">{{resourceAssignment?.effectiveSchedulingInformation?.outAddress?.note}}</span>
                  <span class="noWrap" #address1 [matTooltipDisabled]="isEllipsisActive(address1)" matTooltipPosition="left"
                    matTooltipShowDelay="250"
                    matTooltip="{{resourceAssignment?.effectiveSchedulingInformation?.outAddress?.getAddressString()}}">
                    {{resourceAssignment?.effectiveSchedulingInformation?.outAddress?.getAddressString()}}</span>
                </span>
              </div>
              <div class="items-center flex basis-1/2 pl-5">
                <span class="items-center flex basis-1/2 address">
                  <span>Adresse</span>
                  <mat-icon
                    [class.red]="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.geocodingResultGrade.toString() ==='RED'"
                    class="color">
                    circle
                  </mat-icon>
                  <mat-icon
                    [class.yellow]="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.geocodingResultGrade.toString() ==='YELLOW'"
                    class="color">
                    circle
                  </mat-icon>
                  <mat-icon
                    [class.green]="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.geocodingResultGrade?.toString() ==='GREEN'"
                    class="color">
                    circle
                  </mat-icon>
                </span>
                <span class="address flex flex-col basis-1/2 place-items-end py-2">
                  <span *ngIf="resourceAssignment?.effectiveSchedulingInformation?.inAddress?.name"
                  class="noWrap addressname"
                  #addressname [matTooltipDisabled]="isEllipsisActive(addressname)" matTooltipPosition="left"
                    matTooltipShowDelay="250"
                    matTooltip="{{resourceAssignment?.effectiveSchedulingInformation?.inAddress?.name}}">{{resourceAssignment?.effectiveSchedulingInformation?.inAddress?.name}}</span>
                  <span *ngIf="resourceAssignment?.effectiveSchedulingInformation?.inAddress?.note" class="noWrap addressnote py-2"
                  #addressnote [matTooltipDisabled]="isEllipsisActive(addressnote)" matTooltipPosition="left"
                    matTooltipShowDelay="250"
                    matTooltip="{{resourceAssignment?.effectiveSchedulingInformation?.inAddress?.note}}">{{resourceAssignment?.effectiveSchedulingInformation?.inAddress?.note}}</span>
                  <span class="noWrap" #address2 [matTooltipDisabled]="isEllipsisActive(address2)" matTooltipPosition="left"
                    matTooltipShowDelay="250"
                    matTooltip="{{resourceAssignment?.effectiveSchedulingInformation?.inAddress?.getAddressString()}}">
                    {{resourceAssignment?.effectiveSchedulingInformation?.inAddress?.getAddressString()}}</span>
                </span>
                </div>
            </div>
            <div class="pl-4 flex flex-row basis-full border-b border-gray row">
              <div class="flex basis-1/2 items-center pr-3 border-gray-300 border-r" >
                <span class="flex basis-1/2 address">Kontaktperson(en)</span>
                <span class="flex basis-1/2 place-content-end address">
                  <span
                  *ngIf="resourceAssignment?.effectiveSchedulingInformation?.onSiteContacts">
                  {{getName(resourceAssignment?.effectiveSchedulingInformation?.onSiteContacts)}}
                </span>
                <span
                  *ngIf="!resourceAssignment?.effectiveSchedulingInformation?.onSiteContacts">
                  -
                </span></span>
              </div>
              <div class="items-center flex basis-1/2 pl-5">
                <span class="flex basis-1/2">Kontaktperson(en)</span>
                <span class="flex basis-1/2 place-content-end address">
                  <span
                  *ngIf="resourceAssignment?.effectiveSchedulingInformation?.onSiteContacts">
                  {{getName(resourceAssignment?.effectiveSchedulingInformation?.onSiteContacts)}}
                </span>
                <span
                  *ngIf="!resourceAssignment?.effectiveSchedulingInformation?.onSiteContacts">
                  -
                </span></span>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>

