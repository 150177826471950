<span class="dialog-close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title>Kommentar hinzufügen</h2>
<mat-dialog-content >
  <div [formGroup]="commentFormGroup" >
    <div class="no-margin flex basis-full">
      <!-- text -->
      <mat-form-field class="flex basis-full" appearance="fill">
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
          formControlName="text">
        </textarea>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button [disabled]="!(commentFormGroup.valid && !commentFormGroup.pristine)"
    (click)="saveComment()">Speichern</button>
  <button mat-flat-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>

