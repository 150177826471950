<h1 mat-dialog-title>Tour-Einstellungen</h1>
<mat-dialog-content class="flex felx-col" [formGroup]="tourForm">
  <div class="flex flex-row gap-5 white">
    <mat-form-field class="basis-1/2">
      <mat-label>Fahrzeug</mat-label>
      <mat-select formControlName="resource">
        <mat-option [value]="null">
         kein Fahrzeug geplant
        </mat-option>
        <mat-option *ngFor="let resource of tour?.transportCandidates" [value]="resource.id">
          {{ resource.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="basis-1/2">
      <mat-label>Fahrer</mat-label>
      <mat-select formControlName="assignee">
        <mat-option [value]="null">
          kein Fahrer geplant
         </mat-option>
        <mat-option *ngFor="let assignee of tour?.driverCandidates" [value]="assignee.id">
          {{ getName(assignee) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex flex-row white">
    <mat-form-field class="basis-6/12 pr-2" *ngIf="tour?.state === 'RUNNING'">
      <mat-label>Start</mat-label>
     <input matInput disabled [value]="tour?.actualDepartureDateTime | date:'dd.MM.YYYY HH:mm'" />
    </mat-form-field>
    <mat-form-field class="basis-4/12  pr-2"  *ngIf="tour?.state !== 'RUNNING'">
      <mat-label>Startdatum</mat-label>
      <input matInput [matDatepicker]="picker" placeholder="Start" formControlName="startDateControl">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="basis-2/12 items-center pr-2"  *ngIf="tour?.state !== 'RUNNING'">
      <mat-label>Startzeit</mat-label>
      <input matInput type="time" formControlName="startTime" />
    </mat-form-field>
    <mat-form-field class="basis-4/12 pr-2 pl-2 ml-1">
      <mat-label>Enddatum</mat-label>
      <input matInput [matDatepicker]="picker1" placeholder="End" formControlName="endDateControl">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="basis-2/12 items-center">
      <mat-label>Endzeit</mat-label>
      <input matInput type="time" formControlName="endTime"/>
    </mat-form-field>
   </div>
   <div class="flex flex-row gap-5 white">
    <mat-form-field class="basis-1/2">
      <mat-label>Startort</mat-label>
      <mat-select formControlName="departureAddress">
        <mat-option [value]="null">
          kein Startort geplant
         </mat-option>
        <mat-option *ngFor="let address of tour?.possibleStartAddresses" [value]="address?.id">
          {{ address.getAddressString() }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="basis-1/2">
      <mat-label>Endort</mat-label>
      <mat-select formControlName="arrivalAddress">
        <mat-option [value]="null">
          kein Endort geplant
         </mat-option>
        <mat-option *ngFor="let address of tour?.possibleEndAddresses" [value]="address?.id">
          {{ address?.getAddressString() }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
   <div class="flex flex-row gap-5">
    <mat-form-field class="basis-full" appearance="fill">
      <mat-label>Tour-Hinweis</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
          formControlName="description">
        </textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex flex-row basis-full">
    <div class="basis-1/2">
      <button mat-button (click)="ok()" [disabled]="!(tourForm.valid && !tourForm.pristine)" >Speichern</button>
      <button mat-button (click)="abort()">Abbrechen</button>
    </div>
    <div class="flex basis-1/2 place-content-end justify-end" *ngIf="rolesService?.hasRole('nexcore_tour_delete')">
      <app-nx-button [icon]="'icon icon-trash'" [toolTip]="'Tour Löschen'" (click)="deleteTour()" [disabled]="!tour?.isDeletable"></app-nx-button>
    </div>
  </div>
</mat-dialog-actions>
