import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OrderCommentListDataSource } from './orderCommentsListDataSource';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { merge, tap } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent } from '../../../order-module/components/comment-dialog/comment-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel, LoadingService, User } from '@nexato/nx-core-module';
import { OrderService } from '../../../order-module/shared/services/order/order.service';
import { Comment } from "../../../order-module/shared/entities/comment/comment";

@Component({
  selector: 'app-order-comments-list',
  templateUrl: './order-comments-list.component.html',
  styleUrls: ['./order-comments-list.component.scss']
})
export class OrderCommentsListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges{
  public ownerId: string;
  public ownerType: string;
  public displayedColumns = ['text'];

  @Input() set order(orderId: string) {
    this.ownerId = orderId;
    this.ownerType = 'order';
    this.setTableDataSource();
  }
  @Input() showButtons = true;
  @Input() header: string;
  @Input() headerColor :string = '#EBEEEF';
  @Output() updateChild = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Task>;

  public dataSource: OrderCommentListDataSource;

  constructor(
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    private router: Router,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    public orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes)
    if (changes.order) {
      console.log('changes in comment list componet', changes.order.currentValue)
      this.ownerId = changes.order.currentValue;
      this.ownerType = 'order';
      this.refetchloadTaskList();
    }

  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchloadTaskList()))
      .subscribe();
  }

  setTableDataSource(): void {
    this.dataSource = new OrderCommentListDataSource(this.apollo);
    this.dataSource.loadCommentList({
      number: 0,
      size: 5,
      sortProperty: 'lastModifiedDate',
      sortDirection: 'desc',
      ownerId: this.ownerId,
      ownerType: this.ownerType
    });
  }


  refetchloadTaskList(): void {
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 5,
      sortProperty: this.sort?.active ? this.sort.active : 'lastModifiedDate',
      sortDirection: this.sort?.direction ? this.sort.direction : 'desc',
      ownerId: this.ownerId,
      ownerType: this.ownerType
    });
  }

  createComment() {
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '900px',
      data: {
        orderId: this.ownerId,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(data=>{
      this.updateChild.emit({
        refetch : true
      });
      this.refetchloadTaskList();
    })
  }
  editComment(comment: Comment) {
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '900px',
      data: {
        orderId: this.ownerId,
        comment: comment
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(data=>{
      this.refetchloadTaskList();
    })

  }

  confirmDialog(comment: Comment): void {
    const message = 'Möchtest Du den Kommentar wirklich löschen?';
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.loadingService.setLoading(true);
        this.deleteComment(comment.id);
      }
    });
  }
  deleteComment(id: string){
    this.orderService.deleteComment(
      id,
      this.returnCallback(this.router)
    );
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.updateChild.emit({
        refetch : true
      });
      this.paginator.pageIndex = 0
      this.refetchloadTaskList();
      this.loadingService.setLoading(false);
    }, 500);
  }


  getName(user: User): string {
    if (user?.firstName && user?.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName || user?.lastName) {
      return user?.firstName ? user?.firstName : user?.lastName;
    } else {
      return '-'
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect(this.table);
  }
}
