import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';

export const TASK_LIST_QUERY = gql`
query TaskListQuery(
  $orderId: UUID,
  $states: [TaskState],
  $sortProperty: String,
  $sortDirection: String,
)
{
  tasks: getTasks(
    taskFilter: { orderId: $orderId, states: $states,
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
  }){
      id
      description
      type
      state
      handlingType
      dueDateTimePeriod {
        modifier
        dateTime
        fromDateTime
        toDateTime
      }
      tour {
        id
        name
        resource {
          name
        }
        driver {
          id
          firstName
          lastName
        }
        effectiveArrivalDateTime
        effectiveDepartureDateTime
      }
      completedBy {
        id
        firstName
        lastName
      }
      completedDateTime
      assignment {
        assigneeId
        assignmentType
        typeLabel
        name
        dueDateTimePeriod{
            modifier
            dateTime
            fromDateTime
            toDateTime
        }
      }
      assignmentSuggestion {
        assigneeId
        assignmentType
        typeLabel
        name
        dueDateTimePeriod{
            modifier
            dateTime
            fromDateTime
            toDateTime
        }
      }
    }
}
`;

export interface TaskListQueryResponse {
  tasks: Task[];
  loading: boolean;
}


