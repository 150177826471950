import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

import { DatePipe } from '@angular/common';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-task-files-dialog",
  templateUrl: "task-files-dialog.html",
  styleUrls: ["./task-files-dialog.scss"]
})
export class TaskFilesDialogComponent {
  public task: Task;

  constructor(
    public dialogRef: MatDialogRef<TaskFilesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    public dialog: MatDialog,

  ) {
    this.task = data.task;
  }

  abort() {
    this.dialogRef.close();
  }

}
