<div class="content" fxFlex="100%">
  <app-layout-content-title>
    <ng-container ngProjectAs="[headline]">
      <i [className]="Contact?.getContactIcon(customer?.type)" [matTooltip]="Contact?.getContactType(customer?.type)"></i>
      <span class="noWrap">
        <h2>{{customer?.type?.toString() === 'INDIVIDUAL'? customer?.lastName + ', ' + customer?.firstName : (customer?.type?.toString() === 'COMPANY'? customer.companyName : '')}}<span class="model"> ({{customer?.number? customer.number: '-'}})</span></h2>
      </span>
    </ng-container>
  </app-layout-content-title>
  <div class="body">
    <mat-tab-group animationDuration="0ms" mat-align-tabs="start" mat-stretch-tabs="false">
      <mat-tab label="Allgemein">
        <div class="felx flex-row basis-full">
          <div class="flex flex-column gap-3 md:flex-row rows pt-3">
            <div class="basis-1/2 columns">
              <mat-tab-group animationDuration="0ms" mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Stammdaten">
                  <div class="details border-t border-gray-300">
                    <div class="details flex flex-col ">
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">Kontakt-ID</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.number? customer.number: '-'}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">Typ</div>
                        <div class="basis-2/3 flex justify-end p-3">{{ getContactType(customer?.type)}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray"
                        *ngIf="customer?.type?.toString() === 'INDIVIDUAL'">
                        <div class="label basis-1/3 p-3">Anrede</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.salutation}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray"
                        *ngIf="customer?.type?.toString() === 'INDIVIDUAL'">
                        <div class="label basis-1/3 p-3">Vorname</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.firstName}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray"
                        *ngIf="customer?.type?.toString() === 'INDIVIDUAL'">
                        <div class="label basis-1/3 p-3">Nachname</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.lastName}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray"
                        *ngIf="customer?.type?.toString() === 'COMPANY'">
                        <div class="label basis-1/3 p-3">Firmenname</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.companyName? customer?.companyName: '-'}}
                        </div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray"
                        *ngIf="customer?.type?.toString() === 'COMPANY'">
                        <div class="label basis-1/3 p-3">Firmenname Zusatz</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.companyNameExtension?
                          customer?.companyNameExtension: '-'}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">Telefonnummer</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.phone? customer?.phone: '-'}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">E-Mail</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.email? customer?.email: '-'}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">Straße</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.address?.street? customer?.address?.street:
                          '-'}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">Hausnummer</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.address?.houseNumber?
                          customer?.address?.houseNumber: '-'}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">PLZ</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.address?.postalCode?
                          customer?.address?.postalCode: '-'}}</div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">Ort</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.address?.city? customer?.address?.city: '-'}}
                        </div>
                      </div>
                      <div class="row flex flex-row basis-full border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">Land</div>
                        <div class="basis-2/3 flex justify-end p-3">{{customer?.address?.country? customer?.address?.country:
                          '-'}}</div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab  *ngFor="let section of customAttributeSections; let index = index" [label]="CheckSectionInAttributes(section?.id)? section?.label: null"  class="tab-title">
                  <div *ngFor="let defintion of section?.customAttributeDefinitions">
                    <div *ngIf="CheckDefintionInAttributes(defintion.id)" class="details flex flex-col gap-5">
                      <div class="row flex flex-row basis-full gap-5 border-solid border-b border-gray">
                        <div class="label basis-1/3 p-3">
                          {{defintion?.label}}
                        </div>
                        <div class="basis-2/3 flex justify-end p-3 value" *ngIf="defintion?.type !== 'DATETIME'">{{getValue(defintion?.id)? getValue(defintion?.id): '-'}}
                        </div>
                        <div class="basis-2/3 flex justify-end p-3 value" *ngIf="defintion?.type === 'DATETIME'">{{getValue(defintion?.id)? (getValue(defintion?.id) | date:'dd.MM.YYYY HH:mm'): '-'}} Uhr
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="flex basis-1/2 columns gap-5" *ngIf="rolesService?.hasRole('nexcore_contactPerson_view')">
              <div class="small">
                <app-contact-person-list [contact]="customer?.id"></app-contact-person-list>
              </div>
            </div>
          </div>
          <!-- <div class="flex flex-column gap-3 md:flex-row rows" *ngIf="customer?.customAttributes?.length>0">
            <div class="basis-1/2 medium gap-5">
              <app-custom-attributes-view [SetCustomAttributes]="customer?.customAttributes? customer?.customAttributes: []"
                [Entity]="'Contact'" />
            </div>
          </div> -->
        </div>
      </mat-tab>
      <mat-tab label="Aufträge">
        <div class="flex flex-row basis-full mt-3 full">
          <app-orders-list class="basis-full full" [customer]="customer?.id" *ngIf="customer?.id"></app-orders-list>
        </div>
      </mat-tab>
      <mat-tab label="Schäden">

      </mat-tab>
    </mat-tab-group>
      </div>
</div>

