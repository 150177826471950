<div class="">
    <div class="headlineRow">
        <div class="text-right p-2">
            <span class="font-bold">{{_protocol?.formControlTemplate?.settings?.label}}</span><br>
            <ng-container *ngIf="_protocol?.state === 'COMPLETED' || _protocol?.state === 'ARCHIVED'; else notCompleted">
                {{_protocol.completedDateTime | date:'dd.MM.YYYY HH:mm'}}
                / {{_protocol.completedBy.firstName}} {{_protocol.completedBy.lastName}}
            </ng-container>
            <ng-template #notCompleted>
                {{_protocol?.formControlTemplate?.settings?.notCompletedLabel}}
            </ng-template>   
        </div>
    </div>
    <ng-container *ngIf="referenceFormTemplate">
        <div class="bodyRow">
            <ng-container *ngFor="let referenceSectionControlTemplate of referenceBodyControlTemplates">
            <div class="sectionRow" *ngIf="referenceSectionControlTemplate?.settings?.show">
                <div class="sectionHeaderRow font-bold p-2 border-b border-t border-solid border-gray-300 backgroundColor">{{referenceSectionControlTemplate?.settings?.label}}</div>
                <ng-container *ngFor="let referenceControlTemplate of referenceSectionControlTemplate?.controls">
                <div *ngIf="referenceControlTemplate?.settings?.show"
                    class="controlRow border-b border-solid border-gray-300 flex flex-row last:border-b-0">
                    <div class="basis-1/2 label">
                        <app-protocol-control-label-view [controlTemplate]="referenceControlTemplate">
                        </app-protocol-control-label-view>
                    </div>
                    <div class="basis-1/2 value">
                        <app-protocol-control-value-view [controlTemplate]="referenceControlTemplate">
                        </app-protocol-control-value-view>
                    </div>
                </div>
                </ng-container>
            </div>
            </ng-container>
        </div>
        <div class="completionRow">
            <ng-container *ngFor="let referenceSectionControlTemplate of referenceCompletionControlTemplates">
            <div class="sectionRow" *ngIf="referenceSectionControlTemplate?.settings?.show">
                <div class="sectionHeaderRow font-bold p-2 border-b border-t border-solid border-gray-300 backgroundColor">{{referenceSectionControlTemplate?.settings?.label}}</div>
                <ng-container *ngFor="let referenceControlTemplate of referenceSectionControlTemplate?.controls">
                <div *ngIf="referenceControlTemplate?.settings?.show" 
                    class="controlRow border-b border-solid border-gray-300 flex flex-row last:border-b-0">
                    <div class="basis-1/2 label">
                        <app-protocol-control-label-view [controlTemplate]="referenceControlTemplate">
                        </app-protocol-control-label-view>
                    </div>
                    <div class="basis-1/2 value">
                        <app-protocol-control-value-view [controlTemplate]="referenceControlTemplate">
                        </app-protocol-control-value-view>
                    </div>
                </div>
                </ng-container>
            </div>
            </ng-container>
        </div>
    </ng-container>
</div>
