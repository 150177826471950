import { Component, EventEmitter, OnDestroy, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';


@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss'],
})
export class LocationSelectionComponent implements OnDestroy  {

  locationSelectionFormControl: FormControl = new FormControl();
  locationSelectionFormControlSubscription: Subscription;

  @Input() showClearSelection = false;
  @Input() color: string ='white';


  @Output() selectLocation = new EventEmitter();
  @Input()
  set locationsValue(location: LocationEntity[])  {
    this.locationSelectionFormControl.setValue(location);
  }
  @Input() locations = new Array<LocationEntity>();
  @Input() location: any;
  @Input() multiple = true;
  @Input() emptyValuePossible = true;
  @Input() displayLabel: boolean;

  constructor() {
    this.locationSelectionFormControlSubscription = this.locationSelectionFormControl.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(value => {
        this.selectLocation.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.locationSelectionFormControlSubscription.unsubscribe();
  }
}
