<mat-form-field class="w-full" [formGroup]="addressAutocompleteForm">
  <mat-label>Adresse suchen...</mat-label>
  <input formControlName="query" matInput placeholder="Adresse suchen..." #addressQuery autoActiveFirstOption=true
    [matAutocomplete]="autocomplete">
  <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)='selectAddress($event)'
    [displayWith]="displayAddressLabel">

    <!-- Show standard autocomplete, if only here is active -->
    <ng-container *ngIf="!googleAutocompleteAcitve">
      <mat-option *ngFor="let result of addressAutoCompleteResults" [value]="result">
        {{result?.label}}
      </mat-option>
      <mat-option *ngIf="addressAutoCompleteResults?.length === 0">
        <span class="italic">Keine Ergebnisse</span>
      </mat-option>
    </ng-container>

    <!-- Show opto group, if both, google and here, are active -->
    <ng-container *ngIf="googleAutocompleteAcitve">
      <mat-optgroup *ngFor="let group of addressAutoCompleteResultGroups" [label]="group.label + ' (' + group.results?.length +')'">
        <mat-option *ngFor="let result of group.results" [value]="result">
          {{result?.label}}
        </mat-option>
        <mat-option *ngIf="group?.results?.length === 0">
          <span class="italic">Keine Ergebnisse</span>
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
