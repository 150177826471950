import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel, LoadingService, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ContactPersonDialogComponent } from '../../components/contact-person-dialog/contact-person-dialog.component';
import { Contact } from '../../shared/entities/contact/contact';
import { ContactPerson } from '../../shared/entities/contactPerson/contactPerson';
import { ContactPersonService } from '../../shared/services/contactPerson/contactPerson.service';
import { ContactPersonDataSource } from './contactPersonDataSource';

@Component({
  selector: 'app-contact-person-list',
  templateUrl: './contact-person-list.component.html',
  styleUrls: ['./contact-person-list.component.scss'],
})
export class ContactPersonListComponent implements OnInit, AfterViewInit, OnDestroy {
  public contactId: string;
  public displayedColumns: String[];

  @Input() set contact(contactId: string) {
    this.contactId = contactId;
    if (this.contactId){
      if (this.rolesService?.hasRole('nexcore_contactPerson_update') || this.rolesService?.hasRole('nexcore_contactPerson_delete')) {
        this.displayedColumns = [
          'name', 'phoneNumber', 'mobileNumber', 'email', 'button'];
      }
      else {
        this.displayedColumns = [
          'name', 'phoneNumber', 'mobileNumber', 'email'];
      }
      this.setTableDataSource();
    }
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Contact>;
  @ViewChild('drawerRight') drawerRight: any;

  public text = '';
  contactNameControl = new UntypedFormControl();
  formCtrlSub: any;

  public dataSource: ContactPersonDataSource;

  constructor(
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    private router: Router,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    private contactPersonService: ContactPersonService,
    public rolesService: RolesService
  ) {}

  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
    this.formCtrlSub = this.contactNameControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.text = term;
        this.selectionChanges();
      });
  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchloadContactPersonList()))
      .subscribe();
  }

  setTableDataSource(): void {
    this.dataSource = new ContactPersonDataSource(this.apollo);
    const text = this.text ? this.text : '';
    this.dataSource.loadContactPersonList({
      number: 0,
      size: 6,
      sortProperty: 'id',
      sortDirection: 'asc',
      text,
      contactId: this.contactId,
      isOneTimeContact: false
    });
  }


  refetchloadContactPersonList(): void {
    const text = this.text ? this.text : '';
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 6,
      sortProperty: this.sort?.active ? this.sort.active : 'id',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      text,
      contactId: this.contactId
    });
  }

  selectionChanges() {
    this.paginator.pageIndex = 0;
    this.refetchloadContactPersonList();
  }


  openContact(id: string): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate(['./customers/customer/' + id]));
  }

  getName(linkedContact: Contact): string {
    if (linkedContact?.firstName && linkedContact?.lastName) {
      return linkedContact.lastName + ', ' + linkedContact.firstName;
    } else if (linkedContact?.firstName || linkedContact?.lastName) {
      return linkedContact?.firstName ? linkedContact?.firstName : linkedContact?.lastName;
    } else if (linkedContact.name){
      return linkedContact.name;
    } else {
      return '-'
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect(this.table);
  }

  editContactPerson(contactperson: ContactPerson) {
    const dialogRef = this.dialog.open(ContactPersonDialogComponent, {
      width: '900px',
      data: {
        contactperson: contactperson,
        contactId: this.contactId
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(()=>{
      this.refetchloadContactPersonList();
    })

  }
  createContactPerson() {
    const dialogRef = this.dialog.open(ContactPersonDialogComponent, {
      width: '900px',
      data: {
        contactId: this.contactId
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(()=>{
      this.refetchloadContactPersonList();
    })

  }

  confirmDialog(contactperson: ContactPerson): void {
    const message = 'Möchtest Du den Kontaktperson wirklich löschen?';
    const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.loadingService.setLoading(true);
        this.deleteContactPerson(contactperson.id);
      }
    });
  }
  deleteContactPerson(id: string){
    this.contactPersonService.deleteContactPerson(
      id,
      this.returnCallback(this.router)
    );
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.loadingService.setLoading(false);
      this.refetchloadContactPersonList();
    }, 500);
  }
}
