import { gql } from 'apollo-angular';
import { FormVariant } from '../../shared/entities/formVariant/formVariant';
import { Form } from '../../shared/entities/form/form';

export const FORM_VARIANT_QUERY = gql`
query formVariantQuery(
  $id: UUID, $formKey: String
)
{
  formVariant: getFormVariant(
    id: $id
  )
  {
    id
    name
    key
    isSystem
    isActive
    isDeletable
    formControl
  }
  form: getForm(
    key: $formKey
  )
  {
    name
  }
}
`;

export interface FormVariantQueryResponse {
  formVariant: FormVariant;
  loading: boolean;
  form: Form;
}
