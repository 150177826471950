<h1 mat-dialog-title>Dateien</h1>
<mat-dialog-content class="content">
  <div class="flex flex-row basis-full p-4 row">
    <div class="basis-1/3">Auftragsnummer</div>
    <div class="flex basis-2/3 justify-end">{{task?.order?.number? task?.order?.number : '-'}}
    </div>
  </div>
   <!-- <span class="pl-4 pt-8">Kommentare</span> -->
   <div class="flex flex-row mt-2 basis-full">
      <app-order-files [order]="task.order?.id" class="basis-full" [header]="'Dateien'" [headerColor]="'white'"/>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="abort()" class="flex ml-2 mr-15">Schließen</button>
  <div class="spacer"></div>
  <!-- <app-nx-button (click)="createComment()" toolTip="Kommentar hinzufügen" icon="icon  icon-comment-add">
  </app-nx-button> -->
</mat-dialog-actions>
