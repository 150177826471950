<div class="small">
  <mat-tab-group animationDuration="0ms" mat-align-tabs="start" mat-stretch-tabs="false">
    <mat-tab label="Auftragsdaten">
      <div class="details border-t border-gray-300">
        <div class="details flex flex-col">
          <div class="row flex flex-row basis-full border-solid border-b border-gray">
            <div class="label basis-1/3 p-3">Auftragsnummer</div>
            <div class="basis-2/3 flex justify-end p-3">{{order?.number? order.number: '-'}}
            </div>
          </div>
          <div class="row flex flex-row basis-full border-solid border-b border-gray">
            <div class="label basis-1/3 p-3">Typ</div>
            <div class="basis-2/3 flex justify-end p-3">{{Order.getOrderType(order?.type?.toString())}}</div>
          </div>
          <div class="row flex flex-row basis-full border-solid border-b border-gray">
            <div class="label basis-1/3 p-3">Niederlassung</div>
            <div class="flex basis-2/3 justify-end p-3">{{order?.location?.name? order?.location?.name: '-'}}</div>
          </div>
          <div class="row flex flex-row basis-full border-solid border-b border-gray">
            <div class="label basis-1/3 p-3">Kunde (Nr.)</div>
            <div class="basis-2/3 flex justify-end items-center">
              <a [routerLink]="['/customers/customer/', order?.customer?.id ]" class="jump">
                {{order?.customer?.getFullName()? order?.customer?.getFullName() :'-'}} {{order?.customer?.number? '(' + order?.customer.number+ ')': ''}}
                 <i [className]="'icon icon-jump-to'"></i>
              </a>
            </div>
          </div>
          <div class="row flex flex-row basis-full border-solid border-b border-gray">
            <div class="label basis-1/3 p-3">Bestellnummer</div>
            <div class="flex basis-2/3 justify-end p-3">{{order?.customerOrderNumber? order.customerOrderNumber:
              '-'}}</div>
          </div>
          <div class="row flex flex-row basis-full border-solid border-b border-gray">
            <div class="label basis-1/3 p-3">Besteller</div>
            <div class="flex basis-2/3 justify-end p-3">
              {{order?.orderer?.getFullName() ? order?.orderer.getFullName(): '-'}}</div>
          </div>
          <div class="row flex flex-row basis-full border-solid border-b border-gray">
            <div class="label basis-1/3 p-3">Hinweis</div>
            <div class="flex basis-2/3 justify-end p-3">{{order?.note? order?.note: '-'}}</div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab  *ngFor="let section of customSections; let index = index" [label]="CheckSectionInAttributes(section?.id)? section?.label: null"  class="tab-title">
      <div *ngFor="let defintion of section?.customAttributeDefinitions">
        <div *ngIf="CheckDefintionInAttributes(defintion.id)" class="details flex flex-col gap-5">
          <div class="row flex flex-row basis-full gap-5 border-solid border-b border-gray">
            <div class="label basis-1/3 p-3">
               {{defintion?.label}}
            </div>
            <div class="basis-2/3 flex justify-end p-3 value" *ngIf="defintion?.type !== 'DATETIME'">{{getValue(defintion?.id)? getValue(defintion?.id): '-'}}
            </div>
            <div class="basis-2/3 flex justify-end p-3 value" *ngIf="defintion?.type === 'DATETIME'">{{getValue(defintion?.id)? (getValue(defintion?.id) | date:'dd.MM.YYYY HH:mm'): '-'}} Uhr
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

</div>
