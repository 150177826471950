import { CdkDrag, CdkDragDrop, CdkDragEnter, CdkDropList } from '@angular/cdk/drag-drop';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadingService, RolesService } from '@nexato/nx-core-module';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { Driver } from '../../shared/entities/driver';
import { Tour } from '../../shared/entities/tour';
import { TourSettingsDialogComponent } from '../tour-settings-dialog/tour-settings-dialog.component';

@Component({
  selector: "div[tours-planner-tours-list], app-tours-planner-tours-list",
  templateUrl: "./tours-planner-tours-list.component.html",
  styleUrls: ["./tours-planner-tours-list.component.scss"],
})
// https://github.com/angular/components/issues/18673
export class ToursPlannerToursListComponent implements AfterViewChecked {

  displayNoSignUp = true;
  _tours = new Array<Tour>();
  dragActive = false;
  public panelOpenState: boolean[] = [];

  @Input() loading: boolean;
  @Input() date: any;
  @Input() resources: Resource[];
  @Input() driverEntities: any;
  @Input() drivers: Driver[];
  @Input()
  set tours(tours: Tour[]) {
    this._tours = tours;
    for (let i in tours){
      this.panelOpenState.push(false);
    }

  }
  @Input() isTourFull: boolean;
  @Input() _headline: string;
  @Input() tourState: string;
  @Input()
  set draggingActive(active: boolean){
    this.dragActive = active;
  }
  @Input() openPanels = new Array<any>();
  @Input() sortBy: string;

  @Output() deleteTour = new EventEmitter();
  @Output() setVehicle = new EventEmitter();
  @Output() removeVehicle = new EventEmitter();
  @Output() addTaskToTour = new EventEmitter();
  @Output() addTaskToTourAtPosition = new EventEmitter();
  @Output() removeTaskFromTour = new EventEmitter();
  @Output() setDriver = new EventEmitter();
  @Output() removeDriver = new EventEmitter();
  @Output() changeOrder = new EventEmitter();
  @Output() dragStart = new EventEmitter();
  @Output() dragEnd = new EventEmitter();
  @Output() addTimes = new EventEmitter();
  @Output() addDescription = new EventEmitter();
  @Output() panelOpened = new EventEmitter();
  @Output() panelClosed = new EventEmitter();
  @Output() saveSettings = new EventEmitter();

  public draggingSource: any = undefined;
  public mouseoverPanel: { id: any; } = undefined;

  constructor(
    private changeDetector : ChangeDetectorRef,
    private taskService: TasksService,
    private router: Router,
    private loadingService: LoadingService,
    public dialog: MatDialog,
    public rolesService: RolesService
  ) {
  }


  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  entered($event: CdkDragEnter){
    $event.item.element.nativeElement.setAttribute('style', 'display:none');
    return false;
  }

  mouseEnter($event: any, panel: { id: any; expanded?: any; close?: any; open?: any; }, tourId: any){
    if(this.dragActive){
      this.mouseoverPanel = panel;
      setTimeout( () => {
        if(this.dragActive && this.mouseoverPanel && (this.mouseoverPanel.id === panel.id) && (tourId !== this.draggingSource)) {
          panel.expanded ? panel.close() : panel.open();
        }
      }, 2000);
    }
  }

  mouseLeave($event: any, panel: any){
    this.mouseoverPanel = undefined;
  }

  compareFn(c1: { id: any; }, c2: { id: any; }) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  delete(tour: any) {
    this.deleteTour.emit(tour);
  }

  changeVehicle($event: { vehicle: any; tour: any; }) {
    if ($event.vehicle === "unplanned") {
      // remove vehicle
      this.removeVehicle.emit($event.tour);
    } else {
      this.setVehicle.emit($event); // assign vehicle
    }
  }

  changeDriver($event: { driver: any; tour: any; }) {
    if ($event.driver === "unplanned") {
      // remove driver
      this.removeDriver.emit($event.tour);
    } else {
      this.setDriver.emit($event); // assign driver
    }
  }

  trackByIndex = (index: any) => index;

  showHide(){
    this.displayNoSignUp = !this.displayNoSignUp;
  }

  onDragStart($event: any){
    this.dragStart.emit($event);
  }

  onDragEnd($event: any){
    this.dragEnd.emit($event);
  }

  onAddTimes(tour: Tour){
    this.addTimes.emit(tour);
  }

  onAddDescription(event: { stopPropagation: () => void; }, tour: Tour){
    event.stopPropagation();
    this.addDescription.emit(tour);
  }
  onAddTimesNew(event: { stopPropagation: () => void; }, tour: Tour){
    event.stopPropagation();
    this.addTimes.emit(tour);
  }

  opened(tourId: any){
    this.panelOpened.emit(tourId);
  }

  closed(tourId: any){
    this.panelClosed.emit(tourId);
  }

  checkOpen(tour: Tour){
    if (this.isTourFull && tour?.state !== 'FINISHED') {
      return true;
    }
    else if (this.openPanels){
      return this.openPanels.includes(tour?.id);
    }
    return false;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  isTourButtonDisabled(tour: Tour){
    if(tour.state === 'FINISHED' || tour.state === 'RUNNING' || tour.state === 'PAUSED'){
      return true;
    }
    return false;
  }

  buildProtocolNote(task: Task){
    //TODO return this.taskService.buildProtocolNote(task);
  }

  /**
   * checks if a drop of a task in the list is allowed
   * a drop is allowed if the drop is located after
   * the last "done" task in the tour
   * @param index
   * @param drag
   * @param drop
   * @returns
   */
   tourDropListSortPredicate(index: number, drag: CdkDrag<Task>, drop: CdkDropList<Tour>){
    let tour = drop.data;
    let task = drag.data;
    let lastDoneTaskIndex = undefined;
    console.log('drop to task list of tour ' + tour.id + ' at position ' +  index);
    for (let i = 0; i < tour?.tasks?.length; i++) {
      const currentTask = tour?.tasks[i];
      if(currentTask.state == "done"){
        lastDoneTaskIndex = i;
      }
    }
    // console.log("Drop task " + task.id + "on tour " + tour.id + " at position " + index + ".");
    // no done task at all on tour
    if(lastDoneTaskIndex === undefined){
      // console.log("drop is allowed - no done task");
      return true;
    }
    // is new index located after last done tasks index
    if(index > lastDoneTaskIndex){
      // console.log("drop is allowed - drop index is after last done task");
      return true
    }
    // drop not allowed
    // console.log("drop is not allowed");
    return false;
  }

  /**
   * checks if a drop of a task in the list is allowed
   * a drop is allowed if the drop is located after
   * the last "done" task in the tour
   * @param index
   * @param drag
   * @param drop
   * @returns
   */
  taskDropListSortPredicate(index: number, drag: CdkDrag<Task>, drop: CdkDropList<Tour>){
    // console.log('drop to task list if tour ' + drop.id + ' at position ' +  index);
    let tour = drop.data;
    let task = drag.data;
    let lastDoneTaskIndex = undefined;
    for (let i = 0; i < tour?.tasks.length; i++) {
      const currentTask = tour?.tasks[i];
      if(currentTask.state == "done"){
        lastDoneTaskIndex = i;
      }
    }
    // console.log("Drop task " + task.id + "on tour " + tour.id + " at position " + index + ".");
    // no done task at all on tour
    if(lastDoneTaskIndex === undefined){
      console.log("drop is allowed - no done task");
      return true;
    }
    // is new index located after last done tasks index
    if(index > lastDoneTaskIndex){
      // console.log("drop is allowed - drop index is after last done task");
      return true
    }
    // drop not allowed
    // console.log("drop is not allowed");
    return false;
  }

  taskDropListEnterPredicate(drag: CdkDrag<Task>, drop: CdkDropList<Tour>){
    const task = drag.data as Task;
    const tour = drop.data as Tour;
    // console.log(drag);
    // console.log(drop);
    //console.log("Tours task list: Received cdkDropListEnterPredicate on tour " + tour.id + " for task " + task.id + ".");
    // const result = false;
    // // check, if tour is done - droping on done tours is not allowed
    if(tour.state === "FINISHED"){
      //console.log("Expansion Panel Header: Drop not allowed: Tour is finished");
      return false;
    }
    // // check, if the user wants to drop the task on the tour where the task is already scheduled
    // if(task.tour && task.tour.id === tour.id){
    //   console.log("Expansion Panel Header: Drop not allowed: task is already planned on that tour");
    //   return false;
    // }
    // console.log("Expansion Panel Header: Drop allowed.");
    return true;
  }

  tourDropPredicate(drag: CdkDrag<Task>, drop: CdkDropList<Tour>){
    const task = drag.data as Task;
    const tour = drop.data as Tour;
    // console.log(drag);
    // console.log(drop);
    // console.log("Expansion Panel Header: Received tourDropPredicate on tour " + tour.id + " for task " + task.id + ".");
    const result = false;
    // check, if tour is done - droping on done tours is not allowed
    if(tour.state === "FINISHED"){
      // console.log("Expansion Panel Header: Drop not allowed: Tour is finished");
      return false;
    }
    // check, if the user wants to drop the task on the tour where the task is already scheduled
    // TODO: if(task.tour && task.tour.id === tour.id){
    //   // console.log("Expansion Panel Header: Drop not allowed: task is already planned on that tour");
    //   return false;
    // }
    // console.log("Expansion Panel Header: Drop allowed.");
    return true;
  }

  /**
   * Checks, if its allowed to drag the task. A task can only be dragged if its not 'done'
   * @param task
   * @returns
   */
  dragDisabled(task: Task){
    if(this.loading){
      return true;
    }
    if (!this.rolesService?.hasRole('nexcore_tour_update')) {
      return true;
    }
    if(task.state === 'COMPLETED'){
      return true;
    }
    return false;
  }

  /**
   * Task was dropped on the mat expansion panel header - add at the end
   * @param event
   */
  dropOnExpansionPanelHeader(event: CdkDragDrop<Tour, any>){
    // console.log('event', event)

    // check, if we want to receive this event
    // we want to receive it, if the preivous container is:
    // - openTaskList
    // - toursTaskList
    //console.log("Received drop event on expansion header from " + event.previousContainer.id);
    if(event.previousContainer.id === "openTasksList" || event.previousContainer.id === "toursTaskList"){
      // console.log("Task comes from " + event.previousContainer.id + " and we want to receive it.");
      // console.log('event', event)
      const tour = event.container.data;
      const task = event.item.data;
      this.addTaskToTour.emit({
        tour: tour.id,
        task: task.id,
        position: tour?.tasks? tour?.tasks?.length: 0
      })
      this.dragEnd.emit();
    }
  }

  /**
   * Task was dropped inside a tour at a specific index
   * @param event
   */
  dropInsideTourList(event: CdkDragDrop<Tour, any>){
    // check, if we want to receive this event
    // we want to receive it, if the preivous container is:
    // - toursTaskList
    // - openTaskList
    //console.log("Tours task list: Received drop event on tours task list header from " + event.previousContainer.id);
    if(event.previousContainer.id === "openTasksList" || event.previousContainer.id === "toursTaskList"){
      const tasksNewPosition = event.currentIndex;
      const tasksOldPosition = event.previousIndex;
      const tasksNewTour = event.container.data as Tour;
      const tasksOldTour = event.previousContainer?.data as Tour;
      if( tasksNewTour === tasksOldTour &&  tasksNewPosition ==  tasksOldPosition){
        // nothing has changed - do nothing
        //console.log("Tours task list: nothing has changed.")
        // event.source._dragRef.reset();
        event.item._dragRef.reset();
      } else {
        // something jas changed, change position and/or tour of task
        //console.log("Tours task list: Add task " + event.item.data.id + " on tour " + event.container.data.id + " at postition " + event.currentIndex + ".")
        this.addTaskToTourAtPosition.emit({
          task: event.item.data.id,
          tour: event.container.data.id,
          position: event.currentIndex
        });
      }
      this.dragEnd.emit();
    }
  }

  dragStarted($event: any){
    this.dragStart.emit();
  }

  dragReleased($event: any){
    this.dragEnd.emit();
  }

  getTourDepartureTime(tour: Tour): string{
    if (tour.state === 'NEW') {
      return tour?.scheduledDepartureDateTime;
    }
    else {
      return tour?.actualDepartureDateTime;
    }
  }

  getTourArrivalTime(tour: Tour): string{
    if (tour.state === 'FINISHED' || tour.state === 'ARCHIVED') {
      return tour?.actualArrivalDateTime;
    }
    else {
      return tour?.scheduledArrivalDateTime;
    }
  }
  openSettings(tour: Tour) {
    if (tour.state !== 'FINISHED') {
      const dialogRef = this.dialog.open(TourSettingsDialogComponent, {
        width: '900px',
        data: {
          tour: tour,
          // resources: this.resources,
          // assignees: this.drivers
        },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(dialog=>{
        if (dialog?.model) {
          this.saveSettings.emit(dialog.model);
        }
        if (dialog?.tour) {
          this.deleteTour.emit(tour);
        }
      });
    }
  }
  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }
}



///////////////////////////////////////////////////////////////////
// EDIT: custom min and max range
const customMin = 2
const customMax = 4





