<form class="search-form">
  <mat-form-field class="w-40 lg:w-52  xl:w-60 2xl:w-80 dense-2" [hidden]="!licenseService.isLicenceUser()">
    <input #searchField type="text" matInput [formControl]="searchTerm" [matAutocomplete]="auto" placeholder="Suche…"
      tabindex="-1" (mouseleave)="searchTerm?.value? '': autocomplete?.closePanel()">
    <button *ngIf="searchTerm?.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm?.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="cleanUp()">
      <mat-optgroup [label]="'Aufträge (' + orderSearchResult?.length +')'">
        <mat-option *ngFor="let item of orderSearchResult" [value]="item?.number" (onSelectionChange)="openOrder(item)">
          {{ item?.number }} / {{item?.customer?.getFullName()}}
        </mat-option>
        <mat-option *ngIf="noOrderFound">keine Aufträge gefunden</mat-option>
      </mat-optgroup>
      <!--
      <mat-optgroup [label]="'Lieferanten (' + suppliersSearchResult.length +')'">
          <mat-option *ngFor="let item of suppliersSearchResult" [value]="item.number" (click)=openSupplier(item)>
            {{ item.number }} / {{item.name1}}
          </mat-option>
          <mat-option *ngIf="noCustomerFound">keine Lieferanten gefunden</mat-option>
        </mat-optgroup>-->
      <mat-optgroup [label]="'Resourcen (' + resourcesSearchResult?.length +')'">
        <mat-option *ngFor="let item of resourcesSearchResult" [value]="item?.name" (onSelectionChange)="openResource(item)">
          {{ item?.number }} / {{ item?.name }}
        </mat-option>
        <mat-option *ngIf="noResorceFound">keine Resourcen gefunden</mat-option>
      </mat-optgroup>
      <mat-optgroup [label]="'Kunden (' + customerSearchResult?.length +')'">
        <mat-option *ngFor="let item of customerSearchResult" [value]="item.number" (onSelectionChange)="openCustomer(item)">
          {{ item?.number }} / {{item.getFullName()}}
        </mat-option>
        <mat-option *ngIf="noCustomerFound">keine Kunden gefunden</mat-option>
      </mat-optgroup>

    </mat-autocomplete>
  </mat-form-field>
</form>
