
export class Address {
  id: string | undefined;
  type: AddressType;
  activeSource: AddressSource;
  country: string | undefined;
  city: string | undefined;
  postalCode: string | undefined;
  street: string | undefined;
  houseNumber: string | undefined;
  lat: string | undefined;
  lng: string | undefined;
  note: string | undefined;
  name: string | undefined;
  text: string | undefined;
  geocodingResultGrade: GeocodingResultGrade;
  externalAddress: string | undefined;


  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.type = payload.type;
      this.country = payload.country;
      this.city = payload.city;
      this.postalCode = payload.postalCode;
      this.street = payload.street;
      this.houseNumber = payload.houseNumber;
      this.lat = payload.lat;
      this.lng = payload.lng;
      this.note = payload.note;
      this.text = payload.text;
      this.geocodingResultGrade = payload.geocodingResultGrade;
      this.externalAddress = payload.externalAddress;
      this.name = payload.name;
    }
  }

  getAddressString() {
    let address = undefined;
    address = this.street? this.street: '';
    address += address? this.houseNumber? ' '+this.houseNumber: '': this.houseNumber? this.houseNumber: '';
    address += address? this.postalCode? ', ' + this.postalCode: '': this.postalCode? this.postalCode: '';
    address += address? this.city? ', ' + this.city: '': this.city? this.city: '';
    address += address? this.country? ', ' + this.country: '': this.country? this.country: '';
    return address;
  }

      /**
   * creates Address from a result
   * @param result Array of any
   * @returns Address
   */
      static createAddresses(result: Array<any>): Address[] {
        let addresses = new Array<Address>();
          if (result) {
            result.map((address) => {
              addresses.push(new Address(address));
            });
          }
          return addresses;
        }
}

export class AddressInput {
  id: string | undefined;
  type: any;
  country: string | undefined;
  city: string | undefined;
  postalCode: string | undefined;
  street: string | undefined;
  houseNumber: string | undefined;
  lat: string | undefined;
  lng: string | undefined;
  note: string | undefined;
  text: string | undefined;
  name: string | undefined;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.type = payload.type;
      this.country = payload.country;
      this.city = payload.city;
      this.postalCode = payload.postalCode;
      this.street = payload.street;
      this.houseNumber = payload.houseNumber;
      this.lat = payload.lat;
      this.lng = payload.lng;
      this.note = payload.note;
      this.text = payload.text;
      this.name = payload.name;
    }
  }
}

export enum AddressClass {
  COORDINATES_ONLY, TEXT_ONLY, COMPLETE
}

export enum AddressType {
  COORDINATES_ONLY, TEXT_ONLY, COMPLETE, PARTIAL
}

export enum AddressSource {
  INTERNAL, EXTERNAL
}

export enum GeocodingResultGrade {
  GREEN, YELLOW, RED
}





