import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-task-list-completed',
  templateUrl: './order-task-list-completed.component.html',
  styleUrls: ['./order-task-list-completed.component.scss']
})
export class OrderTaskListCompletedComponent {

  public displayedColumns: String[] = [
    'type', 'dueDate', 'description', 'completedBy'
  ]
  public states = ['COMPLETED' as TaskState];

  @Input() order: string;
}
