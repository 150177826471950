import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

export const ASSIGNMENT_QUERY = gql`
query AssignmentQuery
{
  locations: getLocations(filter:{}){
    id
    name
    label
  }
}
`;

export interface AssignmentsQueryResponse {
  tasks: Page<Task>;
  loading: boolean;
  locations: LocationEntity[];

}

