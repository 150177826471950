<div class="flex flex-row basis-full items-center tasks" [class.completed]="task?.state === 'COMPLETED'" *ngIf="task?.id">
  <div class="flex flex-col items-center justify-center basis-12 pl-3" >
    <i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i>
  </div>

  <!-- delivery time / customer -->
  <div class="flex justify-items-start flex-col basis-full pr-2 pl-2">
    <div class="flex flex-row row items-center">
      <span class="time noWrap" #time [matTooltipDisabled]="isEllipsisActive(time)" matTooltipPosition="left"
      matTooltipShowDelay="250" matTooltip="{{tasksService.getDeliveryTime(task?.dueDateTimePeriod)}}">
        <span class="deliveryTime" [ngClass]="task?.state">
          <i [className]="'check icon-check'" *ngIf="task?.state === 'COMPLETED'"></i> {{tasksService.getDeliveryTime(task?.dueDateTimePeriod)}}
        </span>
      </span>
      <span class="dueDate pl-1">
        {{tasksService.getDeliveryDateFull(task?.dueDateTimePeriod)}}
      </span>
      <span class="location noWrap" #number
      matTooltipShowDelay="250" matTooltip="{{getAddressToolTip(task?.address)}}">
      &nbsp; &nbsp;– &nbsp; &nbsp;{{task?.address?.postalCode}} {{task?.address?.city}}
    </span>
      <span class="location flex items-center">  </span>
      <mat-icon
        [class.red]="task?.address?.geocodingResultGrade.toString() ==='RED'"
        class="color">
        circle
      </mat-icon>
      <mat-icon
        [class.yellow]="task?.address?.geocodingResultGrade.toString() ==='YELLOW'"
        class="color">
        circle
      </mat-icon>
      <mat-icon
        [class.green]="task?.address?.geocodingResultGrade?.toString() ==='GREEN'"
        class="color">
        circle
      </mat-icon>
    </div>
    <div class="flex flex-row row pl-2">
      <span>
      {{task?.description? task?.description: '-'}}
      </span>
    </div>
    <div class="flex flex-row row">
      <div #dueDate class="contact"
      [matTooltipDisabled]="isEllipsisActive(dueDate)"
      matTooltipPosition="right"
      matTooltipShowDelay="250"
      matTooltip="{{getContactName(task?.order?.customer)}}">
      {{getContactName(task?.order?.customer)? getContactName(task?.order?.customer) : ''}}

      </div>
    <span class="order flex items-center"> &nbsp; &nbsp;– &nbsp; &nbsp;{{task?.order?.number}}</span>
     <i [className]="'icon icon-jump-to'" (click)="goToOrder(task)"></i>
    </div>
  </div>
    <div class="orderInfo flex items-center basis-4 flex-col justify-end pr-2">
      <app-nx-button [icon]="'icon icon-annotation'" (click)="taskInfo(task)"
        [matBadge]="tasksService?.getCommentsNotesCount(task?.order)" matBadgeSize="small"
        [matBadgeHidden]="tasksService?.getCommentsNotesCount(task?.order) === 0" />
    </div>
</div>
