import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { TourState } from './tourState';
import { User } from "@nexato/nx-core-module";
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { Address } from 'src/app/shared-module/shared/entities/address/address';

export class Tour {
  id: string | undefined;
  name: string | undefined;
  driver: User | undefined;
  driverCandidates: User[] | undefined;
  tasks: Task[] | undefined;
  state: TourState | undefined;
  location: LocationEntity | undefined;
  isDeletable: boolean | undefined;
  note: string | undefined;
  scheduledDepartureDateTime: string | undefined;
  actualDepartureDateTime: string | undefined;
  scheduledArrivalDateTime: string | undefined;
  actualArrivalDateTime: string | undefined;
  resource: Resource | undefined;
  effectiveArrivalDateTime: string | undefined;
  effectiveDepartureDateTime: string | undefined;
  dateTimeReference: string | undefined;
  departureAddress: Address | undefined;
  arrivalAddress: Address | undefined;
  possibleStartAddresses: Address[];
  possibleEndAddresses: Address[];
  transportCandidates: Resource[] | undefined;


  constructor(payload?: any ) {
    if (payload) {
      this.id = payload.id;
      this.name = payload.name;
      if (payload.driver) {
        this.driver = new User(payload.driver);
      }
      if (payload.driverCandidates) {
        this.driverCandidates = payload.driverCandidates;
      }
      if (payload.tasks) {
        this.tasks = Task.createTasks(payload.tasks);
      }
      this.state = payload.state;
      if (payload.location) {
       this.location = new LocationEntity(payload.location);
      }
      this.isDeletable = payload.isDeletable;
      this.note = payload.note;
      this.scheduledDepartureDateTime = payload.scheduledDepartureDateTime;
      this.actualDepartureDateTime = payload.actualDepartureDateTime;
      this.scheduledArrivalDateTime = payload.scheduledArrivalDateTime;
      this.actualArrivalDateTime = payload.actualArrivalDateTime;
      if (payload.resource) {
        this.resource = new Resource(payload.resource);
       }
       this.effectiveArrivalDateTime = payload?.effectiveArrivalDateTime;
       this.effectiveDepartureDateTime = payload?.effectiveDepartureDateTime;
       this.dateTimeReference = payload?.dateTimeReference;
       if (payload.arrivalAddress) {
        this.arrivalAddress = new Address(payload.arrivalAddress);
       }
       if (payload.departureAddress) {
        this.departureAddress = new Address(payload.departureAddress);
       }
       if (payload.possibleStartAddresses) {
        this.possibleStartAddresses = Address.createAddresses(payload.possibleStartAddresses);
       }
       if (payload.possibleEndAddresses) {
        this.possibleEndAddresses = Address.createAddresses(payload.possibleEndAddresses);
       }
       if (payload.transportCandidates) {
        this.transportCandidates = payload.transportCandidates;
      }
    }
  }

  public indicator(): string {
    if(this.state === 'FINISHED'){
      return "icon icon-tour-finished";
    }
    if(this.state === 'RUNNING'){
      return "icon icon-tour-started";
    }
    if(this.state === 'PAUSED'){
      return "icon icon-tour-paused";
    }
    if(this.state === 'NEW'){
      return "icon icon-tour-scheduled";
    }
    return "";
  }
  /**
   * creates Tours from a result
   * @param result array of any
   * @returns Array of Tour
   */
  static createTours(result: Array<any>): Tour[] {
    const tours = new Array<Tour>();
    if (result) {
      result.map((tour) => {
        tours.push(new Tour(tour));
      });
    }
    return tours;
  }

  getAssigneeName(){
    // console.log('Tour', tour?.driver.firstName + ' ' + tour?.driver.lastName);
    if(this.driver){
      if (this.driver.firstName && this.driver.lastName) {
        return this.driver.firstName + ' ' + this.driver.lastName;
      } else if (this.driver?.firstName) {
        return this.driver.firstName;
      } else if (this.driver?.lastName){
      }
    }
    return '-';
  }

  getAssigneeNameHeader(){
    // console.log('Tour', tour?.driver.firstName + ' ' + tour?.driver.lastName);
    if(this.driver){
      if (this.driver.firstName && this.driver.lastName) {
        return this.driver.firstName + ' ' + this.driver.lastName;
      } else if (this.driver?.firstName) {
        return this.driver.firstName;
      } else if (this.driver?.lastName){
      }
    }
    return undefined;
  }

}

export class TourInput {
  id: string | undefined;
	locationId: string | undefined;
	assignee: string | undefined;
	note: string | undefined;
	scheduledDepartureDateTime: string | undefined;
	scheduledArrivalDateTime: string | undefined;
  resource: string | undefined;
  dateTimeReference: string | undefined;
  departureAddressId: string | undefined;
  arrivalAddressId: string | undefined;

  constructor(payload?: any ) {
    if (payload) {
      this.id = payload?.id;
      this.locationId = payload?.locationId;
      this.assignee = payload?.assignee;
      this.note = payload?.note;
      this.scheduledDepartureDateTime = payload?.scheduledDepartureDateTime;
      this.scheduledArrivalDateTime = payload?.scheduledArrivalDateTime;
      this.resource = payload?.resource;
      this.dateTimeReference = payload?.dateTimeReference;
      this.arrivalAddressId = payload?.arrivalAddressId;
      this.departureAddressId = payload?.departureAddressId;
    }
  }
}
