import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { RolesService } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements AfterViewChecked, OnInit {

  public loading = false;
  public hasUsersRole: boolean;
  public hasTemplatesRole: boolean;
  public hasGroupsRole: boolean;
  public hasTenantOwnRole: boolean;
  public hasTenantsRole: boolean;
  public tenantName: string;
  public hasLocationsView: boolean;
  public hasDeveloperRole: boolean;
  public hasFormsRole: boolean;
  public env = environment;

  constructor(
    public rolesService: RolesService,
    protected readonly router: Router,
    protected keycloakAngular: KeycloakService,
  ) {
    this.hasUsersRole = rolesService.hasRole('framework_users_manage');
    this.hasTemplatesRole = rolesService.hasRole('framework_templates_manage');
    this.hasGroupsRole = rolesService.hasRole('framework_groups_manage');
    this.hasTenantOwnRole = rolesService.hasRole('framework_tenants_manage_own');
    this.hasTenantsRole = rolesService.hasRole('framework_tenants_manage');
    this.hasLocationsView = rolesService.hasRole('nexcore_location_view');
    this.hasDeveloperRole = rolesService.hasRole('framework_developer_view');
    this.hasFormsRole = rolesService.hasRole('framework_forms_manage');
   }

  ngAfterViewChecked(): void {
    if (this.hasTenantOwnRole && this.router.url == '/settings') {
      this.router.navigate(['settings/owntenant', this.tenantName]);
    }
    else if (this.hasUsersRole && this.router.url == '/settings') {
      // console.log('Router URL', this.router.url)
      this.router.navigate(['settings/users']);
    }
    else if (this.hasTemplatesRole && this.router.url == '/settings') {
      this.router.navigate(['settings/templates']);
    }
    else if (this.router.url == '/settings') {
      this.router.navigate(['settings/tenants']);
    }
  }
  ngOnInit() {
    let key = 'tenant_id';
    this.tenantName = this.keycloakAngular.getKeycloakInstance()
      ? (this.keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';

    console.log('Tenant name', this.tenantName);
  }

}
