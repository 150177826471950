import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoadingService, User } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { merge, tap } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { environment } from 'src/environments/environment';
import { TaskSchedulingDialogComponent } from '../../components/task-scheduling-dialog/task-scheduling-dialog.component';
import { Contact } from '../../shared/entities/contact/contact';
import { DateTimePeriodInput } from '../../shared/entities/dateTimePeriod/dateTimePeriod';
import { Task } from '../../shared/entities/task/task';
import { TasksService } from '../../shared/services/tasks/tasks.service';
import { TaskListDataSource } from './taskListDataSource';
import { TaskAssignmentInput } from '../../shared/entities/taskAssignmentInput/taskAssignmentInput';
import { TaskInfoDialogComponent } from 'src/app/tour-planning/components/task-info-dialog/task-info-dialog.component';
import { TaskFilesDialogComponent } from 'src/app/shared-module/components/task-files-dialog/task-files-dialog.component';

@Component({
  selector: 'app-task-assignment-general',
  templateUrl: './task-assignment-general.component.html',
  styleUrls: ['./task-assignment-general.component.scss'],
})
export class TaskAssignmetGeneralComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  public tasks: Task[];
  public tasksType: string;
  public states: TaskState[];
  public activeTask: Task;
  public isActivetTask: boolean;
  public fileApi = environment.fileApi;
  public dataSource: TaskListDataSource;
  selection = new SelectionModel<Task>(true, []);
  public filterValue = '';
  public date = new Date();
  taskForm: FormGroup;
  public data: any;
  isReload = false;
  selectedLocation: LocationEntity;
  locations: LocationEntity [];
  taskType:string = undefined;
  public displayedColumns: String[];
  public isFirst =true;

  @Input() set settings(settings: any) {
    this.tasksType = settings?.type;
    this.displayedColumns = settings.displayedColumns;
    this.selectedLocation = settings.selectedLocation;
    this.date = settings.date;
    this.states = settings.states;
    this.filterValue = settings.filterValue;
    this.taskType = settings.taskType;
    this.isFirst? this.setTableDataSource(): this.refetchloadTaskList();
    this.isFirst = false;
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) table: MatTable<Task>;
  constructor(
    loadingService: LoadingService,
    private apollo: Apollo,
    public datePipe: DatePipe,
    public taskService: TasksService,
    public matPaginatorIntl: MatPaginatorIntl,
    private fb : FormBuilder,
    private cdref: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog
  ) {
  }


  ngOnInit(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Elemente pro Seite';
    this.matPaginatorIntl.nextPageLabel = 'nächste Seite';
    this.matPaginatorIntl.previousPageLabel = 'vorherige Seite';
    this.matPaginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
    this.taskForm = this.fb.group({
      });

  }

  ngAfterContentChecked() {
    if (!this.data || this.data != this.dataSource?.data) {
      if (this.dataSource?.data) {
        for (let element of this.dataSource.data) {
          this.taskForm.addControl(element?.id, this.fb.control(this.date))
        }
      }
    }
    if (this.isReload) {
      if (this.dataSource?.data) {
        for (let element of this.dataSource.data) {
          this.taskForm.controls[element.id]?.setValue(this.date)
        }
      }
    }
    this.isReload = false;
    this.data = this.dataSource?.data;
    this.cdref.detectChanges();
  }


  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(tap(() => this.refetchloadTaskList()))
      .subscribe();

  }


   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.totalElements;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach((row: Task) => this.selection.select(row));
  }

  setTableDataSource(): void {
    this.dataSource = new TaskListDataSource(this.apollo);
    // const text = this.text ? this.text : '';
    this.dataSource.loadTaskList({
      number: 0,
      size: 15,
      sortProperty: 'id',
      sortDirection: 'asc',
      states: this.states,
      text: this.filterValue,
      fromDateTime: this.date?.toISOString(),
      toDateTime: moment(this.date).add(1, 'days').toDate().toISOString(),
      locationIds: this.selectedLocation,
      types: this.taskType
    });
  }

  assign(): void {
    for(const task of this.selection.selected) {
      let taskAssignmentInput = new TaskAssignmentInput();
      taskAssignmentInput.taskId = task.id;
      let dueDateTimePeriodInput = new DateTimePeriodInput();
      taskAssignmentInput.dueDateTimePeriod = dueDateTimePeriodInput;

      // if we have a suggestion, use the suggestion
      // usally we should have one
      if(task.assignmentSuggestion) {
        taskAssignmentInput.assigneeId = task.assignmentSuggestion.assigneeId;
        taskAssignmentInput.assignmentType = task.assignmentSuggestion.assignmentType;
        dueDateTimePeriodInput.dateTime = task.assignmentSuggestion.dueDateTimePeriod.dateTime;
        dueDateTimePeriodInput.fromDateTime = task.assignmentSuggestion.dueDateTimePeriod.fromDateTime;
        dueDateTimePeriodInput.toDateTime = task.assignmentSuggestion.dueDateTimePeriod.toDateTime;
        dueDateTimePeriodInput.modifier = task.assignmentSuggestion.dueDateTimePeriod.modifier;
      } else {
        // if we do not have a suggestion, default to location assignment and the whole day
        taskAssignmentInput.assignmentType = "LocationTaskAssignmentStrategy";
        let date = this.taskForm.controls[task.id]?.value;
        taskAssignmentInput.dueDateTimePeriod = new DateTimePeriodInput({
          'modifier': 'BETWEEN',
          'fromDateTime': date.toISOString(),
          'toDateTime': moment(date).add(1, 'days').toDate().toISOString()

       });
      }
      this.taskService.assignTask(taskAssignmentInput, () => {
        this.selection.clear();
        this.refetchloadTaskList();
      })
    }
  }

  unassign(): void {
    // console.log('selected', this.selection.selected);
      let taskIds = [];
      for(const task of this.selection?.selected) {
        taskIds.push(task.id);
      }
      this.taskService.unassignTasks(taskIds, () => {
        this.selection.clear();
        this.refetchloadTaskList();
      });
  }


  refetchloadTaskList(): void {
    // console.log('types', this.taskType)
    // const text = this.text ? this.text : '';
    this.dataSource.refetchQuery({
      number: this.paginator?.pageIndex ? this.paginator?.pageIndex : 0,
      size: this.paginator?.pageSize ? this.paginator?.pageSize : 15,
      sortProperty: this.sort?.active ? this.sort.active : 'id',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
      states: this.states,
      text: this.filterValue,
      fromDateTime: this.date?.toISOString(),
      toDateTime: moment(this.date).add(1, 'days').toDate().toISOString(),
      locationIds: this.selectedLocation,
      types: this.taskType
    });
  }


  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect(this.table);
  }

  goToOrder(task: Task){
    // console.log('hier bin ich', task)
    if(task && task.order && task.order.id){
      this.router.navigate(["./orders/order/" + task.order.id]);
    }
  }

  openEditTaskAssignmentDialog(task: Task) {
    const dialogRef = this.dialog.open(TaskSchedulingDialogComponent, {
       width: '765px',
       data: {
         task: task,
       },
       autoFocus: false,
     });
     dialogRef.afterClosed().subscribe( taskAssignment =>{

      if(taskAssignment){
        let taskAssignmentInput = new TaskAssignmentInput();
        taskAssignmentInput.taskId = taskAssignment.taskId;
        taskAssignmentInput.assigneeId = taskAssignment.assigneeId;
        taskAssignmentInput.assignmentType = taskAssignment.assignmentType;
        let dueDateTimePeriodInput = new DateTimePeriodInput();
        dueDateTimePeriodInput.modifier = taskAssignment.dueDateTimePeriod.modifier;
        dueDateTimePeriodInput.fromDateTime = taskAssignment.dueDateTimePeriod.fromDateTime;
        dueDateTimePeriodInput.toDateTime = taskAssignment.dueDateTimePeriod.toDateTime;
        dueDateTimePeriodInput.dateTime = taskAssignment.dueDateTimePeriod.dateTime;
        taskAssignmentInput.dueDateTimePeriod = dueDateTimePeriodInput;
        this.taskService.assignTask(taskAssignmentInput, () => {
          this.refetchloadTaskList();
        })
      }

     })
   }

   getName(user: User): string {
    if (user?.firstName && user?.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName || user?.lastName) {
      return user?.firstName ? user?.firstName : user?.lastName;
    } else {
      return '-'
    }
  }
  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  taskInfo(task: Task) {
    const dialogRef = this.dialog.open(TaskInfoDialogComponent, {
      width: '700px',
      minHeight: '650px',
      data: {
        task: task,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(dialog => {
    })
  }

  taskFiles(task: Task) {
    const dialogRef = this.dialog.open(TaskFilesDialogComponent, {
      width: '700px',
      minHeight:'650px',
      data: {
        task: task,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(dialog=>{
    })
  }
}
