import { Injectable } from '@angular/core';
import * as fromGraphQl from './graphql';
import { ApolloService } from '@nexato/nx-core-module';
import { FormVariantInput } from '../../entities/formVariant/formVariant';

/**
 * The formVariants service.
 */
@Injectable()
export class FormVariantsService {
  constructor(private apolloService: ApolloService) {}

  /**
   * updates a mode
   * @param input formVariant Input
   * @param callback method
   */
  public updateFormVariant(input: FormVariantInput, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UPDATE_FORM_VARIANT_MUTATION,
      {
        input,
      },
      callback
    );
  }

  /**
   * creates a formVariant
   * @param input FormVariant input
   * @param callback method
   */
  public createFormVariant(input: FormVariantInput, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.CREATE_FORM_VARIANT_MUTATION,
      {
        input,
      },
      callback
    );
  }

  /**
   * deletes a formVariant
   * @param id FormVariant Id
   * @param callback method
   */
  public deleteFormVariant(id: string, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.DELETE_FORM_VARIANT_MUTATION,
      {
        id,
      },
      callback
    );
  }
}
