import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { User } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { tap } from 'rxjs';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { Task } from '../../../rent-module/shared/entities/task/task';
import { OrderTaskListDataSource } from './orderTaskListDataSource';


@Component({
  selector: 'app-task-list',
  templateUrl: './order-task-list.component.html',
  styleUrls: ['./order-task-list.component.scss'],
})
export class OrderTaskListComponent implements AfterViewInit
{
  public orderId: string;
  public displayedColumns: String[] =  [];
  public tasks: Task[];
  public states: TaskState[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<Task>;

  public dataSource: OrderTaskListDataSource;

  @Input() set settings(settings: any) {
    this.orderId = settings?.order;
    this.states =settings?.states;
    this.displayedColumns = settings?.displayedColumns;
    this.setTableDataSource();
  }

  constructor(
    private apollo: Apollo,
    public matPaginatorIntl: MatPaginatorIntl,
    private router: Router,
    public taskService: TasksService
  ) {

  }

  ngAfterViewInit(): void {
    this.sort?.sortChange.subscribe();
    this.sort?.sortChange
      .pipe(tap(() => this.refetchloadTaskList()))
      .subscribe();

  }

  setTableDataSource(): void {
    this.dataSource = new OrderTaskListDataSource(this.apollo);
    if(this.orderId) {
      this.dataSource.loadTaskList({
        orderId: this.orderId,
        states: this.states,
        sortProperty: 'id',
        sortDirection: 'asc',
      });
    }
  }


  refetchloadTaskList(): void {
    this.dataSource.refetchQuery({
      orderId: this.orderId,
      states: this.states,
      sortProperty: this.sort?.active ? this.sort.active : 'id',
      sortDirection: this.sort?.direction ? this.sort.direction : 'asc',
    });
  }

  getType(task: Task) {
    switch (task?.type) {
      case 'nexcore_rental_resourceAssignmenmt_out' : return 'Übergabe';
      case 'nexcore_rental_resourceAssignmenmt_in' : return 'Rücknahme';
      case 'nexcore_schedule' : return 'Disposition';
      default: return '';
    }

  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  getToolTip(taskIn: Task) {
      let task = new Task(taskIn);
      let isTime = task?.tour?.effectiveDepartureDateTime || task?.tour?.effectiveArrivalDateTime;
      let datum = task?.tour?.effectiveDepartureDateTime ? moment(new Date(task?.tour.effectiveDepartureDateTime)).format('DD.MM.yyyy') : '-';
      let startTime = task?.tour?.effectiveDepartureDateTime ? moment(new Date(task?.tour.effectiveDepartureDateTime)).format('HH:mm') : '--:--';
      let endTime = task?.tour?.effectiveArrivalDateTime ? moment(new Date(task?.tour.effectiveArrivalDateTime)).format('HH:mm') : '--:--'
      if (isTime) {
        return  task?.tour ?
          (`${task?.tour?.name}
         ${datum} / ${startTime}-${endTime}
         Fahrzeug: ${task?.tour?.resource ? task?.tour?.resource?.name : 'ungeplant'}
         Fahrer: ${task?.tour?.driver ? task?.tour?.getAssigneeName() : 'ungeplant'}`) : '-';
      } else {
        return task?.tour ?
          (`${task?.tour?.name}
         Ziet: ungeplant
         Fahrzeug: ${task?.tour?.resource ? task?.tour?.resource?.name : 'ungeplant'}
         Fahrer: ${task?.tour?.driver ? task?.tour?.getAssigneeName() : 'ungeplant'}`) : '-';
      }
    }

    getCompletedBy(task: Task) {
      let pattern = 'dd.MM.yyyy HH:mm';
      return `${this.getName(task?.completedBy)}
     ${moment(new Date(task?.tour.effectiveDepartureDateTime)).format('DD.MM.yyyy HH:mm')} Uhr`;
    }

    getName(user: User): string {
      if (user?.firstName && user?.lastName) {
        return user.firstName + ' ' + user.lastName;
      } else if (user?.firstName || user?.lastName) {
        return user?.firstName ? user?.firstName : user?.lastName;
      } else {
        return '-'
      }
    }
  }


