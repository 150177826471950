import {gql} from 'apollo-angular';

export const UPDATE_FORM_VARIANT_MUTATION = gql`
mutation updateFormVariant($input: FormVariantInput) {
  updateFormVariant(input: $input) {
    id
  }
}
`;

export const CREATE_FORM_VARIANT_MUTATION = gql`
mutation createFormVariant($input: FormVariantInput) {
  createFormVariant(input: $input) {
    id
  }
}
`;

export const DELETE_FORM_VARIANT_MUTATION = gql`
mutation deleteFormVariant($id: UUID) {
  deleteFormVariant(id: $id)
}
`;
