import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-non-license-page',
  templateUrl: './non-license-page.component.html',
  styleUrls: ['./non-license-page.component.scss']
})
export class NonLicensePageComponent {

  @Input() licenseType: string = 'User-Lizenz';

}
