import {Apollo, QueryRef} from 'apollo-angular';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as fromGraphQl from './graphql';
import { Tour } from '../../shared/entities/tour';
import { Driver } from '../../shared/entities/driver';
import { Address } from 'src/app/shared-module/shared/entities/address/address';
import { ApolloService, RolesService } from '@nexato/nx-core-module';
import { TourService } from '../../shared/services/tour/tour.service';
import { TourSettingsDialogComponent } from '../../components/tour-settings-dialog/tour-settings-dialog.component';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-tours-detail-page',
  templateUrl: './tours-detail-page.component.html',
  styleUrls: ['./tours-detail-page.component.scss']
})
export class ToursDetailPageComponent implements OnInit, OnDestroy {

  public loading = true;
  public tourDetailQuery: QueryRef<fromGraphQl.TourDetailQueryResponse>;
  public supplierDetailQuerySubscription: Subscription;
  public tour: Tour;
  public routeSubscription: Subscription;
  private result: any;
  private id: string;
  public drivers: Driver[];
  public resources: Resource[];
  public homebaseAddresses: Address[];
  public objectChoosen = false;

  public isTourInEditingMode = false;
  public _route: any;
  _sections: any[];
  _sumDistance: string;
  _sumDuration: string;
  _baseDuration: string;
  data: any;

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private apollo: Apollo,
    private apolloService: ApolloService,
    // private entityService: EntityService,
    private tourService: TourService,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    public rolesService: RolesService
  ) {

  }

  ngOnInit() {
    this.routeSubscription = this.route.params.pipe(
      filter(params => params["id"] !== undefined),
    ).subscribe((params: { [x: string]: string; }) => {
      this.id = params["id"]; // (+) converts string 'id' to a number
      this.subscribeToTourDetailsData();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.supplierDetailQuerySubscription.unsubscribe();
  }

  subscribeToTourDetailsData() {
    this.tourDetailQuery = this.apollo.watchQuery<fromGraphQl.TourDetailQueryResponse>({
      pollInterval: environment.pollingIntervall,
      fetchPolicy: 'network-only',
      query: fromGraphQl.TOURS_DETAIL_STANDARD_QUERY,
      variables: {
        tourId: this.id,
      },
      notifyOnNetworkStatusChange: true
    });
    this.supplierDetailQuerySubscription = this.tourDetailQuery.valueChanges.subscribe(
      result => {

        if (result.networkStatus !== 6) {
          this.loading = result.loading;
        }
        if (result.networkStatus === 7) {
          // we´ve received fresh data
          this.result = result.data;
          let areSame = JSON.stringify(this.result) === JSON.stringify(this.data);
          if ( !areSame) {
            // console.log('Not same', areSame);
            this.resources = Resource.createResources(result?.data?.tour?.transportCandidates)
            this.tour = new Tour(result.data.tour);
            this.drivers = Driver.createDrivers(result.data.tour.driverCandidates);
            this.data = this.result;
          }
          this.loading= false;

          // this.vehicles = this.entityService.createVehiclesFromResult(result.data.tour.assignableVehicles);
          // this.homebaseAddresses = this.entityService.createAddressesFromResult(result.data.tour.assignableAddresses);
        }
      },
      () => {
        // restart polling after error
        this.tourDetailQuery = undefined;
        setTimeout( () => this.subscribeToTourDetailsData(), environment.pollingIntervall);
      }
    );

    }

  // tour
  saveTour(tour: Tour) {
    this.isTourInEditingMode = false;
    this.loading = true;
    this.tourService.updateTour(tour, this.tourDetailQuery);
  }

  editTour(event$: boolean) {
    this.isTourInEditingMode = event$;
  }


  changeOrder($event: { task: { id: any; }; newPosition: any; }) { // change the 'order' of the orders
    // this.tourService.changeOrder(this.tour.id, $event.task.id, $event.newPosition, this.tourDetailQuery);
  }

  // route
  routeCalculated(route: any) {
    this._route = route;
    // console.log('This is Route', this._route);
    this._sections = route.sections;

    this._sumDistance = this._sections.reduce( (acc, val) => {
      return acc + val.summary.length / 1000;
    }, 0);
    this._sumDuration = this._sections.reduce( (acc, val) => {
      return acc + val.summary.duration / 60;
    }, 0);
    this._baseDuration = this._sections.reduce( (acc, val) => {
      return acc + val.summary.baseDuration / 60;
    }, 0);
    this.cdr.detectChanges();
  }

  goBack() {
    // window.history.back();
    this.location.back();
  }

  openSettings(tour: Tour) {
    if (tour.state !== 'FINISHED') {
      this.tourDetailQuery?.stopPolling();
      const dialogRef = this.dialog.open(TourSettingsDialogComponent, {
        width: '900px',
        data: {
          tour: tour,
          // resources: this.resources,
          // assignees: this.drivers
        },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((dialog: { model: any; tour: any; })=>{
        if (dialog?.model) {
          this.saveSettings(dialog?.model);
        }
        if (dialog?.tour) {
          this.deleteTour(tour);
        }

        if (!dialog?.model && !dialog?.tour) {
          this.tourDetailQuery?.startPolling(environment.pollingIntervall);
        }

      });
    }
   }

   saveSettings(event: any) {
    // console.log('settings', event);
    this.loading = true;
    this.tourService.updateTourInput(event, this.tourDetailQuery);
    this.refetchQuery();
    this.loading = false;

  }
  deleteTour(tour: Tour) {
    this.loading = true;
    this.tourService.deleteTour(tour, this.tourDetailQuery);
    this.router.navigate(["/tour-planner"])
  }

  refetchQuery() {
    this.tourDetailQuery?.startPolling(environment.pollingIntervall);

  }
  getHeader(): string{
      return `${this.tour?.name? this.tour?.name: '–'} / ${(this.tour?.dateTimeReference ? (moment(new Date(this.tour.dateTimeReference)).format('DD.MM.yyyy')): '–' )}
      / ${(this.tour?.location?.name? this.tour?.location?.name: '–')}`;
  }
  getSubHeader(): string {
    return  `( ${(this.tour?.resource?.name? this.tour?.resource?.name: 'kein Fahrzeug geplant')} /
         ${ (this.tour?.getAssigneeNameHeader()? this.tour?.getAssigneeName(): 'kein Fahrer geplant') } )`;
  }

  toLetters(num: number): string {
    "use strict";
    var mod = num % 26,
        pow = num / 26 | 0,
        out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
    return pow ? this.toLetters(pow) + out : out;
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }
}
