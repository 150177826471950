import { Apollo } from 'apollo-angular';
import { Component, AfterViewInit, ChangeDetectorRef, ViewChild, OnDestroy, OnInit } from '@angular/core';
import * as fromGraphQl from './graphql';
import { ComponentState, Container, LoadingService, PersistenceScope, StateService, StateVar } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { DatePipe } from '@angular/common';


interface DashboardComponentState{
  selectedLocationDashboard: string,
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends Container<fromGraphQl.DashboardQueryResponse> implements OnInit,OnDestroy{

  @ViewChild('drawerRight') drawerRight: any;
  public date = new Date();
  initialDate = new Date();
  locations: LocationEntity [];
  selectedLocation: LocationEntity;
  public componentStateCount = 0;

  componentState: ComponentState<DashboardComponent>;
  selectedLocationDashboard: StateVar<string>;
  constructor(
    apollo: Apollo,
    loadingService: LoadingService,
    private datePipe: DatePipe,
    stateService: StateService,
    private cdRef:ChangeDetectorRef
  ) {
    super(apollo, loadingService, fromGraphQl.DASHBOARD_QUERY, environment);
    this.componentState = new ComponentState<DashboardComponent>("DashboardComponent", stateService, apollo);
    this.selectedLocationDashboard = new StateVar({
      key: "selectedLocationDashboard",
      initialValue: this.selectedLocation?.id,
      persistenceScope: PersistenceScope.Backend
    });
    this.componentState.addStateVar(this.selectedLocationDashboard);
    this.subscribeToQuery();
  }

  ngOnInit() {
    this.componentState.watch( (state:any) => {
      if (this.componentStateCount > 0) {
          this.selectedLocationDashboard.currentValue = state[this.selectedLocationDashboard.key];
          if (this.selectedLocationDashboard?.currentValue) {
            this.selectedLocation = this.locations?.find((o: any) => o?.id === this.selectedLocationDashboard.currentValue);
          }
          this.cdRef.detectChanges();
         }
      });
      this.componentStateCount++;
  }

  handleSubscriptionResult(data: fromGraphQl.DashboardQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data.locations) {
      this.queryRef.stopPolling();
      this.locations = LocationEntity.createLocations(data?.locations);
      this.selectedLocation = this.locations? this.locations[0] : null;
    }
  }

  selectLocation(){
    this.componentState.update(this.selectedLocationDashboard,  this.selectedLocation?.id);
    // this.cdRef.detectChanges();
  }

  dateChanged($event: any){
    this.date = $event;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
