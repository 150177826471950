<div class="tasks-list">
  <mat-accordion  #accordion="matAccordion" [multi]="false">
    <mat-expansion-panel [expanded]="true" hideToggle (opened)="panelOpenState[0] = true;" (closed)="panelOpenState[0] = false;">
      <mat-expansion-panel-header class="border-solid border-b border-t border-gray-300" >
        <div class="flex flex-row basis-full items-center">
          <div class="flex basis-1/2 items-center">
              <i [className]="'icon icon-pending_circle'"></i>Offen {{'(' + tasksCountUnAssigned + ')'}}
          </div>
          <div class="flex justify-end basis-1/2">
            <i [className]="'icon icon-open-down'" *ngIf="!panelOpenState[0]"></i>
            <i [className]="'icon icon-close-up'" *ngIf="panelOpenState[0]"></i>
          </div>
        </div>
      </mat-expansion-panel-header>
      <app-order-task-list-unassigned class="tasks" [order]="order"></app-order-task-list-unassigned>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="false" hideToggle (opened)="panelOpenState[1] = true;" (closed)="panelOpenState[1] = false;">
      <mat-expansion-panel-header class="border-solid border-b border-t border-gray-300" >
        <div class="flex flex-row basis-full items-center">
          <div class="flex basis-1/2 items-center">
            <i [className]="'icon icon-time'"></i>Disponiert {{'(' + tasksCountAssigned + ')'}}
          </div>
          <div class="flex justify-end basis-1/2">
            <i [className]="'icon icon-open-down'" *ngIf="!panelOpenState[1]"></i>
            <i [className]="'icon icon-close-up'" *ngIf="panelOpenState[1]"></i>
          </div>
        </div>
      </mat-expansion-panel-header>
      <app-order-task-list-assigned class="tasks" [order]="order"></app-order-task-list-assigned>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="false" hideToggle (opened)="panelOpenState[2] = true;" (closed)="panelOpenState[2] = false;">
      <mat-expansion-panel-header class="border-solid border-b border-t border-gray-300" >
        <div class="flex flex-row basis-full items-center">
          <div class="flex basis-1/2 items-center basis-1/2">
            <i [className]="'icon icon-check-circle'"></i>Abgeschlossen {{'(' + tasksCountCompleted + ')'}}
          </div>
          <div class="flex justify-end basis-1/2">
            <i [className]="'icon icon-open-down'" *ngIf="!panelOpenState[2]"></i>
            <i [className]="'icon icon-close-up'" *ngIf="panelOpenState[2]"></i>
          </div>
        </div>
      </mat-expansion-panel-header>
      <app-order-task-list-completed class="tasks" [order]="order"></app-order-task-list-completed>
    </mat-expansion-panel>
  </mat-accordion>
</div>

