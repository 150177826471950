<div class="flex flex-row pr-5 pl-4 items-center" [ngStyle]="{'background-color': headerColor}" [ngClass]="showButtons? 'border-t': ''">
  <span *ngIf="header" class="basis-1/2">{{header}}</span>
  <span class="flex justify-end" [ngClass]="header? 'basis-1/2': 'basis-full'">
    <app-nx-button (click)="createComment()" *ngIf="showButtons" toolTip="Kommentar hinzufügen" icon="icon  icon-comment-add">
    </app-nx-button>
  </span>
</div>

<div [hidden]="dataSource?.loading| async">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="text">
      <!-- <mat-header-cell *matHeaderCellDef fxFlex="40%" class="header_column table_column">Name</mat-header-cell> -->
      <mat-cell *matCellDef="let comment">
        <div style="width: 100%;">
          <div class="edited pt-2.5">
            {{comment?.lastModifiedDateTime  | date:'dd.MM.YYYY HH:mm'}} Uhr  ({{getName(comment?.lastModifiedBy)}})
          </div>
          <div class="comment flex flex-row">
            <span class="flex basis-4/5">{{comment?.text}}</span>
            <span class="flex basis-1/5 justify-end"><i [className]="'icon icon-options'" [matMenuTriggerFor]="menu" *ngIf="showButtons"></i></span>
          </div>
        </div>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editComment(comment)">
            <mat-icon class="icon-edit"></mat-icon>
            <span>Kommentar bearbeiten</span>
          </button>
           <!--<button mat-menu-item>Öffentlichen Link kopieren</button> -->
          <button mat-menu-item (click)="confirmDialog(comment)">
            <mat-icon class="icon-trash"></mat-icon>
            <span>Kommentar löschen</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row">
    </mat-row>
    <div *matNoDataRow class="no-data">
      <p>keine Kommentare vorhanden</p>
    </div>
  </mat-table>
</div>
<div *ngIf="dataSource?.loading | async" class="spinner">
  <mat-spinner [diameter]="30"></mat-spinner>
</div>
<div class="footer mt-2" [hidden]="!dataSource?.totalElements || dataSource?.totalElements< 6">
  <div class="pagination">
    <mat-paginator [length]="dataSource?.totalElements" [pageSize]="5" [pageIndex]="0"
    [pageSizeOptions]="[5, 10, 15, 20]" aria-label="Select page" class="paginator" [hidden]="!dataSource?.totalElements || dataSource?.totalElements< 6"></mat-paginator>
  </div>
</div>
