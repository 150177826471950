import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';
import { Protocol } from 'src/app/rent-module/shared/entities/protocol/protocol';

@Component({
  selector: 'app-protocol-side-by-side-view, [app-protocol-side-by-side-view]',
  templateUrl: './protocol-side-by-side-view.component.html',
  styleUrls: ['./protocol-side-by-side-view.component.scss']
})
export class ProtocolSideBySideViewComponent {

  referenceFormTemplate: ControlTemplate;
  referenceBodyControlTemplates: ControlTemplate[];
  referenceCompletionControlTemplates: ControlTemplate[];

  _leftProtocol: Protocol;
  leftFormTemplate: ControlTemplate
  leftBodyControlTemplates: ControlTemplate[];
  leftCompletionControlTemplates: ControlTemplate[];
  @Input() set leftProtocol(protocol: Protocol) {
    if (protocol) {
      // console.log(protocol);
      this._leftProtocol = protocol;
      this.leftFormTemplate = protocol.formControlTemplate;
      this.leftBodyControlTemplates = protocol.formControlTemplate?.body[0].controls;
      this.leftCompletionControlTemplates = protocol.formControlTemplate?.completion.controls;
      this.referenceFormTemplate = protocol.formControlTemplate;
      this.referenceBodyControlTemplates = protocol.formControlTemplate?.body[0].controls;
      this.referenceCompletionControlTemplates = protocol.formControlTemplate?.completion.controls;
    }
  }

  _rightProtocol: Protocol;
  rightFormTemplate: ControlTemplate;
  rightBodyControlTemplates: ControlTemplate[];
  rightCompletionControlTemplates: ControlTemplate[];
  @Input() set rightProtocol(protocol: Protocol) {
    if (protocol) {
      this._rightProtocol = protocol;
      this.rightFormTemplate = protocol.formControlTemplate;
      this.rightBodyControlTemplates = protocol.formControlTemplate?.body[0].controls;
      this.rightCompletionControlTemplates = protocol.formControlTemplate?.completion.controls;
      this.referenceFormTemplate = protocol.formControlTemplate;
      this.referenceBodyControlTemplates = protocol.formControlTemplate?.body[0].controls;
      this.referenceCompletionControlTemplates = protocol.formControlTemplate?.completion.controls;
    }
  }

  /**
   * Checks, if a row should be shown. A row should be shown, if
   * either the left or the right controlTemplate is set to show = true.
   * This method can be used to check section rows or control rows.
   *
   * @param leftControlTemplate
   * @param rightControlTemplate
   * @returns true, if the row should be shown, false otherwise
   */
  shouldShowRow(leftControlTemplate: ControlTemplate, rightControlTemplate: ControlTemplate): boolean {
    if(leftControlTemplate?.settings?.show || rightControlTemplate?.settings?.show){
      return true
    }
    return false;
  }
}
