import { Apollo } from 'apollo-angular';
import * as fromGraphQl from './graphql';
import { NXDataSource } from '@nexato/nx-core-module';
import { environment } from 'src/environments/environment';


export class OrderCommentListDataSource extends NXDataSource<Comment> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.COMMENTS_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.CommentsQueryResponse): void {
    super.setSubject(
      data.comments?.content,
      data.comments?.totalElements ? data.comments.totalElements : 0
    );
  }

  loadCommentList(variables: any): void {
    this.subscribeToQuery(variables);
  }
}
