<h1 mat-dialog-title>Zuweisung bearbeiten</h1>
<mat-dialog-content class="flex felx-col" [formGroup]="taskAssignmentForm">

  <!-- Selector for the strategy - currently we offer here "location" only - later we offer user and so on-->
  <div class="flex flex-row gap-5 white">
    <mat-form-field class="basis-full">
      <mat-label>Typ</mat-label>
      <mat-select formControlName="assignmentType">
        <mat-option *ngFor="let assignmentStrategyCandidate of task?.assignmentStrategyCandidates" [value]="assignmentStrategyCandidate.type">
          {{ assignmentStrategyCandidate.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- form for the strategy - gets dynamically rendered -->
  <ng-container *ngFor="let control of getControlsForCurrentlySelectedTasAssignmentStrategy()">
    <div class="flex flex-row gap-5 white">
        <ng-container [ngSwitch]="control.type">
            <mat-form-field class="basis-full" *ngSwitchCase="'selectInput'">
              <mat-label>{{control?.settings?.label}}</mat-label>
              <mat-select formControlName="assigneeId">
                <mat-option *ngFor="let option of control?.settings?.options" [value]="option.id">
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <div *ngSwitchDefault>Error: {{control | json}}</div>
        </ng-container>
    </div>
  </ng-container>

  <!-- selector for dueDateTime: currently this is hardcoded, since the app is completely based on due dates
      it could be that, in future, we allow task assignments without due dates, then we must include this 
      in the dynamic form
    -->
  <div class="flex flex-row gap-5 white">
    <app-date-time-period-form-control formControlName="dueDateTimePeriod" class="basis-full"></app-date-time-period-form-control>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex flex-row basis-full">
    <div class="basis-1/2">
      <button mat-button (click)="assign()" [disabled]="!taskAssignmentForm?.valid">Zuweisen</button>
      <button mat-button (click)="abort()">Abbrechen</button>
    </div>
  </div>
</mat-dialog-actions>
